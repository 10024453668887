import React, { useState, useEffect } from "react";
import CreateOrder from "./CreateOrder";
import EditOrder from "./EditOrder";
import OrderList from "./OrderList";

const OrderDetails = (props) => {
  const [childNav, setChildNav] = useState("orders");
  const { orderDetailToggle, handleBreadCrumInAdmin } = props;

  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    if (childNav !== "orders") {
      setChildNav("orders");
    }
  }, [orderDetailToggle]);

  useEffect(() => {
    handleBreadCrumInAdmin("Order Details");
  }, []);

  /**
   * This function is reponsible for render diffrent HTML
   * @param {string} navBarValue
   * @param {string} orderId
   */
  const UpdateChildNav = (navBarValue, orderId) => {
    if (orderId) {
      setOrderId(orderId);
    }
    setChildNav(navBarValue);
  };

  const getOrderPage = (navBar) => {
    switch (navBar) {
      case "orders":
        return (
          <OrderList dataFromParent={props} UpdateChildNav={UpdateChildNav} />
        );
      case "create order":
        return <CreateOrder UpdateChildNav={UpdateChildNav} />;
      case "edit order":
        return (
          <EditOrder
            dataFromParent={props}
            UpdateChildNav={UpdateChildNav}
            orderId={orderId}
          />
        );
      default:
        return "";
    }
  };

  const showOrderPage = getOrderPage(childNav);

  return <div>{showOrderPage}</div>;
};

export default OrderDetails;
