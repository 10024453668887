import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { faBars, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import AssignmentIcon from "@mui/icons-material/AssignmentOutlined";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUserOutlined";
import ArticleIcon from "@mui/icons-material/ArticleOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFileOutlined";
import AssessmentIcon from "@mui/icons-material/AssessmentOutlined";
import SummarizeIcon from "@mui/icons-material/SummarizeOutlined";
import { Button } from "../../components/Common";
import * as userPermission from "../Utility/userPermission.js";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: true,
      isMobile: window.innerWidth,
      userInfo: {
        rolePermission: { permissions: [] },
      },
      isAcgrUser: false,
    };
  }

  componentDidMount(props) {
    let permission = [];
    this.props.userInfo.rolePermission &&
      this.props.userInfo.rolePermission.permissions.forEach((option) => {
        permission = [...permission, option.permission];
      });
    this.setState({
      userInfo: this.props.userInfo,
      permission: permission,
      isAcgrUser: this.props.userInfo
        ? this.props.userInfo.tenant?.isSystemTenant
        : false,
    });
  }

  getCustomIconClass = () => {
    if (document.URL.includes("main/admin")) {
      return { color: "#1665D8" };
    } else {
      return { color: "#9ea0a5" };
    }
  };
  handleSideBarToggle = () => {
    this.setState((prevState) => ({
      sidebarOpen: !prevState.sidebarOpen,
    }));
  };

  render(props) {
    let url = this.props.match.url;
    let permission = [];
    this.props.userInfo.rolePermission &&
      this.props.userInfo.rolePermission.permissions.forEach((option) => {
        permission = [...permission, option.permission];
      });
    const { t } = this.props;
    const adminLink =
      this.props.userInfo.rolePermission &&
      userPermission.getAdminRoute(
        this.props.userInfo.rolePermission.permissions
      );
    let sidebarIcon = this.state.sidebarOpen ? (
      <FontAwesomeIcon className="arrow_btn" icon={faChevronLeft} />
    ) : (
      <FontAwesomeIcon icon={faBars} />
    );

    return (
      <div
        className={`list--item ${
          this.state.sidebarOpen ? " sidebar open" : "sidebar"
        } ${this.state.isMobile < 600 ? "sidebarclosed" : ""}`}
      >
        <div className="text-right mt-2 mb-2" style={{ marginRight: "-15px" }}>
          <Button
            variant="primary"
            className="btn-rounded btn-sm buttontoggle"
            onClick={() => this.handleSideBarToggle()}
            iconButton={true}
          >
            {sidebarIcon}
          </Button>
        </div>
        <div id="sidebar" className="sidebar-wrapper">
          <div className="flex-column nav">
            {permission !== undefined &&
            permission.indexOf("tenant.project.detail.read") >= 0 ? (
              <NavLink to={`${url}/dashboard`} className="">
                <HomeIcon
                  {...props}
                  className={
                    document.URL.includes("main/dashboard")
                      ? { color: "#1665D8" }
                      : { color: "#9ea0a5" }
                  }
                />{" "}
                <span className="sidebar-text"> {t("Dashboard")} </span>{" "}
              </NavLink>
            ) : (
              ""
            )}
            {permission !== undefined &&
            permission.indexOf("tenant.project.detail.read") >= 0 ? (
              <NavLink to={`/main/project`} className="">
                <AssignmentIcon
                  {...props}
                  className={
                    document.URL.includes("main/project")
                      ? { color: "#1665D8" }
                      : { color: "#9ea0a5" }
                  }
                />
                <span className="sidebar-text"> {t("Projects")} </span>
              </NavLink>
            ) : (
              ""
            )}
            {permission !== undefined &&
            (permission.indexOf("tenant.read") >= 0 ||
              permission.indexOf("callcenter.read") >= 0 ||
              permission.indexOf("tenant.users.read") >= 0 ||
              permission.indexOf("tenant.roles.permissions.read") >= 0) ? (
              <NavLink
                exact
                to={adminLink}
                className={
                  this.props.location.pathname.includes("/admin")
                    ? "active"
                    : ""
                }
              >
                <VerifiedUserIcon
                  {...props}
                  className={this.getCustomIconClass()}
                />
                <span className="sidebar-text"> {t("Admin")}</span>
              </NavLink>
            ) : (
              ""
            )}
            {this.state.isAcgrUser ? (
              <NavLink to={`/main/reports`} className="">
                <ArticleIcon
                  {...props}
                  className={
                    document.URL.includes("main/reports")
                      ? { color: "#1665D8" }
                      : { color: "#9ea0a5" }
                  }
                />{" "}
                <span className="sidebar-text"> {t("Reports")} </span>
              </NavLink>
            ) : (
              ""
            )}
            {permission !== undefined &&
            permission.indexOf("tenant.document.read") >= 0 ? (
              <NavLink to={`/main/documents`} className="">
                <UploadFileIcon
                  {...props}
                  className={
                    document.URL.includes("main/documents")
                      ? { color: "#1665D8" }
                      : { color: "#9ea0a5" }
                  }
                />{" "}
                <span className="sidebar-text"> {t("Documents")} </span>
              </NavLink>
            ) : (
              ""
            )}
            {this.state.isAcgrUser ? (
              <NavLink to={`/main/analytics-dashboard`} className="">
                <AssessmentIcon
                  {...props}
                  className={
                    document.URL.includes("/main/analytics-dashboard")
                      ? { color: "#1665D8" }
                      : { color: "#9ea0a5" }
                  }
                />{" "}
                <span className="sidebar-text"> {t("Analytics")} </span>
              </NavLink>
            ) : null}
            {userPermission.isAnalyticsReportPermission() ? (
              <NavLink to={`/main/analytic-reports`} className="">
                <SummarizeIcon
                  {...props}
                  className={
                    document.URL.includes("/main/analytic-reports")
                      ? { color: "#1665D8" }
                      : { color: "#9ea0a5" }
                  }
                />{" "}
                <span className="sidebar-text"> {t("ANALYTIC_REPORTS")} </span>
              </NavLink>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Sidebar));
