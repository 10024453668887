import React, { useState, useEffect } from "react";
import { Prompt, useHistory,useLocation } from "react-router-dom";
import CustomPromptModal from "./CustomPromptModal";
const CustomPrompt = ({ dirty }) => {
  const [showModal, setShowModal] = useState(false);

  const [navigationPath, setNavigationPath] = useState("/");

  const [dirtyCopy, setDirtyCopy] = useState(false);

  const routeHistory=useHistory();

  useEffect(() => {
    setDirtyCopy(dirty);
  }, [dirty]);

  const handleShowModal = (location) => {
    setNavigationPath(location.pathname);
    setShowModal(true);
  };

  const onConfirm = () => {
    setShowModal(false);
    setDirtyCopy(false);
    setTimeout(() => {
      routeHistory.push(navigationPath);
    }, 500);
  };

  const handleRouteChange = (location, action) => {
    if (action !== "POP") {
      if (dirtyCopy) {
        handleShowModal(location);
        return false;
      }
      return true;
    }
  };

  return (
    <div>
      <Prompt when={dirtyCopy} message={handleRouteChange} />
      {showModal && (
        <CustomPromptModal
          showModal={showModal}
          hideModal={() => setShowModal(false)}
          onConfirm={onConfirm}
        />
      )}
    </div>
  );
};

export default CustomPrompt;
