import {INTERVIEW_LIST,INTERVIEW_REVIEW,IS_UPLOADED, TOTAL_INTERVIEW} from '../constants'



let initialList={
    interviewlist: [],
    totalRecords: 0
}

export default (state=initialList,action)=>{
    switch(action.type){
        case INTERVIEW_LIST:
            //return[...action.payload]
            return {
                interviewlist: action.payload.calls,
                totalRecords: action.payload.totalRecords,
             }
        case INTERVIEW_REVIEW:
            return action.payload
         case IS_UPLOADED:
             //let temp = state.interview
             //temp['uploadStatus']=action.payload
            return Object.assign({}, state, {
                ['uploadStatus']:action.payload
              });    
            
        default:
            return state
    }
}