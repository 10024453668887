import { Server_Restriction } from "../config";

// accept an object and return fiterd object on the basis of 2nd argumnet
export const filterJson = (jsonObject, objKey) => {
  var filtered = jsonObject.filter(function (item) {
    return item.attributeType === objKey;
  });
  return filtered;
};

// To check login or not
export const isLoggedIn = () => {
  let token = localStorage.getItem("token");
  return token ? true : false;
};

// scroll window to top
export const scrollTopWindow = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

// sort droopdown alphabetical order based on dropdown label value
export const sortByLabel = (a, b) => {
  if (a.label.toLowerCase() > b.label.toLowerCase()) {
    return 1;
  }
  if (a.label.toLowerCase() < b.label.toLowerCase()) {
    return -1;
  }
  return 0;
};

export const getlanguageIdFromcode = (language) => {
  let languageId;
  switch (language) {
    case "en":
      languageId = 1;
      break;
    case "es":
      languageId = 2;
      break;
    case "tl":
      languageId = 3;
      break;
    default:
      languageId = 1;
  }
  return languageId;
};

export const getlanguageId = (language) => {
  switch (language) {
    case "en":
      return 1;
    case "es":
      return 2;
    case "tl":
      return 3;
    default:
      return 1;
  }
};

export const showRestrictionsField = (props) => {
  const isSystemTenant =
    props.dataFromParent?.statedata?.userInfo?.tenant?.isSystemTenant;

  const roleName =
    props.dataFromParent?.statedata?.userInfo?.rolePermission?.roleName;

  if (
    roleName === "Customer Admin" &&
    (Server_Restriction === "UK" || Server_Restriction === "US")
  ) {
    return true;
  }
  return (
    (Server_Restriction === "UK" || Server_Restriction === "US") &&
    isSystemTenant
  );
};

export const showPrivilegeField = (props) => {
  const isSystemTenant =
    props.dataFromParent?.statedata?.userInfo?.tenant?.isSystemTenant;

  return (
    (Server_Restriction === "UK" || Server_Restriction === "US") &&
    isSystemTenant
  );
};

export const getRiskLevelDefault = (str) => {
  const riskLevels = {
    "High Risk": { color: "HR", color_text: "HR" },
    Red: { color: "R", color_text: "R" },
    "Potential Risk": { color: "PR", color_text: "PR" },
    "Average Risk": { color: "AR", color_text: "AR" },
    "Low Risk": { color: "LR", color_text: "LR" },
    Green: { color: "G", color_text: "G" },
    "Non-Compliant": { color: "NC", color_text: "NC" },
    Disconnected: { color: "DC", color_text: "DC" },
    "Non-Responsive": { color: "NP", color_text: "NP" },
    Admission: { color: "AD", color_text: "AD" },
    "Counter Measure": { color: "CM", color_text: "CM" },
    default: { color: "default", color_text: "D" },
  };

  return riskLevels[str] || riskLevels["default"];
};

export const findTemplateRiskLevelFlag = (data, type, key) => {
  var flagResponse;
  if (data && data[type] && data[type].hasOwnProperty(key)) {
    flagResponse = data[type][key];
  }
  return flagResponse;
};
