import React, { createContext, useReducer, useContext } from "react";
import { reportReducer } from "./reducer";
import { initState } from "./init";

const ReportContext = createContext();

export const ReportProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reportReducer, initState);

  return (
    <ReportContext.Provider value={{ state, dispatch }}>
      {children}
    </ReportContext.Provider>
  );
};

export const useReportContext = () => {
  const context = useContext(ReportContext);
  if (!context) {
    throw new Error("useReportContext must be used within a ReportProvider");
  }
  return context;
};
