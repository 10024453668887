import "./main.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router } from "react-router-dom";
import { applyMiddleware, compose, createStore } from "redux";
import { Provider } from "react-redux";
import { thunk } from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { ApiInterceptor } from "./Services/api";
import { ReportApiInterceptor } from "./Services/reportApi";
import reducer from "./store/reducers/index";
import AuthProvider from "./useAuth0/AuthProvider";
import App from "./App/App";
import "./index.css";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "15px",
          paddingRight: "15px",
        },
      },
    },
    MuiGrid2: {
      styleOverrides: {
        root: {
          paddingLeft: "0px",
          paddingRight: "0px",
        },
        item: {
          paddingLeft: "15px",
          paddingRight: "15px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "2px 6px 15px -7px rgba(0, 0, 0, 0.2)",
          borderRadius: "5px",
          marginBottom: "20px",
        },
      },
    },
  },
});

const persistor = persistStore(store);
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Router>
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <CssBaseline />
      <AuthProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ApiInterceptor>
              <ReportApiInterceptor>
                <App />
              </ReportApiInterceptor>
            </ApiInterceptor>
          </PersistGate>
        </Provider>
      </AuthProvider>
    </ThemeProvider>
  </Router>
);
