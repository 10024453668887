import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Box } from "@mui/material";
import { useFormikContext, ErrorMessage } from "formik";
import { CustomGrid } from "../../CustomTable";
import { useReportContext } from "../context/ReportContext";
import { Date_Format } from "../../../config/index.js";
import { transformString, generateRandomNumber } from "../../../shared/utils";

const DATE_TIME_FORMAT = "DD-MMM-YYYY-hh-mm-ss";

const ReportTypeStep = ({ handleDisable }) => {
  const { t } = useTranslation();
  const { state: reportState } = useReportContext();
  const { values, setFieldValue } = useFormikContext();

  const {
    reportTypeList: { data, loading },
  } = reportState;
  const [pagination, setPagination] = useState({
    currentPage: 0,
    perPageCount: 10,
  });

  const result =
    (data && data.find((item) => item.table.key === values.reportType)) || "";

  const [selectedRow, setSelectedRow] = useState([result.last_updated]);

  const onChangeRecords = (page) => {
    setPagination({
      ...pagination,
      currentPage: page.page,
      perPageCount: page.pageSize,
    });
  };

  useEffect(() => {
    handleDisable(selectedRow.length === 0);
  }, [selectedRow]);

  const columns = [
    {
      field: "table_name",
      headerName: t("TABLE_NAME"),
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <span>{params.row?.table?.name}</span>
          </>
        );
      },
    },
    {
      field: "description",
      headerName: t("Description"),
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <span>{params.row?.table?.description}</span>
          </>
        );
      },
    },
    {
      field: "last_updated",
      headerName: t("LAST_UPDATE"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row?.last_updated
          ? moment(params.row.last_updated).format(`${Date_Format} HH:mm:ss`)
          : "Invalid Date";
      },
    },
  ];

  const handleRowSelection = (selectedValue) => {
    const result =
      data && data.find((item) => item.last_updated === selectedValue[0]);
    setSelectedRow(selectedValue);
    setFieldValue("reportType", result?.table?.key);
    const transformedTitle = transformString(result?.table?.name);
    const latestDateTIME = moment().format(DATE_TIME_FORMAT);
    const randomNumber = generateRandomNumber();
    setFieldValue(
      "reportName",
      `${transformedTitle}_${latestDateTIME}_${randomNumber}`
    );
    if (selectedValue.length > 0) {
      handleDisable(false);
    }
  };

  return (
    <div className="content-max-width-stepper">
      <Box sx={{ mb: 2 }}>
        <div className="dialog-step-info">{t("SELECT_A_REPORT_TYPE_INFO")}</div>
      </Box>
      <div className="table_wrapper custom_table_container app-table table-row-cursor-selectable">
        <CustomGrid
          data={data || []}
          columns={columns || []}
          getRowId={(row) => row.last_updated}
          perPageCount={pagination.perPageCount}
          currentPage={pagination.currentPage}
          totalPages={data?.length}
          isServerPagination={false}
          onChangeRecords={onChangeRecords}
          loading={loading}
          onRowSelectionModelChange={handleRowSelection}
          selectionModel={selectedRow}
          disableMultipleRowSelection={true}
        />
      </div>
      <div className="error">
        <ErrorMessage name="reportType" />
      </div>
    </div>
  );
};

export default ReportTypeStep;
