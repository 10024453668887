import { sortByLabel, createDropdownOption } from "../Helper";
/**
 * This function will return all dropdown option for ISW accordian
 * @param {object} state
 * @returns
 */
const ISWOptions = (state) => {
  let irnVerificationMethodsOption = state.irnVerificationMethodsA
    .map((option) => {
      return createDropdownOption(option);
    })
    .sort(sortByLabel);

  return {
    irnVerificationMethodsOption,
  };
};

export default ISWOptions;
