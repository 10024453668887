import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  MenuItem,
  FormControl,
  Select,
  Chip,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { Field, ErrorMessage } from "formik";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function SelectBox({
  options,
  values,
  fieldName,
  fieldLabel,
  required,
  className,
  multiSelect = false,
  chip = false,
  isCheckbox = false,
  isAdditionalFlag = false,
  handleChange,
}) {
  const { t } = useTranslation();
  const renderSelectedValues = (selected) => {
    switch (true) {
      case multiSelect && chip:
        return (
          <Box className="multiple-select-chip-render-cell" sx={{ gap: 0.5 }}>
            {selected.map((value) => {
              const option = options.find((opt) => opt.value === value);
              return (
                <Chip
                  className="selected-value-chip"
                  key={value}
                  size="small"
                  label={option ? option.label : value}
                />
              );
            })}
          </Box>
        );

      case multiSelect && !chip && !isCheckbox:
        return selected
          .map((value) => {
            const option = options.find((opt) => opt.value === value);
            return option ? option.label : value;
          })
          .join(", ");

      case multiSelect && isCheckbox:
        return selected
          .map((value) => {
            const option = options.find((opt) => opt.value === value);
            return option ? option.label : value;
          })
          .join(", ");

      case !multiSelect:
        const option = options.find((opt) => opt.value === selected);
        return option ? option.label : selected;

      default:
        return null;
    }
  };

  return (
    <FormControl
      size="medium"
      className={`multiple-select-chip ${className}`}
      sx={{ m: 1, width: "100%" }}
    >
      <label
        className={`mb-2 ${required ? "required" : ""}`}
        htmlFor={`demo-multiple-chip-${fieldName}`}
      >
        {fieldLabel}
      </label>

      <Field
        as={Select}
        labelId={`demo-multiple-chip-${fieldName}`}
        id={`demo-multiple-chip-${fieldName}`}
        name={fieldName}
        multiple={multiSelect}
        value={values}
        onChange={(event) => {
          const {
            target: { value },
          } = event;
          handleChange(
            multiSelect
              ? typeof value === "string"
                ? value.split(",")
                : value
              : value
          );
        }}
        renderValue={renderSelectedValues}
        MenuProps={MenuProps}
      >
        {options &&
          options.length > 0 &&
          options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              className="app-mui-select"
            >
              {isCheckbox && multiSelect ? (
                <>
                  <Checkbox checked={values.includes(option.value)} />
                  <ListItemText primary={option.label} />
                </>
              ) : (
                <div>{option.label}</div>
              )}
              {isAdditionalFlag && (
                <div className="additional-flag">
                  {option.active ? (
                    ""
                  ) : (
                    <Chip size="small" label={t("Inactive")} />
                  )}
                </div>
              )}
            </MenuItem>
          ))}
      </Field>
      <ErrorMessage name={fieldName} component="div" className="error" />
    </FormControl>
  );
}
