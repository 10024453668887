import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { Grid2 as Grid } from "@mui/material";
import moment from "moment-timezone";
import { Alert as AlertDismissible, Button } from "../../../components/Common";
import { Date_Time_Format } from "../../../config";
import { scrollTopWindow } from "../../../Helpers/index";
import { deleteUser } from "../../../Services/userServices";
import { CustomGrid } from "../../CustomTable";
import ConfirmDeleteUser from "./ConfirmDeleteUser";

class ManageUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      rowDeleteId: null,
      alertMsg: "",
      alertStatus: false,
      variant: "",
      isLoading: false,
      currentPage: props.currentPage,
      perPageCount: 10,
      totalPage: 0,
    };
  }

  // Open confirmation modal popup action
  handleDeleteModal = (e, row) => {
    e.stopPropagation();
    this.setState({
      showDeleteModal: true,
      rowDeleteId: row.id,
    });
  };

  // After confirmation delete the user
  handleDeleteUser = (id, param) => {
    if (param) {
      this.setState({
        isLoading: true,
      });
      deleteUser(id)
        .then((res) => {
          console.log("handleDeleteUser API response:", res);
          this.setState(
            {
              alertMsg: res.data,
              variant: "success",
              alertStatus: true,
              showDeleteModal: false,
              rowDeleteId: null,
              isLoading: false,
            },
            () => {
              this.props.dataFromParent.handleList();
              scrollTopWindow();
            }
          );
        })
        .catch((err) => {
          let errMsg = Object.assign({}, err).response.data.message;
          this.setState(
            {
              alertMsg: errMsg,
              variant: "danger",
              alertStatus: true,
              showDeleteModal: false,
              rowDeleteId: null,
              isLoading: false,
            },
            () => {
              scrollTopWindow();
            }
          );
        });
    } else {
      this.setState({
        showDeleteModal: false,
        rowDeleteId: null,
      });
    }
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  onChangeRecords = (page) => {
    this.setState({
      currentPage: page.page,
      perPageCount: page.pageSize,
    });
  };

  onRowClick = (params) => {
    this.props.handleEditRow(params.row, "Edit User");
  };

  render() {
    const { t, searchValue } = this.props;
    const { showDeleteModal, rowDeleteId, isLoading } = this.state;
    const { users = [] } = this.props.dataFromParent.statedata || {};
    const lowercasedQuery = searchValue?.toLowerCase();

    const filteredUsers = lowercasedQuery
      ? users.filter(
          (item) =>
            item.firstName.toLowerCase().includes(lowercasedQuery) ||
            item.lastName.toLowerCase().includes(lowercasedQuery)
        )
      : users;

    const userDeletePermissionCheck =
      this.props.dataFromParent.statedata.userDeleteEnabled;
    const columns = [
      {
        field: "userName",
        headerName: t("USER NAME"),
        flex: 1,
        cellClassName: "tenant-manage-table-col-1",
        sortable: true,
      },
      {
        field: "roleName",
        headerName: t("ROLE"),
        flex: 1,
        cellClassName: "tenant-manage-table-col-1",
        sortable: true,
      },
      {
        field: "title",
        headerName: t("TITLE"),
        flex: 1,
        cellClassName: "tenant-manage-table-col-1",
        sortable: false,
      },
      {
        field: "email",
        headerName: t("USER EMAIL"),
        flex: 1,
        renderCell: (params) => {
          return (
            <>
              <span data-tip data-for={`usertip${params.tabIndex}`}>
                {params.value}
              </span>
            </>
          );
        },
        sortable: false,
      },
      {
        field: "statusValue",
        headerName: t("STATUS"),
        flex: 1,
        sortable: false,
      },
      {
        field: "lastLoginDateTime",
        headerName: t("Last Login"),
        flex: 1,
        renderCell: (params) =>
          params.row.lastLoginDateTime != null
            ? moment(params.row.lastLoginDateTime).format(Date_Time_Format)
            : "-",
        sortable: false,
      },
      ...(userDeletePermissionCheck
        ? [
            {
              field: "action",
              headerName: t("Action"),
              renderCell: (params) => {
                return (
                  <Button
                    variant="secondary"
                    className="btn-rounded btn-sm btn btn-secondary"
                    onClick={(e) => {
                      this.handleDeleteModal(e, params.row),
                        e.stopPropagation();
                    }}
                    iconButton={true}
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="button_icon_only"
                    />
                  </Button>
                );
              },
              sortable: false,
            },
          ]
        : []),
    ];

    return (
      <div>
        <Grid container>
          <Grid item size={12}>
            <AlertDismissible
              msg={this.state.alertMsg}
              variantType={this.state.variant}
              show={this.state.alertStatus}
              close={this.handleAlertClose}
            ></AlertDismissible>
          </Grid>
        </Grid>
        <div className="table_wrapper custom_table_container table-with-search app-table admin-user-module-table">
          <CustomGrid
            columns={columns}
            data={filteredUsers}
            loading={this.state.isLoading}
            isServerPagination={false}
            currentPage={this.state.currentPage}
            perPageCount={this.state.perPageCount}
            totalPages={filteredUsers.length}
            onChangeRecords={this.onChangeRecords}
            onRowClick={this.onRowClick}
          />
        </div>
        {showDeleteModal ? (
          <ConfirmDeleteUser
            show={showDeleteModal}
            delete={this.handleDeleteUser}
            id={rowDeleteId}
            isLoading={isLoading}
          />
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(ManageUser);
