import React from "react";

class FileUpload extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
  };

  componentWillReceiveProps(nextProp) {
    const fileVal = nextProp.file;
    if (!fileVal) {
      return;
    }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };
      try {
        reader.readAsDataURL(fileVal);
      } catch (e) {
        if (this.props.imageValue != null) {
          this.setState({ loading: false, thumb: this.props.imageValue });
        } else {
          console.log(`Error in reading file ${e}`);
        }
      }
    });
  }

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;
    if (!file) {
      return null;
    }
    if (loading) {
      return "";
    }
    return <img src={thumb} alt={file.name} className=" mt-2 tenant-img" />;
  }
}

export { FileUpload };
