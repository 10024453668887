import React, { useState, useEffect } from "react";
import ResultList from "./ResultList";

const ResultConfiguration = (props) => {
  const [childNav, setChildNav] = useState("Result Configuration");
  const { handleBreadCrumInAdmin } = props;

  useEffect(() => {
    handleBreadCrumInAdmin("Result Configuration");
  }, []);

  /**
   * This function is reponsible for render diffrent HTML
   * @param {string} navBarValue
   * @param {string} orderId
   */
  const UpdateChildNav = (navBarValue, orderId) => {
    setChildNav(navBarValue);
  };

  const getResultConfigurationPage = (navBar) => {
    switch (navBar) {
      case "Result Configuration":
        return (
          <ResultList dataFromParent={props} UpdateChildNav={UpdateChildNav} />
        );

      default:
        return "";
    }
  };

  const showResultConfigurationPage = getResultConfigurationPage(childNav);

  return <div>{showResultConfigurationPage}</div>;
};

export default ResultConfiguration;
