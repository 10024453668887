import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { getConsumers } from "../../../Services/userServices";
import { CustomGrid } from "../../CustomTable";

class ManageUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consumers: [],
      currentPage: props.currentPage,
      perPageCount: 10,
      totalPage: 0,
      loading: true,
    };
  }

  componentDidMount() {
    getConsumers()
      .then((res) => {
        this.setState({
          consumers: res.data,
          loading: false,
        });
      })
      .catch((err) => {
        console.log("get error in get consumer", err);
      });
  }

  onChangeRecords = (page) => {
    this.setState({
      currentPage: page.page,
      perPageCount: page.pageSize,
    });
  };

  onRowClick = (params) => {
    this.props.handleEditRow(params.row, "View Client Application");
  };

  render() {
    const { t, searchValue } = this.props;
    const users = this.state.consumers;
    const lowercasedQuery = searchValue?.toLowerCase();
    const filteredUsers = lowercasedQuery
      ? users.filter((item) =>
          item.clientName.toLowerCase().includes(lowercasedQuery)
        )
      : users;

    const columns = [
      {
        field: "clientName",
        headerName: t("Client Name"),
        flex: 1,
        cellClassName: "tenant-manage-table-col-1",
        sortable: true,
      },
      {
        field: "clientId",
        headerName: t("Client ID"),
        flex: 1,
        sortable: false,
        cellClassName: "tenant-manage-table-col-1",
      },
    ];
    return (
      <div className="table_wrapper custom_table_container table-with-search app-table admin-application-module-table">
        <CustomGrid
          columns={columns}
          data={filteredUsers}
          getRowId={(row) => row.clientId}
          currentPage={this.state.currentPage}
          perPageCount={this.state.perPageCount}
          totalPages={filteredUsers.length}
          onChangeRecords={this.onChangeRecords}
          isServerPagination={false}
          loading={this.state.loading}
          onRowClick={this.onRowClick}
        />
      </div>
    );
  }
}

export default withTranslation()(ManageUser);
