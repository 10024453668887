import React from "react";
import { useTranslation } from "react-i18next";
import notNpMarkIcon from "../../../assets/icon/notNpMark.png";
import npMarkIcon from "../../../assets/icon/npMark.png";

export const MarkNpParticipant = (props) => {
  const { t } = useTranslation();
  const {
    selectedParticipant,
    handleSelectedParticipantNP,
    selectedNPParticipant,
    selectAllPageData,
  } = props;

  const allNP = selectedNPParticipant.filter(
    (participant) => participant.status === "NON_RESPONSIVE_PARTICIPANT"
  );
  const allNotNP = selectedNPParticipant.filter(
    (participant) =>
      ![
        "NON_RESPONSIVE_PARTICIPANT",
        "RESULT_PUBLISHED",
        "INTERVIEWED",
        "ARCHIVED",
        "ATTEMPTED_INCOMPLETE",
        "ATTEMPTED_PARTIAL",
      ].includes(participant.status)
  );

  const markedNP =
    allNP.length > 0 && allNotNP.length === 0
      ? true
      : allNP.length === 0 && allNotNP.length > 0
      ? false
      : allNP.length > 0 && allNotNP.length > 0
      ? false
      : true;

  const isDisabledMarkedNP =
    selectedParticipant.length < 1 || markedNP || selectAllPageData;

  const notMarkedNP =
    allNP.length > 0 && allNotNP.length === 0
      ? false
      : allNP.length === 0 && allNotNP.length > 0
      ? true
      : allNP.length > 0 && allNotNP.length > 0
      ? false
      : true;

  const isDisabledReverseNP =
    selectedParticipant.length < 1 || notMarkedNP || selectAllPageData;

  return (
    <>
      <div
        className={`d-flex align-items-center export-btn data-item ${
          isDisabledMarkedNP ? "event-disabled" : "event-not-disabled"
        }`}
        onClick={() => handleSelectedParticipantNP("NP", isDisabledMarkedNP)}
      >
        <div className="disabled-opacity">
          <span>
            <img src={notNpMarkIcon} alt="notNp" />
          </span>
        </div>
        <span className="text-uppercase">{t("MARK_NON_PARTICIPANT")}</span>
      </div>
      <div
        className={`d-flex align-items-center export-btn data-item ${
          isDisabledReverseNP ? "event-disabled" : "event-not-disabled"
        }`}
        onClick={() =>
          handleSelectedParticipantNP("REVERSE_NP", isDisabledReverseNP)
        }
      >
        <div
          style={{
            textDecoration: "line-through green 3px",
          }}
          className="disabled-opacity"
        >
          <span>
            <img src={npMarkIcon} alt="npMark" />
          </span>
        </div>
        <span className="text-uppercase">{t("REVERSE_NON_PARTICIPANT")}</span>
      </div>
    </>
  );
};
