import React, { useState } from "react";
import {
  Select,
  Checkbox,
  OutlinedInput,
  MenuItem,
  FormControl,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function MultiSelectDropDown(props) {
  const { options, value, setSelectedOption, placeholder, className } = props;
  const [selectedValue, setSelectedValue] = useState(value || []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newValue = typeof value === "string" ? value.split(",") : value;
    setSelectedValue(newValue);
    setSelectedOption(newValue);
  };

  const getDropdownButtonLabel = (value) => {
    if (value.length === 0) {
      return <span className="select__placeholder">{placeholder}</span>;
    } else if (value.length === 1) {
      const getValue = options.find((val) => val.value === Number(value));
      return (
        <span className="select__placeholder">
          {getValue?.label || value || ""}
        </span>
      );
    } else {
      return (
        <span className="select__placeholder">{`Selected : ${value.length}`}</span>
      );
    }
  };

  const CustomArrowIcon = (props) => {
    return <ExpandMoreIcon {...props} fontSize="17px" />;
  };

  return (
    <div>
      <FormControl
        className={className}
        sx={{ width: 200, minWidth: 200 }}
        size="small"
      >
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedValue}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => getDropdownButtonLabel(selected)}
          displayEmpty
          IconComponent={CustomArrowIcon}
        >
          {options.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox checked={selectedValue.includes(item.value)} />
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
