import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, Grid2 as Grid, Tab, Tabs } from "@mui/material";
import CustomPromptModal from "../../Utility/CustomPrompt/CustomPromptModal";
import Email from "./Email";
import SMS from "./SMS";

const Communication = (props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  /* const "key" store selected key */
  const [key, setKey] = useState("email");
  const [storeTabKey, setStoreTabKey] = useState("email");
  const [isDirty, setIsDirty] = useState(false);

  const handleTabChange = (event, newValue) => {
    if (isDirty) {
      setStoreTabKey(newValue); // Store new value temporarily
      setShowModal(true); // Open modal to confirm change
    } else {
      setKey(newValue); // Directly switch tabs if no unsaved changes
    }
  };

  const onConfirm = () => {
    setKey(storeTabKey); // Confirm tab switch
    setShowModal(false); // Close modal
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item size={12}>
              <h5 className="page-title">{t("Communication")}</h5>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container>
        <Grid item size={12}>
          <Tabs
            value={key}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            variant="fullWidth"
          >
            <Tab value="email" label={t("Email")} />
            <Tab value="sms" label={t("SMS")} />
          </Tabs>
          {key === "email" && <Email {...props} setIsDirty={setIsDirty} />}
          {key === "sms" && <SMS {...props} setIsDirty={setIsDirty} />}
        </Grid>
      </Grid>
      {showModal && (
        <CustomPromptModal
          showModal={showModal}
          hideModal={() => setShowModal(false)}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default Communication;
