import api from './api';


const createRole = async (param,tenantId) => {
// var token = JSON.parse(localStorage.getItem('token'));

let form = new FormData();
form.append("tenantId", tenantId);
form.append("name", param.roleName);
form.append("description", param.description);

const data = {
tenantId: tenantId,
name: param.roleName,
description: param.description
}
return api.post(`user-service/v1/role/`, data);
}

export { createRole };