import React, { useState, memo } from "react";
import moment from "moment";
import UploadedResult from "./UploadedResult";
import { Date_Time_Format } from "../../../../../config";
import { CustomGrid } from "../../../../CustomTable";

const BackendData = (props) => {
  const { loading, allBackendData, t } = props;

  const [showbackendRowData, setShowbackendRowData] = useState(false);

  const [uploadedResultData, setUploadedResultData] = useState();

  const [currentPage, setCurrentPage] = useState(0);
  const [perPageCount, setPerPageCount] = useState(10);

  const formateResult = (params) => {
    return (
      <pre
        onClick={() => openBackendDataRow(params.row)}
        style={{ cursor: "pointer" }}
      >
        {params.value}
      </pre>
    );
  };

  const formateDate = (params) => {
    return moment(params.value).format(Date_Time_Format);
  };

  const formateRunId = (params) => {
    return params.value !== null ? (
      <span>{`RunId: ${params.value}`}</span>
    ) : (
      <span>NA</span>
    );
  };

  const columns = [
    {
      headerName: `${t("Upload Date")}`,
      field: "uploadedAt",
      renderCell: formateDate,
      flex: 1,
      sortable: false,
    },
    {
      headerName: `${t("Payload")}`,
      field: "uploadedResult",
      renderCell: formateResult,
      flex: 1,
      sortable: false,
    },
    {
      headerName: `${t("Debug Info")}`,
      field: "runId",
      flex: 1,
      renderCell: formateRunId,
      sortable: false,
    },
  ];

  const openBackendDataRow = (rowData) => {
    setUploadedResultData(rowData);
    setShowbackendRowData(true);
  };

  const onChangeRecords = (page) => {
    setCurrentPage(page.page);
    setPerPageCount(page.pageSize);
  };

  return (
    <div className="table_wrapper participant-table app-table custom_table_container">
      <CustomGrid
        data={allBackendData || []}
        getRowId={(row) => row.id}
        columns={columns || []}
        loading={loading}
        totalPages={allBackendData.length}
        currentPage={currentPage}
        perPageCount={perPageCount}
        onChangeRecords={onChangeRecords}
      />
      {showbackendRowData && (
        <UploadedResult
          isOpen={showbackendRowData}
          uploadedResultData={uploadedResultData}
          t={t}
          close={() => setShowbackendRowData(false)}
        />
      )}
    </div>
  );
};
export default memo(BackendData);
