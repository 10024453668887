import moment from "moment";
import { nanoid } from "nanoid";
import React, { memo, useEffect, useState } from "react";
import { Date_Time_Format } from "../../../../../config";
import { EnhancedTable } from "../../../../CustomTable";

const ParticipantGuide = (props) => {
  const { loading, participantGuideData, t, state } = props;
  const [prepareGuideData, setPrepareGuideData] = useState([]);
  useEffect(() => {
    if (!loading && participantGuideData.length > 0) {
      const uniqueSessionIds = [];
      const uniqueParticipantGuideData = participantGuideData.filter(
        (element) => {
          const isDuplicateSessionIds = uniqueSessionIds.includes(
            element.sessionId
          );
          if (!isDuplicateSessionIds) {
            uniqueSessionIds.push(element.sessionId);
            return true;
          }
          return false;
        }
      );
      const updatedParticipantGuideData = uniqueParticipantGuideData.map(
        (uniqueGuideData) => {
          let uniqueData = participantGuideData.filter(
            (guideData) => guideData.sessionId === uniqueGuideData.sessionId
          );
          uniqueGuideData.innerData = uniqueData;
          uniqueGuideData.id = nanoid();
          return uniqueGuideData;
        }
      );
      setPrepareGuideData(updatedParticipantGuideData);
    }
  }, [loading]);

  const formateDate = (params) => {
    const date = params.row.createTs;
    return date ? moment(date).format(Date_Time_Format) : "-";
  };

  const formateSessionId = (params) => {
    return <span className="sessionId-color">{params.row.sessionId}</span>;
  };

  const columns = [
    {
      headerName: `${t("session Id")}`,
      field: "sessionId",
      sortable: false,
      renderCell: formateSessionId,
    },
    {
      headerName: `${t("CREATED DATE")}`,
      field: "createTs",
      sortable: false,
      renderCell: formateDate,
    },
  ];

  const innerTableColumn = [
    ...(state?.isSystemTenant
      ? [
          {
            headerName: `${t("IP Address")}`,
            field: "ipAddress",
            flex: 1,
            sortable: false,
          },
        ]
      : []),
    {
      headerName: `${t("CREATED DATE")}`,
      field: "createTs",
      flex: 1,
      sortable: false,
      renderCell: formateDate,
    },
    {
      headerName: `${t("ButtonName")}`,
      field: "buttonName",
      flex: 1,
      sortable: false,
    },
    {
      headerName: `${t("PageName")}`,
      field: "pageName",
      flex: 1,
      sortable: false,
    },
  ];

  return (
    <div className="app-table">
      <EnhancedTable
        columns={columns}
        data={prepareGuideData}
        isHidePagination={true}
        showExpendedRow={true}
        isGuidTable={true}
        innerTableColumn={innerTableColumn}
      />
    </div>
  );
};
export default memo(ParticipantGuide);
