import moment from "moment";

export const stepsLabel = [
  "SELECT_A_REPORT_TYPE",
  "DATE_RANGE_AND_PROJECT",
  "SELECT_ATTRIBUTES",
  "REVIEW_AND_SUBMIT",
];

export const addReportInitialValues = {
  reportType: "",
  projectId: [],
  startDate: new Date(),
  endDate: new Date(),
  attributes: [],
  reportName: "",
  timeZone: moment.tz.guess(),
};
