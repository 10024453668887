import React from "react";
import i18n from "../../../../../../locale/i18n";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../PdfStyle";
export const PrecisionRGWithExpressFooterText = () => {
  return (
    <View style={styles.footerMainContainer}>
      <View style={styles.footerFirstContainer} wrap={false}>
        <Text style={styles.footerHeading}>{i18n.t("RESULTS_LEGEND")}</Text>

        <View style={styles.footerContainer}>
          <Text
            style={{
              ...styles.averageRiskIcon,
              ...styles.iconWidth,
              ...styles.iconMargin,
            }}
          ></Text>
          <Text style={styles.footerText}>{`${i18n.t("GREEN_G")} - ${i18n.t(
            "GREEN_G_DES"
          )}`}</Text>
        </View>

        <View style={styles.footerContainer}>
          <Text
            style={{
              ...styles.highRiskIcon,
              ...styles.iconWidth,
              ...styles.iconMargin,
            }}
          ></Text>
          <Text style={styles.footerText}>{`${i18n.t("RED_R")} - ${i18n.t(
            "RED_R_DES"
          )}`}</Text>
        </View>

        <View style={styles.footerContainer}>
          <Text
            style={{
              ...styles.admissionIcon,
              ...styles.iconWidth,
              ...styles.iconMargin,
            }}
          ></Text>
          <Text style={styles.footerText}>
            {`${i18n.t("Admission_Participant")} - ${i18n.t(
              "Admission_Participant_Des"
            )}`}
          </Text>
        </View>

        <View style={styles.footerContainer}>
          <Text
            style={{
              ...styles.counterMeasureIcon,
              ...styles.iconWidth,
              ...styles.iconMargin,
            }}
          ></Text>
          <Text style={styles.footerText}>{`${i18n.t(
            "Counter_Measure"
          )} - ${i18n.t("Counter_Measure_Des")}`}</Text>
        </View>

        <View style={styles.footerContainer}>
          <Text
            style={{
              ...styles.disconnectedIcon,
              ...styles.iconWidth,
              ...styles.iconMargin,
            }}
          ></Text>
          <Text style={styles.footerText}>
            {`${i18n.t("Disconnected_Participant")} - ${i18n.t(
              "Disconnected_Participant_Des"
            )}`}
          </Text>
        </View>

        <View style={styles.footerContainer}>
          <Text
            style={{
              ...styles.notCompletedIcon,
              ...styles.iconWidth,
              ...styles.iconMargin,
            }}
          ></Text>
          <Text style={styles.footerText}>
            {`${i18n.t("Non_Compliant_Participant")} - ${i18n.t(
              "Non_Compliant_Participant_Des"
            )}`}
          </Text>
        </View>

        <View style={{ ...styles.footerContainer }}>
          <Text
            style={{
              ...styles.nonResponsiveParticipant,
              ...styles.iconWidth,
              ...styles.iconMargin,
            }}
          ></Text>
          <Text style={styles.footerText}>
            {`${i18n.t("Non_Responsive_Participant")} - ${i18n.t(
              "Non_Responsive_Participant_Des"
            )}`}
          </Text>
        </View>
      </View>
    </View>
  );
};
