import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { CustomGrid } from "../../CustomTable";

class ManageRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: props.currentPage,
      perPageCount: 10,
    };
  }

  onChangeRecords = (params) => {
    this.setState({
      currentPage: params.page,
      perPageCount: params.pageSize,
    });
  };
  render() {
    const { t, searchValue } = this.props;
    const lowercasedQuery = searchValue?.toLowerCase();
    const roleList = this.props.dataFromParent.statedata.roles;
    const filteredRole = lowercasedQuery
      ? roleList.filter((item) =>
          item.name.toLowerCase().includes(lowercasedQuery)
        )
      : roleList;
    const roles = filteredRole.map(function (role) {
      role.roleName = role.name;
      return role;
    });
    const columns = [
      {
        field: "roleName",
        headerName: t("ROLE"),
        flex: 1,
        sortable: true,
        cellClassName: "tenant-manage-table-col-1",
      },
      {
        field: "description",
        headerName: t("DESCRIPTION"),
        flex: 1,
        sortable: false,
        cellClassName: "tenant-manage-table-col-1",
      },
    ];

    return (
      <div>
        <div className="table_wrapper custom_table_container table-with-search app-table admin-role-module-table">
          <CustomGrid
            columns={columns}
            data={roles}
            isServerPagination={false}
            currentPage={this.state.currentPage}
            perPageCount={this.state.perPageCount}
            totalPages={roles.length}
            isServerSorting={false}
            onChangeRecords={this.onChangeRecords}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(ManageRoles);
