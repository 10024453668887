import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Grid2 as Grid } from "@mui/material";
import {
  Alert as AlertDismissible,
  Button,
  Spinner,
} from "../../../components/Common";
import { createClientapp } from "../../../Services/userServices";
import PreventLeaveRoute from "../../Utility/PreventLeaveRoute.js";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMsg: "",
      alertStatus: false,
      variant: "",
      dirty: false,
      responseShow: false,
      responseData: null,
      disablesavebtn: false,
    };
  }

  componentDidMount = () => {};

  cancelUserClick = () => {
    this.props.updateNavBar("Client Applications");
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  dirtyFlagChanged = (dirty) => {
    if (dirty != this.state.dirty) {
      this.setState({
        dirty: dirty,
      });
      this.props.dataFromParent.handleChildDirtyGFlag(dirty);
    }
  };

  handleAddUser = (fields, resetForm) => {
    this.setState({
      disablesavebtn: true,
    });
    createClientapp(fields)
      .then((res) => {
        this.setState({
          alertMsg: `Application Created Successfully`,
          variant: "success",
          alertStatus: true,
          dirty: false,
          responseShow: true,
          responseData: res.data,
          disablesavebtn: false,
        });
        this.props.dataFromParent.handleChildDirtyGFlag(false);
        resetForm();
      })
      .catch((err) => {
        let errMsg = Object.assign({}, err).response.data.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
          disablesavebtn: false,
        });
      });
  };

  render() {
    const { t } = this.props;
    return (
      <Formik
        initialValues={{
          clientName: "",
          description: "",
        }}
        validationSchema={Yup.object().shape({
          clientName: Yup.string().required(t("Client App Name is required")),
        })}
        onSubmit={(fields, { resetForm }) => {
          this.handleAddUser(fields, resetForm);
        }}
        render={({
          errors,
          values,
          touched,
          handleReset,
          dirty,
          handleBlur,
        }) => (
          <Form onChange={this.dirtyFlagChanged(dirty)}>
            <PreventLeaveRoute
              reset={handleReset}
              when={dirty}
              // Navigate function
              navigate={(field, historyObj) => {
                if (field == "/main/admin/clientapp") {
                  this.cancelUserClick();
                } else {
                  historyObj.push(field);
                }
              }}
              // Use as "message" prop of Prompt of React-Router
              shouldBlockNavigation={(location) => {
                if (location.pathname === "/") {
                  return false;
                }
                return !!(dirty || this.state.dirty);
              }}
            />
            <Grid container>
              <Grid item size={12}>
                <AlertDismissible
                  msg={this.state.alertMsg}
                  variantType={this.state.variant}
                  show={this.state.alertStatus}
                  close={this.handleAlertClose}
                ></AlertDismissible>
              </Grid>
            </Grid>
            <Grid container className="add-tenant-row">
              <Grid item className="add-tenant-col" size={4}>
                <div className="form-group">
                  <label className="required" htmlFor="clientName">
                    {t("CLIENT APP NAME")}
                  </label>
                  <Field
                    name="clientName"
                    type="text"
                    autoComplete="off"
                    className={
                      "form-control" +
                      (errors.clientName && touched.clientName
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {errors.clientName && touched.clientName && (
                    <div className="input-feedback add-tenant-validation">
                      {errors.clientName}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="" htmlFor="description">
                    {t("CLIENT APP DESCRIPTION")}
                  </label>
                  <Field
                    name="description"
                    type="text"
                    component="textarea"
                    rows="5"
                    className={
                      "form-control" +
                      (errors.description && touched.description
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                    onBlur={handleBlur}
                    value={values.description}
                  />
                </div>
              </Grid>
              <Grid item size={8}>
                {this.state.responseShow ? (
                  <div className="card card-code add-response-card">
                    <div className="form-group row">
                      <label className="col-md-4">{t("CLIENT NAME")}</label>
                      <div className="col-md-8">
                        <p className="mb-0">
                          {this.state.responseData.clientName}
                        </p>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4">{t("CLIENT ID")}</label>
                      <div className="col-md-8">
                        <p className="mb-0">
                          {this.state.responseData.clientId}
                        </p>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4">{t("Client Secret")}</label>
                      <div className="col-md-8">
                        <p className="mb-0">
                          {this.state.responseData.clientSecret}
                        </p>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4">{t("Sample Curl")} </label>
                      <div className="col-md-8">
                        <code>
                          {this.state.responseData.sampleCURLForAccessToken}
                        </code>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item size={12}>
                <div className="form-group float-right">
                  <Button
                    variant="primary"
                    type="submit"
                    className="rounded-0 mr-4 save-btn button_with_loader"
                    disabled={this.state.disablesavebtn}
                  >
                    {this.state.disablesavebtn ? (
                      <>
                        {t("Save")}...
                        <div className="custom-loader linesLoader loader_inner_button">
                          <Spinner color="#ffffff" size={"19px"} />
                        </div>
                      </>
                    ) : (
                      <span>{t("Save")}</span>
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    className="rounded-0 cancel-btn"
                    onClick={this.cancelUserClick}
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    );
  }
}

export default withTranslation()(AddUser);
