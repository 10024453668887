import React, { useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Select from "react-select";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, ErrorMessage } from "formik";
import { Grid2 as Grid } from "@mui/material";
import { sortByLabel } from "../../../../Helpers";
import { checkMultiLanguageFieldExist } from "../../../../Services/projectServices";
import { projectActions } from "../../../../store/actions/projectActions";
import TextError from "./TextError";

const MultiLanguageRowAccordion = (props) => {
  const {
    index,
    t,
    state,
    multilanguageRow,
    projectMultiLanguageConfig,
    projectLanguagesData,
    formik,
    projectId,
    remove,
  } = props;

  const { canUserUpdateField } = state;

  const [timer, setTimer] = useState("");

  const createDropdownOption = (option, flag = false) => {
    if (flag) {
      return {
        value: option.attributeId,
        label: option.description,
      };
    } else {
      return {
        value: option.attributeId,
        label: option.description,
        sequence: option.sequence,
      };
    }
  };

  const prepareLanguageOption = (projectLanguagesData, languages) => {
    return projectLanguagesData.filter((object1) => {
      return !languages.some((object2) => {
        return object1.value === object2.language.value;
      });
    });
  };

  const getInboundTelcoOption = () => {
    let inboundTelcoOption = state.inboundtelcoA
      .map((option) => {
        return createDropdownOption(option);
      })
      .sort(sortByLabel);
    return inboundTelcoOption;
  };

  const getPhoneNoStatusOption = () => {
    let phoneNoStatusOption = state.phoneNoStatusA
      .map((option) => {
        return createDropdownOption(option);
      })
      .sort(sortByLabel);
    return phoneNoStatusOption;
  };

  const getIvrPreambleLanguage = () => {
    let language = state.languageA
      .map((option) => {
        return createDropdownOption(option);
      })
      .sort(sortByLabel);
    return language;
  };

  const checkDuplicateData = (formData, index) => {
    checkMultiLanguageFieldExist(formData)
      .then((res) => {
        const response = res?.data;
        const { projectCode } = response;
        if (projectCode) {
          const project = Object.keys(projectCode);
          const { project_name, tenant_name } = projectCode[project];
          const errorMessage = {
            projectName: project_name,
            tenantName: tenant_name,
          };
          let createError = {
            errorId: index,
            errorMessage: errorMessage,
            errorCode: projectCode,
          };
          props.setProjectCodeError([...props.projectCodeErrors, createError]);
        } else {
          props.setProjectCodeError([]);
        }
      })
      .catch((err) => {
        console.log("err---", err);
        props.setProjectCodeError([]);
      });
  };

  // This function will handle project code error
  const handleProjectCode = (event, formik, index) => {
    props.setProjectCodeError([]);
    const { currentTarget } = event;
    clearTimeout(timer);
    let newTimer = setTimeout(() => {
      const formData = {
        projectCode: currentTarget.value,
        projectId: projectId,
      };
      checkDuplicateData(formData, index);
    }, 1000);
    setTimer(newTimer);
    formik.setFieldTouched(
      `projectMultiLanguageConfig[${index}].projectCode`,
      true
    );
    formik.setFieldValue(
      `projectMultiLanguageConfig[${index}].projectCode`,
      event.target.value
    );
  };

  const handleDialNumber = (event) => {
    formik.setFieldValue(
      `projectMultiLanguageConfig[${index}].dialInNumber`,
      event.target.value
    );
    props.setProjectPhoneNumberError([]);
  };

  const handleMatchingNumber = (event) => {
    formik.setFieldValue(
      `projectMultiLanguageConfig[${index}].matchingPhoneNumber`,
      event.target.value
    );
    props.setProjectPhoneNumberError([]);
  };

  const removeRow = (index) => {
    const updatedErrors = props.projectCodeErrors.filter(
      (projectCodeError) => projectCodeError.errorId !== index
    );
    props.setProjectCodeError(updatedErrors);
    remove(index);
  };
  // use this function for expanding Accordion if there is error in Accordion on save button click
  const createUUIDArray = () => {
    const numberArray = Array.from(Array(30).keys());
    const stringArray = numberArray.map((number) => {
      return number.toString();
    });
    return stringArray;
  };

  return (
    <Grid item size={12}>
      <Accordion
        preExpanded={createUUIDArray()}
        allowZeroExpanded="true"
        allowMultipleExpanded="true"
      >
        <AccordionItem uuid={props.isAccordianUUId}>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div className="row-header">
                <div className="d-flex align-items-center">
                  <label className="row-header-title" style={{ flex: 1 }}>
                    {multilanguageRow.language.label
                      ? t(multilanguageRow.language.label)
                      : t("please Select Language")}
                  </label>
                  <span onClick={() => removeRow(index)}>
                    <FontAwesomeIcon icon={faTrashAlt} className="trashicon" />
                  </span>
                </div>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Grid container key={index} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item size={3}>
                <div className="form-group">
                  <label
                    className="required"
                    htmlFor={`projectMultiLanguageConfig[${index}].language`}
                  >
                    {t("Language")}
                  </label>
                  <Select
                    className="select-style"
                    isDisabled={canUserUpdateField}
                    classNamePrefix="select"
                    id={`projectMultiLanguageConfig[${index}].language`}
                    value={multilanguageRow.language}
                    onChange={(selectedOption) =>
                      formik.setFieldValue(
                        `projectMultiLanguageConfig[${index}].language`,
                        selectedOption
                      )
                    }
                    options={prepareLanguageOption(
                      projectLanguagesData,
                      projectMultiLanguageConfig
                    )}
                  />
                  <ErrorMessage
                    name={`projectMultiLanguageConfig[${index}].language`}
                    component={TextError}
                  />
                </div>
              </Grid>
              <Grid item size={3}>
                <div className="form-group">
                  <label
                    className="required"
                    htmlFor={`projectMultiLanguageConfig[${index}].projectCode`}
                  >
                    {t("Project Code")}
                  </label>
                  <Field
                    name={`projectMultiLanguageConfig[${index}].projectCode`}
                    id={`projectMultiLanguageConfig[${index}].projectCode`}
                  >
                    {(props) => (
                      <input
                        disabled={canUserUpdateField}
                        value={multilanguageRow.projectCode}
                        className="form-control"
                        type="text"
                        onBlur={() => {
                          formik.setFieldTouched(
                            `projectMultiLanguageConfig[${index}].projectCode`,
                            true
                          );
                        }}
                        onChange={(event) =>
                          handleProjectCode(event, formik, index)
                        }
                      />
                    )}
                  </Field>

                  {props.projectCodeErrors.length > 0 &&
                    props.projectCodeErrors.map((projectCodeError) => {
                      if (
                        projectCodeError.errorId === index &&
                        Object.hasOwn(
                          projectCodeError.errorCode,
                          formik.values.projectMultiLanguageConfig[index]
                            .projectCode
                        ) &&
                        projectCodeError.errorCode[
                          formik.values.projectMultiLanguageConfig[index]
                            .projectCode
                        ] !== null
                      ) {
                        const toggle = formik.errors
                          .projectMultiLanguageConfig?.[index]?.projectCode
                          ? false
                          : true;
                        const { errorMessage } = projectCodeError;
                        return (
                          toggle && (
                            <p className="error">
                              {t("PROJECT_CODE_VALIDATION_MESSAGE", {
                                project_name: errorMessage.projectName,
                                tenant_name: errorMessage.tenantName,
                              })}
                            </p>
                          )
                        );
                      } else {
                        return "";
                      }
                    })}

                  <ErrorMessage
                    name={`projectMultiLanguageConfig[${index}].projectCode`}
                    component={TextError}
                  />
                </div>
              </Grid>
              <Grid item size={3}>
                <div className="form-group">
                  <label htmlFor="interviewServerPrimary">
                    {t("Primary Server #")}
                  </label>
                  <Field
                    disabled={canUserUpdateField}
                    className="form-control"
                    component="textarea"
                    name={`projectMultiLanguageConfig[${index}].interviewServerPrimary`}
                    id={`projectMultiLanguageConfig[${index}].interviewServerPrimary`}
                  />
                  <ErrorMessage
                    name={`projectMultiLanguageConfig[${index}].interviewServerPrimary`}
                    component={TextError}
                  />
                </div>
              </Grid>
              <Grid item size={3}>
                <div className="form-group">
                  <label htmlFor="interviewServerBackup">
                    {t("Backup Server #")}
                  </label>
                  <Field
                    disabled={canUserUpdateField}
                    className="form-control"
                    component="textarea"
                    name={`projectMultiLanguageConfig[${index}].interviewServerBackup`}
                    id={`projectMultiLanguageConfig[${index}].interviewServerBackup`}
                  />
                </div>
              </Grid>
              <Grid item size={3}>
                <div className="form-group">
                  <label
                    htmlFor={`projectMultiLanguageConfig[${index}].dialInNumber`}
                  >
                    {t("Dial-In Phone Number")}
                  </label>
                  <Field
                    name={`projectMultiLanguageConfig[${index}].dialInNumber`}
                    id={`projectMultiLanguageConfig[${index}].dialInNumber`}
                  >
                    {(props) => (
                      <textarea
                        disabled={canUserUpdateField}
                        value={multilanguageRow.dialInNumber}
                        className="form-control"
                        type="text"
                        onBlur={() => {
                          formik.setFieldTouched(
                            `projectMultiLanguageConfig[${index}].dialInNumber`,
                            true
                          );
                        }}
                        onChange={handleDialNumber}
                      />
                    )}
                  </Field>

                  {props.projectPhoneNumberErrors.length > 0 &&
                    props.projectPhoneNumberErrors.map(
                      (projectPhoneNumberError) => {
                        if (
                          projectPhoneNumberError.number ===
                          formik.values.projectMultiLanguageConfig[index]
                            .dialInNumber
                        ) {
                          return (
                            <p key={`dial_number_${index}`} className="error">
                              {t("PROJECT_PHONE_NUMBER__CONFIRM_MESSAGE", {
                                number: projectPhoneNumberError.number,
                                project_name:
                                  projectPhoneNumberError.project_name,
                                tenant_name:
                                  projectPhoneNumberError.tenant_name,
                              })}
                            </p>
                          );
                        } else {
                          return "";
                        }
                      }
                    )}

                  <ErrorMessage
                    name={`projectMultiLanguageConfig[${index}].dialInNumber`}
                    component={TextError}
                  />
                </div>
              </Grid>

              <Grid item size={3}>
                <div className="form-group">
                  <label htmlFor="matchingPhoneNumber">
                    {t("Matching Phone Number")}
                  </label>
                  <Field
                    name={`projectMultiLanguageConfig[${index}].matchingPhoneNumber`}
                    id={`projectMultiLanguageConfig[${index}].matchingPhoneNumber`}
                  >
                    {(props) => (
                      <textarea
                        disabled={canUserUpdateField}
                        value={multilanguageRow.matchingPhoneNumber}
                        className="form-control"
                        type="text"
                        onBlur={() => {
                          formik.setFieldTouched(
                            `projectMultiLanguageConfig[${index}].matchingPhoneNumber`,
                            true
                          );
                        }}
                        onChange={handleMatchingNumber}
                      />
                    )}
                  </Field>

                  {props.projectPhoneNumberErrors.length > 0 &&
                    props.projectPhoneNumberErrors.map(
                      (projectPhoneNumberError) => {
                        if (
                          projectPhoneNumberError.number ===
                          formik.values.projectMultiLanguageConfig[index]
                            .matchingPhoneNumber
                        ) {
                          return (
                            <p
                              key={`matching_number_${index}`}
                              className="error"
                            >
                              {t("PROJECT_PHONE_NUMBER__CONFIRM_MESSAGE", {
                                number: projectPhoneNumberError.number,
                                project_name:
                                  projectPhoneNumberError.project_name,
                                tenant_name:
                                  projectPhoneNumberError.tenant_name,
                              })}
                            </p>
                          );
                        } else {
                          return "";
                        }
                      }
                    )}

                  <ErrorMessage
                    name={`projectMultiLanguageConfig[${index}].matchingPhoneNumber`}
                    component={TextError}
                  />
                </div>
              </Grid>
              <Grid item size={3}>
                <div className="form-group">
                  <label htmlFor="inboundTelcoId">{t("Inbound Telco")}</label>
                  <Select
                    isDisabled={canUserUpdateField}
                    className="select-style"
                    classNamePrefix="select"
                    id={`projectMultiLanguageConfig[${index}].inboundTelcoId`}
                    value={
                      multilanguageRow.inboundTelcoId
                        ? getInboundTelcoOption().find(
                            (option) =>
                              option.value === multilanguageRow.inboundTelcoId
                          )
                        : ""
                    }
                    onChange={(selectedOption) =>
                      formik.setFieldValue(
                        `projectMultiLanguageConfig[${index}].inboundTelcoId`,
                        selectedOption.value
                      )
                    }
                    options={getInboundTelcoOption()}
                  />

                  <ErrorMessage
                    name={`projectMultiLanguageConfig[${index}].inboundTelcoId`}
                    component={TextError}
                  />
                </div>
              </Grid>
              <Grid item size={3}>
                <div className="form-group">
                  <label htmlFor="phoneNumberStatusId">
                    {t("Phone Number Status")}
                  </label>
                  <Select
                    isDisabled={canUserUpdateField}
                    className="select-style"
                    classNamePrefix="select"
                    id={`projectMultiLanguageConfig[${index}].phoneNumberStatusId`}
                    value={
                      multilanguageRow.phoneNumberStatusId
                        ? getPhoneNoStatusOption().find(
                            (option) =>
                              option.value ===
                              multilanguageRow.phoneNumberStatusId
                          )
                        : ""
                    }
                    onChange={(selectedOption) =>
                      formik.setFieldValue(
                        `projectMultiLanguageConfig[${index}].phoneNumberStatusId`,
                        selectedOption.value
                      )
                    }
                    options={getPhoneNoStatusOption()}
                  />
                </div>
              </Grid>
              <Grid item size={3}>
                <div className="form-group">
                  <label htmlFor="ivrOption">{t("IVR Option")}</label>

                  <Field
                    disabled={canUserUpdateField}
                    className="form-control"
                    type="number"
                    id={`projectMultiLanguageConfig[${index}].ivrOption`}
                    name={`projectMultiLanguageConfig[${index}].ivrOption`}
                  />
                  <ErrorMessage
                    name={`projectMultiLanguageConfig[${index}].ivrOption`}
                    component={TextError}
                  />
                </div>
              </Grid>
              <Grid item size={3}>
                <div className="form-group">
                  <label htmlFor="inboundTelcoId">
                    {t("IVR PREAMBLE LANGUAGE")}
                  </label>
                  <Select
                    isDisabled={canUserUpdateField}
                    className="select-style"
                    classNamePrefix="select"
                    id={`projectMultiLanguageConfig[${index}].ivrPreambleLanguageId`}
                    value={
                      multilanguageRow.ivrPreambleLanguageId
                        ? getIvrPreambleLanguage().find(
                            (option) =>
                              option.value ===
                              multilanguageRow.ivrPreambleLanguageId
                          )
                        : ""
                    }
                    onChange={(selectedOption) =>
                      formik.setFieldValue(
                        `projectMultiLanguageConfig[${index}].ivrPreambleLanguageId`,
                        selectedOption.value
                      )
                    }
                    options={getIvrPreambleLanguage()}
                  />
                  <ErrorMessage
                    name={`projectMultiLanguageConfig[${index}].ivrPreambleLanguageId`}
                    component={TextError}
                  />
                </div>
              </Grid>
              <Grid item size={2}>
                <div className="input-group mb-3 ">
                  <div className="d-block w-100">
                    <label className="required" htmlFor="callDelayBy">
                      {t("Call Delay By")}
                    </label>
                  </div>
                  <Field
                    disabled={canUserUpdateField}
                    className="form-control"
                    type="number"
                    id={`projectMultiLanguageConfig[${index}].callDelayBy`}
                    name={`projectMultiLanguageConfig[${index}].callDelayBy`}
                  />

                  <div
                    className={`${
                      canUserUpdateField
                        ? "input-group-append disabled"
                        : "input-group-append"
                    }`}
                  >
                    <span className="input-group-text" id="">
                      3x sec.
                    </span>
                  </div>
                  <ErrorMessage
                    name={`projectMultiLanguageConfig[${index}].callDelayBy`}
                    component={TextError}
                  />
                </div>
              </Grid>
            </Grid>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    isProjectCodeExist: state.projects?.isProjectCodeExist,
    isAccordianUUId: state.projects?.isAccordianUUId,
    projectCodeErrors: state.projects?.projectCodeErrors,
    projectPhoneNumberErrors: state.projects?.projectPhoneNumberErrors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    projectCodeExist: () => dispatch(projectActions.projectCodeExist()),
    projectCodeNotExist: () => dispatch(projectActions.projectCodeNotExist()),
    setProjectCodeError: (projectCodeError) =>
      dispatch(projectActions.setProjectCodeError(projectCodeError)),
    setProjectPhoneNumberError: (phoneNumberError) =>
      dispatch(projectActions.setProjectPhoneNumberError(phoneNumberError)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MultiLanguageRowAccordion)
);
