import React from "react";
import { Route, Switch,Redirect } from "react-router-dom";
import MainWrapper from "./App/layouts/MainWrapper";
import Login from "./components/Login/Login";
import ResetPassword from "./components/ResetPassword";
import PrivateRoute from "./App/layouts/PrivateRoute";

const Routes = () => {
  return (
    <div className="App">
      <Switch>
        <Route path="/resetPassword" component={ResetPassword} />
        <Route exact path="/login" render={() => <Redirect to="/" />} />
        <Route exact path="/" component={Login} />
        <PrivateRoute path="/main">
          <MainWrapper />
        </PrivateRoute>
        <Route path="*" render={() => <div>No Page Found</div>} />
      </Switch>
    </div>
  );
};

export default Routes;
