import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { exportInterviewResults } from "../../../../../../../Services/executionService";
import { ResultsContext } from "../../../../ResultsContext";
import { formatDate, getResultsPayload } from "../../../../ResultsUtils";
import * as FileSaver from "file-saver";
import { filenameFromResponseHeader } from "../../../../../../../shared/utils";

const DownloadResults = () => {
  const { t } = useTranslation();
  const { resultsState, resultsDispatch, projectDetail } =
    useContext(ResultsContext);
  const { selectedResultRow, isSelectAllRow } = resultsState;
  const { projectId } = projectDetail;

  const showError = (errMsg, variant, status) => {
    dispatch("set_alert_msg", errMsg);
    dispatch("set_variant", variant);
    dispatch("set_alert_status", status);
  };

  /**
   * This function work as a setter function for state based
   * @param {string} type
   * @param {anyType} payload
   */
  const dispatch = (type, payload) => {
    resultsDispatch({ type: type, payload: payload });
  };

  const downloadResults = () => {
    dispatch("is_result_download_file", true);
    const today = formatDate(new Date());
    const tenantName = localStorage.getItem("operatingTenantName");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const usertenantName = userInfo.tenant.tenantName;
    const requestPayload = getResultsPayload(resultsState, projectId);
    const filterIds =
      selectedResultRow &&
      selectedResultRow.map((value, index) => {
        return value.interviewResultId;
      });
    // console.log("requestPayload filterIds", filterIds);
    requestPayload.interviewResultIds = filterIds;
    // console.log("requestPayload", requestPayload);
    // delete requestPayload.allRecords;
    // return;
    exportInterviewResults(requestPayload)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "text/csv; charset=utf-8",
        });
        FileSaver.saveAs(
          blob,
          filenameFromResponseHeader(res.headers["content-disposition"])
        );
        dispatch("is_result_download_file", false);
      })
      .catch((err) => {
        console.log("err", err);
        if (err?.response?.status === 500) {
          let errMsg = err.response?.data.message;
          showError(errMsg, "danger", true);
          dispatch("is_result_download_file", false);
        } else {
          let errMsg = err.response?.data.message;
          const buffer = Buffer.from(err?.response?.data);
          const object = JSON.parse(buffer.toString("utf-8"));
          showError(object?.message, "danger", true);
          dispatch("is_result_download_file", false);
        }
      });
  };

  return (
    <button className="dropdown-item" onClick={downloadResults}>
      <span>{t("Download Results")} </span>
    </button>
  );
};

export default DownloadResults;
