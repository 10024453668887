import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ErrorComponent from "./ErrorComponent";
import { Spinner } from "../../components/Common";

import "./login.css";

function Login() {
  const { isLoading, error, loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, []);

  if (isLoading) {
    return (
      <div className="main-loader">
        <Spinner />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <ErrorComponent error={error} />
      </div>
    );
  }
  return null;
}

export default Login;
