import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { ModalPopUp } from "../../../../components/Common";
import {
  getParticipantDetail,
  getResultAuditLogs,
} from "../../../../Services/participantsService";
import BackendData from "./BackendData";
import CallLogs from "./CallLogs";
import EmailTrackingData from "./EmailTrackingData";
import "./ParticipantDetail.css";
import ParticipantGuide from "./ParticipantGuide";
import ParticipantStatus from "./ParticipantStatus.js";
import SMSTrackingData from "./SMSTrackingData";

const ParticipantDetail = (props) => {
  const {
    isOpenParticipantDetail,
    ParticipantData,
    state,
    t,
    closeParticipantDetail,
  } = props;

  const [tabIndex, setTabIndex] = useState(0); // Manage active tab index
  const [loading, setLoading] = useState(true);
  const [loadingBackEndData, setLoadingBackEndData] = useState(true);
  const [allCallLogs, setAllCallLogs] = useState([]);
  const [allBackendData, setAllBackendData] = useState([]);
  const [participantGuideData, setParticipantGuideData] = useState([]);
  const [participantStatusData, setParticipantStatusData] = useState([]);
  const [allSMSTrackingData, setAllSMSTrackingData] = useState([]);
  const [allEmailTrackingData, setAllEmailTrackingData] = useState([]);

  useEffect(() => {
    participantAllDetail();
    resultAuditLogs();
  }, []);

  const participantAllDetail = () => {
    getParticipantDetail(ParticipantData.participantId)
      .then((res) => {
        if (res.status === 200) {
          for (let key in res.data) {
            switch (key) {
              case "calls":
                setAllCallLogs(res.data[key]);
                break;
              case "participantStatusTransitionLogs":
                setParticipantStatusData(res.data[key]);
                break;
              case "supervisorGuideTrackingLogs":
                setParticipantGuideData(res.data[key]);
                break;
              case "sms_tracking":
                setAllSMSTrackingData(res.data[key]);
                break;
              case "email_tracking":
                setAllEmailTrackingData(res.data[key]);
                break;
            }
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const resultAuditLogs = () => {
    getResultAuditLogs(ParticipantData.participantId)
      .then((res) => {
        setAllBackendData(res.data);
        setLoadingBackEndData(false);
      })
      .catch((err) => {
        console.log("err", err);
        setAllBackendData([]);
        setLoadingBackEndData(false);
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <ModalPopUp
      show={isOpenParticipantDetail}
      onHide={closeParticipantDetail}
      dialogClassName="participant-detail-container"
      className="participant-detail-container-height"
      centered
      width="1280"
      title={
        <>
          {t("Participant Detail")}
          {ParticipantData.interviewRefNum && (
            <>
              <span className="set-color"> Irn:</span>
              <span>{ParticipantData.interviewRefNum}</span>
            </>
          )}
          {ParticipantData.accesscode && (
            <>
              | <span className="set-color">Access Code:</span>
              <span>{ParticipantData.accesscode}</span>
            </>
          )}
        </>
      }
    >
      <div>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label={t("Call Logs")} />
          <Tab label={t("Participant Guide")} />
          {state.isSystemTenant && <Tab label={t("Backend Data")} />}
          <Tab label={t("Participant status")} />
          <Tab label={t("SMS Tracking")} />
          <Tab label={t("Email Tracking")} />
        </Tabs>

        {/* Render the selected tab's content */}
        {tabIndex === 0 && (
          <CallLogs loading={loading} allCallLogs={allCallLogs} t={t} />
        )}
        {tabIndex === 1 && (
          <ParticipantGuide
            loading={loading}
            participantGuideData={participantGuideData}
            t={t}
            state={state}
          />
        )}
        {tabIndex === 2 && state.isSystemTenant && (
          <BackendData
            loading={loadingBackEndData}
            allBackendData={allBackendData}
            t={t}
          />
        )}
        {tabIndex === (state.isSystemTenant ? 3 : 2) && (
          <ParticipantStatus
            loading={loading}
            participantStatusData={participantStatusData}
            t={t}
          />
        )}
        {tabIndex === (state.isSystemTenant ? 4 : 3) && (
          <SMSTrackingData
            loading={loading}
            allSMSTrackingData={allSMSTrackingData}
            t={t}
          />
        )}
        {tabIndex === (state.isSystemTenant ? 5 : 4) && (
          <EmailTrackingData
            loading={loading}
            allEmailTrackingData={allEmailTrackingData}
            t={t}
          />
        )}
      </div>
    </ModalPopUp>
  );
};

export default ParticipantDetail;
