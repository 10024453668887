import * as actionTypes from "../constants";

const initialState = {
  alertMsg: "",
  variant: "",
  alertStatus: false,
};

export function participant(state = initialState, action) {
  // console.log("actionvalue", action.type);
  switch (action.type) {
    case actionTypes.SMS_MESSAGE:
      //   console.log("Check data loading", action.payload);
      return {
        ...state,
        alertStatus: action.payload.alertStatus,
        alertMsg: action.payload.alertMsg,
        variant: action.payload.variant,
      };
    case actionTypes.SMS_MESSAGE_ERROR:
      return {
        alertStatus: action.payload.alertStatus,
        alertMsg: action.payload.alertMsg,
        variant: action.payload.variant,
      };
    case actionTypes.SMS_ALERT_CLOSE:
      return {
        ...state,
        alertStatus: false,
        alertMsg: "",
        variant: "",
      };
    default:
      return state;
  }
}
