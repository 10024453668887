import React from "react";
import { Button, ModalPopUp } from "../../../../components/Common";

const DeleteLanguageRow = (props) => {
  const { isOpenModal, t, closeModal } = props;

  return (
    <ModalPopUp
      show={isOpenModal}
      onHide={closeModal}
      centered
      title={"Delete Project Language"}
    >
      <div>
        Configuration exist with this language.First remove the configuraiton
        from multilanguage then remove it ?
      </div>
      <div>
        <div className="d-flex p-2">
          <Button
            variant="secondary"
            type="button"
            className="rounded-0 mx-2 cancel-btn text-uppercase"
            onClick={closeModal}
          >
            {t("Cancel")}
          </Button>
        </div>
      </div>
    </ModalPopUp>
  );
};

export default DeleteLanguageRow;
