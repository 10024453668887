import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Date_Format, Date_Time_Format} from "../../../../../../../config";

const OutComeTrackingHistory = (props) => {
  const { response, getAttributeLabel, languageCode } = props;

  const { t } = useTranslation();

  function numberWithCommas(x) {
    if(typeof x==="string"&& x ){
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
   
  }

  return response.map((item, index) => {
    return (
      <div className="mitigate-history card" key={index}>
        <div className="d-flex">
          <p className="mb-1 text-blue" style={{ flex: 1 }}>
          {t("Date Recorded")}  :{" "}
            {item.followUpDate != null
              ? moment(item.followUpDate).local().format(Date_Format)
              : null}
          </p>
          <p className="mb-1 text-blue">
          {item.currencyString&& t("Outcome Value")}{" : "}{item.currencyString}{" "}{numberWithCommas(item.amount)}
          </p>
        </div>
        <p className="mb-1">{item.note} </p>
        <p className="mb-1 text-blue">
          <span className="float-right">
            {item.outcomeTrackingActionId &&
              getAttributeLabel(
                languageCode,
                item.outcomeTrackingActionId
              )}{" "}
            <span>
              Date :{" "}
              {item.createTs != null
                ? moment(item.createTs).local().format(Date_Time_Format)
                : null}
            </span>{" "}
            by <span>[ {item.user.firstName}{" "}{item.user.lastName} ]</span>
          </span>
        </p>
      </div>
    );
  });
};

export default React.memo(OutComeTrackingHistory);
