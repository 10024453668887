import {
  GET_ANALYTIC_REPORT,
  ADD_ANALYTIC_REPORT,
  GET_REPORT_TYPE_LIST,
  GET_PROJECT_LIST,
} from "./constants";
import { initState } from "./init";

export const reportReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ANALYTIC_REPORT:
      return {
        ...state,
        analyticReport: action.payload,
      };
    case GET_REPORT_TYPE_LIST:
      return {
        ...state,
        reportTypeList: action.payload,
      };
    case GET_PROJECT_LIST:
      return {
        ...state,
        projectList: action.payload,
      };
    case ADD_ANALYTIC_REPORT:
      return {
        ...state,
      };

    default:
      return state;
  }
};
