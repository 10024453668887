import { sortByLabel, createDropdownOption } from "../Helper";

const ConfigurationOptions = (state) => {
  let languageTypeOption = state.languageTypeA
    .map((option) => {
      return createDropdownOption(option, true);
    })
    .sort(sortByLabel);

  return {
    languageTypeOption,
  };
};

export default ConfigurationOptions;
