import React from "react";
import CheckboxGroup from "./CheckBoxGroupComponent/CheckboxGroup";
import CheckBoxGroupWithLabel from "./CheckBoxGroupComponent/CheckBoxGroupWithLabel";
import FieldArrayComponent from "./FieldArray";
import "./index.css";
import Input from "./InputComponent/Input";
import InputWithLabel from "./InputComponent/InputWithLabel";
import RadioButtons from "./RadioButtonComponent/RadioButtons";
import RadioButtonsWithLabel from "./RadioButtonComponent/RadioButtonsWithLabel";
import SelectBox from "./SelectBoxComponent/SelectBox";
import SelectBoxWithLabel from "./SelectBoxComponent/SelectBoxWithLabel";
import Textarea from "./TextareaComponent/Textarea";
import TextAreaWithLabel from "./TextareaComponent/TextAreaWithLabel";

const FormikControl = (props) => {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "inputWithLabel":
      return <InputWithLabel {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "textareaWithLabel":
      return <TextAreaWithLabel {...rest} />;
    case "select":
      return <SelectBox {...rest} />;
    case "selectWithLabel":
      return <SelectBoxWithLabel {...rest} />;
    case "radio":
      return <RadioButtons {...rest} />;
    case "radioWithLabel":
      return <RadioButtonsWithLabel {...rest} />;
    case "checkbox":
      return <CheckboxGroup {...rest} />;
    case "checkboxWithLabel":
      return <CheckBoxGroupWithLabel {...rest} />;
    case "fieldArray":
      return <FieldArrayComponent {...rest} />;
    default:
      return null;
  }
};

export default FormikControl;
