import React from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FieldArray, Form } from "formik";
import { Grid2 as Grid } from "@mui/material";
import { Button } from "../../../../components/Common";
import MultiLanguageRowAccordion from "./MultiLanguageRowAccordion";

const MultiLanguageRows = (props) => {
  const { t, formik, state, projectId } = props;
  const { canUserUpdateField } = state;

  const multiLanguageRowObj = {
    language: "",
    projectCode: "",
    interviewServerPrimary: "",
    dialInNumber: "",
    interviewServerBackup: "",
    matchingPhoneNumber: "",
    callDelayBy: "5",
    inboundTelcoId: null,
    phoneNumberStatusId: null,
    ivrOption: null,
    ivrPreambleLanguageId: null,
  };

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
      }}
      className="form-row-container"
    >
      <FieldArray name="projectMultiLanguageConfig">
        {(fieldArrayProps) => {
          const { push, remove, form } = fieldArrayProps;
          const { values } = form;
          const { projectMultiLanguageConfig, projectLanguagesData } = values;
          return (
            <>
              <Grid container>
                {projectMultiLanguageConfig.map((multilanguageRow, index) => (
                  <MultiLanguageRowAccordion
                    index={index}
                    t={t}
                    state={state}
                    key={multilanguageRow.languageConfigId}
                    multilanguageRow={multilanguageRow}
                    projectLanguagesData={projectLanguagesData}
                    projectMultiLanguageConfig={projectMultiLanguageConfig}
                    formik={formik}
                    projectId={projectId}
                    remove={remove}
                  />
                ))}
              </Grid>
              {projectLanguagesData.length > 0 ? (
                <div className="multi-language-container">
                  <Button
                    variant="primary"
                    onClick={() => push(multiLanguageRowObj)}
                    className="add-row-btn btn-sm"
                    disabled={
                      canUserUpdateField
                        ? canUserUpdateField
                        : projectMultiLanguageConfig.length <
                          projectLanguagesData.length
                        ? false
                        : true
                    }
                    icon={faPlus}
                    isEndIcon={true}
                  >
                    <span>{t("Add Row")}</span>
                  </Button>
                </div>
              ) : (
                <div className="no-language">
                  <p>No language configured</p>
                </div>
              )}
            </>
          );
        }}
      </FieldArray>
    </Form>
  );
};

export default MultiLanguageRows;
