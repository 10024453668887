import * as Yup from "yup";

export const getValidateAddReportSchema = (t) => [
  Yup.object({
    reportType: Yup.string().required(t("REPORT_TYPE_IS_REQUIRED")),
  }),
  Yup.object({
    projectId: Yup.array()
      .min(1, t("AT_LEAST_ONE_ITEM_IS_REQUIRED"))
      .required(t("THIS_FIELD_IS_REQUIRED")),
    startDate: Yup.date().required(t("START_DATE_IS_REQUIRED")),
    endDate: Yup.date()
      .required(t("END_DATE_IS_REQUIRED"))
      .test("endDate-check", t("END_DATE_MUST_BE_GREATER"), function (value) {
        const { startDate } = this.parent;
        if (startDate && value) {
          return value >= startDate;
        }
        return true;
      }),
  }),
  Yup.object({
    attributes: Yup.array().min(1, t("AT_LEAST_ONE_COLUMN_MUST_BE_SELECTED")),
  }),
  Yup.object({
    reportName: Yup.string().required(t("REPORT_NAME_IS_REQUIRED")),
  }),
];
