import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../store/constants";
import Main from "./Main";
import { userAuditLoginData } from "../../Services/authFunctions";
import { DOMAIN_URL } from "../../config";
const MainWrapper = () => {
  const {
    isAuthenticated,
    getIdTokenClaims,
    getAccessTokenSilently,
    loginWithRedirect,
    logout
  } = useAuth0();

  const dispatch = useDispatch();

  const [showMainComponent, setShowMainComponent] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      onRedirectCallback();
    }
  }, [isAuthenticated]);

  const onRedirectCallback = async () => {
    const idTokenClaims = await getIdTokenClaims();
    const { __raw: idToken } = idTokenClaims;
    let accessToken = "";
    try {
      accessToken = await getAccessTokenSilently();
    } catch (error) {
      console.error("Error retrieving access token:", error);
    }
    let tokenInfo = {
      access_token: accessToken,
      expires_in: 86400,
      id_token: idToken,
      scope: "openid profile email read:results read:tenant",
      token_type: "Bearer",
    };
    localStorage.setItem("token", JSON.stringify(tokenInfo));
    dispatch(success(tokenInfo));
    if (idToken && accessToken) {
      userAuditLoginData()
        .then((res) => {
          setShowMainComponent(true);
        })
        .catch((err) => {
          console.log("error", err);
          logout({
            logoutParams: { returnTo: DOMAIN_URL },
          });
        });
    } else {
      loginWithRedirect();
    }
  };

  const success = (res) => {
    return {
      type: actionTypes.LOG_IN,
      value: res,
    };
  };
  return <div>{showMainComponent && <Main />}</div>;
};

export default MainWrapper;
