import i18next from "i18next";
import React, { useContext, useEffect, useState } from "react";
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  SwapVert as SwapVertIcon,
} from "@mui/icons-material";
import { Spinner } from "../../../../components/Common";
import { getlanguageId } from "../../../../Helpers";
import i18n from "../../../../locale/i18n";
import { projectInterviewResults } from "../../../../Services/executionService";
import {
  getAllAttributeWithProjectId,
  getProjectQuestions,
  getResultTemplateFlags,
} from "../../../../Services/projectServices";
import { getlaguageAttribute } from "../../../../Services/tenantService";
import { getUserTenantLogo } from "../../../../Services/userServices";
import { CustomTooltip } from "../../../Utility/CustomTooltip";
import FullScreenLoader from "../../../Utility/FullScreenLoader";
import { ResultsContext } from "../ResultsContext";
import {
  getAllResultsPayload,
  getLocalTimezoneOffset,
  getResultsPayload,
} from "../ResultsUtils";
import { displayTImeZoneOption } from "../ResultsUtils/filterOptions";
import "./results.css";
import ResultsFilters from "./ResultsFilters";
import ResultsHeader from "./ResultsHeader";
import ResultsTable from "./ResultsTable";

const Results = () => {
  const [isLanguageSet, setIsLanguageSet] = useState(false);
  const [pdfLogo, setPdfLogo] = useState(null);
  const { resultsState, resultsDispatch, projectDetail } =
    useContext(ResultsContext);
  const { projectId, projectLanguagesData, questionaire_type } = projectDetail;
  const {
    loading,
    perPageCount,
    onHoldCheck,
    searchingText,
    resetFilter,
    applyFilter,
    sortOrder,
    questionList,
    questionNumber,
    loadingFileUpload,
    isPdfDownload,
    onTablePageChange,
    reRender,
    isSelectAllRow,
    preSelectedRow,
    isResultDownload,
    isMarkReview,
    hideReviewedOnly,
  } = resultsState;

  useEffect(() => {
    if (resultsState.riskLevels.length < 1) {
      switch (questionaire_type) {
        case "EXPRESS_QUESTIONNAIRE":
        case "PRECISION_RED_GREEN_QUESTIONNAIRE":
          return dispatch("set_risk_levels", ["R", "G", "NC", "NP", "DC"]);
        default:
          return dispatch("set_risk_levels", [
            "HR",
            "LR",
            "AR",
            "PR",
            "NC",
            "DC",
            "NP",
          ]);
      }
    }
  }, []);

  useEffect(() => {
    getPdfTenantLogo();
    getResultTemplateOptionsFlag();
    setDefaultLanguage();
  }, []);

  useEffect(() => {
    if (isLanguageSet) {
      fetchConfigurationJson();
      fetchAllAttribute();
      fetchTanentAttribute();
      fetchQuestionList();
      fetchProjectResult();
      setDefaultTimeZone();
      //fetchProjectAllResult();
      i18next.on("languageChanged", function (lng) {
        dispatch("set_language", lng);
        fetchTanentAttribute();
      });
    }
  }, [isLanguageSet]);

  /**
   * This useEffect will call when you reset filter ,
   * click on hold result checkbox ,page changes , page size changes
   */
  useEffect(() => {
    if (loading) {
      dispatch("loading_results", false);
      dispatch("selected_result_row", []);
      fetchProjectResult();
    }
  }, [
    perPageCount,
    onTablePageChange,
    onHoldCheck,
    resetFilter,
    applyFilter,
    reRender,
    isMarkReview,
    hideReviewedOnly,
  ]);

  /**
   * This useEffect will call when you click on question sort icon so
   *  it will update text for question
   */
  // useEffect(() => {
  //   if (loading) {
  //     dispatch("selected_result_row", []);
  //     fetchProjectAllResult();
  //   }
  // }, [resetFilter, onHoldCheck, searchingText]);

  useEffect(() => {
    if (
      sortOrder.questionnum ||
      sortOrder.overall ||
      sortOrder.date ||
      sortOrder.irnno
    ) {
      dispatch("loading_results", false);
      dispatch("selected_result_row", []);
      fetchProjectResult();
      dispatch(
        "set_questions_text_for_column",
        prepareQuestionsTextForColumn(questionList)
      );
    }
  }, [sortOrder]);

  /**
   * This useEffect will call when we search Irn and Name of right side filter component
   */
  useEffect(() => {
    let getResult;
    if (loading) {
      dispatch("loading_results", false);
      dispatch("selected_result_row", []);
      getResult = setTimeout(() => {
        fetchProjectResult();
      }, 2000);
    }
    return () => clearTimeout(getResult);
  }, [searchingText]);

  /**
   * This function work as a setter function for state based
   * @param {string} type
   * @param {anyType} payload
   */
  const dispatch = (type, payload) => {
    resultsDispatch({ type: type, payload: payload });
  };

  const setDefaultLanguage = () => {
    let languageIds = [];
    projectLanguagesData.map((languages) => {
      languageIds.push(languages.value);
    });
    dispatch("set_filter_language", languageIds);
    setIsLanguageSet(true);
  };

  const getPdfTenantLogo = () => {
    const getTenantId = sessionStorage.getItem("operatingTenant");
    getUserTenantLogo(getTenantId)
      .then((res) => {
        setPdfLogo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * This function will give configuration json for project and store it in local storage
   */
  const fetchConfigurationJson = () => {
    let configurationJSON = "";
    if (projectDetail.configurationJSON) {
      let parseConfigurationJSON = JSON.parse(projectDetail.configurationJSON);
      if (parseConfigurationJSON.length > 0) {
        configurationJSON = parseConfigurationJSON.find(
          (row) => row.language === i18n.language
        );
      }
    }
    dispatch("set_configuration_json", configurationJSON);
  };

  /**
 * This function will give all attribute {option of every dropdown 
  in projectDetail with multiple language } and set it on local storage
 */
  const fetchAllAttribute = () => {
    getAllAttributeWithProjectId(projectId)
      .then((response) => {
        dispatch("set_all_attributes", response.data.attributes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
 * This function will give all attribute of tanent {option of every dropdown 
   in projectDetail with multiple language } and set it on local storage
 */
  const fetchTanentAttribute = () => {
    let languageShortKey = i18n.language;
    let languageCode = getlanguageId(languageShortKey);
    getlaguageAttribute(languageCode)
      .then((response) => {
        dispatch("set_tenant_attributes", response.data.tenantAttributes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * This function will give to question list and store it in local state
   */
  const fetchQuestionList = () => {
    getProjectQuestions(projectId)
      .then((response) => {
        const questionList = response.data.questions.map((data, index) => {
          data.questionNumber = index + 1;
          return data;
        });

        dispatch("set_question_list", questionList);
        dispatch(
          "set_questions_text_for_column",
          prepareQuestionsTextForColumn(response.data.questions)
        );
      })
      .catch((error) => {
        console.log("err", error);
        let errMsg = error.response?.data?.message;
        showError(errMsg, "danger", true);
      });
  };

  /**
   * This Function will prepare text of question column
   * @param {array} questions
   * @returns
   */
  const prepareQuestionsTextForColumn = (questions) => {
    let questionsLabelStructure = filteredQuestions(questions)
      .sort((a, b) => a.sequence - b.sequence)
      .map((question, index) => {
        return (
          <CustomTooltip title={question.questionText} placement="top">
            <div
              onClick={() => handleQuestionSorting(index + 1)}
              key={question.id}
              className="float-left question-label"
            >
              <span data-tip="" data-for={`test_${index})`}>
                {index + 1}
              </span>
              <span className="question-sort-icon">
                {handleQuestionStyle(index + 1)}
              </span>
            </div>
          </CustomTooltip>
        );
      });
    return questionsLabelStructure;
  };

  const filteredQuestions = (questions) => {
    const filterQuestion = questions.filter((question) => {
      return (
        question.isPertinant === true &&
        question.preInterview === false &&
        question.expectedAnswer !== "Narr"
      );
    });
    return filterQuestion;
  };

  /**
   * This Function is responsible for changing sorting icon style up and down of question column
   * @param {number} clickQuestionNumber
   * @returns
   */
  const handleQuestionStyle = (clickQuestionNumber) => {
    if (questionNumber !== clickQuestionNumber) {
      return (
        <div className="table-sorting-hover-icon">
          <SwapVertIcon />
        </div>
      );
    }
    return (
      <div className="table-sorting-icon">
        {sortOrder.questionnum == "asc" ? (
          <ArrowUpwardIcon />
        ) : (
          <ArrowDownwardIcon />
        )}
      </div>
    );
  };

  /**
   * This function will result fetch data based upon sort value and question number
   * @param {number} clickQuestionNumber
   */
  const handleQuestionSorting = (clickQuestionNumber) => {
    if (questionNumber === clickQuestionNumber) {
      dispatch("set_sort_order", {
        questionnum: sortOrder.questionnum === "asc" ? "desc" : "asc",
      });
    } else {
      dispatch("question_number", clickQuestionNumber);
      dispatch("set_sort_order", {
        questionnum: "asc",
      });
    }
  };

  /**
   * This function will fetch project Result
   */
  const fetchProjectResult = () => {
    const resultsPayload = getResultsPayload(resultsState, projectId);
    delete resultsPayload.allRecords;
    projectInterviewResults(resultsPayload)
      .then((response) => {
        dispatch("set_results", response.data.results);
        dispatch("total_pages_count", getTotalPages(response));
        dispatch("total_results_count", response.data.totalResults);
        dispatch("set_loading", true);
        dispatch("loading_results", true);
        if (isSelectAllRow) {
          const selectedData = response.data.results.filter(
            (res) =>
              !preSelectedRow.some(
                (item) => item.interviewResultId === res.interviewResultId
              )
          );
          dispatch("selected_result_row", selectedData);
        }
      })
      .catch((error) => {
        console.log("err", error);
        let errMsg = error.response?.data?.message;
        showError(errMsg, "danger", true);
      });
  };

  // set default time zone fo result screen asper browser  local
  const setDefaultTimeZone = () => {
    const offsetValue = getLocalTimezoneOffset();
    let filterOffset = displayTImeZoneOption.filter((optionList) => {
      return optionList.offset === offsetValue;
    });
    if (filterOffset.length > 0) {
      dispatch("set_display_timezone", filterOffset[0]);
    }
  };

  /**
   * This function will fetch project Result
   */
  const fetchProjectAllResult = () => {
    const resultsPayload = getAllResultsPayload(resultsState, projectId);
    projectInterviewResults(resultsPayload)
      .then((response) => {
        dispatch("set_all_results_of_project", response.data.results);
      })
      .catch((error) => {
        console.log("err", error);
      });
  };

  /**
   * This function will give you page count
   * @param {object} response
   * @returns
   */
  const getTotalPages = (response) => {
    const totalPages = Math.ceil(
      response.data.totalResults / resultsState.perPageCount
    );
    return totalPages;
  };

  /**
   * This function will show error on top of table
   * @param {string} errMsg
   * @param {string} variant
   * @param {string} status
   */
  const showError = (errMsg, variant, status) => {
    dispatch("set_alert_msg", errMsg);
    dispatch("set_variant", variant);
    dispatch("set_alert_status", status);
  };

  const getResultTemplateOptionsFlag = () => {
    getResultTemplateFlags(projectId)
      .then((res) => {
        dispatch("set_flags_options_data", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      {loadingFileUpload && (
        <div className="file_upload_loader_full_screen">
          <div className="custom-loader">
            <Spinner />
          </div>
          <p>Result Uploading...</p>
        </div>
      )}

      {isPdfDownload && (
        <div className="file_upload_loader_full_screen flex-column">
          <div className="custom-loader">
            <Spinner />
          </div>
          <p className="pdfLoaderMsg">
            Thank you for your patience. We are currently downloading your PDFs
            Zip.
            <br />
            Please wait for a moment.
          </p>
        </div>
      )}
      {isResultDownload && <FullScreenLoader message={"Please Wait"} />}

      {loading ? (
        <div className="w-1200">
          <ResultsHeader
            fetchProjectResult={fetchProjectResult}
            pdfLogo={pdfLogo}
          />
          <ResultsFilters fetchProjectResult={fetchProjectResult} />
          <ResultsTable
            fetchProjectResult={fetchProjectResult}
            pdfLogo={pdfLogo}
          />
        </div>
      ) : (
        <div className="result_loader">
          <Spinner />
        </div>
      )}
    </>
  );
};

export default Results;
