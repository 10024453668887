import * as actionTypes from "../constants";

const initialState = {
  projectList: [],
  stageList: [],
  alertstage: false,
  alertMsg: "",
  variant: "",
  loading: true,
  isProjectCodeExist: false,
  isAccordianUUId: "1",
  isMultiAccordianUUID: "1",
  projectCodeErrors: [],
  scheduleLastUpdateTime: null,
  isStageSetup: false,
  projectPhoneNumberErrors: [],
};

export function projects(state = initialState, action) {
  switch (action.type) {
    case actionTypes.DATA_LOADING:
      // console.log("Check data loading");
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_PROJECTS:
      return {
        ...state.projects,
        projectList: action.payload.projects,
        stageList: action.payload.stages,
        alertstage: action.payload.alertstage,
        alertMsg: action.payload.alertMsg,
        variant: action.payload.variant,
        loading: action.isLoading,
        isProjectCodeExist: false,
        isAccordianUUId: "1",
        isMultiAccordianUUID: "1",
        projectCodeErrors: [],
        scheduleLastUpdateTime: action.payload?.scheduleLastUpdateTime || null,
        projectPhoneNumberErrors: []
      };

    case actionTypes.IS_STAGE_SETUP:
      return {
        ...state,
        isStageSetup: action.payload,
      };

    case actionTypes.ALERT_CLOSE:
      return {
        ...state,
        alertstage: false,
      };
    case actionTypes.IS_PROJECTCODE_EXIST:
      return {
        ...state,
        isProjectCodeExist: true,
      };
    case actionTypes.IS_PROJECTCODE_NOT_EXIST:
      return {
        ...state,
        isProjectCodeExist: false,
      };
    case actionTypes.ACCORDIAN_UUID:
      return {
        ...state,
        isAccordianUUId: (Math.floor(Math.random() * 30) + 1).toString(),
      };
    case actionTypes.MULTI_ACCORDIAN_UUID:
      return {
        ...state,
        isMultiAccordianUUID: (Math.floor(Math.random() * 30) + 1).toString(),
      };
    case actionTypes.PROJECT_CODE_ERRORS:
      return {
        ...state,
        projectCodeErrors: action.payload,
      };
    case actionTypes.PROJECT_PHONE_NUMBER_ERROR:
      return {
        ...state,
        projectPhoneNumberErrors: action.payload
      }
    default:
      return state;
  }
}
