import * as actionTypes from "../constants";
const initialState = {
  authentication: {
    user: null,
  },
  togglePass: false,
  loginError: null,
  loggedIn: false,
  headerLogo: "",
};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOG_IN:
      return {
        ...state,
        loggedIn: true,
        authentication: {
          ...state.authentication,
          user: action.value,
        },
      };

    case actionTypes.LOG_OUT:
      return {
        ...state,
        loggedIn: false,
      };
    case actionTypes.LOG_ERROR:
      return {
        ...state,
        loginError: action.value,
      };
    case actionTypes.CLEAR_ERROR:
      return {
        ...state,
        loginError: null,
      };
    case actionTypes.HEADER_LOGO:
      return {
        ...state,
        headerLogo: action.payload,
      };
    default:
      return state;
  }
}
