import React, { useEffect, useState } from "react";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import { getEmbedDashboardURL } from "../../Services/userServices";
import { Spinner } from "../../components/Common";

const QuickSightDashboard = () => {
  const [embedUrlList, setEmbedUrlList] = useState([]);

  const tenantId = localStorage.getItem("operatingTenant");

  const selectedValue = [
    {
      Name: "TenantId",
      Values: tenantId,
    },
  ];

  useEffect(() => {
    getEmbedDashboardURL()
      .then((response) => {
        console.log("response.data", response.data);
        setEmbedUrlList(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    if (embedUrlList.length > 0) {
      console.log("embedUrlList", embedUrlList);
      embedUrlList.map((embedUrl, index) => {
        setTimeout(() => {
          fetchDashboard(embedUrl, index);
        }, 1000);
      });
    }
  }, [embedUrlList]);

  const fetchDashboard = async (embedUrl, index) => {
    let containerDiv = document.getElementById(
      `dashboard-container${embedUrl}`
    );

    try {
      // Perform asynchronous operations here
      const embeddingContext = await createEmbeddingContext({
        onChange: (changeEvent) => {
          console.log("Context received a change", changeEvent);
        },
      });

      const { embedDashboard } = embeddingContext;

      const frameOptions = {
        url: embedUrl,
        container: containerDiv,
        withIframePlaceholder: true,
        resizeHeightOnSizeChangedEvent: true,
      };

      const contentOptions = {
        parameters: selectedValue,
      };
      await embedDashboard(frameOptions, contentOptions);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      {embedUrlList.length > 0 ? (
        embedUrlList.map((embedUrl) => {
          return (
            <div
              id={`dashboard-container${embedUrl}`}
              key={`dashboard-container_key_${embedUrl}`}
            ></div>
          );
        })
      ) : (
        <div className="main-loader">
          <Spinner />
        </div>
      )}
    </>
  );
};

export default QuickSightDashboard;
