import React from "react";
import Select, { Option, ReactSelectProps } from "react-select";

export const SelectField = ({
  options,
  field,
  form,
  values,
  isDisabled,
  placeholder,
  isSearchable,
}) => {
  console.log("SelectField", field.isSearchable, isSearchable);
  return (
    <Select
      className="basic-multi-select"
      options={options}
      name={field.name}
      value={options.find((option) => option.value === values)}
      onChange={(option) => form.setFieldValue(field.name, option.value)}
      onBlur={() => {
        form.setFieldTouched(field.name);
      }}
      placeholder={placeholder ? placeholder : "Select"}
      menuPlacement="auto"
      //   menuIsOpen={true}
      isDisabled={isDisabled}
      isSearchable={isSearchable ? true : false}
    />
  );
};
