import React, { useContext } from "react";
import { Checkbox } from "@mui/material";
import { ResultsContext } from "../../../ResultsContext";

const HandleResultRowSelection = () => {
  const { resultsState, resultsDispatch } = useContext(ResultsContext);

  const { results, selectedResultRow } = resultsState;

  /**
   * This function work as a setter function for state based
   * @param {string} type
   * @param {anyType} payload
   */
  const dispatch = (type, payload) => {
    resultsDispatch({ type: type, payload: payload });
  };

  const selectResult = (selectedValue) => {
    switch (selectedValue) {
      case "selectAllPageResult":
        dispatch("selected_result_row", results);
        dispatch("selected_all_row", true);
        break;
      case "selectCurrentPageResult":
        dispatch("selected_result_row", results);
        dispatch("mark_review_result_list", results);
        dispatch("selected_all_row", false);
        break;
      case "removeSelectedResult":
        dispatch("selected_result_row", []);
        dispatch("mark_review_result_list", []);
        dispatch("selected_all_row", false);
        break;
    }
  };

  const handleOnRowSelectAll = (e) => {
    if (e.target.checked) {
      dispatch("selected_result_row", results);
      dispatch("pre_selected_row", []);
    } else {
      dispatch("selected_result_row", []);
      dispatch("selected_all_row", false);
    }
  };

  return (
    <Checkbox
      //className="participant select-all-dropdown"
      checked={
        // allResultsOfProject.length === 0
        //   ? false
        //   : allResultsOfProject.length === selectedResultRow.length
        results.length === 0
          ? false
          : selectedResultRow.length === results.length
      }
      type="checkbox"
      name="selectAllCheckbox"
      indeterminate={
        selectedResultRow[0] !== selectedResultRow[1] &&
        results.length !== selectedResultRow.length
      }
      onChange={(event) => handleOnRowSelectAll(event)}
      className="form-check-input"
    />
  );
};

export default HandleResultRowSelection;
