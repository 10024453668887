import React from "react";

const ErrorComponent = ({error}) => {
  return (
    <div className="something-went-wrong">
      <h1>Oops! Something Went Wrong</h1>
      <p>{error}</p>
    </div>
  );
};

export default ErrorComponent;
