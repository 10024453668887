import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import momentTz from "moment-timezone";

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { Formik, Form } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { useGetReport } from "./context/actions";
import {
  ReportTypeStep,
  ProjectAndDateRangeStep,
  SelectAttributesStep,
  ReviewAndConfirmStep,
} from "./components";
import { stepsLabel, addReportInitialValues } from "./resources";
import { Stepper, ModalPopUp, Spinner } from "../Common";
import { getValidateAddReportSchema } from "./validationSchema";

export const AddReport = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { addReport } = useGetReport();

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState({});
  const [disabledButton, setDisabledButton] = useState(true);

  const { id, operatingTenant } = JSON.parse(localStorage.getItem("userInfo"));
  const validateAddReportSchema = useMemo(
    () => getValidateAddReportSchema(t),
    [t, activeStep]
  );

  const formatReportData = async (data) => {
    return {
      report_name: data.reportName,
      table_name: [data.reportType],
      start_date: momentTz
        .tz(
          moment(data.startDate).format("YYYY-MM-DDTHH:mm:ss.SSS"),
          data.timeZone
        )
        .startOf("day")
        .valueOf(),
      end_date: momentTz
        .tz(
          moment(data.endDate).format("YYYY-MM-DDT23:59:59.999"),
          data.timeZone
        )
        .utc()
        .valueOf(),
      created_by: id,
      project_list: data.projectId,
      tenant_ids: [operatingTenant],
      attributes: data.attributes,
      time_zone: data.timeZone,
    };
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const getFormatedValues = await formatReportData(values);
    // console.log("getFormatedValues", getFormatedValues);
    // return;
    await addReport(getFormatedValues, onClose);
    setLoading(false);
  };

  const isStepCompleted = (step) => {
    return completed[step] === true;
  };

  const handleEdit = (step) => {
    setActiveStep(step);
  };

  const handleDisable = (value) => {
    setDisabledButton(value);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <ReportTypeStep handleDisable={handleDisable} />;
      case 1:
        return <ProjectAndDateRangeStep handleDisable={handleDisable} />;
      case 2:
        return <SelectAttributesStep handleDisable={handleDisable} />;
      case 3:
        return (
          <ReviewAndConfirmStep
            handleEdit={handleEdit}
            handleDisable={handleDisable}
          />
        );
      default:
        return <ReportTypeStep />;
    }
  };

  return (
    <ModalPopUp
      show={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="lg"
      width={1082}
      className={"stepper-dialog-wrapper"}
    >
      <Formik
        initialValues={addReportInitialValues}
        validationSchema={validateAddReportSchema[activeStep]}
        onSubmit={(values) => {
          if (activeStep === stepsLabel.length - 1) {
            handleSubmit(values);
          } else {
            setCompleted({ ...completed, [activeStep]: true });
            setActiveStep((prevStep) => prevStep + 1);
          }
        }}
        enableReinitialize
      >
        {({ isValid, dirty }) => (
          <Form>
            <DialogTitle className="stepper-dialog-title">
              <Box>
                <IconButton
                  aria-label="close"
                  onClick={onClose}
                  className="stepper-dialog-close-button"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box mb={4}>
                <Stepper
                  activeStep={activeStep}
                  stepsLabel={stepsLabel}
                  isStepCompleted={isStepCompleted}
                  stepCompleted={completed}
                />
              </Box>
            </DialogTitle>
            <DialogContent className="stepper-dialog-content">
              <Box className={"stepper-dialog-container"}>
                <Box>{getStepContent(activeStep)}</Box>
              </Box>
            </DialogContent>
            <DialogActions className="stepper-dialog-actions">
              <Box display="flex" justifyContent="flex-end">
                {activeStep > 0 && (
                  <Button
                    onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                    variant="outlined"
                    className="mr-3"
                  >
                    {t("BACK")}
                  </Button>
                )}

                {activeStep < stepsLabel.length - 1 ? (
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={disabledButton}
                  >
                    {t("CONTINUE")}
                  </Button>
                ) : (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={
                        !(isValid && dirty) || loading || disabledButton
                      }
                    >
                      {t("SUBMIT")}
                      {loading && (
                        <div className="custom-loader linesLoader loader_inner_button">
                          <Spinner color="#ffffff" size={"19px"} />
                        </div>
                      )}
                    </Button>
                  </>
                )}
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </ModalPopUp>
  );
};
