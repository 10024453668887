import axios from "axios";
import api from "./api";
import { BASE_URL } from "../config";

const authConfig = {
  authUrl: `${BASE_URL}authz-service/userLogin`,
  changePasswordUrl: process.env.REACT_APP_CHANGE_PASSWORD_URL,
  header: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

//via fetch
export const login = (user) => {
  let userData = { ...user };
  return fetch(authConfig.authUrl, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userData),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
    });
};

//via fetch
export const userAuditLoginData = () => {
  return api.post(`user-service/v1/user/login`);
};

export const userAuditLogoutData = () => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let email = JSON.parse(localStorage.getItem("userInfo")).email;
  if (userInfo && email) {
    return api
      .post("user-service/v1/user/logout")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  }
};
export const resetPassword = (email) => {
  let resetData = {
    email: email.emailAdd,
  };
  return axios({
    method: "post",
    url: `${BASE_URL}authz-service/reset-password`,
    data: resetData,
    header: authConfig.header,
  });
};
