import { combineReducers } from "redux";
import { authentication } from "./authReducer";
import { projects } from "./projectsReducer";
import interview from "./interview.reducer";
import general from "./general.reducer";
import { theme } from "./ThemeReducer";
import { participant } from "./participantReducer";

const allReducers = combineReducers({
  theme,
  authentication,
  projects,
  interview,
  general,
  participant,
});
// reference: alligator.io/redux/reset-state-redux/
const reducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    state = undefined;
  }

  return allReducers(state, action);
};

export default reducer;
