import { sortByLabel, createDropdownOption } from "../Helper";

const DropDownOptions = (state, t) => {
  let languageOption = state.languageA
    .map((option) => {
      return createDropdownOption(option);
    })
    .sort(sortByLabel);

  let currencyOptions = state.currencyOptions
    .map((option) => {
      return createDropdownOption(option);
    })
    .sort(sortByLabel);

  let locationOption = state.locationA
    .map((option) => {
      return createDropdownOption(option);
    })
    .sort(sortByLabel);

  let projectTypeOption = state.projectTypeA
    .map((option) => {
      return createDropdownOption(option);
    })
    .sort(sortByLabel);

  let riskExternalOption = state.riskExternalA
    .map((option) => {
      return createDropdownOption(option);
    })
    .sort(sortByLabel);

  let statusOption = state.statusA
    .map((option) => {
      return createDropdownOption(option);
    })
    .sort(sortByLabel);

  let stageOption = state.stageA
    .map((option) => {
      return createDropdownOption(option);
    })
    .sort((a, b) => a.sequence - b.sequence);

  const questionaireTypeOption = [
    {
      id: 1,
      value: "PRECISION_QUESTIONNAIRE",
      label: t("PRECISION_QUESTIONNAIRE"),
    },
    {
      id: 2,
      value: "EXPRESS_QUESTIONNAIRE",
      label: t("EXPRESS_QUESTIONNAIRE"),
    },
    {
      id: 3,
      value: "HYBRID_QUESTIONNAIRE",
      label: t("Hybrid Questionnaire"),
    },
    {
      id: 4,
      value: "PRECISION_RED_GREEN_QUESTIONNAIRE",
      label: t("PRECISION_RED_GREEN_QUESTIONNAIRE"),
    },
    {
      id: 5,
      value: "SURGE_QUESTIONNAIRE",
      label: t("Surge Questionnaire"),
    },
    {
      id: 6,
      value: "PROTECT_QUESTIONNAIRE",
      label: t("Protect Questionnaire"),
    },
  ];

  const templateTypeOption = state.resultConfigurationTemplateOptions.map(
    (option) => {
      // console.log("Test", option);
      return {
        value: option.id,
        label: option.template_name,
      };
    }
  );

  return {
    languageOption,
    locationOption,
    projectTypeOption,
    riskExternalOption,
    statusOption,
    stageOption,
    questionaireTypeOption,
    templateTypeOption,
    currencyOptions,
  };
};

export default DropDownOptions;
