import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ModalPopUp, Spinner } from "../../components/Common";

export const IsConfirm = (props) => {
  const { t } = props;
  const { show, onOkay, isLoading } = props;

  return (
    <ModalPopUp
      show={show}
      onHide={() => onOkay("hide")}
      className="text-center"
    >
      <p className="d-flex justify-content-center text-bold text-center mb-5 modal-box-text">
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="save-change-warning-icon"
        />
        {/* This will end the call immediately, please confirm this is correct. Do you want to terminate this call ? */}
        {props.children}
      </p>

      <button
        className="btn btn-secondary save-modal-btn"
        onClick={() => onOkay(false)}
      >
        {t("Cancel")}
      </button>
      <button
        type="submit"
        className="btn btn-primary mr-2 save-modal-btn"
        onClick={() => onOkay(true)}
      >
        {isLoading ? (
          <div className="custom-loader linesLoader loader_inner_button">
            <Spinner />
          </div>
        ) : (
          t("OK")
        )}
      </button>
    </ModalPopUp>
  );
};

export const IsOK = (props) => {
  const { t } = props;
  const { show, onOkay } = props;

  return (
    <ModalPopUp
      show={show}
      onHide={() => onOkay("hide")}
      className="text-center"
    >
      <p className="d-flex justify-content-center text-bold text-center mb-5 modal-box-text">
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="save-change-warning-icon"
        />

        {props.children}
      </p>
      <button
        type="submit"
        className="btn btn-primary mr-2 save-modal-btn"
        onClick={() => onOkay(true)}
      >
        {t("OK")}
      </button>
    </ModalPopUp>
  );
};
