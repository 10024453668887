import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { ModalPopUp } from "../../Common";

const CustomPromptModal = ({ showModal, hideModal, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <ModalPopUp
      show={showModal}
      onHide={hideModal}
      centered={true}
      backdrop="static"
    >
      <div className="text-center">
        <p className="d-flex justify-content-center text-bold text-center mb-5 modal-box-text">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="save-change-warning-icon"
          />
          {t("Are you sure you want to leave? You have some unsaved changes.")}
        </p>

        <button
          className="btn btn-secondary save-modal-btn"
          onClick={hideModal}
        >
          {t("Cancel")}
        </button>
        <button
          type="submit"
          className="btn btn-primary mr-2 save-modal-btn"
          onClick={onConfirm}
        >
          {t("OK")}
        </button>
      </div>
    </ModalPopUp>
  );
};
export default CustomPromptModal;
