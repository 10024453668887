import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getVersionDetail } from "../../Services/userServices";
import ClearSpeedLogo from "../../assets/images/ClearspeedLogo.png";
import ClearSpeedLogoWhite from "../../assets/images/ClearspeedLogoWhite.png";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      versions: "",
    };
  }
  componentDidMount() {
    getVersionDetail()
      .then((res) => {
        this.setState({
          versions: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { versions } = this.state;
    let { t } = this.props;

    return (
      <div className="w-1200">
        <div className="card">
          <div className="card-body">
            <h6 className="page-title">{t("About")}</h6>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="about-content-box">
              <div className="text-center">
                <img
                  className="img-fluid about-logo"
                  src={
                    this.props.themeColor === "light"
                      ? ClearSpeedLogo
                      : ClearSpeedLogoWhite
                  }
                />
              </div>
              <div className="text-center">
                <div className="mt-4">
                  <h5>Enterprise Web Application</h5>
                  <h6>
                    Version {versions.majorVersion}.{versions.minorVersion}
                  </h6>
                  <h6>Released {versions.releaseDate}</h6>
                </div>
                <div className="mt-4">
                  <p className="mb-1">Email Support:</p>
                  <a href="mailto:ops@clearspeed.com" target="_blank">
                    {versions.email}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    themeColor: state.theme.themeColor,
  };
};
const mapDispatchToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(About))
);
