import api from './api';

const getLookUpList = async (tenantId) => {
return await api.get(`project-service/v1/project/attributes?tenant_id=${tenantId}`)
}

const createLookUp = async (param, tenantId) => {
return api.post(`project-service/v1/project/attributes?tenant_id=${tenantId}`, param);
}

const updateLookUp = async (param, tenantId) => {
return api.put(`project-service/v1/project/attributes?tenant_id=${tenantId}`, param);
}
export { getLookUpList, createLookUp, updateLookUp };