import React, { Component } from "react";
import { connect } from "react-redux";
import i18next from "i18next";
import { filter, get, isEmpty } from "lodash";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { withTranslation } from "react-i18next";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { Grid2 as Grid } from "@mui/material";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {
  Alert as AlertDismissible,
  Button,
  ModalPopUp,
  Spinner,
} from "../../../components/Common";
import { Send_Api_Date_Format } from "../../../config";
import {
  filterJson,
  getlanguageIdFromcode,
  scrollTopWindow,
  sortByLabel,
} from "../../../Helpers/index";
import i18n from "../../../locale/i18n";
import {
  checkMultiLanguageFieldExist,
  getAdditionalList,
  getAttribute,
  getBusinessLine,
  getCallCenterList,
  getProjectDetail,
  getResultConfigListData,
  MobileAppAudioConfig,
  MobileAppConfig,
  MobileAppxlsxConfig,
  updateProject,
} from "../../../Services/projectServices";
import { getlaguageAttribute } from "../../../Services/tenantService";
import { projectActions } from "../../../store/actions/projectActions";
import CustomPrompt from "../../Utility/CustomPrompt/CustomPrompt";
import { IsConfirm } from "../../Utility/ModalPopUp";
import { isPermissionExistsInList } from "../../Utility/userPermission";
import AddAdditionalConfigurationFields from "./AddAdditionalConfigurationFields";
import AdditionalConfiguration from "./AdditionalConfiguration";
import BusinessLine from "./BusinessLine/index.js";
import { createDropdownOption, fileDownload } from "./Helper";
import InternalUseOnly from "./InternalUseOnly";
import IntialProjectDetail from "./IntialProjectDetail";
import ISW from "./ISW";
import MultiLanguageConfiguration from "./MultiLanguageConfiguration";
const hideBusinessLineFeature = true;

class ProjectInfo extends Component {
  MobileAppAudioFileInput = React.createRef();
  MobileAppxlsxFileInput = React.createRef();

  state = {
    projectDetail: {},
    projectId: "",
    departmentA: [],
    statusA: [],
    stageA: [],
    multilingualPhoneA: [],
    integrationMethodA: [],
    riskInternalA: [],
    riskExternalA: [],
    languageA: [],
    locationA: [],
    tagsA: [],
    interviewMethodA: [],
    phoneNoStatusA: [],
    deliveryMethodsA: [],
    irnVerificationMethodsA: [],
    inboundtelcoA: [],
    inboundtelco: [],
    projectTypeA: [],
    callCenterA: [],
    resultConfigurationTemplateOptions: [],
    alertMsg: "",
    alertStatus: false,
    variant: "",
    callCenterOption: [],
    isinternal: [],
    noPermissionmsg: "",
    internalFieldDisabled: true,
    language: i18n.language,
    selectedFile: "", // to store selected file
    selectedASFile: "",
    invalidImage: null, // handle the message of the image validation
    showConfigurationJson: "",
    disablesavebtn: true,
    acessCodeB: [],
    guideURLB: [],
    supervisormsg: "",
    supervisormsg_error: "",
    ISWmsg: "",
    ISWmsg_error: "",
    MobileConfigmsg: "",
    MobileConfigmsg_error: "",
    MobileConfigAudiomsg: "",
    MobileConfigAudiomsg_error: "",
    MobileConfigxlsxmsg: "",
    MobileConfigxlsxmsg_error: "",
    Customer_apiurlError: false,
    disablesavebtnmy: false,
    configurationInitialValues: {
      participant_callback_url: "",
      result_callback_url: "",
      project_callback_url: "",
      participant_verification_url: "",
      security_type: "API_KEY",
      caller_id_check: false,
      client_credential: {
        secret: "",
        audience: "",
        clientid: "",
        tokenuri: "auth0.com/token",
        authourization: "Bearer ",
        scope: "",
      },
    },
    toggleApikeyValue: false,
    toggleSecretkeyValue: false,
    toggleClientIdkeyValue: false,
    disableAssestBtn: false,
    disableAudioBtn: false,
    disableXlsxBtn: false,
    keys: [],
    confirmCloneModal: false,
    confirmDialNumberModal: false,
    languageTypeA: [],
    timer: "",
    canUserUpdateField: false,
    formValues: null,
    formHelpers: null,
    confirmLoading: false,
    confirmDialNumber: [],
    isCancelDisable: false,
    isBusinessLineData: !hideBusinessLineFeature,
    currencyOptions: [],
  };

  componentDidMount() {
    const self = this;
    const id = this.props.match.params.id;
    this.setState({
      projectId: id,
    });
    this.loadAttribute();
    i18next.on("languageChanged", function (lng) {
      console.log("Ing", lng);
      self.setState({ language: lng }, () => {
        self.loadAttribute();
      });
    });
    this.props.setProjectCodeError([]);
    this.props.setProjectPhoneNumberError([]);
    getCallCenterList()
      .then((res) => {
        let callCenterOptions = res.data
          .map((option) => {
            return {
              value: option.id,
              label: option.name,
            };
          })
          .sort(sortByLabel);
        this.setState({
          callCenterA: callCenterOptions,
          callCenterOption: callCenterOptions,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    getAdditionalList()
      .then((res) => {
        // console.log("res.data", res.data);
        self.setState({
          keys: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          alertMsg: err.response.data.message,
          variant: "danger",
          alertStatus: true,
        });
      });

    getProjectDetail(id)
      .then((res) => {
        let tagOptions = res.data.tags.map((option) => {
          return {
            value: option,
            label: option,
          };
        });
        let mergeTwoObject = {
          ...this.state.configurationInitialValues,
          ...res.data.clientAPIUrl1,
        };
        self.setState({
          projectDetail: res.data,
          configurationInitialValues: mergeTwoObject,
          tagsA: tagOptions,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          alertMsg: err.response.data.message,
          variant: "danger",
          alertStatus: true,
        });
      });
    this.getBusinessLineData();
  }

  getBusinessLineData = () => {
    getBusinessLine()
      .then((res) => {
        this.setState(
          {
            businessLineData: res.data,
          },
          () => {
            this.setState({
              isBusinessLineData: !hideBusinessLineFeature,
            });
          }
        );
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  loadAttribute = () => {
    let self = this;
    let languagecode = i18n.language;
    // console.log("languagecode", languagecode);

    let languageId = getlanguageIdFromcode(languagecode);

    getAttribute(languageId)
      .then((res) => {
        self.filterAttribute(res);
      })
      .catch((err) => {
        console.log(err);
      });
    getlaguageAttribute(languageId)
      .then((res) => {
        self.filterLanguageAttribute(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  filterAttribute = (res) => {
    let department = res.data.attributes.filter((item) => {
      return item.attributeType === "DEPARTMENT";
    });
    let status = res.data.attributes.filter((item) => {
      return item.attributeType === "STATUS";
    });
    let stage = res.data.attributes.filter((item) => {
      return item.attributeType === "STAGE";
    });
    let multilingualPhone = res.data.attributes.filter((item) => {
      return item.attributeType === "MULTILINGUAL_PHONE";
    });
    let integrationMethod = filterJson(
      res.data.attributes,
      "INTEGRATION_METHOD"
    );
    let riskObj = res.data.attributes.filter((item) => {
      return item.attributeType === "RISK_OBJECTIVE_EXTERNAL";
    });
    let riskObjint = res.data.attributes.filter((item) => {
      return item.attributeType === "RISK_OBJECTIVE_INTERNAL";
    });
    let language = res.data.attributes.filter((item) => {
      return item.attributeType === "LANGUAGE";
    });

    let interviewMethods = res.data.attributes.filter((item) => {
      return item.attributeType === "INTERVIEW_METHOD";
    });
    let phoneNoStatus = res.data.attributes.filter((item) => {
      return item.attributeType === "PHONE_NUMBER_STATUS";
    });

    let projectType = res.data.attributes.filter((item) => {
      return item.attributeType === "TYPE";
    });
    let deliveryMethods = res.data.attributes.filter((item) => {
      return item.attributeType === "DELIVERY_METHOD";
    });
    let irnVerificationMethods = res.data.attributes.filter((item) => {
      return item.attributeType === "IRN_Validation";
    });
    let locationOption = res.data.attributes.filter((item) => {
      return item.attributeType === "LOCATION";
    });
    let inboundtelcoA = res.data.attributes.filter((item) => {
      return item.attributeType === "TELCO";
    });
    let acessCodeOption = res.data.attributes.filter((item) => {
      return item.attributeType === "ACCESS_CODE_BEHAVIOR";
    });
    let guideURLOption = filterJson(res.data.attributes, "GUIDE_URL");
    // console.log("GUIDE_URL", guideURLOption);

    this.setState({
      departmentA: department,
      statusA: status,
      stageA: stage,
      multilingualPhoneA: multilingualPhone,
      integrationMethodA: integrationMethod,
      riskExternalA: riskObj,
      riskInternalA: riskObjint,
      languageA: language,
      locationA: locationOption,
      interviewMethodA: interviewMethods,
      phoneNoStatusA: phoneNoStatus,
      projectTypeA: projectType,
      deliveryMethodsA: deliveryMethods,
      irnVerificationMethodsA: irnVerificationMethods,
      inboundtelcoA: inboundtelcoA,
      acessCodeB: acessCodeOption,
      guideURLB: guideURLOption,
    });
  };

  filterLanguageAttribute = (res) => {
    let languageType = filterJson(
      res.data.tenantAttributes,
      "PROJECT_LANGAUGE"
    );
    let currencyOptionsFilter = filterJson(
      res.data.tenantAttributes,
      "CURRENCY"
    );

    this.setState({
      languageTypeA: languageType,
      currencyOptions: currencyOptionsFilter,
    });
  };

  getTemplateTypeListOptions = () => {
    let internalFieldTemplateRead =
      this.state.isinternal.indexOf("tenant.result.config.read") >= 0
        ? true
        : false;

    if (internalFieldTemplateRead) {
      getResultConfigListData()
        .then((res) => {
          // console.log("getResultConfigListData res", res.data.template);
          this.setState({
            resultConfigurationTemplateOptions: res.data.template,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.isinternal !== nextProps.statedata.isinternal) {
      const canUserUpdateField = !isPermissionExistsInList(
        nextProps.statedata.isinternal,
        "tenant.project.detail.update"
      );

      this.setState(
        {
          isinternal: nextProps.statedata.isinternal,
          canUserUpdateField: canUserUpdateField,
          noPermissionmsg: "",
        },
        () => {
          this.getTemplateTypeListOptions();
        }
      );
    }
  }

  cancelClick = () => {
    this.props.history.push(`/main/project`);
  };

  showExportDetail = () => {
    this.setState({
      alertMsg: `Error`,
      variant: "danger",
      alertStatus: true,
    });
  };

  submitHandler = (field, actions, setSubmitting, isPhoneCheck) => {
    // console.log("Check form value", field);
    // return;
    let fields = { ...field };
    fields.estStartDate = fields.estStartDate
      ? moment(fields.estStartDate).format(Send_Api_Date_Format)
      : fields.estStartDate;
    fields.estEndDate = fields.estEndDate
      ? moment(fields.estEndDate).format(Send_Api_Date_Format)
      : fields.estEndDate;
    // return;
    this.setState({
      disablesavebtnmy: isPhoneCheck ? false : true,
    });
    // return;
    let values = fields;
    if (values.tags != null) {
      values.tags = values.tags.map((item) => {
        return item.value;
      });
    } else {
      values.tags = [];
    }

    values.creationDate = "";
    updateProject(values, this.state.projectDetail.projectId, actions)
      .then((res) => {
        let tagOptions = res.data.tags.map((option) => {
          return {
            value: option,
            label: option,
          };
        });
        this.setState({
          alertMsg: this.props.t("Project Updated Successfully"),
          variant: "success",
          alertStatus: true,
          projectDetail: res.data,
          tagsA: tagOptions,
          disablesavebtnmy: false,
          confirmDialNumberModal: false,
          formValues: null,
          formHelpers: null,
          confirmLoading: false,
        });
        scrollTopWindow();
        this.props.setProjectCodeError([]);
        setTimeout(() => {
          if (this.props.location.pathname === window.location.pathname) {
            this.props.history.push("/main/project");
          }
        }, 3000);
      })
      .catch((err) => {
        scrollTopWindow();
        this.setState({
          alertMsg: err.response?.data?.message,
          variant: "danger",
          alertStatus: true,
          disablesavebtnmy: false,
          disablesavebtn: false,
          confirmDialNumberModal: false,
          formValues: null,
          formHelpers: null,
        });
        setSubmitting(false);
        this.props.setProjectCodeError([]);
      });
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  b64toBlob = async (b64Data, contentType = "") => {
    const base64Response = await fetch(`data:${contentType};base64,${b64Data}`);
    const blob = await base64Response.blob();
    return blob;
  };

  selectMobileAppFile = (e, uuid, setFieldValue) => {
    let self = this;
    if (e.target.files) {
      /* Get files in array form */
      const getFiles = Array.from(e.target.files);
      let valid = false;
      let sCurExtension = getFiles[0];
      const checkFileType = sCurExtension.name.match(/\.[0-9a-z]+$/i)[0];
      if (checkFileType == ".zip") {
        valid = true;
      }
      if (!valid) {
        alert("File type not supported");
      } else {
        this.setState({
          disableAssestBtn: true,
        });
        /* Map each file to a promise that resolves to an array of image URI's */
        Promise.all(
          getFiles.map((file, index) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.addEventListener("load", (ev) => {
                resolve({
                  path: ev.target.result,
                  mime_type: file.type,
                  name: file.name,
                  file_type: checkFileType,
                });
              });
              reader.addEventListener("error", reject);
              reader.readAsDataURL(file);
            });
          })
        ).then(
          (zipData) => {
            /* Once all promises are resolved, update state with audioData URI array */
            let filename = zipData[0].name;
            MobileAppConfig(uuid, filename)
              .then((res) => {
                if (res.status == 200) {
                  const getDataAgent = zipData[0];
                  const contentTypeAgent = getDataAgent.mime_type;
                  const b64DataAgent = getDataAgent.path;
                  const replaceDataAudioAgent = b64DataAgent.replace(
                    /^data:application\/[a-z]+;base64,/,
                    ""
                  );
                  const blobAgent = this.b64toBlob(
                    replaceDataAudioAgent,
                    contentTypeAgent
                  );
                  fetch(res.data.url, {
                    method: "PUT",
                    body: blobAgent,
                  })
                    .then(function () {
                      setFieldValue("assets_zip_file_name", filename);
                      self.setState(
                        {
                          disableAssestBtn: false,
                          MobileConfigmsg: "File Uploaded Successfully",
                          MobileConfigmsg_error: "",
                        },
                        () => {
                          setTimeout(() => {
                            self.setState({
                              MobileConfigmsg: "",
                            });
                          }, 5000);
                        }
                      );
                    })
                    .catch(function (error) {
                      self.setState(
                        {
                          disableAssestBtn: false,
                          MobileConfigmsg_error:
                            "Error while uploading the file on bucket",
                          MobileConfigmsg: "",
                        },
                        () => {
                          setTimeout(() => {
                            self.setState({
                              MobileConfigmsg_error: "",
                            });
                          }, 5000);
                        }
                      );
                    });
                }
              })
              .catch((err) => {
                self.setState({
                  MobileConfigmsg_error: "error in file upload",
                });
              });
          },
          (error) => {
            console.error(error);
          }
        );
      }
    }
  };

  selectMobileAppAudioZip = (e, uuid, setFieldValue) => {
    e.preventDefault();
    this.setState({ disableAudioBtn: true });
    let MobileAppfileName = this.MobileAppAudioFileInput.current.files[0];
    let formData = new FormData();
    formData.append("questions", MobileAppfileName);
    this.MobileAppAudioFileInput.current.value = "";
    MobileAppAudioConfig(formData, uuid)
      .then((res) => {
        setFieldValue("questions_audio_file_name", MobileAppfileName.name);
        this.setState(
          {
            MobileConfigAudiomsg: res.data.message,
            MobileConfigAudiomsg_error: "",
            disableAudioBtn: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                MobileConfigAudiomsg: "",
              });
            }, 5000);
          }
        );
      })
      .catch((err) => {
        this.setState(
          {
            MobileConfigAudiomsg_error: err.response.data.message,
            MobileConfigAudiomsg: "",
            disableAudioBtn: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                MobileConfigAudiomsg_error: "",
              });
            }, 8000);
          }
        );
      });
  };

  selectMobileAppxlsx = (e, uuid, setFieldValue) => {
    e.preventDefault();
    this.setState({ disableXlsxBtn: true });
    let MobileAppfileName = this.MobileAppxlsxFileInput.current.files[0];
    let formData = new FormData();
    formData.append("config", MobileAppfileName);
    this.MobileAppxlsxFileInput.current.value = "";
    MobileAppxlsxConfig(formData, uuid)
      .then((res) => {
        setFieldValue("mobile_app_xlsx_file_name", MobileAppfileName.name);
        this.setState(
          {
            MobileConfigxlsxmsg: res.data.message,
            MobileConfigxlsxmsg_error: "",
            disableXlsxBtn: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                MobileConfigxlsxmsg: "",
              });
            }, 5000);
          }
        );
      })
      .catch((err) => {
        this.setState(
          {
            MobileConfigxlsxmsg_error: err.response.data.message,
            MobileConfigxlsxmsg: "",
            disableXlsxBtn: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                MobileConfigxlsxmsg_error: "",
              });
            }, 8000);
          }
        );
      });
  };

  handleConfirmationCloneProject = () => {
    this.setState({
      confirmCloneModal: true,
    });
  };

  handleCloneProject = (param) => {
    const now = new Date();
    const nowIso = now.toISOString();
    const nowInMilliseconds = Date.parse(nowIso);
    if (param) {
      this.props.history.push(
        `/main/project/createproject?_C=${nowInMilliseconds}&_ID=${this.props.match.params.id}`
      );
    } else {
      this.setState({
        confirmCloneModal: false,
      });
    }
  };

  handleDialSubmit = (param) => {
    const { formValues, formHelpers } = this.state;
    const isPhoneCheck = true;
    if (formValues && formHelpers && param) {
      this.setState({
        confirmLoading: true,
        isCancelDisable: true,
      });
      this.submitHandler(
        formValues,
        formHelpers.resetForm,
        formHelpers.setSubmitting,
        isPhoneCheck
      );
    } else {
      this.setState({
        confirmDialNumberModal: false,
        formValues: null,
        formHelpers: null,
        disablesavebtn: false,
        confirmLoading: false,
      });
    }
  };

  handleModalClose = () => {
    const { confirmDialNumber } = this.state;
    this.props.setProjectPhoneNumberError(confirmDialNumber);
    this.setState({
      confirmDialNumberModal: false,
      disablesavebtn: false,
    });
  };

  checkDuplicatePhoneNumber = (payload, fields, resetForm, setSubmitting) => {
    checkMultiLanguageFieldExist(payload)
      .then((res) => {
        const phoneNumbers = Object.entries(res.data.phoneNumber)
          .filter(([_, value]) => value !== null)
          .flatMap(([number, value]) =>
            value.map(({ tenant_name, project_name }) => ({
              number,
              tenant_name,
              project_name,
            }))
          );
        if (phoneNumbers.length > 0) {
          this.setState({
            confirmDialNumberModal: true,
            formValues: fields,
            formHelpers: { resetForm, setSubmitting },
            confirmDialNumber: phoneNumbers,
          });
        } else {
          this.submitHandler(fields, resetForm, setSubmitting);
          this.props.setProjectPhoneNumberError([]);
        }
      })
      .catch((err) => {
        this.setState({
          alertMsg: err.response.data.message,
          variant: "danger",
          alertStatus: true,
          disablesavebtnmy: false,
          disablesavebtn: false,
          confirmDialNumberModal: false,
          formValues: null,
          formHelpers: null,
        });
        this.props.setProjectPhoneNumberError([]);
      });
  };

  render() {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const { t } = this.props;
    const noWhiteSpaceRegExp = /^\S*$/;
    let self = this;
    const { confirmCloneModal, confirmDialNumberModal } = this.state;
    let stageOption = this.state.stageA
      .map((option) => {
        return createDropdownOption(option);
      })
      .sort((a, b) => a.sequence - b.sequence);

    // Project Language Delivery dropdown Opion
    let multilingualPhoneOption = this.state.multilingualPhoneA
      .map((option) => {
        return createDropdownOption(option);
      })
      .sort((a, b) => a.sequence - b.sequence);

    let currencyOptions = this.state.currencyOptions
      .map((option) => {
        return createDropdownOption(option);
      })
      .sort((a, b) => a.sequence - b.sequence);

    let statusOption = this.state.statusA
      .map((option) => {
        return createDropdownOption(option);
      })
      .sort(sortByLabel);

    let language = this.state.languageA
      .map((option) => {
        return createDropdownOption(option);
      })
      .sort(sortByLabel);

    let prtProjects = [];
    this.props.projectList.filter((option) => {
      if (
        option.projectId !== parseInt(this.props.match.params.id) &&
        option.parentId !== parseInt(this.props.match.params.id)
      ) {
        prtProjects.push({ value: option.projectId, label: option.name });
      }
    });

    if (prtProjects.length < 1) {
      prtProjects = [{ value: 0, label: "No Project Found" }];
    }
    prtProjects.sort(sortByLabel);

    let selectedCallCenter = [];

    // default Stage option
    let defaultStageOption = stageOption.filter(
      (option) => option.sequence === 1
    );
    // default language Delivery Option
    let deafultMultilingualPhoneOption = multilingualPhoneOption.filter(
      (option) => option.sequence === 2
    );
    // default Status option
    let defaultStatusOption = statusOption.filter(
      (option) => option.sequence === 1
    );
    let defaultLanguageOption = language.filter(
      (option) => option.sequence === 1
    );

    let internalFieldRead =
      this.state.isinternal.indexOf("tenant.project.detail.internal.read") >= 0
        ? true
        : false;
    let internalFieldDisabled =
      this.state.isinternal.indexOf("tenant.project.detail.internal.update") >=
      0
        ? true
        : false;
    let internalFieldObj = {};
    if (internalFieldDisabled) {
      internalFieldObj = {
        deliveryMethodId: this.state.projectDetail.deliveryMethodId || "",
        irnVerificationMethodId:
          this.state.projectDetail.irnVerificationMethodId || "",
        callcenters: selectedCallCenter || "",
        isIrnLength: internalFieldRead,
        irnLength: this.state.projectDetail.irnLength || "",
        briefingQuestion: this.state.projectDetail.briefingQuestion || "",
        isRingCentral: this.state.projectDetail.isRingCentral || "",
        headsetQuestion: this.state.projectDetail.headsetQuestion || "",
        clientAPIUrl: this.state.projectDetail.clientAPIUrl || "",
        clientAPICredential: this.state.projectDetail.clientAPICredential || "",
      };
    }
    let mergeTwoObject = {
      participant_callback_url: "",
      result_callback_url: "",
      project_callback_url: "",
      participant_verification_url: "",
      security_type: "API_KEY",
      caller_id_check: false,
      client_credential: {
        secret: "",
        audience: "",
        clientid: "",
        tokenuri: "auth0.com/token",
        authourization: "Bearer ",
        scope: "",
      },
      multiLanguageId: this.state.projectDetail.multiLanguageId || "",
      additional_fields: [],
      ...this.state.projectDetail.clientAPIUrl1,
    };

    let businessLineInitialValue = {
      businessLine: this.state?.projectDetail?.businessLine || [
        {
          value: "",
          label: "",
          currentFieldKey: "industry",
          currentFieldlabel: "Industry",
        },
      ],
    };

    let initialValue = {
      startDate: this.state.projectDetail.startDate || "",
      tenantId: this.state.projectDetail.tenantId || "",
      name: this.state.projectDetail.name || "",
      resourcesPassword: this.state.projectDetail.resourcesPassword || "",
      description: self.state.projectDetail.description || "",
      note: this.state.projectDetail.note || "",
      languageId:
        this.state.projectDetail.languageId !== null
          ? this.state.projectDetail.languageId
          : language.length > 0
          ? defaultLanguageOption[0].value
          : "",
      parentId: this.state.projectDetail.parentId || "",
      locationId: this.state.projectDetail.locationId || "",
      accessCodeBehaviorId: this.state.projectDetail.accessCodeBehaviorId || "",
      guideURLOptionId: this.state.projectDetail.guideURLOptionId || "",
      configurationJSON: this.state.selectedFile || "",
      departmentId: this.state.projectDetail.departmentId || "",
      typeId: this.state.projectDetail.typeId || "",
      externalRiskObjectiveId:
        this.state.projectDetail.externalRiskObjectiveId || "",
      noOfQuestions: this.state.projectDetail.noOfQuestions || "",
      tags: this.state.tagsA || [],
      estStartDate: this.state.projectDetail.estStartDate || "",
      estEndDate: this.state.projectDetail.estEndDate || "",
      estimatedInterviewTime:
        this.state.projectDetail.estimatedInterviewTime || "",

      closeDate: this.state.projectDetail.closeDate || "",
      estParticipant: this.state.projectDetail.estParticipant || "",
      statusId:
        this.state.projectDetail.statusId !== null
          ? this.state.projectDetail.statusId
          : statusOption.length > 0
          ? defaultStatusOption[0].value
          : "",
      questionaire_type: this.state.projectDetail.questionaire_type || "",
      result_configuration_template_id:
        this.state.projectDetail.result_configuration_template_id || "",
      sender_name: this.state.projectDetail.sender_name || "",
      result_email_recipient:
        this.state.projectDetail.result_email_recipient || "",
      stageId:
        this.state.projectDetail.stageId !== null
          ? this.state.projectDetail.stageId
          : stageOption.length > 0
          ? defaultStageOption[0].value
          : "",
      multilingualPhoneId:
        this.state.projectDetail.multilingualPhoneId !== null
          ? this.state.projectDetail.multilingualPhoneId
          : multilingualPhoneOption.length > 0
          ? deafultMultilingualPhoneOption[0].value
          : "",
      integration_method_id:
        Object.keys(this.state.projectDetail).length !== 0
          ? this.state.projectDetail.integration_method_id
          : "",
      defaultDeliveryMethodId: 2,
      enableEmailCommunication:
        this.state.projectDetail.enableEmailCommunication,
      enable_refresh_accesscode:
        this.state.projectDetail.enable_refresh_accesscode,
      enable_indivisual_result_email:
        this.state.projectDetail.enable_indivisual_result_email || false,
      enableSMSCommunication: this.state.projectDetail.enableSMSCommunication,
      isWebrtcEnable: this.state.projectDetail.isWebrtcEnable || "",
      enablePilot: this.state.projectDetail.enablePilot,
      retake_restricted_24_hours:
        this.state.projectDetail.retake_restricted_24_hours || "",
      mask_overall_result_to_HR_for_AD:
        this.state.projectDetail.mask_overall_result_to_HR_for_AD || false,
      mask_overall_result_to_HR_for_CM:
        this.state.projectDetail.mask_overall_result_to_HR_for_CM || false,
      mask_overall_result_to_HR_for_NC:
        this.state.projectDetail.mask_overall_result_to_HR_for_NC || false,
      enable_pending_result_notification:
        this.state.projectDetail.enable_pending_result_notification || false,
      auto_reminder_time_in_days:
        this.state.projectDetail.auto_reminder_time_in_days || 3,
      auto_reminder_configuration:
        this.state.projectDetail.auto_reminder_configuration || false,
      allow_isw_create_participant:
        this.state.projectDetail.allow_isw_create_participant || false,
      allow_partial_nc_call_after_24_hours:
        this.state.projectDetail.allow_partial_nc_call_after_24_hours || false,
      make_partial_nc_as_complete_nc:
        this.state.projectDetail.make_partial_nc_as_complete_nc || false,
      review_unreview_configuration:
        this.state.projectDetail.review_unreview_configuration || false,
      cm_recordings_configuration:
        this.state.projectDetail.cm_recordings_configuration || false,
      allow_nc_call_after_24_hours:
        this.state.projectDetail.allow_nc_call_after_24_hours || false,
      invite_on_participant_create:
        this.state.projectDetail.invite_on_participant_create,
      sms_invite_on_participant_create:
        this.state.projectDetail.sms_invite_on_participant_create,
      block_irn_on_call_logs: this.state.projectDetail.block_irn_on_call_logs,
      unblock_irn_after_nc_result:
        this.state.projectDetail.unblock_irn_after_nc_result,
      unblock_irn_after_dc_result:
        this.state.projectDetail.unblock_irn_after_dc_result,
      hide_deleted_participant:
        this.state.projectDetail.hide_deleted_participant,
      reverse_np_configuration:
        this.state.projectDetail.reverse_np_configuration,
      riskObjectiveExternal: "test risk objective",
      defaultCustomerInterviewMethodId:
        this.state.projectDetail.defaultCustomerInterviewMethodId || "",
      result_auto_release:
        this.state.projectDetail.result_auto_release || false,
      uuid: this.state.projectDetail.uuid || "",
      clientAPIUrl1: mergeTwoObject,
      isw_xlsx_file_name: this.state.projectDetail.isw_xlsx_file_name,
      questions_audio_file_name:
        this.state.projectDetail.questions_audio_file_name,
      supervisor_xlsx_file_name:
        this.state.projectDetail.supervisor_xlsx_file_name,
      mobile_app_xlsx_file_name:
        this.state.projectDetail.mobile_app_xlsx_file_name,
      assets_zip_file_name: this.state.projectDetail.assets_zip_file_name,
      projectLanguagesData: this.state.projectDetail.projectLanguagesData || [],
      projectMultiLanguageConfig:
        this.state.projectDetail.projectMultiLanguageConfig || [],
      currencyId:
        this.state.projectDetail.currencyId || userInfo?.currencyId || null,
      isBusinessLineOptional:
        this.state.projectDetail.isBusinessLineOptional || false,
      enable_pii_data_in_analytics:
        this.state.projectDetail.enable_pii_data_in_analytics || false,
    };
    let finalInitialValue = {
      ...initialValue,
      ...internalFieldObj,
      ...businessLineInitialValue,
    };
    // get permission for view
    Yup.addMethod(
      Yup.array,
      "uniqueProperty",
      function (propertyPath, message) {
        return this.test("unique", "", function (list) {
          const errors = [];

          list.forEach((item, index) => {
            const propertyValue = get(item, propertyPath);
            if (
              propertyValue &&
              filter(list, [propertyPath, propertyValue]).length > 1
            ) {
              errors.push(
                this.createError({
                  path: `${this.path}[${index}].${propertyPath}`,
                  message,
                })
              );
            }

            if (
              propertyValue &&
              list
                .filter((_, i) => i !== index)
                .some((otherItem) => otherItem.dialInNumber === propertyValue)
            ) {
              errors.push(
                this.createError({
                  path: `${this.path}[${index}].${propertyPath}`,
                  message,
                })
              );
            }

            if (
              propertyValue &&
              list
                .filter((_, i) => i !== index)
                .some(
                  (otherItem) => otherItem.matchingPhoneNumber === propertyValue
                )
            ) {
              errors.push(
                this.createError({
                  path: `${this.path}[${index}].${propertyPath}`,
                  message,
                })
              );
            }
          });

          if (!isEmpty(errors)) {
            throw new Yup.ValidationError(errors);
          }

          return true;
        });
      }
    );

    Yup.addMethod(
      Yup.object,
      "requiredProperty",
      function (propertyPath, message) {
        return this.test("requiredProperty", message, function (value) {
          const { path, createError } = this;
          const propertyValue = propertyPath
            .split(".")
            .reduce(
              (acc, key) =>
                acc && acc[key] !== undefined ? acc[key] : undefined,
              value
            );
          if (
            !propertyValue ||
            (Array.isArray(propertyValue) && propertyValue.length === 0)
          ) {
            return createError({ path: `${path}.${propertyPath}`, message });
          }
          return true;
        });
      }
    );

    const interviewMethodId = this.state.interviewMethodA
      .filter(
        (item) =>
          item.attributeType === "INTERVIEW_METHOD" &&
          item.description.toLowerCase().includes("direct")
      )
      .map((item) => item.attributeId);

    const businessLineSchema = Yup.object().shape({
      value: Yup.string().required(t("BUSINESS_VALIDATION_MESSAGE")),
    });

    return this.state.isinternal.indexOf("tenant.project.detail.read") >= 0 ? (
      <React.Fragment>
        <div className="w-1200">
          <Grid container>
            <Grid item size={12}>
              <AlertDismissible
                msg={this.state.alertMsg}
                variantType={this.state.variant}
                show={this.state.alertStatus}
                close={this.handleAlertClose}
              ></AlertDismissible>
            </Grid>
          </Grid>
          <Formik
            enableReinitialize
            initialValues={finalInitialValue}
            onSubmit={(fields, { resetForm, setSubmitting }) => {
              const dailNumbers = fields.projectMultiLanguageConfig
                .map((item) => item.dialInNumber)
                .filter(Boolean);
              const matchingNumbers = fields.projectMultiLanguageConfig
                .map((item) => item.matchingPhoneNumber)
                .filter(Boolean);
              const phoneNumbers = [...dailNumbers, ...matchingNumbers];
              const payloadData = {
                projectId: this.state.projectId,
                phoneNumber: phoneNumbers,
                interviewMethodId: fields.defaultCustomerInterviewMethodId,
              };
              if (phoneNumbers.length > 0) {
                this.checkDuplicatePhoneNumber(
                  payloadData,
                  fields,
                  resetForm,
                  setSubmitting
                );
              } else {
                this.submitHandler(fields, resetForm, setSubmitting);
              }
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .max(99, t("Project name should be less than 100 character"))
                .required(t("Project Name is required")),
              projectMultiLanguageConfig: Yup.array()
                .of(
                  Yup.object().shape({
                    language: Yup.object()
                      .required("Please select the language")
                      .nullable(),
                    projectCode: Yup.string()
                      .matches(
                        noWhiteSpaceRegExp,
                        t("White Space is not allowed")
                      )
                      .required("Please enter the project code"),

                    ivrOption: Yup.number()
                      .transform((value) => (isNaN(value) ? undefined : value))
                      .nullable()
                      .min(1, "Min value 1")
                      .max(15, t("Max value 15"))
                      .notRequired("IVR option is required"),
                    callDelayBy: Yup.number()
                      .typeError(t("you must specify a number"))
                      .min(1, "Min value 1")
                      .max(15, t("Max value 15"))
                      .required("callDelayBy is required"),
                  })
                )
                .uniqueProperty(
                  "projectCode",
                  "Duplicate project code configured"
                )
                .when("defaultCustomerInterviewMethodId", {
                  is: (val) => !interviewMethodId.includes(val),
                  then: (schema) =>
                    schema
                      .uniqueProperty(
                        "dialInNumber",
                        "Duplicate dial-in number configured"
                      )
                      .uniqueProperty(
                        "matchingPhoneNumber",
                        "Duplicate matching phone number configured"
                      ),
                }),
              clientAPIUrl1: Yup.object({
                additional_fields: Yup.array().of(
                  Yup.object().shape({
                    key: Yup.object()
                      .required(t("Please select the key"))
                      .nullable(),
                  })
                ),
              }),
              sender_name: Yup.string().max(
                30,
                t("Email sender name should be less than 30 character")
              ),
              result_email_recipient: Yup.string().when(
                "enable_indivisual_result_email",
                {
                  is: true,
                  then: () =>
                    Yup.string()
                      .required(t("Emails are required"))
                      .test(
                        "valid-emails",
                        "Invalid email address(es)",
                        (value) => {
                          if (!value) {
                            return true;
                          }
                          const emails = value.split(",");
                          const emailRegex =
                            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                          return emails.every((email) =>
                            emailRegex.test(email.trim())
                          );
                        }
                      ),
                  otherwise: () =>
                    Yup.string().test(
                      "valid-emails",
                      "Invalid email address(es)",
                      (value) => {
                        if (!value) {
                          return true;
                        }
                        const emails = value.split(",");
                        const emailRegex =
                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                        return emails.every((email) =>
                          emailRegex.test(email.trim())
                        );
                      }
                    ),
                }
              ),
              isIrnLength: Yup.boolean(),
              irnLength: Yup.number().when("isIrnLength", {
                is: true,
                then: () =>
                  Yup.number()
                    .typeError(t("you must specify a number"))
                    .min(6, t("Min value 6"))
                    .max(15, t("Max value 15"))
                    .required(t("IRN Length is required")),
              }),
              isBusinessLineOptional: Yup.boolean(),
              businessLine: Yup.array().when("isBusinessLineOptional", {
                is: false,
                then: () => Yup.array().of(businessLineSchema),
                otherwise: () => Yup.array().notRequired(),
              }),
              tags: Yup.array().when(
                ["businessLine", "isBusinessLineOptional"],
                {
                  is: (businessLine, isBusinessLineOptional) =>
                    !isBusinessLineOptional &&
                    businessLine.some((line) => line.value === "other"),
                  then: () =>
                    Yup.array().min(1, t("BUSINESS_VALIDATION_MESSAGE")),
                  otherwise: () => Yup.array().notRequired(),
                }
              ),
            })}
          >
            {(prop) => {
              const {
                dirty,
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                isSubmitting,
                setFieldValue,
              } = prop;
              return (
                <form
                  onSubmit={(event, field) => {
                    event.preventDefault();
                    if (errors.projectMultiLanguageConfig) {
                      this.props.setAccordianUUId();
                      this.props.setMultiAccordianUUId();
                    }
                    handleSubmit(field);
                  }}
                >
                  <CustomPrompt dirty={dirty} />

                  <Field
                    name="tenantId"
                    type="hidden"
                    id="tenantId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tenantId}
                  />
                  <div className="card">
                    <div className="card-body">
                      <Grid container>
                        <Grid item size={9}>
                          <h6 className="page-title">{t("Project Details")}</h6>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <IntialProjectDetail formik={prop} state={this.state} />

                      <Grid container>
                        <Grid item size={12}>
                          <Grid container>
                            {internalFieldRead && (
                              <BusinessLine formik={prop} state={this.state} />
                            )}
                            {internalFieldRead && (
                              <InternalUseOnly
                                formik={prop}
                                state={this.state}
                                showExportDetail={this.showExportDetail}
                              />
                            )}

                            <AdditionalConfiguration
                              formik={prop}
                              state={this.state}
                            />

                            {internalFieldRead && (
                              <AddAdditionalConfigurationFields
                                formik={prop}
                                state={this.state}
                              />
                            )}
                            {internalFieldRead && (
                              <MultiLanguageConfiguration
                                formik={prop}
                                state={this.state}
                              />
                            )}
                            {internalFieldRead && (
                              <Grid item size={12}>
                                <div className="mb-2 mt-2 additional_configuration_accordion">
                                  <Accordion
                                    allowZeroExpanded="true"
                                    allowMultipleExpanded="true"
                                  >
                                    <AccordionItem>
                                      <AccordionItemHeading>
                                        <AccordionItemButton>
                                          <div className="row-header">
                                            <div>
                                              <label className="row-header-title">
                                                {t("Mobile APP Configuration")}
                                              </label>
                                            </div>
                                          </div>
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <Grid
                                          container
                                          className="internal_view_old pt-4 pb-4"
                                          columnSpacing={{
                                            xs: 1,
                                            sm: 2,
                                            md: 3,
                                          }}
                                        >
                                          <Grid item size={3}>
                                            <div className="form-group">
                                              <label htmlFor="file">
                                                {t("Assets File")}
                                              </label>
                                              <div
                                                className="custom-upload"
                                                data-permission={
                                                  this.state.canUserUpdateField
                                                }
                                              >
                                                <input
                                                  name="file"
                                                  accept=".zip"
                                                  type="file"
                                                  disabled={
                                                    this.state.disableAssestBtn
                                                  }
                                                  className="configuration-file-input upload-zip"
                                                  onChange={(e) =>
                                                    this.selectMobileAppFile(
                                                      e,
                                                      values.uuid,
                                                      setFieldValue
                                                    )
                                                  }
                                                  onClick={(event) => {
                                                    event.target.value = null;
                                                  }}
                                                  data-content={t("UPLOAD ZIP")}
                                                />
                                              </div>
                                              {values.assets_zip_file_name !=
                                              null ? (
                                                <div className="file_download_link_parent">
                                                  <p
                                                    onClick={() =>
                                                      fileDownload(
                                                        this.props.match.params
                                                          .id,
                                                        "ASSETS_ZIP",
                                                        values.assets_zip_file_name
                                                      )
                                                    }
                                                  >
                                                    {
                                                      values.assets_zip_file_name
                                                    }
                                                  </p>
                                                </div>
                                              ) : null}

                                              <div className="supervisormsg">
                                                {this.state
                                                  .MobileConfigmsg_error ? (
                                                  <p className="error">
                                                    {
                                                      this.state
                                                        .MobileConfigmsg_error
                                                    }
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {this.state.MobileConfigmsg ? (
                                                  <p className="success">
                                                    {this.state.MobileConfigmsg}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item size={3}>
                                            <div className="form-group">
                                              <label htmlFor="file">
                                                {t("Questions Audio File")}
                                              </label>
                                              <div
                                                className="custom-upload"
                                                data-permission={
                                                  this.state.canUserUpdateField
                                                }
                                              >
                                                <input
                                                  name="file"
                                                  ref={
                                                    this.MobileAppAudioFileInput
                                                  }
                                                  accept=".zip"
                                                  type="file"
                                                  disabled={
                                                    this.state.disableAudioBtn
                                                  }
                                                  className="configuration-file-input upload-zip"
                                                  onChange={(e) =>
                                                    this.selectMobileAppAudioZip(
                                                      e,
                                                      values.uuid,
                                                      setFieldValue
                                                    )
                                                  }
                                                  data-content={t("UPLOAD ZIP")}
                                                />
                                              </div>
                                              {values.questions_audio_file_name !=
                                              null ? (
                                                <div className="file_download_link_parent">
                                                  <p
                                                    onClick={() =>
                                                      fileDownload(
                                                        this.props.match.params
                                                          .id,
                                                        "QUESTIONS_AUDIO",
                                                        values.questions_audio_file_name
                                                      )
                                                    }
                                                  >
                                                    {
                                                      values.questions_audio_file_name
                                                    }
                                                  </p>
                                                </div>
                                              ) : null}
                                              <div className="supervisormsg">
                                                {this.state
                                                  .MobileConfigAudiomsg_error ? (
                                                  <p className="error">
                                                    {
                                                      this.state
                                                        .MobileConfigAudiomsg_error
                                                    }
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {this.state
                                                  .MobileConfigAudiomsg ? (
                                                  <p className="success">
                                                    {
                                                      this.state
                                                        .MobileConfigAudiomsg
                                                    }
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item size={3}>
                                            <div className="form-group">
                                              <label htmlFor="file">
                                                {t("xlsx file")}
                                              </label>
                                              <div
                                                className="custom-upload"
                                                data-permission={
                                                  this.state.canUserUpdateField
                                                }
                                              >
                                                <input
                                                  name="file"
                                                  accept=".xlsx"
                                                  type="file"
                                                  ref={
                                                    this.MobileAppxlsxFileInput
                                                  }
                                                  disabled={
                                                    this.state.disableXlsxBtn
                                                  }
                                                  className="configuration-file-input upload-xlsx"
                                                  onChange={(e) =>
                                                    this.selectMobileAppxlsx(
                                                      e,
                                                      values.uuid,
                                                      setFieldValue
                                                    )
                                                  }
                                                  data-content={t(
                                                    "UPLOAD XLSX"
                                                  )}
                                                />
                                              </div>
                                              {values.mobile_app_xlsx_file_name !=
                                              null ? (
                                                <div className="file_download_link_parent">
                                                  <p
                                                    onClick={() =>
                                                      fileDownload(
                                                        this.props.match.params
                                                          .id,
                                                        "MOBILE_APP_XLSX",
                                                        values.mobile_app_xlsx_file_name
                                                      )
                                                    }
                                                  >
                                                    {
                                                      values.mobile_app_xlsx_file_name
                                                    }
                                                  </p>
                                                </div>
                                              ) : null}
                                              <div className="supervisormsg">
                                                {this.state
                                                  .MobileConfigxlsxmsg_error ? (
                                                  <p className="error">
                                                    {
                                                      this.state
                                                        .MobileConfigxlsxmsg_error
                                                    }
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {this.state
                                                  .MobileConfigxlsxmsg ? (
                                                  <p className="success">
                                                    {
                                                      this.state
                                                        .MobileConfigxlsxmsg
                                                    }
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                  </Accordion>
                                </div>
                              </Grid>
                            )}

                            <ISW formik={prop} state={this.state} />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item size={12}>
                          <div className="form-group float-right">
                            <Button
                              variant="primary"
                              type="submit"
                              className={`rounded-0 mr-4 save-btn text-uppercase button_with_loader ${
                                !this.state.Customer_apiurlError
                                  ? ""
                                  : "disabled disabled_link"
                              }`}
                              disabled={
                                this.state.disablesavebtn == false
                                  ? false
                                  : !dirty ||
                                    isSubmitting ||
                                    this.props.projectCodeErrors.length > 0
                              }
                            >
                              {this.state.disablesavebtnmy ? (
                                <>
                                  {t("Save")}...
                                  <div className="custom-loader linesLoader loader_inner_button">
                                    <Spinner color="#ffffff" size={"19px"} />
                                  </div>
                                </>
                              ) : (
                                <span>{t("Save")}</span>
                              )}
                            </Button>
                            <Button
                              variant="secondary"
                              type="button"
                              className="rounded-0 cancel-btn text-uppercase"
                              onClick={this.cancelClick}
                            >
                              {t("Cancel")}
                            </Button>
                            {internalFieldRead ? (
                              <Button
                                variant="primary"
                                type="button"
                                disabled={this.state.canUserUpdateField}
                                className="rounded-0 cancel-btn text-uppercase ml-4"
                                onClick={this.handleConfirmationCloneProject}
                              >
                                {t("Clone")}
                              </Button>
                            ) : null}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
        {confirmCloneModal ? (
          <IsConfirm
            show={confirmCloneModal}
            onOkay={this.handleCloneProject}
            t={t}
          >
            {t("PROJECT_CLONE_CONFIRM_MESSAGE")}
          </IsConfirm>
        ) : null}
        <ModalPopUp close={this.handleModalClose} show={confirmDialNumberModal}>
          <div>
            {this.state.confirmDialNumber.map((item, index) => (
              <p key={index} className="d-flex text-bold mb-5 modal-box-text">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  className="save-change-warning-icon"
                />
                {t("PROJECT_PHONE_NUMBER__CONFIRM_MESSAGE", {
                  number: item.number,
                  project_name: item.project_name,
                  tenant_name: item.tenant_name,
                })}
              </p>
            ))}
          </div>
          <div className="float-right">
            <Button
              variant="secondary"
              type="button"
              className={`rounded-0 cancel-btn text-uppercase mr-4 ${
                this.state.isCancelDisable ? "disabled" : ""
              }`}
              onClick={this.handleModalClose}
              disabled={this.state.isCancelDisable}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={this.handleDialSubmit}
            >
              {this.state.confirmLoading ? (
                <>
                  {t("Save")}...
                  <div className="custom-loader linesLoader loader_inner_button">
                    <Spinner color="#ffffff" size={"19px"} />
                  </div>
                </>
              ) : (
                <span>{t("Save")}</span>
              )}
            </Button>
          </div>
        </ModalPopUp>
      </React.Fragment>
    ) : (
      this.state.noPermissionmsg
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projectList: state.projects.projectList,
    isProjectCodeExist: state.projects?.isProjectCodeExist,
    projectCodeErrors: state.projects?.projectCodeErrors,
    projectPhoneNumberErrors: state.projects?.projectPhoneNumberErrors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setAccordianUUId: () => dispatch(projectActions.setAccordianUUId()),
    setMultiAccordianUUId: () =>
      dispatch(projectActions.setMultiAccordianUUId()),
    setProjectCodeError: (projectCodeError) =>
      dispatch(projectActions.setProjectCodeError(projectCodeError)),
    setProjectPhoneNumberError: (phoneNumberError) =>
      dispatch(projectActions.setProjectPhoneNumberError(phoneNumberError)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ProjectInfo)
);
