import React, { useState, useEffect } from "react";
import moment from "moment";
import { Date_Time_Format } from "../../config/index";
import { useTranslation } from "react-i18next";

function TimeDifferenceCalculator({ epochTimestamp }) {
  const [timeDifference, setTimeDifference] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    // Convert epoch timestamp to moment object with local timezone
    // const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // console.log("userTimeZone", userTimeZone);
    // const utcOffsetInMinutes = moment.tz(userTimeZone).utcOffset();
    // console.log("userTimeZone utcOffsetInMinutes", utcOffsetInMinutes);

    // const epochMoment = moment
    //   .unix(epochTimestamp / 1000)
    //   .utcOffset(utcOffsetInMinutes)
    //   .format(Date_Time_Format);
    // const currentMoment = moment();
    const difference = moment().diff(epochTimestamp);
    setTimeDifference(difference);
  }, [epochTimestamp]);

  const formatTimeDifference = () => {
    return moment.duration(timeDifference).humanize();
  };

  return (
    <>
      <>
        {t("Updated")}&nbsp;
        {timeDifference !== null ? formatTimeDifference() : "Calculating..."}
        &nbsp;{t("ago")}
      </>
    </>
  );
}

export default TimeDifferenceCalculator;
