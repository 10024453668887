import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Grid2 as Grid, Box } from "@mui/material";
import { useFormikContext, ErrorMessage, Field } from "formik";
import { useReportContext } from "../context/ReportContext";
import StandardDatePicker from "../../Utility/StandardDatePicker";
import { formatDate } from "../../Utility/DatePickerFunctions";
import { SelectField } from "../../Utility/selectField";
import { SelectBox } from "../../Common";

const ProjectAndDateRangeStep = ({ handleDisable }) => {
  const { t } = useTranslation();
  const { state: reportState } = useReportContext();
  const { values, setFieldValue, errors, handleBlur } = useFormikContext();
  const {
    projectList: { data, loading },
  } = reportState;
  const { projects } = data;
  const [timeZone, setTimeZone] = useState([]);

  useEffect(() => {
    const getTZ = moment.tz.names();
    const createTimeZoneDropdownOptions =
      getTZ &&
      getTZ.map((option) => {
        return {
          value: option,
          label: option,
        };
      });
    setTimeZone(createTimeZoneDropdownOptions);
  }, []);

  useEffect(() => {
    if (checkEmptyFields(values)) {
      handleDisable(true);
    } else {
      handleDisable(false);
    }
  }, [values]);

  const checkEmptyFields = (obj) => {
    return obj.projectId.length === 0 || obj.endDate === "";
  };

  const createDropdownOption = (option) => {
    return {
      value: option.projectId,
      label: option.name,
      active: option.active,
    };
  };

  let projectListOption =
    projects &&
    projects.map((option) => {
      return createDropdownOption(option);
    });

  const handleSelectProject = (e) => {
    setFieldValue("projectId", e);
  };

  const getMaxEndDate = () => {
    if (values.startDate) {
      const startDate = moment(values.startDate);
      const maxDate = startDate.add(90, "days").toDate();
      const today = new Date();
      return maxDate > today ? today : maxDate;
    }
    return new Date();
  };

  const handleSelectChange = (e) => {
    setFieldValue("projectId", e);
    handleDisable(false);
  };

  // console.log("selectedValue values", errors, values);

  return (
    <div className="content-max-width-stepper">
      <Box sx={{ mb: 2 }}>
        <div className="dialog-step-info">
          {t("DATE_RANGE_AND_PROJECT_INFO")}
        </div>
      </Box>
      <Grid container>
        <Grid item size={12}>
          <Box mb={4}>
            <SelectBox
              options={projectListOption}
              fieldName={"projectId"}
              values={values.projectId}
              fieldLabel={t("PROJECTS_LABEL")}
              required={true}
              multiSelect={true}
              chip={true}
              isAdditionalFlag={true}
              handleChange={handleSelectChange}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item size={12}>
          <label className="required">{t("PARTICIPANT_CREATION_TIME")}</label>
        </Grid>
      </Grid>
      <Box className="date-range-section">
        <Grid container className="" spacing={2}>
          <Grid item size={4}>
            <div className="form-group">
              <label className="required" htmlFor="startDate">
                {t("Start Date")}
              </label>
              <StandardDatePicker
                dateFormat={formatDate()}
                placeholderText={t("Start Date")}
                autoComplete="off"
                className="form-control w-100 "
                name="startDate"
                onChange={(selectedValue) => {
                  setFieldValue("startDate", selectedValue);
                  setFieldValue("endDate", "");
                  handleDisable(false);
                }}
                selected={values.startDate ? new Date(values.startDate) : null}
                maxDate={new Date()}
              />
              <div className="error">
                <ErrorMessage name="startDate" />
              </div>
            </div>
          </Grid>
          <Grid item size={4}>
            <div className="form-group">
              <label className="required" htmlFor="endDate">
                {t("End Date")}
              </label>
              <StandardDatePicker
                dateFormat={formatDate()}
                placeholderText={t("End Date")}
                id="endDate"
                name="endDate"
                autoComplete="off"
                className="form-control w-100"
                onChange={(selectedValue) => {
                  setFieldValue("endDate", selectedValue), handleDisable(false);
                }}
                selected={values.endDate}
                maxDate={getMaxEndDate()}
              />
              <div className="error">
                <ErrorMessage name="endDate" />
              </div>
            </div>
          </Grid>
          <Grid item size={4}>
            <div className="form-group">
              <label htmlFor="timeZone">{t("SELECT_TIMEZONE")}</label>
              <Field
                id={"timeZone"}
                name={"timeZone"}
                onBlur={handleBlur}
                component={SelectField}
                options={timeZone}
                values={values.timeZone}
                isSearchable={true}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default ProjectAndDateRangeStep;
