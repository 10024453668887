import React, { useContext } from "react";
import { ResultsContext } from "../../../../ResultsContext";
import i18n from "../../../../../../../locale/i18n";
export const MarkReviewResult = () => {
  const { resultsDispatch, resultsState } = useContext(ResultsContext);
  const { selectMarkReviewResult, isSelectAllRow, selectedResultRow } =
    resultsState;
  const dispatch = (type, payload) => {
    resultsDispatch({ type: type, payload: payload });
  };

  const handleMarkReview = (type) => {
    const markReviewAction = type === "unreviewed" ? "Unreviewed" : "Reviewed";
    dispatch("result_review_modal_show", true);
    dispatch("mark_review_action", markReviewAction);
    dispatch("mark_review_result_list", selectMarkReviewResult);
  };

  const allMarked = selectMarkReviewResult.filter(({ reviewed }) => reviewed);
  const allUnmarked = selectMarkReviewResult.filter(({ reviewed }) => !reviewed);

  const hasMarked = allMarked.length < 1;
  const hasUnmarked = allUnmarked.length < 1;

  /* Marks the provided cases as reviewed */
  const markReview = hasUnmarked;
  /* Marks the provided cases as unreviewed */
  const markUnReview = hasMarked;

  return (
    <>
      <button
        disabled={!selectedResultRow.length > 0 || isSelectAllRow || markReview}
        className="dropdown-item"
        onClick={() => handleMarkReview("reviewed")}
      >
        <span>{i18n.t("MARK_REVIEWED")} </span>
      </button>
      <button
        disabled={
          !selectedResultRow.length > 0 || isSelectAllRow || markUnReview
        }
        className="dropdown-item"
        onClick={() => handleMarkReview("unreviewed")}
      >
        <span>{i18n.t("MARK_UNREVIEWED")} </span>
      </button>
    </>
  );
};
