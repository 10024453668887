import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Grid2 as Grid } from "@mui/material";
import {
  Alert as AlertDismissible,
  Button,
  ModalPopUp,
  Spinner,
} from "../../../components/Common";
import { scrollTopWindow } from "../../../Helpers/index";
import {
  getParticipant,
  updateParticipant,
} from "../../../Services/participantsService";
import AddEditParticipant from "./AddEditParticipant";

class EditParticipant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participant: [],
      alertMsg: "",
      alertStatus: false,
      variant: "",
      isinternal: [],
      noPermissionmsg: "Loading...",
      disablesavebtn: false,
      isTestParticipant: true,
      showModalPopup: false,
      isLoading: {},
      formValues: null,
      formSubmitProps: null,
      isEmailChanged: false,
      isPhoneNumberChanged: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isinternal !== nextProps.statedata.isinternal) {
      this.setState({
        isinternal: nextProps.statedata.isinternal,
        noPermissionmsg: "",
      });
    }
  }
  componentDidMount() {
    let participantId = parseInt(this.props.match.params.id);
    getParticipant(participantId)
      .then((res) => {
        this.setState({
          participant: res.data,
          isTestParticipant: res.data.is_test_participant,
        });
      })
      .catch((err) => {
        console.log("error in get participant");
      });
  }

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };
  onCancelParticipant = () => {
    this.props.history.push(`/main/project/${this.props.id}/participants`);
  };

  editParticipant = (fields, submitProps, isModalSubmit, resendSmsEmail) => {
    this.setState({
      disablesavebtn: isModalSubmit ? false : true,
    });
    let participantId = parseInt(this.props.match.params.id);
    const payLoad = {
      ...fields,
      sent_invite_on_update: resendSmsEmail ? resendSmsEmail : false,
    };
    updateParticipant(payLoad, participantId)
      .then((res) => {
        if (res.status == 200) {
          this.setState({
            participant: res.data,
            alertMsg: "Participant Updated Successfully!",
            variant: "success",
            alertStatus: true,
            disablesavebtn: false,
            showModalPopup: false,
            isEmailChanged: false,
            isPhoneNumberChanged: false,
          });
          scrollTopWindow();
          submitProps.setSubmitting(false);
          submitProps.resetForm();
          setTimeout(() => {
            if (this.props.location.pathname === window.location.pathname) {
              this.props.history.push(
                `/main/project/${this.props.id}/participants`
              );
            }
          }, 3000);
        }
      })
      .catch((err) => {
        this.setState({
          alertMsg: err.response.data.message
            ? err.response.data.message
            : "Something went wrong",
          variant: "danger",
          alertStatus: true,
          disablesavebtn: false,
          showModalPopup: false,
          isEmailChanged: false,
          isPhoneNumberChanged: false,
        });
      });
  };

  handleSubmit = (fields, submitProps) => {
    const { email, phoneNumber } = this.state.participant;
    const { projectDetail } = this.props.statedata;
    const { enableSMSCommunication, enableEmailCommunication } = projectDetail;
    const isSameFields =
      (email.toLowerCase() === fields.email.toLowerCase() ||
        fields.email == "") &&
      (phoneNumber === fields.phoneNumber || fields.phoneNumber == "");
    const isCommunicationDisabled =
      !enableSMSCommunication && !enableEmailCommunication;
    const isEditParticipant = isSameFields || isCommunicationDisabled;
    const isEmailChanged = email.toLowerCase() !== fields.email.toLowerCase();
    const isPhoneNumberChanged = phoneNumber !== fields.phoneNumber;
    const isCommunicationEnabled =
      (isEmailChanged && !enableEmailCommunication) ||
      (isPhoneNumberChanged && !enableSMSCommunication);
    if (isEditParticipant || isCommunicationEnabled) {
      this.editParticipant(fields, submitProps);
    } else {
      this.setState({
        showModalPopup: true,
        formValues: fields,
        formSubmitProps: submitProps,
        isEmailChanged: isEmailChanged,
        isPhoneNumberChanged: isPhoneNumberChanged,
      });
    }
  };

  handleModalClose = () => {
    this.setState({
      showModalPopup: false,
    });
  };

  handleModalPopupSubmit = (type) => {
    const { formValues, formSubmitProps } = this.state;
    const isModalSubmit = true;
    this.setState({
      isLoading:
        type === "without-resend" ? { withoutResend: true } : { resend: true },
    });
    const resendSmsEmail = type === "with-resend" ? true : false;
    this.editParticipant(
      formValues,
      formSubmitProps,
      isModalSubmit,
      resendSmsEmail
    );
  };

  shouldComponentUpdate() {
    return true;
  }

  getMessage(type) {
    const { t } = this.props;
    if (this.state.isEmailChanged && this.state.isPhoneNumberChanged) {
      return type === "title" ? t("UPDATE_EMAIL_SMS") : t("SEND_EMAIL_SMS");
    }
    if (this.state.isEmailChanged) {
      return type === "title" ? t("UPDATE_EMAIL") : t("SEND_EMAIL");
    }
    return type === "title" ? t("UPDATE_SMS") : t("SEND_SMS");
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Grid container>
          <Grid item size={12}>
            <AlertDismissible
              msg={this.state.alertMsg}
              variantType={this.state.variant}
              show={this.state.alertStatus}
              close={this.handleAlertClose}
            ></AlertDismissible>
          </Grid>
        </Grid>

        <AddEditParticipant
          title={"Edit"}
          {...this.props}
          participant={this.state.participant}
          isTestParticipant={this.state.isTestParticipant}
          handleSubmit={this.handleSubmit}
          onCancelClick={this.onCancelParticipant}
          disableIrn={true}
          disablesavebtn={this.state.disablesavebtn}
        />

        <ModalPopUp
          close={this.handleModalClose}
          show={this.state.showModalPopup}
        >
          <div>
            <p className="d-flex text-bold mb-5 modal-box-text">
              {this.getMessage("title")}
            </p>
          </div>
          <div className="float-right">
            <Button
              variant="primary"
              type="submit"
              className="m-2 text-uppercase"
              onClick={() => this.handleModalPopupSubmit("without-resend")}
            >
              {this.state.isLoading.withoutResend ? (
                <>
                  {t("Save")}...
                  <div className="custom-loader linesLoader loader_inner_button">
                    <Spinner color="#ffffff" size={"19px"} />
                  </div>
                </>
              ) : (
                <span>{t("Save")}</span>
              )}
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={() => this.handleModalPopupSubmit("with-resend")}
              className="m-2 text-uppercase"
            >
              {this.state.isLoading.resend ? (
                <>
                  <span>{this.getMessage("button")}</span>
                  <div className="custom-loader linesLoader loader_inner_button">
                    <Spinner color="#ffffff" size={"19px"} />
                  </div>
                </>
              ) : (
                <span>{this.getMessage("button")}</span>
              )}
            </Button>
            <Button
              variant="secondary"
              type="button"
              className="rounded-0 m-2 cancel-btn text-uppercase"
              onClick={this.handleModalClose}
            >
              {t("Cancel")}
            </Button>
          </div>
        </ModalPopUp>
      </div>
    );
  }
}

export default withRouter(withTranslation()(EditParticipant));
