import React, { useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useFormikContext, ErrorMessage, Field } from "formik";
import { Grid2 as Grid, Divider, Box, IconButton } from "@mui/material";
import { useReportContext } from "../context/ReportContext";
import { Date_Format } from "../../../config/index.js";
import EditIcon from "@mui/icons-material/Edit";

const ReviewAndConfirmStep = ({ handleEdit, handleDisable }) => {
  const { t } = useTranslation();
  const { state: reportState } = useReportContext();
  const { values, handleChange, handleBlur } = useFormikContext();

  const {
    reportTypeList: { data },
    projectList: {
      data: { projects },
    },
  } = reportState;

  const getReportTypeName = (key) => {
    const getData = data && data?.find((value) => value?.table?.key === key);
    return getData.table?.name;
  };

  const projectValues =
    projects &&
    projects.filter((item) => values.projectId.includes(item.projectId));

  useEffect(() => {
    if (checkEmptyFields(values)) {
      handleDisable(true);
    } else {
      handleDisable(false);
    }
  }, [values.reportName]);

  const checkEmptyFields = (obj) => {
    return obj.reportName === "";
  };

  return (
    <div className="content-max-width-stepper">
      <Box sx={{ mb: 2 }}>
        <div className="dialog-step-info">{t("REVIEW_AND_SUBMIT_INFO")}</div>
      </Box>
      <Grid container>
        <Grid item size={6}>
          <div>
            <div className="form-group">
              <label className="required" htmlFor="reportName">
                {t("REPORT_NAME")}
              </label>
              <Field
                name="reportName"
                type="text"
                id="reportName"
                autoComplete="off"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.reportName}
              />
              <div className="error">
                <ErrorMessage name="reportName" />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Divider />
      <Box mt={2} mb={2}></Box>
      <Grid container>
        <Grid item size={3}>
          <div>
            <strong>{t("REPORT_TYPE")}</strong>
            <Box className="inline-box">
              <IconButton
                className="edit-icon-button-confirm-step"
                onClick={() => handleEdit(0)}
              >
                <EditIcon className="edit-icon-confirm-step" />
              </IconButton>
            </Box>
          </div>
          <div>{getReportTypeName(values.reportType)}</div>
        </Grid>
        <Grid item size={4}>
          <div>
            <strong>{t("PROJECTS_LABEL")}</strong>
            <Box className="inline-box">
              <IconButton
                className="edit-icon-button-confirm-step"
                onClick={() => handleEdit(1)}
              >
                <EditIcon className="edit-icon-confirm-step" />
              </IconButton>
            </Box>
          </div>
          <div>
            <ul className="review-project-list">
              {projectValues &&
                projectValues.map((value, index) => (
                  <li key={`project_values_map_${index}`}>{value.name}</li>
                ))}
            </ul>
          </div>
        </Grid>
        <Grid item size={3}>
          <div>
            <strong>{t("DATA_RANGE")}</strong>
            <Box className="inline-box">
              <IconButton
                className="edit-icon-button-confirm-step"
                onClick={() => handleEdit(1)}
              >
                <EditIcon className="edit-icon-confirm-step" />
              </IconButton>
            </Box>
          </div>
          <div>
            {moment(values.startDate).format(`${Date_Format}`)} to{" "}
            {moment(values.endDate).format(`${Date_Format}`)}
          </div>
        </Grid>
        <Grid item size={2}>
          <div>
            <strong>{t("TIMEZONE")}</strong>
            <Box className="inline-box">
              <IconButton
                className="edit-icon-button-confirm-step"
                onClick={() => handleEdit(1)}
              >
                <EditIcon className="edit-icon-confirm-step" />
              </IconButton>
            </Box>
          </div>
          <div>{values.timeZone}</div>
        </Grid>
      </Grid>
      <Box mt={2} mb={2}></Box>
      <Grid container>
        <Grid item={12}>
          <div>
            <strong>{t("ATTRIBUTES")}</strong>
            <Box className="inline-box">
              <IconButton
                className="edit-icon-button-confirm-step"
                onClick={() => handleEdit(2)}
              >
                <EditIcon className="edit-icon-confirm-step" />
              </IconButton>
            </Box>
          </div>
          <div className="d-flex attributes-section-list">
            {values &&
              values.attributes.map((attribute, index) => (
                <div
                  className="attribute-list-tag"
                  key={`review-attributes-${index}`}
                >
                  {attribute}
                </div>
              ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReviewAndConfirmStep;
