import { useTranslation } from "react-i18next";
import * as FileSaver from "file-saver";
import * as reportConstants from "./constants";
import * as reportService from "./services";
import { useReportContext } from "./ReportContext";
import { useCustomSnackbar } from "../../Common";

export const useGetReport = () => {
  const { t } = useTranslation();
  const showSnackbar = useCustomSnackbar();
  const { dispatch: reportDispatch } = useReportContext();

  const getReports = async () => {
    reportDispatch({
      type: reportConstants.GET_ANALYTIC_REPORT,
      payload: { data: [], loading: true },
    });
    try {
      const res = await reportService.getReportHistory();
      const { data: reports } = res?.data?.response;
      reportDispatch({
        type: reportConstants.GET_ANALYTIC_REPORT,
        payload: { data: reports, loading: false },
      });
    } catch (err) {
      console.error(err);
      reportDispatch({
        type: reportConstants.GET_ANALYTIC_REPORT,
        payload: { data: [], loading: false },
      });
    }
  };

  const getReportTypes = async () => {
    reportDispatch({
      type: reportConstants.GET_REPORT_TYPE_LIST,
      payload: { data: [], loading: true },
    });
    try {
      const res = await reportService.getReportTypeList();
      const { data } = res?.data?.response;
      reportDispatch({
        type: reportConstants.GET_REPORT_TYPE_LIST,
        payload: { data: data, loading: false },
      });
    } catch (err) {
      console.error(err);
      reportDispatch({
        type: reportConstants.GET_REPORT_TYPE_LIST,
        payload: { data: [], loading: false },
      });
    }
  };

  const getProjectList = async () => {
    reportDispatch({
      type: reportConstants.GET_PROJECT_LIST,
      payload: { data: {}, loading: true },
    });
    try {
      const data = await reportService.getProjectList();
      // console.log("getProjectList data", data.data);
      reportDispatch({
        type: reportConstants.GET_PROJECT_LIST,
        payload: { data: data.data, loading: false },
      });
    } catch (err) {
      console.error(err);
      reportDispatch({
        type: reportConstants.GET_PROJECT_LIST,
        payload: { data: {}, loading: false },
      });
    }
  };

  const addReport = async (values, onClose) => {
    try {
      await reportService.createReport(values);
      await getReports();
      showSnackbar(t("ADD_REPORT_SUCCESS_MESSAGE"), "success");
      onClose();
    } catch (err) {
      showSnackbar(
        err?.response?.data?.message || t("Something went wrong"),
        "error"
      );
    }
  };

  const getDownloadReportUrl = async (id, onClose, fileName) => {
    try {
      const res = await reportService.getDownloadUrl(id);
      const downloadUrl = res.data?.response?.data;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const blobResponse = await fetch(downloadUrl);
      const blobData = await blobResponse.blob();
      const blob = new Blob([blobData], { type: fileType });

      FileSaver.saveAs(blob, `${fileName}.csv`);

      showSnackbar(t("FILE_DOWNLOAD_SUCCESS"), "success");
      onClose();
    } catch (err) {
      console.error(err);
      showSnackbar(
        err?.response?.data?.message || t("Something went wrong"),
        "error"
      );
    }
  };

  return {
    getReports,
    getReportTypes,
    getProjectList,
    addReport,
    getDownloadReportUrl,
  };
};
