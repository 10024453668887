import React, { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  Pagination,
  Typography,
  Box,
  Collapse,
  IconButton,
} from "@mui/material";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  SwapVert as SwapVertIcon,
} from "@mui/icons-material";
import { Spinner } from "../../components/Common";
import "./table.css";
const EnhancedTable = ({
  columns = [],
  data = [],
  loading = false,
  onChangeRecords,
  onPageSizeChange,
  perPageCount,
  currentPage,
  totalPages,
  isServerSorting = false,
  selectedCount = 0,
  showExpendedRow = false,
  isHidePagination = false,
  innerTableColumn = [],
}) => {
  const { t } = useTranslation();
  const tableContainerRef = useRef(null);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "",
  });
  const [isRowExpended, setRowExpended] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const handleScroll = useCallback(
    debounce((event) => {
      const scrollLeft = event.target.scrollLeft;
      setScrollPosition(scrollLeft);
    }, 0),
    []
  );

  useEffect(() => {
    const container = tableContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const customPagination = (props) => {
    const { count, page, className, onPageChange, rowsPerPage } = props;
    const totalPageCount = Math.ceil(count / rowsPerPage);

    const handleChange = (event, value) => {
      onPageChange(value - 1);
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <Pagination
          count={totalPageCount}
          page={page + 1}
          color="primary"
          className={className}
          onChange={handleChange}
        />
      </Box>
    );
  };

  const sortedData = React.useMemo(() => {
    if (sortConfig.key) {
      return [...data].sort((a, b) => {
        const firstItem = a[sortConfig.key];
        const secondItem = b[sortConfig.key];

        if (firstItem < secondItem)
          return sortConfig.direction === "asc" ? -1 : 1;
        if (firstItem > secondItem)
          return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
    }
    return data;
  }, [data, sortConfig]);

  const tableData = (isServerSorting ? data : sortedData) || [];
  const handleSort = (columnKey) => {
    let direction = "asc";
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const handleExpendRow = (row_id) => {
    setRowId(row_id);
    setRowExpended(row_id === rowId && isRowExpended ? false : true);
  };

  const renderSortingIcon = (column) => {
    if (!column.sortable) return null;
    if (sortConfig.key === column.field) {
      return (
        <div className="table-sorting-icon">
          {sortConfig.direction === "asc" ? (
            <ArrowUpwardIcon />
          ) : (
            <ArrowDownwardIcon />
          )}
        </div>
      );
    }
    return (
      <div className="table-sorting-hover-icon">
        <SwapVertIcon />
      </div>
    );
  };

  const renderRowExpander = (rowIndex) => {
    const isExpanded = isRowExpended && rowId === rowIndex;
    return (
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => handleExpendRow(rowIndex)}
      >
        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    );
  };

  const renderRows = () =>
    tableData.map((row, rowIndex) => {
      const isExpanded = isRowExpended && rowId === rowIndex;
      return (
        <React.Fragment key={rowIndex}>
          <TableRow>
            {showExpendedRow && (
              <TableCell>{renderRowExpander(rowIndex)}</TableCell>
            )}
            {columns.map((column, colIndex) => (
              <TableCell
                key={colIndex}
                className={
                  column.fixedColumnClass !== "" ? column.fixedColumnClass : ""
                }
                sx={{
                  left:
                    column.leftColumnFixed !== "" ? column.leftColumnFixed : "",
                  right:
                    column.rightColumnFixed !== ""
                      ? column.rightColumnFixed
                      : "",
                  width: column.width || "auto",
                  textAlign: column.alignItem || "inherit",
                }}
              >
                {column.renderCell
                  ? column.renderCell({ row })
                  : row[column.field]}
              </TableCell>
            ))}
          </TableRow>
          {isExpanded && (
            <TableRow>
              <TableCell
                colSpan={columns.length + 1}
                style={{ paddingBottom: 0, paddingTop: 0 }}
              >
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <EnhancedTable
                      data={row.innerData}
                      columns={innerTableColumn}
                      isHidePagination
                    />
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          )}
        </React.Fragment>
      );
    });

  return (
    <Paper>
      <TableContainer className="paper-table-container" ref={tableContainerRef}>
        <Table
          sx={{ tableLayout: "fixed" }}
          className={`table ${
            scrollPosition === 0 ? "" : "table-scroll-enabled"
          }`}
        >
          <TableHead>
            <TableRow>
              {showExpendedRow && <TableCell style={{ width: "5%" }} />}
              {columns?.map((column, colIndex) => (
                <TableCell
                  key={colIndex}
                  className={
                    column.fixedColumnHeadClass !== ""
                      ? column.fixedColumnHeadClass
                      : ""
                  }
                  sx={{
                    left:
                      column.leftColumnFixed !== ""
                        ? column.leftColumnFixed
                        : "",
                    right:
                      column.rightColumnFixed !== ""
                        ? column.rightColumnFixed
                        : "",
                    width: column.width || "auto",
                    cursor: column.sortable && "pointer",
                    textAlign: column.alignItem || "inherit",
                  }}
                  onClick={() => column.sortable && handleSort(column.field)}
                >
                  <div
                    className={`d-flex ${
                      column.alignItem === "center"
                        ? "justify-content-center"
                        : ""
                    }`}
                  >
                    {column.renderHeader
                      ? column.renderHeader(column)
                      : column.headerName}
                    {renderSortingIcon(column)}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  colSpan={
                    columns.length > 5
                      ? 8
                      : showExpendedRow
                      ? columns.length + 1
                      : columns.length
                  }
                  style={{
                    border: "none",
                    padding: 0,
                  }}
                >
                  <div className="table-spinner-container">
                    <Spinner />
                  </div>
                </TableCell>
              </TableRow>
            ) : tableData && tableData.length > 0 ? (
              renderRows()
            ) : (
              <TableRow>
                <TableCell
                  colSpan={
                    columns.length > 5
                      ? 8
                      : showExpendedRow
                      ? columns.length + 1
                      : columns.length
                  }
                  style={{
                    border: "none",
                    padding: 0,
                  }}
                >
                  <div className="table-empty-container">
                    {t("NO_RECORD_FOUND")}
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!isHidePagination && (
        <TableFooter className="table-footer-container">
          <Typography className="pagination-footer-text">
            {selectedCount !== 0 &&
              `${selectedCount} ${
                selectedCount !== 1 ? t("ROWS_SELECTED") : t("ROW_SELECTED")
              }`}
          </Typography>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage={t("ROWS_PER_PAGE")}
            count={totalPages}
            colSpan={12}
            rowsPerPage={perPageCount}
            page={currentPage}
            onPageChange={onChangeRecords}
            onRowsPerPageChange={onPageSizeChange}
            ActionsComponent={customPagination}
            slotProps={{
              select: {
                inputProps: {
                  "aria-label": t("ROWS_PER_PAGE"),
                },
                native: true,
              },
            }}
            className="custom-pagination"
          />
        </TableFooter>
      )}
    </Paper>
  );
};
export default EnhancedTable;
