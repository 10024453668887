import i18n from '../../../../locale/i18n';
export const resultsInitialState = {
  loading: false,
  loadingResults: false,
  loadingFileUpload: false,
  isPdfDownload: false,
  language: i18n.language,
  filterLanguage: [],
  initialRenderStatus: false,
  searchingText: '',
  displayTimeZoneOffset: '',
  displayTimeZone: null,
  sortOrder: {},
  currentPage: 0,
  perPageCount: 100,
  totalResultsCount: 0,
  totalPagesCount: 0,
  riskLevels: [],
  questionsRisk: [],
  onHoldCheck: false,
  questionNumber: '',
  startDate: new Date('2020-01-01'),
  endDate: new Date(),
  results: [],
  resultDetails: {},
  resultCMRecording: {},
  resultsTableRow: [],
  filterResult: [],
  allAttributes: [],
  tanentAttributes: [],
  questionList: [],
  alertMsg: '',
  variant: '',
  alertStatus: false,
  configurationJson: '',
  customQuestionText: [],
  showOutComeTrackingModal: false,
  singleResult: '',
  resultNotesDetails: '',
  resultNotesModalShow: false,
  resultCMRecordingModalShow: false,
  questionRiskOption: [],
  resetFilter: false,
  selectedResultList: [],
  selectedZipResultList: [],
  selectedZipResultIds: [],
  isSelectAll: false,
  checkedState: [],
  selectedResultRow: [],
  allResultsOfProject: [],
  questionnaireType: '',
  applyFilter: true,
  onTablePageChange: true,
  reRender: true,
  isSelectAllRow: false,
  preSelectedRow: [],
  selectCreatedBy: null,
  flagsOptionsData: {},
  isResultDownload: false,
  downloadPdfList: [],
  resultReviewModalShow: false,
  isMarkReview: false,
  selectMarkReviewResult: [],
  selectMark: [],
  markReviewAction: '',
  hideReviewedOnly: false
};

export function resultsReducer(state, action) {
  switch (action.type) {
    case 'set_loading':
      return {
        ...state,
        loading: action.payload,
      };
    case 'loading_results':
      return {
        ...state,
        loadingResults: action.payload,
      };
    case 'loading_file_upload':
      return {
        ...state,
        loadingFileUpload: action.payload,
      };
    case 'is_pdf_download':
      return {
        ...state,
        isPdfDownload: action.payload,
      };
    case 'is_result_download_file':
      return {
        ...state,
        isResultDownload: action.payload,
      };
    case 'set_configuration_json':
      return {
        ...state,
        configurationJson: action.payload,
      };
    case 'set_all_attributes':
      return {
        ...state,
        allAttributes: action.payload,
      };
    case 'set_tenant_attributes':
      return {
        ...state,
        tanentAttributes: action.payload,
      };
    case 'set_question_list':
      return {
        ...state,
        questionList: action.payload,
      };
    case 'set_searching_text':
      return {
        ...state,
        searchingText: action.payload,
      };
    case 'set_display_time_zone_offset':
      return {
        ...state,
        displayTimeZoneOffset: action.payload,
      };
    case 'set_display_timezone':
      return {
        ...state,
        displayTimeZone: action.payload,
      };

    case 'set_sort_order':
      return {
        ...state,
        sortOrder: action.payload,
      };
    case 'set_current_page':
      return {
        ...state,
        currentPage: action.payload,
      };
    case 'set_per_page_count':
      return {
        ...state,
        perPageCount: action.payload,
      };

    case 'set_risk_levels':
      return {
        ...state,
        riskLevels: action.payload,
      };
    case 'set_questions_risk':
      return {
        ...state,
        questionsRisk: action.payload,
      };
    case 'set_on_hold_check':
      return {
        ...state,
        onHoldCheck: action.payload,
      };
    case 'question_number':
      return {
        ...state,
        questionNumber: action.payload,
      };
    case 'start_date':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'end_date':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'set_results':
      return {
        ...state,
        results: action.payload,
      };

    case 'filter_result':
      return {
        ...state,
        filterResult: action.payload,
      };

    case 'set_alert_msg':
      return {
        ...state,
        alertMsg: action.payload,
      };
    case 'set_variant':
      return {
        ...state,
        variant: action.payload,
      };
    case 'set_alert_status':
      return {
        ...state,
        alertStatus: action.payload,
      };

    case 'set_questions_text_for_column':
      return {
        ...state,
        customQuestionText: action.payload,
      };

    case 'total_results_count':
      return {
        ...state,
        totalResultsCount: action.payload,
      };
    case 'total_pages_count':
      return {
        ...state,
        totalPagesCount: action.payload,
      };
    case 'single_result':
      return {
        ...state,
        singleResult: action.payload,
      };
    case 'show_out_come_tracking_modal':
      return {
        ...state,
        showOutComeTrackingModal: action.payload,
      };
    case 'set_language':
      return {
        ...state,
        language: action.payload,
      };
    case 'set_filter_language':
      return {
        ...state,
        filterLanguage: action.payload,
      };
    case 'result_notes_details':
      return {
        ...state,
        resultNotesDetails: action.payload,
      };

    case 'set_result_details':
      return {
        ...state,
        resultDetails: action.payload,
      };

    case 'result_notes_modal_show':
      return {
        ...state,
        resultNotesModalShow: action.payload,
      };

    //cm recordings set
    case 'select_recording':
      console.log('Trying to set cm recording');

      console.log(action.payload);
      return {
        ...state,
        selectedRecording: action.payload,
      };

    //cm recordings modal show
    case 'result_cm_recording_modal_show':
      return {
        ...state,
        resultCMRecordingModalShow: action.payload,
      };

    case 'results_table_row':
      return {
        ...state,
        resultsTableRow: action.payload,
      };
    case 'reset_filter':
      return {
        ...state,
        resetFilter: action.payload,
      };
    case 'selected_result_list':
      return {
        ...state,
        selectedResultList: action.payload,
      };
    case 'selected_zip_result_list':
      return {
        ...state,
        selectedZipResultList: action.payload,
      };
    case 'selected_zip_result_list_ids':
      return {
        ...state,
        selectedZipResultIds: action.payload,
      };

    case 'is_select_all':
      return {
        ...state,
        isSelectAll: action.payload,
      };
    case 'checked_state':
      return {
        ...state,
        checkedState: action.payload,
      };
    case 'initial_render_status':
      return {
        ...state,
        initialRenderStatus: action.payload,
      };
    case 'selected_result_row':
      return {
        ...state,
        selectedResultRow: action.payload,
      };
    case 'set_all_results_of_project':
      return {
        ...state,
        allResultsOfProject: action.payload,
      };
    case 'set_questionnaire_type':
      return {
        ...state,
        questionnaireType: action.payload,
      };
    case 'set_apply_filter':
      return {
        ...state,
        applyFilter: action.payload,
      };
    case 'set_table_page_change':
      return {
        ...state,
        onTablePageChange: action.payload,
      };
    case 'set_re_render':
      return {
        ...state,
        reRender: action.payload,
      };
    case 'selected_all_row':
      return {
        ...state,
        isSelectAllRow: action.payload,
      };
    case 'pre_selected_row':
      return {
        ...state,
        preSelectedRow: action.payload,
      };
    case 'select_created_by':
      return {
        ...state,
        selectCreatedBy: action.payload,
      };
    case 'set_flags_options_data':
      return {
        ...state,
        flagsOptionsData: action.payload,
      };
    case 'set_all_interview_result_id':
      return {
        ...state,
        downloadPdfList: action.payload,
      };
    case 'result_review_modal_show':
      return {
        ...state,
        resultReviewModalShow: action.payload,
      };
    case 'set_result_review_mark':
      return {
        ...state,
        isMarkReview: action.payload,
      };
    case 'mark_review_action':
      return {
        ...state,
        markReviewAction: action.payload,
      };
    case 'mark_review_result_list':
      return {
        ...state,
        selectMarkReviewResult: action.payload,
      };
    case 'hide_reviewed_only':
      return{
        ...state,
        hideReviewedOnly: action.payload
      }
    default:
      return state;
  }
}
