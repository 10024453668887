import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { CustomGrid } from "../../CustomTable";

class ManageLookups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: props.currentPage,
      perPageCount: 10,
      totalPage: 0,
    };
  }

  onChangeRecords = (page) => {
    this.setState({
      currentPage: page.page,
      perPageCount: page.pageSize,
    });
  };

  onRowClick = (params) => {
    this.props.handleEditRow(params.row, "Edit Lookups");
  };

  render() {
    const { t, searchValue } = this.props;
    const lowercasedQuery = searchValue?.toLowerCase();
    const lookups = this.props.dataFromParent.statedata.lookups;

    const filteredLookup = lowercasedQuery
      ? lookups.filter(
          (item) => item.attributeTypeVal.toLowerCase() === lowercasedQuery
        )
      : lookups;
    let lookupsColumn = filteredLookup.filter((lookup) => {
      if (
        !(lookup.attributeType == "STAGE" || lookup.attributeType == "STATUS")
      ) {
        return lookup;
      }
    });

    const columns = [
      {
        field: "attributeTypeVal",
        headerName: t("FIELD NAME"),
        flex: 1,
        cellClassName: "tenant-manage-table-col-1",
        sortable: true,
      },
      {
        field: "englishValue",
        headerName: t("ENGLISH"),
        flex: 1,
        sortable: false,
      },
      {
        field: "spanishValue",
        headerName: t("SPANISH"),
        flex: 1,
        sortable: false,
      },
      {
        field: "status",
        headerName: t("STATUS"),
        flex: 1,
        sortable: false,
      },
    ];

    return (
      <div className="table_wrapper custom_table_container table-with-search app-table admin-lookup-module-table">
        <CustomGrid
          columns={columns}
          data={lookupsColumn}
          perPageCount={this.state.perPageCount}
          currentPage={this.state.currentPage}
          totalPages={lookupsColumn.length}
          onChangeRecords={this.onChangeRecords}
          isServerPagination={false}
          onRowClick={this.onRowClick}
        />
      </div>
    );
  }
}

export default withTranslation()(ManageLookups);
