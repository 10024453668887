import React, { useState } from "react";
import { ErrorMessage, Field } from "formik";
import TextError from "../../TextError";
//import CheckboxGroup from "react-checkbox-group";

function CheckBoxGroupWithLabel(props) {
  const {
    name,
    options,
    formik,
    label,
    containerStyle,
    labelStyle,
    fieldContainerStyle,
    fieldStyle,
  } = props;

  // const [values, setValues] = useState([]);

  // useEffect(() => {
  // }, [])

  // const handleChange = (selectedValue) => {
  //   // setValues(selectedValue);
  //   formik.setFieldValue(name, selectedValue);
  // };

  return (
    <div className={containerStyle}>
      <label className={labelStyle}>{label}</label>
      <div className={fieldContainerStyle}>
        {/* <CheckboxGroup name={name} value={formik.values.time} onChange={handleChange}>
          {(Checkbox) =>
            options.map((option) => {
              return (
                <label className={fieldStyle}>
                  <Checkbox value={option.value} />
                  {option.label}
                </label>
              );
            })
          }
        </CheckboxGroup> */}
        {options.map((option) => (
          <label key={option.value} className="checkbox-field">
            <Field
              type="checkbox"
              name={name}
              value={option.value}
              checked={formik.values.time.includes(option.value)}
            />
            {option.label}
          </label>
        ))}
      </div>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default CheckBoxGroupWithLabel;
