import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Spinner } from "../../components/Common";
import { Route } from "react-router-dom";
import RedirectToLogin from "./RedirectToLogin";
const PrivateRoute = ({ children, ...rest }) => {
  const { isLoading, isAuthenticated } = useAuth0();
  if (isLoading) {
    return (
      <div className="main-loader">
        <Spinner />
      </div>
    );
  }
  return (
    <Route {...rest}>{isAuthenticated ? children : <RedirectToLogin />}</Route>
  );
};
export default PrivateRoute;
