import api from './api';
export const getProjectQuestionList = (projectId)=> {
    return api
            .get('project-service/v1/project/'+projectId+'/questions?pre_interview_questions=false')
            .then(response => {
                return response.data
            })
            .catch(err => {
               return err
            });
}

export const getListOfAttribute=(tenant_id)=>{
    return api
    .get('project-service/v1/project/attributes?tenant_id='+tenant_id+'&include_internal_values=true')
    .then(response => {
        return response.data
    })
    .catch(err => {
       return err
    });
}

//submit an answer

export const submitResponse=(data)=>{
    return api
            .put('execution-service/v1/call/',data)
            .then(response =>{
                //console.log(response)
                return response.data
            })
            .catch(err => {
                console.log(err)
            })
}

//Get Raw data from server 
export const getRawJsonData=(endpoint)=>{
    return api
    .get(endpoint)
    .then(response => {
        return response.data
    })
    .catch(err => {
       return err
    });
}

//Verify IRN number
export const serviceVerifyIRN=(data)=>{
    return api
    .post('execution-service/v1/execute/irn/verify',data)
    .then(response => {
        return response.data
    })
    .catch(err => {
       return err
    });
}

export const handlePostRequest=(url,data)=>{
    return api
    .post(url,data)
    .then(response => {
        return response.data
    })
    .catch(err => {
       return err
    });
}

// call center api
export const getCallCenterList = ()=>{
    return api
    .get('user-service/v1/callcenter/callcenterlist');
}

export const getCallCenterDetails = (id)=>{
    return api
    .get(`user-service/v1/callcenter/${id}`);
}

export const createCallCenter =(data)=>{
    return api
    .post('user-service/v1/callcenter/',data);
}
export const updateCallCenter = (data,id)=>{
    return api.put(`user-service/v1/callcenter/${id}`,data)
}
// http://acg.sofmen.com/user-service/v1/callcenter/4
export const getCallCenterAgentList = (id)=>{
    return api
    .get(`user-service/v1/callcenter/${id}/usersList`);
}

// add user in call center
export const addUserInCallCenter =(data, id)=>{
    return api
    .post(`user-service/v1/callcenter/${id}/user`,data);
}

export const getAllRole = () => {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    return api.get(`project-service/v1/project/roles`, {
        params: {
          tenantId: userInfo.tenantId
        }
      })
}

// http://acg.sofmen.com/user-service/v1/callcenter/4/user/208 
export const getCallCenterUserDetails = (callCenterId, agentId)=>{
    return api
    .get(`user-service/v1/callcenter/${callCenterId}/user/${agentId}`);
}

export const updateCallCenterUserDetails = (data,callCenterId, agentId)=>{
    return api
    .put(`user-service/v1/callcenter/${callCenterId}/user/${agentId}`, data);
}
