import moment from 'moment';
import { Send_Api_Date_Format } from '../../../../config';
export const getResultsPayload = (resultState, projectId) => {
  const payload = {
    projectId: parseInt(projectId),
    search: resultState.searchingText,
    displayTimeZone: resultState.displayTimeZoneOffset ? resultState.displayTimeZoneOffset : getLocalTimezoneOffset(),
    sortBy: resultState.sortOrder,
    page: resultState.currentPage,
    pageSize: resultState.perPageCount,
    riskLevels: resultState.riskLevels,
    questionsRisk: resultState.questionsRisk,
    released: !resultState.onHoldCheck,
    questionNumber: resultState.questionNumber,
    participantLanguageIds: resultState.filterLanguage,
    startDate: moment(resultState.startDate).format(Send_Api_Date_Format),
    endDate: moment(resultState.endDate).format(Send_Api_Date_Format),
    allRecords: resultState.isSelectAllRow,
    createdBy: resultState.selectCreatedBy ? resultState.selectCreatedBy.value : null,
    systemCreateByUser: resultState.selectCreatedBy && resultState.selectCreatedBy.label === 'SYSTEM' ? true : false,
    hide_reviewed_only: resultState.hideReviewedOnly
  };
  return payload;
};

export const getLocalTimezoneOffset = () => {
  const timeZoneOffsetInMs = new Date().getTimezoneOffset() * 60 * 1000;
  const sign = timeZoneOffsetInMs < 0 ? '+' : '-';
  const hours = Math.floor(Math.abs(timeZoneOffsetInMs) / 3600000)
    .toString()
    .padStart(2, '0');
  const minutes = ((Math.abs(timeZoneOffsetInMs) / 60000) % 60).toString().padStart(2, '0');
  return `${sign}${hours}:${minutes}`;
};

export const getAllResultsPayload = (resultState, projectId) => {
  const payload = {
    projectId: parseInt(projectId),
    search: resultState.searchingText,
    displayTimeZone: resultState.displayTimeZoneOffset,
    sortBy: resultState.sortOrder,
    riskLevels: resultState.riskLevels,
    questionsRisk: resultState.questionsRisk,
    released: !resultState.onHoldCheck,
    questionNumber: resultState.questionNumber,
    startDate: moment(resultState.startDate).format(Send_Api_Date_Format),
    endDate: moment(resultState.endDate).format(Send_Api_Date_Format),
  };
  return payload;
};

export const getInitials = (initial) => {
  if (initial === 'Incomplete') {
    return 'DC';
  }
  if (initial === 'NP') {
    return 'NP';
  }
  return initial;
};

export const formatDate = (date) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  date = mm + dd + yyyy;
  return date;
};
