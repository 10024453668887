import api from "../../Services/api";
import * as actionTypes from "../constants";
import { DOMAIN_URL } from "../../config";
import {
  userAuditLoginData,
  userAuditLogoutData,
  login,
} from "../../Services/authFunctions";

const loginUser = (values) => {
  return (dispatch) => {
    login(values)
      .then((res) => {
        if (res !== undefined && res.error === undefined) {
          localStorage.setItem("token", JSON.stringify(res));
          dispatch(success(res));
        } else {
          dispatch(error(res.message));
        }

        let body = {
          email: values.username,
        };
        if (res.error) {
          body.status = "failed";
          return;
        }
        userAuditLoginData(body)
          .then((res) => {})
          .catch((err) => {
            console.log("error", err);
          });
      })
      .catch((err) => {
        console.log("error", err);
        let errMsg = Object.assign({}, err).response.data.message;
        dispatch(error(errMsg));
      });
  };
};

const logOut = (action, authLogoutMethod) => {
  return (dispatch) => {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo != null) {
      userAuditLogoutData()
        .then((res) => {
          console.log("logoutconsole", res);
          // localStorage.clear();
          localStorage.removeItem("languageId");
          localStorage.removeItem("langid");
          localStorage.removeItem("token");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("piidata");
          localStorage.removeItem("operatingTenant");
          localStorage.removeItem("operatingTenantName");
          localStorage.removeItem("projectFilters");
          sessionStorage.removeItem("operatingTenant");
          if (action === "signOutFromBtn") {
            // console.log("window DOMAIN_URL", DOMAIN_URL);
            authLogoutMethod({
              logoutParams: { returnTo: DOMAIN_URL },
            });
            localStorage.removeItem("currentURL");
          }
          delete api.defaults.headers.common["operatingTenant"];
          dispatch(logout());
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
    // localStorage.removeItem("userInfo");
    // localStorage.removeItem("piidata");
    // localStorage.removeItem("operatingTenant");
    // localStorage.removeItem("operatingTenantName");
    // localStorage.removeItem("languageId");
    // localStorage.removeItem("langid");
    // localStorage.removeItem("token");
    // dispatch(logout());
  };
};

const success = (res) => {
  return {
    type: actionTypes.LOG_IN,
    value: res,
  };
};

const logout = () => {
  return {
    type: actionTypes.LOG_OUT,
    value: false,
  };
};
const error = (err) => {
  return {
    type: actionTypes.LOG_ERROR,
    value: err,
  };
};

const clearError = () => {
  return {
    type: actionTypes.CLEAR_ERROR,
  };
};

const setHeaderLogo = (ImageUrl) => {
  return {
    type: actionTypes.HEADER_LOGO,
    payload: ImageUrl,
  };
};

export const authActions = {
  loginUser,
  logOut,
  clearError,
  setHeaderLogo,
};
