import Communication from "../components/ProjectDetail/Communication";
import api from "./api";

export const getProjectListold = () => {
  return api.get("project-service/v1/project/projectList");
};

export const getAdditionalList = () => {
  return api.get("project-service/v1/project/additional-json");
};

export const getProjectList = (filterStatusValue, filterStageValue) => {
  return api.get(`project-service/v1/project/projectListNew`, {
    params: {
      statusId: filterStatusValue,
      stageId: filterStageValue,
    },
  });
};

export const getProjectDetail = (id) => {
  return api.get(`project-service/v1/project/${id}`);
};

export const getFileDownload = (id, type) => {
  return api.get(
    `project-service/v1/project/files/download?project_id=${id}&file_type=${type}`,
    {
      responseType: "blob",
    }
  );
};
export const getResultErrorFileDownload = (data) => {
  return api.post(`project-service/v1/project/error/file/download`, data, {
    responseType: "blob",
  });
};
export const getSESList = (data) => {
  return api.post(`project-service/v1/project/email/bounce-report`, data);
};

export const getemailCopsDetail = (id) => {
  return api.get(
    `project-service/v1/project/${id}/email/project_tech_details?email_cops_team=true`
  );
};
export const getemailJimDetail = (id) => {
  return api.get(
    `project-service/v1/project/${id}/email/project_tech_details?email_ai_team=true`
  );
};

export const getExportDetailClick = (id) => {
  return api.get(`project-service/v1/project/export/details?project_id=${id}`, {
    responseType: "blob",
  });
};

export const getCVCFile = () => {
  return api.get(
    `http://192.168.50.70:8084/participant-service/v1/participants/downloadTemplate`
  );
};
export const uploadSupervisorData = (projectId, formData) => {
  return api.post(
    `project-service/v1/project/guide/uploadJson?projectId=${projectId}`,
    formData
  );
};
export const uploadISWMsg = (projectId, formData) => {
  return api.post(
    `project-service/v1/project//isw/uploadxlsx?projectId=${projectId}`,
    formData
  );
};
export const MobileAppConfig = (uuid, filename) => {
  return api.post(
    `project-service/v1/project/isw/mobile/uploadxlsx1?project_uuid=${uuid}&assets=true&file_name=${filename}`
  );
};

export const MobileAppAudioConfig = (formData, uuid) => {
  return api.post(
    `project-service/v1/project/isw/mobile/uploadxlsx1?project_uuid=${uuid}`,
    formData
  );
};

export const MobileAppxlsxConfig = (formData, uuid) => {
  return api.post(
    `project-service/v1/project/isw/mobile/uploadxlsx1?project_uuid=${uuid}`,
    formData
  );
};

export const getAttribute = (id) => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return api.get(`project-service/v1/project/attributes`, {
    params: {
      language_id: id,
      tenant_id: userInfo.tenantId,
      active: true,
    },
  });
};
export const getAllAttribute = () => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return api.get(`project-service/v1/project/attributes`, {
    params: {
      tenant_id: userInfo.tenantId,
      active: true,
    },
  });
};
export const getAllAttributeWithProjectId = (projectId) => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return api.get(`project-service/v1/project/attributes`, {
    params: {
      tenant_id: userInfo.tenantId,
      active: true,
      project_id: projectId,
    },
  });
};
export const createProject = (values) => {
  delete values.createDate;
  return api.post(`project-service/v1/project/`, values);
};

export const updateProject = (values, projectId) => {
  return api.put(`project-service/v1/project/${projectId}`, values);
};

export const updateCommunicationDetail = (values, projectId) => {
  return api.put(
    `project-service/v1/email//template/detail/${projectId}`,
    values
  );
};

export const getProjectTeam = (id) => {
  return api.get(`project-service/v1/project/${id}/projectTeam`);
};

export const getProjectQuestions = (id) => {
  return api.get(
    `project-service/v1/project/${id}/questions?pre_interview_questions=false`
  );
};

export const updateProjectQuestions = (values, projectId) => {
  return api.put(
    `project-service/v1/project/${projectId}/questions?pre_interview_questions=false`,
    values
  );
};
export const getQuestionsApproved = (projectId) => {
  return api.put(`project-service/v1/project/${projectId}/questions/approve`);
};
export const getQuestionsDownload = (projectId) => {
  return api.get(`project-service/v1/project/${projectId}/questions/download`, {
    responseType: "blob",
  });
};
// Communication tab data fetch
export const getCommunicationDetail = (projectId, templateType, languageId) => {
  return api.get(
    `project-service/v1/email/template/detail?type=${templateType}&project_id=${projectId}&language_id=${languageId}`
  );
};
export const deleteProjectQuestions = (id, projectId) => {
  return api.delete(`project-service/v1/project/${projectId}/questions/${id}`);
};

export const assignProjectTeam = (values, projectId) => {
  return api.post(`project-service/v1/project/${projectId}/assign`, values);
};

export const getTenantTeam = (id) => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return api.get(
    `project-service/v1/project/user-role-List?tenantId=${userInfo.tenantId}&projectId=${id}`
  );
};

export const getProjectRoles = (tenantId) => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  tenantId = userInfo.tenantId;
  return api.get(`project-service/v1/project/roles?tenantId=${tenantId}`);
};

export const unassignProjectTeam = (values, id) => {
  return api.post(`project-service/v1/project/${id}/unassign`, values);
};

export const getCallCenterList = () => {
  return api.get(`user-service/v1/callcenter/callcenterlist`);
};

export const uploadAccessCode = (formData) => {
  return api.post(`project-service/v1/project/uploadAccessCodes`, formData);
};

export const getAccessCodesList = (payload) => {
  return api.post(`project-service/v1/project/accessCodesList`, payload);
};

export const burnSelectedAccessCodesList = (array) => {
  return api.put(`project-service/v1/project/burnCode`, array);
};

export const getAccessCodeFiles = (id) => {
  return api.get(`project-service/v1/project/accessCodeFiles`, {
    params: {
      projectId: id,
    },
  });
};

export const getProjectsYearlyData = () => {
  return api.get(`project-service/v1/project/results/yearly`);
};

export const getDashboardTile = (tenantId) => {
  return api.post(
    `project-service/v1/project/dashboard/kpi?tenantId=${tenantId}`
  );
};

export const checkMultiLanguageFieldExist = (formData) => {
  return api.post(`project-service/v1/project/check`, formData);
};

export const getResultConfigListData = (pageNumber, pageSize) => {
  return api.post(`project-service/v1/result-config/list`);
};

export const deleteResultConfigListData = (id) => {
  return api.delete(`project-service/v1/result-config/${id}`);
};

export const getResultConfigData = () => {
  return api.get(`project-service/v1/result-config/view-template`);
};

export const getResultTemplateData = (id) => {
  return api.get(`project-service/v1/result-config/${id}`);
};

export const getResultTemplateFlags = (id) => {
  return api.get(`project-service/v1/result-config/project/${id}`);
};

export const createResultTemplate = (formData) => {
  return api.post(`project-service/v1/result-config/create-template`, formData);
};
export const updateResultTemplate = (id, formData) => {
  return api.put(`project-service/v1/result-config/${id}`, formData);
};

export const getBusinessLine = () => api.get('project-service/v1/project/business-line/data');
