import React, { Component } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Lookups.css";
import ManageLookups from "./ManageLookups";
import NewLookups from "./AddLookups";
import EditLookups from "./EditLookups";
import { AddCircleIcon } from "../../../assets/images/icons/customIcons.js";
import { withTranslation } from "react-i18next";
import { isLookupCreateEnabled } from "../../Utility/userPermission";

class Lookups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childNav: "Lookups",
      searchValue: "",
      currentPage: 0,
    };
    this.updateNavBar = this.updateNavBar.bind(this);
  }

  manageLookupsHtml = (props) => {
    const { t } = this.props;
    const userPermissionVal =
      this.props.statedata?.userInfo?.rolePermission?.permissions;
    const canUserCreateLookup = isLookupCreateEnabled(userPermissionVal);

    return (
      <div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="page-title">{t("Manage Lookups")}</h6>
              </div>
              <div className="col-lg-5">
                <div className="search-custom">
                  <FontAwesomeIcon
                    type="submit"
                    icon={faSearch}
                    className="searchicon"
                  />
                  <input
                    className="form-control customsearch"
                    name="tablesearch"
                    type="text"
                    value={this.state.searchValue}
                    onChange={(e) => {
                      this.setState({
                        searchValue: e.target.value,
                        currentPage: 0,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-1">
                {canUserCreateLookup && (
                  <div
                    className="tenant-add-button"
                    onClick={() => {
                      this.updateNavBar("New Lookups");
                    }}
                  >
                    <AddCircleIcon className={{ color: "#34AA44" }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card1">
          <div className="card-body1">
            <ManageLookups
              dataFromParent={props}
              handleEditRow={this.handleEditRow}
              searchValue={this.state.searchValue}
              currentPage={this.state.currentPage}
            />
          </div>
        </div>
      </div>
    );
  };
  createLookupsHtml = (props) => {
    const { t } = this.props;
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <h6 className="page-title">{t("Add Lookups Value")}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <NewLookups
              dataFromParent={props}
              updateNavBar={this.updateNavBar}
            />
          </div>
        </div>
      </div>
    );
  };

  editLookupsHtml = (props) => {
    const { t } = this.props;
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <h6 className="page-title">{t("Edit Lookups Value")}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <EditLookups
              dataFromParent={props}
              updateNavBar={this.updateNavBar}
            />
          </div>
        </div>
      </div>
    );
  };

  componentDidMount() {
    this.props.onRef(this);
    if (this.props.statedata.adminDetail.navBar != "Lookups") {
      this.updateNavBar("Lookups");
    }
  }

  updateNavBar(navBar) {
    this.setState({
      childNav: navBar,
    });
    this.props.handleBreadCrumInAdmin(navBar);
  }

  handleEditRow = (row, navBar) => {
    this.setState({
      childNav: navBar,
    });
    this.props.handleEditRow(row, navBar);
  };

  getLookUpComponent(navBar) {
    let showLookups = "";

    switch (navBar) {
      case "Lookups":
        showLookups = this.manageLookupsHtml(this.props);
        break;
      case "New Lookups":
        showLookups = this.createLookupsHtml(this.props);
        break;
      case "Edit Lookups":
        showLookups = this.editLookupsHtml(this.props);
        break;
    }

    return showLookups;
  }

  render() {
    let showLookups = this.getLookUpComponent(this.state.childNav);

    if (
      showLookups == "" &&
      this.props.location.pathname == "/main/admin/tenant"
    ) {
      showLookups = this.getLookUpComponent(
        this.props.statedata.adminDetail.prevNavBar
      );
    }

    return <React.Fragment>{showLookups}</React.Fragment>;
  }
}

export default withTranslation()(Lookups);
