import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Card, Grid2 as Grid, CardContent } from "@mui/material";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Alert as AlertDismissible,
  Button,
  Spinner,
} from "../../../../components/Common";
import { scrollTopWindow } from "../../../../Helpers/index";
import { SendSMS } from "../../../../Services/participantsService";
import {
  getCommunicationDetail,
  getProjectDetail,
  updateCommunicationDetail,
} from "../../../../Services/projectServices";
import CustomPrompt from "../../../Utility/CustomPrompt/CustomPrompt";
import { stringExistsInArray } from "../../../Utility/userPermission";

class SMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: "",
      smsData: "",
      alertMsg: "",
      alertStatus: false,
      variant: "",
      loading: false,
      disablesavebtnme: false,
      initialValuesTemplateType: "TEST_INVITATION_SMS",
      projectLanguagesData: [],
      selectedLanguage: null,
      languageId: "",
      smsCount: 0,
      loadingSMSData: true,
    };
  }

  componentDidMount() {
    const projectId = this.props.match.params.id;
    getProjectDetail(projectId)
      .then((res) => {
        this.setState(
          {
            projectLanguagesData: res.data.projectLanguagesData || [],
            languageId:
              res.data.projectLanguagesData.length > 0
                ? res.data.projectLanguagesData[0].value
                : "",
            projectId: projectId,
          },
          () => {
            const languageId = this.state.languageId;
            this.getCommunicationData(
              projectId,
              "TEST_INVITATION_SMS",
              languageId
            );
          }
        );
      })
      .catch((err) => {
        if (err.response.data.status === 403) {
          this.setState({
            alertMsg: err.response.data.message,
            alertStatus: true,
            variant: "danger",
          });
        }
      });
  }

  /**
   * This function will give you a communication detail
   * @param {string} projectId
   * @param {string} templateType
   * @param {stirng} languageId
   */
  getCommunicationData = (projectId, templateType, languageId) => {
    getCommunicationDetail(projectId, templateType, languageId)
      .then((res) => {
        console.log("getCommunicationDataSMS", res);
        this.setState({
          smsData: res.data,
          loadingSMSData: false,
        });
      })
      .catch((err) => {
        this.setState({
          variant: "danger",
          loadingSMSData: false,
          alertMsg: `${err.response?.data?.message}`,
          alertStatus: true,
        });
      });
  };

  /**
   * This function will send email
   * @param {string} languageId
   */
  handleSubmit = (languageId) => {
    this.setState({ loading: true });
    const projectid = this.props.match.params.id;
    SendSMS(projectid, languageId)
      .then((res) => {
        this.setState({ loading: false });
        scrollTopWindow();
        this.setState({
          alertMsg: `SMS Sent Successfully`,
          alertStatus: true,
          variant: "success",
        });
      })
      .catch((err) => {
        scrollTopWindow();
        console.log("Error", err);
        this.setState({
          variant: "danger",
          alertMsg: `${err.response?.data?.message}`,
          alertStatus: true,
        });
      });
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  handleCommunicationSubmit = (fields, resetForm) => {
    this.setState({
      disablesavebtnme: true,
    });
    const projectid = this.props.match.params.id;
    console.log("sumitfield", fields, projectid);
    // return;
    updateCommunicationDetail(fields, projectid)
      .then((res) => {
        scrollTopWindow();
        this.setState(
          {
            alertMsg: `Template Updated Successfully`,
            alertStatus: true,
            variant: "success",
            disablesavebtnme: false,
          },
          () => {
            this.getCommunicationData(
              projectid,
              fields.template_type,
              fields.language_id
            );
          }
        );
      })
      .catch((err) => {
        console.log("Template not updated ", err);
        scrollTopWindow();
        this.setState({
          variant: "danger",
          alertMsg: `${err.response?.data?.message}`,
          alertStatus: true,
          disablesavebtnme: false,
        });
      });
  };

  /**
   * This function fired when we select language
   * @param {object} option
   * @param {function} setFieldValue
   */
  handleLanguageSelection = (option, setFieldValue) => {
    setFieldValue("language_id", option.value);
    this.setState(
      {
        selectedLanguage: option,
        languageId: option.value,
      },
      () => {
        const projectId = this.props.match.params.id;
        this.getCommunicationData(
          projectId,
          this.state.initialValuesTemplateType,
          this.state.languageId
        );
      }
    );
  };
  handleSMSCount = (e, setFieldValue) => {
    setFieldValue("template_content", e.target.value);
    this.setState({
      smsCount: e.target.value.length,
    });
  };

  dirtyFlagChanged = (isDirty) => {
    this.props.setIsDirty(isDirty);
  };

  render() {
    let { t } = this.props;
    const SmsSenderOption = [
      {
        value: "ALPHANUMERIC_NUMBER",
        label: "ALPHANUMERIC NUMBER",
      },
      {
        value: "PHONE_NUMBER",
        label: "PHONE NUMBER",
      },
    ];
    const SmsProviderOption = [
      {
        value: "TELNYX",
        label: "TELNYX",
      },
      {
        value: "TWILIO",
        label: "TWILIO",
      },
    ];
    let languageOption = this.state.projectLanguagesData;

    const canUserUpdateFields = !stringExistsInArray(
      this.props.statedata.isinternal
    );

    return (
      <div className="w-1200">
        <Formik
          enableReinitialize
          initialValues={{
            language_id: this.state.languageId,
            template_type: this.state.initialValuesTemplateType,
            description: this.state.smsData.description || "",
            sms_subject: this.state.smsData.sms_subject || "",
            sms_sender: this.state.smsData.sms_sender || "",
            sms_provider: this.state.smsData.sms_provider || "",
            template_content: this.state.smsData.template_content || "",
          }}
          validationSchema={Yup.object().shape({
            sms_subject: Yup.string().when("template_type", {
              is: "TEST_INVITATION_SMS",
              then: () => Yup.string().required("Please enter SMS subject"),
            }),
            sms_sender: Yup.string().required("Please select SMS sender"),
            sms_provider: Yup.string().required("Please select SMS provider"),
            template_content: Yup.string().required(
              "Please enter SMS template"
            ),
          })}
          onSubmit={(fields, { resetForm }) => {
            console.log("fields", fields);
            this.handleCommunicationSubmit(fields, resetForm);
          }}
          render={(props) => {
            console.log("props.values", props);
            return (
              <Form
                className="agent-view"
                onChange={this.dirtyFlagChanged(props.dirty)}
              >
                <Grid container>
                  <Grid item size={12}>
                    <AlertDismissible
                      msg={this.state.alertMsg}
                      variantType={this.state.variant}
                      show={this.state.alertStatus}
                      close={this.handleAlertClose}
                    ></AlertDismissible>
                  </Grid>
                </Grid>
                <CustomPrompt dirty={props.dirty} />
                <Card>
                  {this.state.loadingSMSData ? (
                    <div className="custom-tab-loader">
                      <Spinner />
                    </div>
                  ) : (
                    <CardContent>
                      <Grid container className="email-temaplate-row">
                        <Grid item size={1} />
                        <Grid item className="email-temaplate-col" size={10}>
                          <div className="form-group row">
                            <label
                              className="col-sm-3 col-form-label"
                              htmlFor="language_id"
                            >
                              {t("language")}
                            </label>
                            <div className="col-sm-9">
                              <Select
                                name="language_id"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                options={languageOption}
                                isDisabled={canUserUpdateFields}
                                value={
                                  props.values.language_id
                                    ? languageOption.find(
                                        (option) =>
                                          option.value ===
                                          props.values.language_id
                                      )
                                    : null
                                }
                                onChange={(option) =>
                                  this.handleLanguageSelection(
                                    option,
                                    props.setFieldValue
                                  )
                                }
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              className="col-sm-3 col-form-label"
                              htmlFor="description"
                            >
                              {t("Description")}
                            </label>
                            <div className="col-sm-9">
                              <Field
                                name="description"
                                disabled={canUserUpdateFields}
                                type="text"
                                className={
                                  "form-control" +
                                  (props.errors.description &&
                                  props.touched.description
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              className="col-sm-3 col-form-label required"
                              htmlFor="sms_subject"
                            >
                              {t("SMS Subject")}
                            </label>
                            <div className="col-sm-9">
                              <Field
                                name="sms_subject"
                                type="text"
                                disabled={canUserUpdateFields}
                                autocomplete="off"
                                className={
                                  "form-control" +
                                  (props.errors.sms_subject &&
                                  props.touched.sms_subject
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              {props.errors.sms_subject &&
                                props.touched.sms_subject && (
                                  <div className="input-feedback add-tenant-validation">
                                    {props.errors.sms_subject}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              className="col-sm-3 col-form-label required"
                              htmlFor="sms_sender"
                            >
                              {t("SMS Sender")}
                            </label>
                            <div className="col-sm-9">
                              <Select
                                name="sms_sender"
                                className="basic-multi-select"
                                isDisabled={canUserUpdateFields}
                                classNamePrefix="select"
                                options={SmsSenderOption}
                                value={SmsSenderOption.filter(
                                  (options) =>
                                    options.value == props.values.sms_sender
                                )}
                                onChange={(option) =>
                                  props.setFieldValue(
                                    "sms_sender",
                                    option.value
                                  )
                                }
                              />
                              {props.errors.sms_sender &&
                                props.touched.sms_sender && (
                                  <div className="input-feedback add-tenant-validation">
                                    {props.errors.sms_sender}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              className="col-sm-3 col-form-label required"
                              htmlFor="sms_provider"
                            >
                              {t("SMS Provider")}
                            </label>
                            <div className="col-sm-9">
                              <Select
                                name="sms_provider"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isDisabled={canUserUpdateFields}
                                options={SmsProviderOption}
                                value={SmsProviderOption.filter(
                                  (options) =>
                                    options.value == props.values.sms_provider
                                )}
                                onChange={(option) =>
                                  props.setFieldValue(
                                    "sms_provider",
                                    option.value
                                  )
                                }
                              />
                              {props.errors.sms_provider &&
                                props.touched.sms_provider && (
                                  <div className="input-feedback add-tenant-validation">
                                    {props.errors.sms_provider}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="helper-box">
                            <p>Edit the contents of the SMS below.</p>
                            <span>
                              You can use the following placeholders:{" "}
                            </span>
                            <p className="helper-box-content">
                              {this.state.smsData != "" &&
                              this.state.smsData.placeholders !== null
                                ? this.state.smsData.placeholders.map(
                                    (placeholders) => (
                                      <span>{placeholders.placeholder}, </span>
                                    )
                                  )
                                : ""}
                            </p>
                          </div>

                          <div className="form-group row d-flex flex-column">
                            <label
                              className="col-sm-6 col-form-label required"
                              htmlFor="description"
                            >
                              {t("SMS Template")}
                            </label>
                            <div className="col-sm-12 d-flex flex-column">
                              <Field
                                placeholder={t("Start typing...")}
                                disabled={canUserUpdateFields}
                                component="textarea"
                                name="template_content"
                                className="form-control htmltemplate-view sms-textarea"
                                onChange={(e) =>
                                  this.handleSMSCount(e, props.setFieldValue)
                                }
                              />
                              {props.errors.template_content &&
                                props.touched.template_content && (
                                  <div className="input-feedback add-tenant-validation">
                                    {props.errors.template_content}
                                  </div>
                                )}
                              <div className="d-flex justify-content-end my-2">
                                <p>{`${this.state.smsCount}/160`}</p>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid size={1} />
                        <Grid item size={10}>
                          <div className="form-group float-left">
                            <Button
                              variant="primary"
                              type="button"
                              className="rounded-0 mr-4 save-btn"
                              onClick={() =>
                                this.handleSubmit(props.values.language_id)
                              }
                            >
                              {this.state.loading
                                ? `${t("Send Test SMS")}...`
                                : t("Send Test SMS")}
                            </Button>
                          </div>

                          <div className="form-group float-right">
                            <Button
                              variant="primary"
                              type="submit"
                              className="rounded-0 save-btn button_with_loader"
                              disabled={
                                canUserUpdateFields
                                  ? canUserUpdateFields
                                  : this.state.disablesavebtnme
                              }
                            >
                              {this.state.disablesavebtnme ? (
                                <>
                                  {t("Save")}...
                                  <div className="custom-loader linesLoader loader_inner_button">
                                    <Spinner color="#ffffff" size={"19px"} />
                                  </div>
                                </>
                              ) : (
                                <span>{t("Save")}</span>
                              )}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  )}
                </Card>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}
export default withTranslation()(SMS);
