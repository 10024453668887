import React from "react";
import { Button, ModalPopUp } from "../../../../components/Common";

const DeleteLanguageModal = (props) => {
  const { isOpenModal, t, deleteLanguage, closeModal } = props;

  return (
    <ModalPopUp
      show={isOpenModal}
      onHide={closeModal}
      centered
      title={"Delete Language"}
    >
      <div>
        Configuration exist with this language. Are you sure you want to delete
        it ?
      </div>
      <div>
        <div className="d-flex p-2">
          <Button
            type="button"
            className="btn btn-primary mx-2 text-uppercase "
            onClick={deleteLanguage}
          >
            ok
          </Button>
          <Button
            variant="secondary"
            type="button"
            className="rounded-0 mx-2 cancel-btn text-uppercase"
            onClick={closeModal}
          >
            {t("Cancel")}
          </Button>
        </div>
      </div>
    </ModalPopUp>
  );
};

export default DeleteLanguageModal;
