import React from "react";
import { Field } from "formik";

function Checkbox(props) {
  return (
    <Field name={props.name}>
      {({ field, form }) => {
        if (field.value.includes(props.value)) {
        }
        return (
          <div className="form-check custom_checkbox_componnt">
            <input
              type="checkbox"
              className="form-check-input"
              disabled={props.disabled}
              {...props}
              checked={field.value.includes(props.value)}
              onChange={() => {
                if (field.value.includes(props.value)) {
                  const nextValue = field.value.filter(
                    (value) => value !== props.value
                  );
                  form.setFieldValue(props.name, nextValue);
                } else {
                  const nextValue = field.value.concat(props.value);
                  form.setFieldValue(props.name, nextValue);
                }
              }}
            />
            <label className="form-check-label"></label>
          </div>
        );
      }}
    </Field>
  );
}

export { Checkbox };
