import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

export const TextError = (props) => {
  const { t } = useTranslation();
  return <div className="error">{t(props.children)}</div>;
};

export const SelectField = ({ options, field, form, values, isDisabled }) => {
  const { t } = useTranslation();
  return (
    <Select
      className="select-style"
      classNamePrefix="select"
      options={options}
      name={field.name}
      value={values ? options.find((option) => option.value === values) : ""}
      onChange={(option) => form.setFieldValue(field.name, option.value)}
      onBlur={() => {
        form.setFieldTouched(field.name);
      }}
      placeholder={t("Select")}
      isDisabled={isDisabled}
      isSearchable={false}
    />
  );
};

export const getTenantId = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const tenantId =
    userInfo.operatingTenant !== null
      ? userInfo.operatingTenant
      : userInfo.tenant.tenantId;
  return tenantId;
};
