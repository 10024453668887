import axios from "axios";
import api from "./api";
import { BASE_URL } from "../config";

// export const getAuditLog = (filters, perPageCount) => {
//     data: {"lastEvaluatedKey" : "None","limit": perPageCount},

export const getAuditLog = (filters) => {
  let token = JSON.parse(localStorage.getItem("token")).access_token;
  const requestBody = {
    ...filters,
    lastEvaluatedKey: "None",
  };
  return axios({
    method: "post",
    url: `${BASE_URL}authz-service/audit-log`,
    data: requestBody,
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  });
};
