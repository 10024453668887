import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { Grid2 as Grid } from "@mui/material";
import {
  Alert as AlertDismissible,
  Button,
  Spinner,
} from "../../../components/Common";
import { updateLookUp } from "../../../Services/lookUpService";
import PreventLeaveRoute from "../../Utility/PreventLeaveRoute.js";
import { SelectField } from "../../Utility/selectField";
import * as userPermission from "../../Utility/userPermission.js";

class EditLookups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMsg: "",
      alertStatus: false,
      variant: "",
      rowVal: {
        attributeType: "",
        status: "",
        englishValue: "",
        spanishValue: "",
      },
      dirty: false,
      disablesavebtn: false,
    };
  }

  componentDidMount = () => {
    if (this.props.dataFromParent.statedata.editRow != null) {
      console.log("inside LookUp");
      console.log(this.props.dataFromParent.statedata.editRow);
      this.setState({
        rowVal: this.props.dataFromParent.statedata.editRow,
      });
    }
  };

  cancelUserClick = () => {
    this.props.updateNavBar("Lookups");
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  dirtyFlagChanged = (dirty) => {
    if (dirty != this.state.dirty) {
      this.setState({
        dirty: dirty,
      });
      this.props.dataFromParent.handleChildDirtyGFlag(dirty);
    }
  };

  lookUpwrapper = (res, id) => {
    let lookUpGroup = {};
    res.data.map((lookUp) => {
      if (lookUpGroup[lookUp.attributeId] != null) {
        lookUpGroup[lookUp.attributeId].push(lookUp);
      } else {
        lookUpGroup[lookUp.attributeId] = [lookUp];
      }
    });

    const lookUpGroupList = Object.keys(lookUpGroup).map(
      (keys) => lookUpGroup[keys]
    );

    const lookUpList = lookUpGroupList.map((lookUp) => {
      return {
        id: lookUp[0].id,
        englishId: lookUp[0].id,
        spanishId: lookUp[1] != null ? lookUp[1].id : null,
        attributeId: lookUp[0].attributeId,
        attributeType: lookUp[0].attributeType,
        englishValue: lookUp[0].description,
        spanishValue: lookUp[1] != null ? lookUp[1].description : null,
        englishLanguageId: lookUp[0].languageId,
        spanishLanguageId: lookUp[1] != null ? lookUp[1].languageId : null,
        status: lookUp[0].active,
        sequence: lookUp[0].sequence,
        tenantId: lookUp[0].tenantId,
        default: lookUp[0].default,
        lookUpGroup: lookUp,
      };
    });
    const lookUpRow = lookUpList.filter(function (row) {
      return row.id == id;
    });
    return lookUpRow[0];
  };

  handleUpdateLookUp = (fields, tenantId) => {
    this.setState({
      disablesavebtn: true,
    });
    console.log(fields);
    const param = [
      {
        id: this.props.dataFromParent.statedata.editRow.englishId,
        description: fields.englishValue,
        attributeType: fields.name.toUpperCase(),
        languageId: 1,
        tenantId: tenantId,
        active: fields.status,
        default: false,
        object: fields.object,
      },
      {
        id: this.props.dataFromParent.statedata.editRow.spanishId,
        description: fields.SpanishValue,
        attributeType: fields.name.toUpperCase(),
        languageId: 2,
        tenantId: tenantId,
        active: fields.status,
        default: false,
        object: fields.object,
      },
    ];
    updateLookUp(param, tenantId)
      .then((res) => {
        // console.log(res.data);
        const rowData = this.lookUpwrapper(
          res,
          this.props.dataFromParent.statedata.editRow.id
        );
        console.log(rowData);
        this.setState({
          alertMsg: `lookup updated successfully`,
          variant: "success",
          alertStatus: true,
          projectDetail: rowData,
          rowVal: rowData,
          dirty: false,
          disablesavebtn: false,
        });
        this.props.dataFromParent.handleChildDirtyGFlag(false);
      })
      .catch((err) => {
        console.log(Object.assign({}, err).response);
        let errMsg = Object.assign({}, err).response.data.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
          disablesavebtn: false,
        });
        this.props.dataFromParent.handleChildDirtyGFlag(false);
      });
  };

  render() {
    const { t } = this.props;
    const tenantId = this.props.dataFromParent.statedata.tenantId;

    const lookUpStatusOption = [
      { id: 1, value: true, label: t("Active"), status: true },
      { id: 2, value: false, label: t("Inactive"), status: false },
    ];

    const canUserUpdateFields = !userPermission.isLookupUpdateEnabled(
      this.props.dataFromParent.statedata?.userInfo?.rolePermission?.permissions
    );

    const lookUpFieldNameOption =
      require("../../../config/lookUpFieldNameOption.json").map((el) => {
        return { ...el, label: t(el.label) };
      });
    const lookUpObjectOption = require("../../../config/lookUpObject.json").map(
      (el) => {
        return { ...el, label: t(el.label) };
      }
    );

    return (
      <Formik
        enableReinitialize
        initialValues={{
          object: "PROJECT",
          name: this.state.rowVal.attributeType,
          status: this.state.rowVal.status,
          englishValue: this.state.rowVal.englishValue,
          SpanishValue: this.state.rowVal.spanishValue,
        }}
        onSubmit={(fields, { resetForm }) => {
          this.handleUpdateLookUp(fields, tenantId);
        }}
        render={({
          errors,
          values,
          touched,
          handleReset,
          dirty,
          handleBlur,
        }) => (
          <Form onChange={this.dirtyFlagChanged(dirty)}>
            <PreventLeaveRoute
              reset={handleReset}
              when={dirty}
              // Navigate function
              navigate={(field, historyObj) => {
                if (field == "/main/admin/lookups") {
                  this.cancelUserClick();
                } else {
                  historyObj.push(field);
                }
              }}
              // Use as "message" prop of Prompt of React-Router
              shouldBlockNavigation={(location) => {
                if (location.pathname === "/") {
                  return false;
                }
                return !!(dirty || this.state.dirty);
              }}
            />
            <Grid container>
              <Grid item size={12}>
                <AlertDismissible
                  msg={this.state.alertMsg}
                  variantType={this.state.variant}
                  show={this.state.alertStatus}
                  close={this.handleAlertClose}
                ></AlertDismissible>
              </Grid>
            </Grid>
            <Grid container className="add-tenant-row">
              <Grid item className="add-tenant-col" size={4}>
                <div className="form-group">
                  <label className="required" htmlFor="object">
                    {t("OBJECT")}
                  </label>
                  <Field
                    name={"object"}
                    onBlur={handleBlur}
                    component={SelectField}
                    options={lookUpObjectOption}
                    values={values.object}
                    isDisabled={true}
                  />
                </div>
                <div className="form-group">
                  <label className="required" htmlFor="status">
                    {t("FEILD STATUS")}
                  </label>
                  <Field
                    name={"status"}
                    isDisabled={canUserUpdateFields}
                    onBlur={handleBlur}
                    component={SelectField}
                    options={lookUpStatusOption}
                    values={values.status}
                  />
                </div>
              </Grid>
              <Grid item className="add-tenant-col" size={4}>
                <div className="form-group">
                  <label className="required" htmlFor="name">
                    {t("FEILD NAME")}
                  </label>
                  <Field
                    name={"name"}
                    onBlur={handleBlur}
                    component={SelectField}
                    options={lookUpFieldNameOption}
                    values={values.name}
                    isDisabled={true}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container className="add-tenant-row">
              <Grid item className="add-tenant-col" size={4}>
                <div className="form-group">
                  <label htmlFor="englishValue">{t("ENGLISH VALUE")}</label>
                  <Field
                    name="englishValue"
                    disabled={canUserUpdateFields}
                    type="text"
                    autoComplete="off"
                    className={"form-control"}
                  />
                  {errors.englishValue && touched.englishValue && (
                    <div className="input-feedback add-tenant-validation">
                      {errors.englishValue}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item className="add-tenant-col" size={4}>
                <div className="form-group">
                  <label htmlFor="SpanishValue">{t("SPANISH VALUE")}</label>
                  <Field
                    name="SpanishValue"
                    disabled={canUserUpdateFields}
                    type="text"
                    autoComplete="off"
                    className={"form-control"}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item size={12}>
                <div className="form-group float-right">
                  <Button
                    variant="primary"
                    type="submit"
                    className="rounded-0 mr-4 save-btn button_with_loader"
                    disabled={
                      canUserUpdateFields
                        ? canUserUpdateFields
                        : this.state.disablesavebtn
                    }
                  >
                    {this.state.disablesavebtn ? (
                      <>
                        {t("Save")}...
                        <div className="custom-loader linesLoader loader_inner_button">
                          <Spinner color="#ffffff" size={"19px"} />
                        </div>
                      </>
                    ) : (
                      <span>{t("Save")}</span>
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    className="rounded-0 cancel-btn"
                    onClick={this.cancelUserClick}
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    );
  }
}

export default withTranslation()(EditLookups);
