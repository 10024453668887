import React from "react";
import { Prompt, withRouter } from "react-router-dom";
import { ModalPopUp } from "../../components/Common";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";

class PreventLeaveRoute extends React.Component {
  state = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false,
  };
  showModal = (location) =>
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });
  closeModal = (callback) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        modalVisible: false,
        confirmedNavigation: false,
      };
    }, callback);
  };
  componentDidMount() {}
  handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation } = this.state;
    const { shouldBlockNavigation } = this.props;
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      this.showModal(nextLocation);
      return false;
    }
    return true;
  };
  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { navigate } = this.props;
      const { lastLocation } = this.state;

      if (lastLocation) {
        this.setState(
          (prevState) => {
            return {
              confirmedNavigation: true,
            };
          },
          () => {
            navigate(lastLocation.pathname, this.props.history);
          }
        );
      }
    });
  render() {
    const { t, text } = this.props;
    const { when } = this.props;
    const { modalVisible, lastLocation } = this.state;
    return (
      <React.Fragment>
        <Prompt
          when={when}
          message={(location) => this.handleBlockedNavigation(location)}
        />
        <CustomModal
          t={t}
          text={text}
          visible={modalVisible}
          onCancel={() => this.closeModal(() => {})}
          onConfirm={() => this.handleConfirmNavigationClick()}
        />
      </React.Fragment>
    );
  }
}

const CustomModal = (props) => {
  const { t, text } = props;
  return (
    <ModalPopUp
      show={props.visible}
      onHide={props.onCancel}
      centered={true}
      backdrop="static"
    >
      <div className="text-center">
        <p className="d-flex justify-content-center text-bold text-center mb-5 modal-box-text">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="save-change-warning-icon"
          />
          {text !== undefined
            ? text
            : t(
                "Are you sure you want to leave? You have some unsaved changes."
              )}
        </p>

        <button
          className="btn btn-secondary save-modal-btn"
          onClick={props.onCancel}
        >
          {t("Cancel")}
        </button>
        <button
          type="submit"
          className="btn btn-primary mr-2 save-modal-btn"
          onClick={props.onConfirm}
        >
          {t("OK")}
        </button>
      </div>
    </ModalPopUp>
  );
};

export default withTranslation()(withRouter(PreventLeaveRoute));
