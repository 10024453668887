import * as actionTypes from "../constants";

const getTheme = (value) => {
  return (dispatch) => {
    dispatch(getThemeAction(value));
  };
};

const getThemeAction = (value) => {
  return {
    type: actionTypes.THEME_INFO,
    theme: value,
  };
};

export const themeActions = {
  getThemeAction,
  getTheme,
};
