import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../store/actions/authAction";
import { REPORT_BASE_URL } from "../config";
import { useAuth0 } from "@auth0/auth0-react";

const reportApi = axios.create({
  baseURL: REPORT_BASE_URL,
});

const ReportApiInterceptor = ({ children }) => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  useEffect(() => {
    const responseInterceptor = (response) => {
      return response;
    };

    const errorInterceptor = (error) => {
      if (error.response) {
        const { status } = error.response;

        if (status === 401) {
          dispatch(authActions.logOut());
          logout();
        } else if (status === 500) {
          // Handle 500 server error
          error.response = {
            data: {
              message:
                "Something went wrong! We are unable to process your request at this time. Please contact Clearspeed support for assistance.",
            },
            status: 500,
          };
        }
      }

      return Promise.reject(error);
    };

    const requestInterceptor = (config) => {
      const token = JSON.parse(localStorage.getItem("token"));

      if (token) {
        config.headers["Authorization"] = `Bearer ${token.access_token}`;
        config.headers["Id-Token"] = token.id_token;
        //config.headers['Content-Type'] = contentType;
      } else {
        dispatch(authActions.logOut());
        logout();
      }

      return config;
    };

    const responseInterceptorId = reportApi.interceptors.response.use(
      responseInterceptor,
      errorInterceptor
    );
    const requestInterceptorId =
      reportApi.interceptors.request.use(requestInterceptor);

    return () => {
      reportApi.interceptors.response.eject(responseInterceptorId);
      reportApi.interceptors.request.eject(requestInterceptorId);
    };
  }, [dispatch]);

  return children;
};

export default reportApi;
export { ReportApiInterceptor };
