import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Grid2 as Grid } from "@mui/material";
import { Alert as AlertDismissible } from "../../../components/Common";
import { scrollTopWindow } from "../../../Helpers/index";
import { addParticipant as addParticipantService } from "../../../Services/participantsService";
import AddEditParticipant from "./AddEditParticipant";

class AddParticipant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMsg: "",
      alertStatus: false,
      variant: "",
      isinternal: [],
      noPermissionmsg: "Loading...",
      disablesavebtn: false,
      errorMsgToggle: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log("add participnt componetwillreceive props", nextProps);
    if (this.state.isinternal !== nextProps.statedata.isinternal) {
      this.setState({
        isinternal: nextProps.statedata.isinternal,
        noPermissionmsg: "",
      });
    }
  }

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };
  handleSubmit = (fields, submitProps) => {
    this.setState({
      disablesavebtn: true,
    });
    // return;
    let addParticipant = {};
    let { t } = this.props;
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    addParticipant.tenantId = userInfo.tenantId;
    addParticipant.projectId = parseInt(this.props.id);
    addParticipant.participants = [fields];
    addParticipantService(addParticipant)
      .then((res) => {
        if (res.status == 200) {
          this.setState({
            alertMsg: t("Participant Added Successfully!"),
            variant: "success",
            alertStatus: true,
            disablesavebtn: false,
            errorMsgToggle: true,
          });
          scrollTopWindow();
          submitProps.setSubmitting(false);
          submitProps.resetForm();
          setTimeout(() => {
            this.props.history.push(
              `/main/project/${this.props.id}/participants`
            );
          }, 3000);
        }
      })
      .catch((err) => {
        this.setState({
          alertMsg: err.response?.data?.message,
          variant: "danger",
          alertStatus: true,
          disablesavebtn: false,
          errorMsgToggle: false,
        });
        scrollTopWindow();
      });
  };

  shouldComponentUpdate() {
    return true;
  }
  onCancelParticipant = () => {
    this.props.history.push(`/main/project/${this.props.id}/participants`);
  };
  render() {
    const { statedata } = this.props;
    const { isinternal } = statedata;
    return (
      <div>
        <Grid container>
          <Grid item size={12}>
            <AlertDismissible
              msg={this.state.alertMsg}
              variantType={this.state.variant}
              show={this.state.alertStatus}
              close={this.handleAlertClose}
              errorMsgToggle={this.state.errorMsgToggle}
            ></AlertDismissible>
          </Grid>
        </Grid>

        {isinternal.length > 0 &&
        isinternal.indexOf("tenant.project.participants.create") >= 0 ? (
          <AddEditParticipant
            title={"Add"}
            {...this.props}
            handleSubmit={this.handleSubmit}
            onCancelClick={this.onCancelParticipant}
            disablesavebtn={this.state.disablesavebtn}
          />
        ) : (
          this.state.noPermissionmsg
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(AddParticipant));
