import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Grid2 as Grid } from "@mui/material";
import viewCloseIcon from "../../../assets/icon/view-close.png";
import viewIcon from "../../../assets/icon/view-icon.png";
import {
  Alert as AlertDismissible,
  Button,
  Spinner,
} from "../../../components/Common";
import {
  filterJson,
  getlanguageIdFromcode,
  scrollTopWindow,
  showRestrictionsField,
  sortByLabel,
} from "../../../Helpers/index";
import i18n from "../../../locale/i18n";
import {
  getlaguageAttribute,
  getTenant,
  refreshCustifyCompanies,
  refreshCustifyPeople,
  updateTenant,
} from "../../../Services/tenantService";
import { authActions } from "../../../store/actions/authAction";
import NewFileUpload from "../../Utility/NewFileUpload/index.js";
import PreventLeaveRoute from "../../Utility/PreventLeaveRoute.js";
import { SelectField } from "../../Utility/selectField";
import * as userPermission from "../../Utility/userPermission.js";

class EditTenant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMsg: "",
      alertStatus: false,
      variant: "",
      rowVal: [],
      dirty: false,
      disablesavebtn: false,
      toggleGreenhouseAPIKeyValue: false,
      toggleAccountAPIKeyValue: false,
      toggleGreenhouseAppKeyValue: false,
      accountTypeA: [],
      customerSegmentA: [],
      language: i18n.language,
      fielddisable: false,
      restrictionsOptions: [],
      isImageUploading: false,
      loadingData: true,
      logoImage: "",
      isRestrictionsFieldEnable: false,
    };
  }

  loadAttribute = () => {
    let languagecode = i18n.language;
    let languageId = getlanguageIdFromcode(languagecode);
    getlaguageAttribute(languageId)
      .then((res) => {
        this.filterAttribute(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //function to Resfresh the Custify Data
  refreshCustify = () => {
    const tenantId = this.state.rowVal.tenantId;
    console.log("tenantId", tenantId);
    scrollTopWindow();
    // Do not wait for response, just show toaster message 'Refreshing Custify Data'
    this.setState({
      alertMsg: "Refreshing Custify Data",
      variant: "success",
      alertStatus: true,
    });
    // refresh the cutify data for companies
    refreshCustifyCompanies(tenantId)
      .then((res) => {
        console.log("get res from refreshCustifyCompanies");
      })
      .catch((err) => {
        this.setState({
          alertMsg: this.props.t("Something went wrong please contact support"),
          variant: "danger",
          alertStatus: true,
        });
        console.log(err);
      });
    // refresh the cutify data for People
    refreshCustifyPeople(tenantId)
      .then((res) => {
        console.log("get res from refreshCustifyPeople");
      })
      .catch((err) => {
        this.setState({
          alertMsg: this.props.t("Something went wrong please contact support"),
          variant: "danger",
          alertStatus: true,
        });
        console.log(err);
      });
  };

  componentDidMount = () => {
    this.setState({
      isRestrictionsFieldEnable: showRestrictionsField(this.props),
    });
    const self = this;
    this.loadAttribute();
    if (
      !userPermission.isTenantEditEnabled(
        this.props.dataFromParent.statedata.userInfo.rolePermission.permissions
      )
    ) {
      this.setState({
        fielddisable: true,
      });
    }
    i18next.on("languageChanged", function (lng) {
      self.setState(
        {
          language: lng,
        },
        () => {
          self.loadAttribute();
        }
      );
    });
    getTenant(this.props.dataFromParent.statedata.editRow.id)
      .then((res) => {
        this.setState({
          rowVal: res.data,
          logoImage: this.blobToFile(res.data.image),
          alertMsg: "",
          loadingData: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  filterAttribute = (res) => {
    let accountType = filterJson(res.data.tenantAttributes, "ACCOUNT_TYPE");
    let customerSegment = filterJson(
      res.data.tenantAttributes,
      "CUSTOMER_SEGMENT"
    );

    let restrictionsOptions = filterJson(
      res.data.tenantAttributes,
      "SPECIAL_RESTRICTION"
    );

    this.setState({
      accountTypeA: accountType,
      customerSegmentA: customerSegment,
      restrictionsOptions: restrictionsOptions,
    });
  };
  cancelTenantClick = () => {
    this.props.updateNavBar("Tenant");
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  dirtyFlagChanged = (dirty) => {
    if (dirty != this.state.dirty) {
      this.setState({
        dirty: dirty,
      });
      this.props.dataFromParent.handleChildDirtyGFlag(dirty);
    }
  };

  handleUpdateTenant = (fields, resetForm) => {
    this.setState({
      disablesavebtn: true,
    });
    console.log("handleUpdateTenant fields", fields);
    const isRestrictionsFieldEnable = this.state.isRestrictionsFieldEnable;
    // return;
    updateTenant(fields, this.state.rowVal.tenantId, isRestrictionsFieldEnable)
      .then((res) => {
        scrollTopWindow();
        this.setState({
          alertMsg: `Tenant ${res.data.tenantName} Updated Successfully`,
          variant: "success",
          alertStatus: true,
          projectDetail: res.data,
          rowVal: res.data,
          logoImage: this.blobToFile(res.data.image),
          dirty: false,
          disablesavebtn: false,
        });
        setTimeout(() => {
          resetForm();
          this.cancelTenantClick();
        }, 3000);
        this.props.setHeaderLogo(res.data.image);
        this.props.dataFromParent.handleChildDirtyGFlag(false);
      })
      .catch((err) => {
        scrollTopWindow();
        let errMsg = err.response?.data?.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
          dirty: false,
          disablesavebtn: false,
        });
        this.props.dataFromParent.handleChildDirtyGFlag(false);
      });
  };

  blobToFile = (theBlob) => {
    if (theBlob) {
      const src = "data:image/png;base64," + theBlob;
      return src;
    } else {
      return theBlob;
    }
  };
  createDropdownOption = (option) => {
    return {
      value: option.attributeId,
      label: option.description,
      sequence: option.sequence,
      selected: option.sequence === 1 ? true : false,
    };
  };

  handleImageUpload = (event) => {
    this.setState({ isImageUploading: true });
    const uploadedImage = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({ isImageUploading: false });
      this.setState({ logoImage: reader.result });
    };
    reader.readAsDataURL(uploadedImage);
  };
  render() {
    const { t, currencyOptions } = this.props;
    const tenantStatusOption = [
      { id: 1, value: true, label: t("Active"), status: true },
      { id: 2, value: false, label: t("Inactive"), status: false },
    ];

    let accountTypeOption = this.state.accountTypeA
      .map((option) => {
        return this.createDropdownOption(option);
      })
      .sort(sortByLabel);

    let customerSegmentOption = this.state.customerSegmentA
      .map((option) => {
        return this.createDropdownOption(option);
      })
      .sort((a, b) => a.sequence - b.sequence);

    let sortRestrictionsOptions = this.state.restrictionsOptions
      .map((option) => {
        return this.createDropdownOption(option);
      })
      .sort((a, b) => a.sequence - b.sequence);
    let defaultCurrencyOption = currencyOptions
      .map((option) => {
        return this.createDropdownOption(option);
      })
      .sort((a, b) => a.sequence - b.sequence);

    console.log(
      "isRestrictionsFieldEnable",
      this.state.isRestrictionsFieldEnable
    );

    return (
      <Formik
        enableReinitialize
        initialValues={{
          accountName: this.state.rowVal.tenantName,
          salesPerson: this.state.rowVal.salesPerson,
          backendSystemCode: this.state.rowVal.backendSystemCode,
          customerId: this.state.rowVal.customerId,
          accountTypeId: this.state.rowVal.accountTypeId || "",
          customerSegmentId: this.state.rowVal.customerSegmentId || "",
          restrictionId: this.state.rowVal.restrictionId || "",
          numberOfInterviews: this.state.rowVal.numberOfInterviews || "",
          greenhouseAPIKey: this.state.rowVal.greenhouseAPIKey,
          accountAPIKey: this.state.rowVal.accountAPIKey,
          greenhouseAccount: this.state.rowVal.greenhouseAccount,
          partnerAccount: this.state.rowVal.partnerAccount || "",
          greenhouseAppKey: this.state.rowVal.greenhouseAppKey || "",
          status: this.state.rowVal.isActive,
          tenantProfileImage: this.blobToFile(this.state.rowVal.image),
          imageUrl: this.state.rowVal.imageUrl,
          image: this.state.rowVal.image,
          logoUpdated: false,
          isUkEnvironment: this.state.isRestrictionsFieldEnable,
          currencyId: this.state.rowVal.currencyId || "",
        }}
        validationSchema={Yup.object().shape({
          accountName: Yup.string().required(t("Account Name is required")),
          salesPerson: Yup.string()
            .email(t("Email is invalid"))
            .required(t("Sales Person Email is required"))
            .nullable(),
          numberOfInterviews: Yup.number()
            .positive(t("Only positive number"))
            .integer(t("Only positive number")),
          status: Yup.string().required(t("Status is required")),
          accountTypeId: Yup.string()
            .required(t("Account Type is Required"))
            .nullable(),
          customerSegmentId: Yup.string()
            .required(t("Customer Segment is Required"))
            .nullable(),
          isUkEnvironment: Yup.boolean(),
          restrictionId: Yup.string().when("isUkEnvironment", {
            is: () => true,
            then: () =>
              Yup.string()
                .required("Special Restrictions is Required")
                .nullable(),
            otherwise: Yup.string().nullable(),
          }),
        })}
        onSubmit={(fields, { resetForm }) => {
          this.handleUpdateTenant(fields, resetForm);
        }}
      >
        {(props) => (
          <Form onChange={this.dirtyFlagChanged(props.dirty)}>
            {console.log("props", props)}
            <PreventLeaveRoute
              reset={props.handleReset}
              when={props.dirty}
              // Navigate function
              navigate={(field, historyObj) => {
                if (field == "/main/admin/tenant") {
                  this.cancelTenantClick();
                } else {
                  historyObj.push(field);
                }
              }}
              // Use as "message" prop of Prompt of React-Router
              shouldBlockNavigation={(location) => {
                if (location.pathname === "/") {
                  return false;
                }
                return !!(props.dirty || this.state.dirty);
              }}
            />
            <Grid container>
              <Grid item size={12}>
                <AlertDismissible
                  msg={this.state.alertMsg}
                  variantType={this.state.variant}
                  show={this.state.alertStatus}
                  close={this.handleAlertClose}
                ></AlertDismissible>
              </Grid>
            </Grid>
            {this.state.loadingData ? (
              <div className="custom-tab-loader">
                <Spinner />
              </div>
            ) : (
              <>
                <Grid container className="add-tenant-row">
                  <div className="form-group col-md-4">
                    <label className="required" htmlFor="accountName">
                      {t("ACCOUNT NAME")}
                    </label>
                    <Field
                      name="accountName"
                      type="text"
                      autoComplete="off"
                      disabled={this.state.fielddisable}
                      className={
                        "form-control" +
                        (props.errors.accountName && props.touched.accountName
                          ? " is-invalid"
                          : " add-tenant-input")
                      }
                    />
                    {props.errors.accountName && props.touched.accountName && (
                      <div className="input-feedback add-tenant-validation">
                        {props.errors.accountName}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label className="required" htmlFor="customerId">
                      {t("Customer ID")}
                    </label>
                    <Field
                      name="customerId"
                      autoComplete="off"
                      disabled={this.state.fielddisable}
                      type="text"
                      className={
                        "form-control" +
                        (props.errors.customerId && props.touched.customerId
                          ? " is-invalid"
                          : " add-tenant-input")
                      }
                    />
                    {props.errors.customerId && props.touched.customerId && (
                      <div className="input-feedback add-tenant-validation">
                        {props.errors.customerId}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="backendSystemCode">
                      {t("BACKEND SYSTEM CODE")}
                    </label>
                    <Field
                      name="backendSystemCode"
                      type="text"
                      autoComplete="off"
                      disabled={this.state.fielddisable}
                      className={
                        "form-control" +
                        (props.errors.backendSystemCode &&
                        props.touched.backendSystemCode
                          ? " is-invalid"
                          : " add-tenant-input")
                      }
                    />
                    {props.errors.backendSystemCode &&
                      props.touched.backendSystemCode && (
                        <div className="input-feedback add-tenant-validation">
                          {props.errors.backendSystemCode}
                        </div>
                      )}
                  </div>
                  <div className="form-group col-md-4">
                    <label className="required" htmlFor="Account Type">
                      {t("Account type")}
                    </label>
                    <Field
                      name={"accountTypeId"}
                      onBlur={props.handleBlur}
                      component={SelectField}
                      isDisabled={this.state.fielddisable}
                      options={accountTypeOption}
                      values={props.values.accountTypeId}
                      className={
                        "form-control" +
                        (props.errors.accountTypeId &&
                        props.touched.accountTypeId
                          ? " is-invalid"
                          : " add-tenant-input")
                      }
                    />
                    {props.errors.accountTypeId &&
                      props.touched.accountTypeId && (
                        <div className="input-feedback">
                          {props.errors.accountTypeId}
                        </div>
                      )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="customer Segment" className="required">
                      {t("Customer segment")}
                    </label>
                    <Field
                      name={"customerSegmentId"}
                      onBlur={props.handleBlur}
                      component={SelectField}
                      isDisabled={this.state.fielddisable}
                      options={customerSegmentOption}
                      values={props.values.customerSegmentId}
                      className={
                        "form-control" +
                        (props.errors.customerSegmentId &&
                        props.touched.customerSegmentId
                          ? " is-invalid"
                          : " add-tenant-input")
                      }
                    />
                    {props.errors.customerSegmentId &&
                      props.touched.customerSegmentId && (
                        <div className="input-feedback">
                          {props.errors.customerSegmentId}
                        </div>
                      )}
                  </div>
                  {this.state.isRestrictionsFieldEnable ? (
                    <div className="form-group col-md-4">
                      <label htmlFor="customer Segment" className="required">
                        {t("Clearspeed Restrictions")}
                      </label>
                      <Field
                        name={"restrictionId"}
                        onBlur={props.handleBlur}
                        component={SelectField}
                        isDisabled={this.state.fielddisable}
                        options={sortRestrictionsOptions}
                        values={props.values.restrictionId}
                        className={
                          "form-control" +
                          (props.errors.restrictionId &&
                          props.touched.restrictionId
                            ? " is-invalid"
                            : " add-tenant-input")
                        }
                      />
                      {props.errors.restrictionId &&
                        props.touched.restrictionId && (
                          <div className="input-feedback"></div>
                        )}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="form-group col-md-4">
                    <label htmlFor="numberOfInterviews">
                      {t("Number Of Calls")}
                    </label>
                    <Field
                      name="numberOfInterviews"
                      type="text"
                      autoComplete="off"
                      disabled={this.state.fielddisable}
                      className={
                        "form-control" +
                        (props.errors.numberOfInterviews &&
                        props.touched.numberOfInterviews
                          ? " is-invalid"
                          : " add-tenant-input")
                      }
                    />
                    {props.errors.numberOfInterviews &&
                      props.touched.numberOfInterviews && (
                        <div className="input-feedback add-tenant-validation">
                          {t("Only positive number or nothing")}
                        </div>
                      )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="status" className="required">
                      {t("STATUS")}
                    </label>
                    <Field
                      name={"status"}
                      isDisabled={this.state.fielddisable}
                      onBlur={props.handleBlur}
                      component={SelectField}
                      options={tenantStatusOption}
                      values={props.values.status}
                      className={
                        "form-control" +
                        (props.errors.status && props.touched.status
                          ? " is-invalid"
                          : " add-tenant-input")
                      }
                    />
                    {props.errors.status && props.touched.status && (
                      <div className="input-feedback add-tenant-validation">
                        {props.errors.status}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label className="required" htmlFor="salesPerson">
                      {t("SalesPerson")}
                    </label>
                    <Field
                      name="salesPerson"
                      type="text"
                      placeholder={t("Sales Person Email")}
                      disabled={this.state.fielddisable}
                      autoComplete="off"
                      className={
                        "form-control" +
                        (props.errors.salesPerson && props.touched.salesPerson
                          ? " is-invalid"
                          : " add-tenant-input")
                      }
                    />
                    {props.errors.salesPerson && props.touched.salesPerson && (
                      <div className="input-feedback add-tenant-validation">
                        {props.errors.salesPerson}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="Partner Account">
                      {t("Partner Account")}
                    </label>
                    <Field
                      name="partnerAccount"
                      disabled={this.state.fielddisable}
                      autoComplete="off"
                      type="text"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="greenhouseAppKey">
                      {t("Greenhouse APP Key")}
                    </label>
                    <Field
                      name="greenhouseAppKey"
                      autoComplete="off"
                      disabled={this.state.fielddisable}
                      type={
                        this.state.toggleGreenhouseAppKeyValue
                          ? "text"
                          : "password"
                      }
                      className={
                        "form-control" +
                        (props.errors.greenhouseAppKey &&
                        props.touched.greenhouseAppKey
                          ? " is-invalid"
                          : " add-tenant-input")
                      }
                    />
                    {props.errors.greenhouseAppKey &&
                      props.touched.greenhouseAppKey && (
                        <div className="input-feedback add-tenant-validation">
                          {props.errors.greenhouseAppKey}
                        </div>
                      )}
                    <span
                      className="eye-icon show_value"
                      onClick={() =>
                        this.setState((prevState) => ({
                          toggleGreenhouseAppKeyValue:
                            !prevState.toggleGreenhouseAppKeyValue,
                        }))
                      }
                    >
                      {this.state.toggleGreenhouseAppKeyValue ? (
                        <img src={viewCloseIcon} />
                      ) : (
                        <img src={viewIcon} />
                      )}
                    </span>
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="greenhouseAPIKey">
                      {t("Greenhouse API Key")}
                    </label>
                    <Field
                      name="greenhouseAPIKey"
                      readOnly
                      autoComplete="off"
                      disabled={this.state.fielddisable}
                      type={
                        this.state.toggleGreenhouseAPIKeyValue
                          ? "text"
                          : "password"
                      }
                      className={
                        "form-control" +
                        (props.errors.greenhouseAPIKey &&
                        props.touched.greenhouseAPIKey
                          ? " is-invalid"
                          : " add-tenant-input")
                      }
                    />
                    {props.errors.greenhouseAPIKey &&
                      props.touched.greenhouseAPIKey && (
                        <div className="input-feedback add-tenant-validation">
                          {props.errors.greenhouseAPIKey}
                        </div>
                      )}
                    <span
                      className="eye-icon show_value"
                      onClick={() =>
                        this.setState((prevState) => ({
                          toggleGreenhouseAPIKeyValue:
                            !prevState.toggleGreenhouseAPIKeyValue,
                        }))
                      }
                    >
                      {this.state.toggleGreenhouseAPIKeyValue ? (
                        <img src={viewCloseIcon} />
                      ) : (
                        <img src={viewIcon} />
                      )}
                    </span>
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="accountAPIKey">
                      {t("Account API Key")}
                    </label>
                    <Field
                      name="accountAPIKey"
                      disabled={this.state.fielddisable}
                      autoComplete="off"
                      type={
                        this.state.toggleAccountAPIKeyValue
                          ? "text"
                          : "password"
                      }
                      className={
                        "form-control" +
                        (props.errors.accountAPIKey &&
                        props.touched.accountAPIKey
                          ? " is-invalid"
                          : " add-tenant-input")
                      }
                    />
                    {props.errors.accountAPIKey &&
                      props.touched.accountAPIKey && (
                        <div className="input-feedback add-tenant-validation">
                          {props.errors.accountAPIKey}
                        </div>
                      )}
                    <span
                      className="eye-icon show_value"
                      onClick={() =>
                        this.setState((prevState) => ({
                          toggleAccountAPIKeyValue:
                            !prevState.toggleAccountAPIKeyValue,
                        }))
                      }
                    >
                      {this.state.toggleAccountAPIKeyValue ? (
                        <img src={viewCloseIcon} />
                      ) : (
                        <img src={viewIcon} />
                      )}
                    </span>
                  </div>
                  <Grid item size={4}>
                    <div className="form-group">
                      <label className="required" htmlFor="tenant_currency_id">
                        {t("Currency")}
                      </label>
                      <Field
                        name={"currencyId"}
                        id="tenant_currency_id"
                        onBlur={props.handleBlur}
                        component={SelectField}
                        options={defaultCurrencyOption}
                        values={props.values.currencyId}
                        placeholder={t("SELECT_CURRENCY_PLACEHOLDER")}
                      />
                    </div>
                    {props.errors.currencyId && props.touched.currencyId && (
                      <div className="input-feedback">
                        {props.errors.currencyId}
                      </div>
                    )}
                  </Grid>

                  <div className="form-group col-md-4">
                    <label htmlFor="file">
                      {t("UPLOAD LOGO")} (
                      <small className="">
                        {t("maximum file size")}: 1MB - (Appx. 300px X 70px)
                      </small>
                    </label>
                    <input
                      id="file"
                      name="file"
                      disabled={this.state.fielddisable}
                      type="file"
                      accept="image/*"
                      onChange={(event) => {
                        if (event.currentTarget.files[0]) {
                          props.setFieldValue("logoUpdated", true);
                          props.setFieldValue(
                            "tenantProfileImage",
                            event.currentTarget.files[0]
                          );
                        }
                        this.handleImageUpload(event);
                      }}
                      className="form-control"
                    />

                    {this.state.isImageUploading ? (
                      <p className="d-flex align-item-center justify-content-center">
                        ...uploading
                      </p>
                    ) : (
                      this.state.logoImage && (
                        <NewFileUpload logoImage={this.state.logoImage} />
                      )
                    )}
                  </div>
                  <div className="form-check col-md-4 padding-l35 mt-4">
                    <Field
                      type="checkbox"
                      name="greenhouseAccount"
                      disabled={this.state.fielddisable}
                      checked={props.values.greenhouseAccount}
                      className="form-check-input"
                    />
                    <label
                      htmlFor="greenhouseAccount"
                      className="form-check-label"
                    >
                      {t("Greenhouse Account")}
                    </label>
                  </div>
                </Grid>
                <Grid container>
                  <Grid item size={12}>
                    <div className="form-group float-right">
                      <Button
                        variant="primary"
                        type="submit"
                        className="rounded-0 mr-4 save-btn button_with_loader"
                        disabled={
                          this.state.disablesavebtn || this.state.fielddisable
                        }
                      >
                        {this.state.disablesavebtn ? (
                          <>
                            {t("Save")}...
                            <div className="custom-loader linesLoader loader_inner_button">
                              <Spinner color="#ffffff" size={"19px"} />
                            </div>
                          </>
                        ) : (
                          <span>{t("Save")}</span>
                        )}
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        className="rounded-0 cancel-btn"
                        onClick={this.cancelTenantClick}
                      >
                        {t("Cancel")}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    themeColor: state.theme.themeColor,
  };
};
const mapDispatchToProps = {
  setHeaderLogo: authActions.setHeaderLogo,
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(EditTenant))
);
