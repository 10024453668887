import * as React from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

export default function Checkboxes({
  checked,
  label,
  onChange,
  className,
  indeterminate,
}) {
  return (
    <FormGroup>
      <FormControlLabel
        className={className}
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            indeterminate={indeterminate}
          />
        }
        label={label}
      />
    </FormGroup>
  );
}
