import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid2 as Grid } from "@mui/material";
import moment from "moment";
import deleteGrayIcon from "../../../../assets/icon/delete_gray.png";
import editGrayIcon from "../../../../assets/icon/edit_gray.png";
import { AddCircleIcon } from "../../../../assets/images/icons/customIcons.js";
import { Alert as AlertDismissible } from "../../../../components/Common";
import {
  isResultConfigurationCreateEnabled,
  isResultConfigurationDeleteEnabled,
  isResultConfigurationUpdateEnabled,
} from "../../../../components/Utility/userPermission.js";
import { Date_Format } from "../../../../config/index.js";
import {
  createResultTemplate,
  deleteResultConfigListData,
  getResultConfigListData,
  updateResultTemplate,
} from "../../../../Services/projectServices.js";
import { CustomGrid } from "../../../CustomTable/index.js";
import DeleteTemplate from "./DeleteTemplate.js";
import TemplateModalForm from "./TemplateModalForm";

const ResultList = ({ dataFromParent }) => {
  const { t } = useTranslation();

  const userPermissionVal =
    dataFromParent.statedata?.userInfo?.rolePermission?.permissions;
  const canUserCreateTemplate =
    isResultConfigurationCreateEnabled(userPermissionVal);
  const canUserDeleteTemplate =
    isResultConfigurationDeleteEnabled(userPermissionVal);
  const canUserUpdateTemplate =
    isResultConfigurationUpdateEnabled(userPermissionVal);

  const [isConfirm, setIsConfirm] = useState(false);
  const [deletedTemplateId, setDeletedTemplateId] = useState("");
  const [deletedTemplateMsg, setDeletedTemplateMsg] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPageCount, setPerPageCount] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);
  const [variant, setVariant] = useState("");
  const [resultConfigList, setResultConfigList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogModalAdd, setDialogModalAdd] = useState(false);
  const [dialogModalUpdate, setDialogModalUpdate] = useState(false);
  const [rowID, setRowID] = useState(null);
  const [dialogModal, setDialogModal] = useState(null);

  useEffect(() => {
    fetchResultConfigList("showLoder");
  }, [currentPage, perPageCount]);

  /**
   * This function will fethc all Result Config list
   */
  const fetchResultConfigList = (loaderStatus) => {
    if (loaderStatus === "showLoder") {
      setLoading(true);
    }

    getResultConfigListData(currentPage, perPageCount)
      .then((res) => {
        // console.log("res getResultConfigListData", res.data.template);
        if (res.data.total_template > 0 && res.data.template.length === 0) {
          onChangePage(currentPage - 1);
        } else {
          const totalPages = Math.ceil(res.data.total_template / perPageCount);
          setTotalPages(totalPages);
          setTotalResults(res.data.total_template);
          setResultConfigList(res.data.template);
          setLoading(false);
        }
      })
      .catch(() => {
        setResultConfigList([]);
        setLoading(false);
      });
  };

  const columns = [
    {
      field: "template_name",
      headerName: t("Template Name"),
      width: 300,
      sortable: false,
    },
    {
      field: "create_date",
      headerName: t("Date Created"),
      flex: 1,
      renderCell: (params) =>
        params.row.create_date
          ? moment(params.row.create_date).format(Date_Format)
          : "-",
      sortable: false,
    },
    {
      field: "action",
      headerName: t("Action"),
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center result_conf_table_action_icon">
            {canUserUpdateTemplate && (
              <div
                onClick={() => {
                  setRowID(params.row.id);
                  setDialogModal("EDIT");
                  setDialogModalUpdate(true);
                }}
              >
                <img src={editGrayIcon} alt="Edit" />
              </div>
            )}
            {canUserDeleteTemplate && (
              <div
                onClick={() => {
                  setDeletedTemplateMsg(
                    "Are you sure you want to delete this template ?"
                  );
                  setDeletedTemplateId(params.row.id);
                  setIsConfirm(true);
                }}
              >
                <img src={deleteGrayIcon} alt="Delete" />
              </div>
            )}
          </div>
        );
      },
      width: 100,
      sortable: false,
    },
  ];

  const handleDeleteTemplate = (toggle) => {
    if (toggle) {
      setDisabledBtn(true);
      deleteResultConfigListData(deletedTemplateId)
        .then(() => {
          fetchResultConfigList("dontShowLoder");
          setDisabledBtn(false);
          setIsConfirm(false);
          setAlertMsg(`Template Deleted Successfully`);
          setVariant("success");
          setAlertStatus(true);
        })
        .catch((error) => {
          setDisabledBtn(false);
          setIsConfirm(false);
          setAlertMsg(error.response?.data?.message);
          setVariant("danger");
          setAlertStatus(true);
        });
    } else {
      setDeletedTemplateId("");
      setIsConfirm(false);
    }
  };

  const onChangeRecords = (perPageCount) => {
    setPerPageCount(perPageCount.pageSize);
    setCurrentPage(perPageCount.page);
  };

  const handleAddSubmit = (values) => {
    createResultTemplate(values)
      .then((res) => {
        setDialogModalAdd(false);
        fetchResultConfigList("dontShowLoder");
        setAlertMsg(`Template Created Successfully`);
        setVariant("success");
        setAlertStatus(true);
        setRowID(null);
        setDialogModal(null);
      })
      .catch((error) => {
        setDialogModalAdd(false);
        setAlertMsg(error.response?.data?.message);
        setVariant("danger");
        setAlertStatus(true);
        setRowID(null);
        setDialogModal(null);
      });
  };

  const handleUpdateSubmit = (values) => {
    updateResultTemplate(rowID, values)
      .then((res) => {
        setDialogModalUpdate(false);
        fetchResultConfigList("dontShowLoder");
        setAlertMsg(`Template Update Successfully`);
        setVariant("success");
        setAlertStatus(true);
        setRowID(null);
        setDialogModal(null);
      })
      .catch((error) => {
        setDialogModalUpdate(false);
        setAlertMsg(error.response?.data?.message);
        setVariant("danger");
        setAlertStatus(true);
        setRowID(null);
        setDialogModal(null);
      });
  };

  const recordstatus1 = parseInt(currentPage * perPageCount) + 1;
  const recordstatus2 =
    parseInt(currentPage * perPageCount) + parseInt(perPageCount);
  return (
    <div>
      <div className="card">
        <div
          className="card-body"
          style={{
            padding: "9px 20px",
          }}
        >
          <div className="row">
            <div className="col-lg-8">
              <h6 className="page-title">{t("Result Configuration")}</h6>
            </div>

            {canUserCreateTemplate && (
              <div className="col-lg-4">
                <div
                  className="tenant-add-button"
                  onClick={() => {
                    setDialogModalAdd(true);
                    setDialogModal("ADD");
                  }}
                >
                  <AddCircleIcon className={{ color: "#34AA44" }} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        {alertStatus && (
          <Grid container>
            <Grid item size={12}>
              <AlertDismissible
                msg={alertMsg}
                variantType={variant}
                show={alertStatus}
                close={() => setAlertStatus(false)}
              ></AlertDismissible>
            </Grid>
          </Grid>
        )}

        <div className="card">
          <div className="table_wrapper custom_table_container app-table admin-resultconfiguration-module-table">
            <CustomGrid
              data={resultConfigList}
              columns={columns}
              currentPage={currentPage}
              perPageCount={perPageCount}
              totalPages={totalResults}
              isServerPagination={true}
              loading={loading}
              onChangeRecords={onChangeRecords}
            />
          </div>
        </div>
        <DeleteTemplate
          show={isConfirm}
          t={t}
          onOkay={(e) => handleDeleteTemplate(e)}
          disabledBtn={disabledBtn}
        >
          {t(deletedTemplateMsg)}
        </DeleteTemplate>

        {dialogModalAdd && dialogModal === "ADD" && (
          <TemplateModalForm
            show={dialogModalAdd}
            handleClose={() => {
              setDialogModalAdd(false);
              setDialogModal(null);
            }}
            handleSubmit={handleAddSubmit}
            rowID={rowID}
            dialogModal={dialogModal}
          />
        )}
        {dialogModalUpdate && dialogModal === "EDIT" && (
          <TemplateModalForm
            show={dialogModalUpdate}
            handleClose={() => {
              setDialogModalUpdate(false);
              setRowID(null);
            }}
            handleSubmit={handleUpdateSubmit}
            rowID={rowID}
            dialogModal={dialogModal}
          />
        )}
      </div>
    </div>
  );
};
export default ResultList;
