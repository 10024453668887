import React, { Component } from "react";
const AdminMenuIconProp = function (props) {
  return (
    <svg
      style={{
        width: "18px",
        height: "18px",
        verticalAlign: "sub",
        marginRight: "10px",
        marginBottom: "2px",
      }}
      viewBox="0 0 20 20"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Dribbble-Light-Preview"
          transform="translate(-100.000000, -1119.000000)"
          fill={props.className.color || "#000000"}
        >
          <g id="icons" transform="translate(56.000000, 160.000000)">
            <path
              fill={props.className.color || "#000000"}
              d={props.d}
              id="admin_menu"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

const AdminMenuIcon = function (props) {
  return (
    <AdminMenuIconProp
      {...props}
      d="M46,977 L62,977 L62,969 L46,969 L46,977 Z M44,979 L64,979 L64,967 L44,967 L44,979 Z M49,973 L59,973 L59,971 L49,971 L49,973 Z M46,965 L62,965 L62,963 L46,963 L46,965 Z M48,961 L60,961 L60,959 L48,959 L48,961 Z"
    />
  );
};

const AddCircleIconProp = function (props) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      width="35"
      height="35"
      // style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
      // preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.className.color || "#000000"}
        d={props.d}
        id="add_circle"
      ></path>
      <rect x="0" y="0" width="35" height="35" fill="rgba(0, 0, 0, 0)" />
    </svg>
  );
};

const AddCircleIcon = function (props) {
  return (
    <AddCircleIconProp
      {...props}
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm4 11h-3v3c0 .55-.45 1-1 1s-1-.45-1-1v-3H8c-.55 0-1-.45-1-1s.45-1 1-1h3V8c0-.55.45-1 1-1s1 .45 1 1v3h3c.55 0 1 .45 1 1s-.45 1-1 1z"
    />
  );
};

const InterviewMenuIconProp = function (props) {
  return (
    <svg
      style={{
        width: "18px",
        height: "18px",
        verticalAlign: "sub",
        marginRight: "10px",
      }}
      viewBox="0 0 20 18"
    >
      <g
        id="Screens"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CCA-Interview-Review"
          transform="translate(-30.000000, -88.000000)"
        >
          <g id="Icon" transform="translate(30.000000, 88.000000)">
            <path
              fill={props.className.color || "#000000"}
              d={props.d}
              id="interview_menu"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

const InterviewMenuIcon = function (props) {
  return (
    <InterviewMenuIconProp
      {...props}
      d="M18,13.6 L2.804,10.561 C2.336,10.467 2,10.057 2,9.58 L2,8.42 C2,7.943 2.336,7.533 2.804,7.439 L18,4.4 L18,13.6 Z M8,14.362 C8,14.995 7.419,15.469 6.798,15.341 L4.798,14.93 C4.334,14.834 4,14.425 4,13.95 L4,12.8 L8,13.6 L8,14.362 Z M18,1 L18,2.4 L1.608,5.678 C0.673,5.865 0,6.686 0,7.64 L0,10.36 C0,11.314 0.673,12.135 1.608,12.322 L2,12.4 L2,14.724 C2,15.673 2.667,16.491 3.597,16.683 L7.597,17.506 C8.838,17.761 10,16.813 10,15.547 L10,14 L18,15.6 L18,17 C18,17.552 18.448,18 19,18 C19.552,18 20,17.552 20,17 L20,1 C20,0.448 19.552,0 19,0 C18.448,0 18,0.448 18,1 L18,1 Z"
    />
  );
};

const HomeMenuIconProp = function (props) {
  return (
    <svg
      style={{
        width: "18px",
        height: "22px",
        verticalAlign: "top",
        marginRight: "7px",
      }}
      viewBox="0 0 20 18"
    >
      <g
        id="Screens"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="CCA-Interview-Review" transform="translate(0)">
          <g id="Icon" transform="translate(0)">
            <path
              fill={props.className.color || "#000000"}
              d={props.d}
              id="interview_menu"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

const HomeMenuIcon = function (props) {
  return (
    <HomeMenuIconProp
      {...props}
      d="M 9 20 H 2 V 10.813 l -0.589 0.593 L 0 9.992 L 10 0 l 8 8 l 2 2 l -1.416 1.414 L 18 10.832 V 20 l -7 0 V 16 H 9 v 4 Z M 7 14 h 6 v 4 h 3 V 8.832 L 9.99 2.823 L 4 8.813 V 18 H 7 V 14 Z"
    />
  );
};

const ProjectsMenuIconProp = function (props) {
  return (
    <svg
      style={{
        width: "18px",
        height: "22px",
        verticalAlign: "top",
        marginRight: "10px",
      }}
      viewBox="0 0 20 18"
    >
      <g
        id="Screens"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="CCA-Interview-Review" transform="translate(0)">
          <g id="Icon" transform="translate(0)">
            <path
              fill={props.className.color || "#000000"}
              d={props.d}
              id="interview_menu"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

const ProjectsMenuIcon = function (props) {
  return (
    <ProjectsMenuIconProp
      {...props}
      d="M 3 20 a 3 3 0 1 1 3 -3 A 3 3 0 0 1 3 20 Z m 0 -4 a 1 1 0 1 0 1 1 A 1 1 0 0 0 3 16 Z m 17 2 H 8 V 16 H 20 v 2 Z M 3 13 a 3 3 0 1 1 3 -3 A 3 3 0 0 1 3 13 Z M 3 9 a 1 1 0 1 0 1 1 A 1 1 0 0 0 3 9 Z m 17 2 H 8 V 9 H 20 v 2 Z M 3 6 A 3 3 0 1 1 6 3 A 3 3 0 0 1 3 6 Z M 3 2 A 1 1 0 1 0 4 3 A 1 1 0 0 0 3 2 Z M 20 4 H 8 V 2 H 20 V 4 Z"
    />
  );
};

const ReportsMenuIconProp = function (props) {
  return (
    <svg
      style={{
        width: "18px",
        height: "30px",
        verticalAlign: "sub",
        marginRight: "7px",
      }}
      viewBox="0 0 20 18"
    >
      <g
        id="Screens"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="CCA-Interview-Review" transform="translate(-23309.8 -11375.7)">
          <g id="Icon" transform="translate(0)">
            <path
              fill={props.className.color || "#000000"}
              d={props.d}
              id="interview_menu"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

const ReportsMenuIcon = function (props) {
  return (
    <ReportsMenuIconProp
      {...props}
      d="M 23310 11400.8 l 0.08 -23.74 h 4.3 v -1.447 h 10.891 v 1.447 h 4.379 v 23.74 Z m 2.045 -2.045 h 15.5 v -19.457 h -2.277 v 1.788 h -10.891 v -1.788 h -2.334 Z m 4.2 -19.6 h 7.152 v -1.493 h -7.152 Z m -3.324 15.721 l 0.895 -0.913 l 0.9 0.913 l 1.738 -1.769 l 0.9 0.908 l -2.635 2.682 Z m 6.41 0.123 v -1.5 h 7.377 v 1.5 Z m -6.41 -4.988 l 0.895 -0.908 l 0.9 0.908 l 1.738 -1.769 l 0.9 0.907 l -2.635 2.682 Z m 6.41 0.188 v -1.5 h 7.377 v 1.5 Z m -6.41 -5.146 l 0.895 -0.908 l 0.9 0.908 l 1.738 -1.769 l 0.9 0.907 l -2.635 2.682 Z m 6.41 0.345 v -1.5 h 7.377 v 1.5 Z"
    />
  );
};

const UpdownArrowIcon = function () {
  return (
    <svg
      style={{
        width: "6px",
        height: "10px",
        verticalAlign: "baseline",
        marginRight: "10px",
        marginLeft: "5px",
      }}
      viewBox="0 0 5.998 9.997"
    >
      <path
        id="Arrows"
        fill="#bebebe"
        d="M 0 7.054 l 6 -0.111 L 2.943 10 Z M 3.045 0 L 5.979 2.934 L 0 3.045 Z"
        id="interview_menu"
      ></path>
    </svg>
  );
};
const CopyIcon = function (props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.handleCopy}
      className={props.className}
      width="18pt"
      height="18pt"
      viewBox="0 0 18 18"
      version="1.1"
    >
      <g id="surface1">
        <path
          fill={props.color}
          d="M 12.175781 18 L 2.800781 18 C 1.664062 18 0.738281 17.074219 0.738281 15.9375 L 0.738281 5.8125 C 0.738281 4.675781 1.664062 3.75 2.800781 3.75 L 3.175781 3.75 C 3.484375 3.75 3.738281 4.003906 3.738281 4.3125 C 3.738281 4.625 3.484375 4.875 3.175781 4.875 L 2.800781 4.875 C 2.285156 4.875 1.863281 5.296875 1.863281 5.8125 L 1.863281 15.9375 C 1.863281 16.453125 2.285156 16.875 2.800781 16.875 L 12.175781 16.875 C 12.691406 16.875 13.113281 16.453125 13.113281 15.9375 L 13.113281 15.5625 C 13.113281 15.253906 13.367188 15 13.675781 15 C 13.984375 15 14.238281 15.253906 14.238281 15.5625 L 14.238281 15.9375 C 14.238281 17.074219 13.3125 18 12.175781 18 Z M 12.175781 18 "
        />
        <path
          fill={props.color}
          d="M 15.175781 13.5 L 7.300781 13.5 C 6.164062 13.5 5.238281 12.574219 5.238281 11.4375 L 5.238281 2.0625 C 5.238281 0.925781 6.164062 0 7.300781 0 L 15.175781 0 C 16.3125 0 17.238281 0.925781 17.238281 2.0625 L 17.238281 11.4375 C 17.238281 12.574219 16.3125 13.5 15.175781 13.5 Z M 7.300781 1.125 C 6.785156 1.125 6.363281 1.546875 6.363281 2.0625 L 6.363281 11.4375 C 6.363281 11.953125 6.785156 12.375 7.300781 12.375 L 15.175781 12.375 C 15.691406 12.375 16.113281 11.953125 16.113281 11.4375 L 16.113281 2.0625 C 16.113281 1.546875 15.691406 1.125 15.175781 1.125 Z M 7.300781 1.125 "
        />
      </g>
    </svg>
  );
};
const UploadFileIcon = function (props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: "18px",
        height: "20px",
        verticalAlign: "sub",
        marginRight: "7px",
      }}
      viewBox="0 0 25 25"
    >
      <g>
        <g>
          <path
            fill={props.className.color || "#000000"}
            d="M25,22.5v.63A3.17,3.17,0,0,1,23.13,25H2a3.63,3.63,0,0,1-2-2.25V2.25A3.51,3.51,0,0,1,2.13,0H17.5c.2.17.42.33.61.52Q21.26,3.7,24.4,6.9a4.66,4.66,0,0,1,.5.84q0,7.17,0,14.36A1.52,1.52,0,0,0,25,22.5Zm-16.28,0c0-2.6,0-5.12,0-7.64a.72.72,0,0,0-.39-.55.81.81,0,0,0-.66.19c-.36.3-.67.68-1,1a1.22,1.22,0,0,1-1.78,0,1.19,1.19,0,0,1,0-1.74c1.17-1.15,2.35-2.3,3.57-3.4a2.37,2.37,0,0,1,3.3,0c1.21,1.08,2.38,2.21,3.54,3.34a1.23,1.23,0,0,1,.1,1.79,1.27,1.27,0,0,1-1.84,0,11.11,11.11,0,0,0-1-.92c-.26-.17-.69-.36-.92-.26s-.38.56-.39.86c0,2.23,0,4.46,0,6.69,0,.2,0,.39,0,.63H21c1.08,0,1.44-.33,1.44-1.4,0-3.5,0-7,0-10.5,0-.46-.12-.62-.6-.61-1.39,0-2.79,0-4.18,0A2.6,2.6,0,0,1,15,7.3c0-1.36,0-2.71,0-4.06,0-.23,0-.45,0-.64s-.13-.08-.17-.08c-3.64,0-7.29,0-10.93,0A1.31,1.31,0,0,0,2.5,3.94V21.06a1.29,1.29,0,0,0,1.21,1.41C5.35,22.52,7,22.48,8.72,22.48Z"
          />
        </g>
      </g>
    </svg>
  );
};

const AnalyticsReportsIcon = function (props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: "18px",
        height: "22px",
        verticalAlign: "sub",
        marginRight: "7px",
      }}
    >
      <path
        d="M5.5 12.1667H3.83333V6.33333H5.5V12.1667ZM8.83333 12.1667H7.16667V3.83333H8.83333V12.1667ZM12.1667 12.1667H10.5V8.83333H12.1667V12.1667ZM13.8333 13.8333H2.16667V2.16667H13.8333V13.8333ZM13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5Z"
        fill={props.className.color || "#000000"}
      />
    </svg>
  );
};

export {
  AdminMenuIcon,
  AddCircleIcon,
  InterviewMenuIcon,
  HomeMenuIcon,
  ProjectsMenuIcon,
  ReportsMenuIcon,
  UpdownArrowIcon,
  CopyIcon,
  UploadFileIcon,
  AnalyticsReportsIcon,
};
