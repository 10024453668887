import React, { useEffect } from "react";
import { useGetReport } from "./context/actions";
import { ReportList } from "./ReportList";
import "./analyticsReports.css";

const AnalyticsReports = () => {
  const { getReports, getReportTypes, getProjectList } = useGetReport();

  useEffect(() => {
    getReports();
    getReportTypes();
    getProjectList();
  }, []);

  return <ReportList />;
};

export default AnalyticsReports;
