import api from './api';


const getRolePermission = async (roleId) => {
return await api.get(`user-service/v1/permissions?roleId=${roleId}`)
}


const upRolePermission = async (param) => {
    return api.put(`user-service/v1/role/permission`, param);
}

export { getRolePermission, upRolePermission };