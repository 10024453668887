import React from "react";
import Select from "react-select";
import * as FileSaver from "file-saver";
import { getFileDownload } from "../../../../Services/projectServices";
import { useTranslation } from "react-i18next";

export const SelectField = ({
  options,
  field,
  form,
  values,
  isDisabled,
  hasClearable,
  handlePhoneNumber,
  handleChange,
  isBusinessLine,
}) => {
  const { t } = useTranslation();
  const selectedValue =
    isBusinessLine && values
      ? values
      : values
      ? options.find((option) => option.value === values)
      : null;
  return (
    <Select
      className="select-style"
      classNamePrefix="select"
      options={options}
      name={field.name}
      value={selectedValue}
      onChange={(option) => {
        if (isBusinessLine) {
          handleChange(option);
        } else {
          form.setFieldValue(field.name, option ? option.value : null);
          if (field.name === "defaultCustomerInterviewMethodId") {
            handlePhoneNumber();
          }
        }
      }}
      onBlur={() => {
        form.setFieldTouched(field.name);
      }}
      placeholder={t("Select")}
      isDisabled={isDisabled}
      isSearchable={false}
      isClearable={hasClearable ? hasClearable : false}
    />
  );
};

/**
 * This function will create dropdwon Option for select box
 * @param {object} option
 * @param {boolean} flag
 * @returns
 */
export const createDropdownOption = (option, flag = false) => {
  if (flag) {
    return {
      value: option.attributeId,
      label: option.description,
    };
  } else {
    return {
      value: option.attributeId,
      label: option.description,
      sequence: option.sequence,
    };
  }
};

/**
 * This function will sort dropdwon Option for select box
 * @param {string} option
 * @param {string} flag
 * @returns
 */
export const sortByLabel = (a, b) => {
  if (a.label.toLowerCase() > b.label.toLowerCase()) {
    return 1;
  }
  if (a.label.toLowerCase() < b.label.toLowerCase()) {
    return -1;
  }
  return 0;
};

/**
 * This function will download file
 * @param {string} id
 * @param {string} type
 * @param {string} fileName
 */
export const fileDownload = (id, type, fileName) => {
  getFileDownload(id, type)
    .then((res) => {
      let blob = "";
      if (
        type === "ISW_XLSX" ||
        type === "SUPERVISOR_XLSX" ||
        type === "MOBILE_APP_XLSX"
      ) {
        blob = new Blob([res.data], {
          type: "text/xlsx; charset=utf-8",
        });
        FileSaver.saveAs(blob, fileName);
      } else if (type === "QUESTIONS_AUDIO") {
        blob = new Blob([res.data], {
          type: "audio/ogg; charset=utf-8",
        });
        FileSaver.saveAs(blob, fileName);
      } else if (type === "ASSETS_ZIP") {
        blob = new Blob([res.data], {
          type: "application/zip; charset=utf-8",
        });
        FileSaver.saveAs(blob, fileName);
      } else {
        blob = new Blob([res.data], {
          type: "text/csv; charset=utf-8",
        });
        FileSaver.saveAs(blob, fileName);
      }
    })
    .catch((err) => {
      console.log("fileDownload err", err);
    });
};
