import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Field } from "formik";
import { Grid2 as Grid } from "@mui/material";
import * as FileSaver from "file-saver";
import { Button } from "../../../../components/Common";
import { scrollTopWindow } from "../../../../Helpers";
import {
  getExportDetailClick,
  uploadSupervisorData,
} from "../../../../Services/projectServices";
import { getDaysOptions } from "../../../../shared/utils";
import { projectActions } from "../../../../store/actions/projectActions";
import { fileDownload, SelectField } from "../Helper";
import DropDownOptions from "../IntialProjectDetail/DropDownOptions";
import InternalUseOnlyOption from "./InternalUseOnlyOption";

const InternalUseOnly = (props) => {
  //const { t } = useTranslation();

  const { t, formik, state, showExportDetail } = props;
  const { templateTypeOption } = DropDownOptions(state, t);
  const { projectId, canUserUpdateField } = state;

  const fileInput = useRef(null);
  const supervisorFileInput = useRef(null);

  /* const "invalidImage" show upload image status */
  const [invalidImage, setInvalidImage] = useState(null);
  const [supervisormsg, setSupervisormsg] = useState("");
  const [loadingFileDownload, setLoadingFileDownload] = useState(false);
  const [showConfigurationJson, setShowConfigurationJson] = useState("");
  const [disablefilebtn, setDisablefilebtn] = useState(false);
  const [disableJsonbtn, setDisableJsonbtn] = useState(false);
  const [colorToggle, setColorToggle] = useState(true);

  const {
    handleBlur,
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const {
    interviewOption,
    acessCodeOption,
    guideURLOption,
    integrationMethodOption,
    multilingualPhoneOption,
  } = InternalUseOnlyOption(state);

  let internalFieldDisabled =
    state.isinternal.indexOf("tenant.project.detail.internal.update") >= 0
      ? true
      : false;
  let internalFieldTemplateRead =
    state.isinternal.indexOf("tenant.result.config.read") >= 0 ? true : false;

  /**
   * THis function will upload Json file
   * @param {object} event
   * @param {function} setFieldValue
   * @returns
   */
  const onChangeHandler = (event, setFieldValue) => {
    const imageFile = event.target.files[0];
    if (!imageFile) {
      setInvalidImage("Please select Json File");
      return false;
    } else {
      setDisableJsonbtn(true);
      const reader = new FileReader();
      reader.onload = () => {
        let data = reader.result.substring(0, 20000000);
        setShowConfigurationJson(data);
        let response = JSON.parse(data);
        let jsonresponse = JSON.stringify(response);
        if (data) {
          try {
            setFieldValue("configurationJSON", jsonresponse);
            setDisableJsonbtn(false);
            setInvalidImage("");
          } catch (e) {
            if (e instanceof SyntaxError) {
              setInvalidImage(
                "JSON is not valid. Upload again after correction"
              );
              fileInput.current.value = "";
            } else {
              console.log(e, false);
            }
            setDisableJsonbtn(false);
          }
        }
      };
      reader.readAsText(event.target.files[0]);
    }
  };

  /**
   * This function will export data
   * @param {string} projectId
   */
  const exportDetailClick = (projectId) => {
    setLoadingFileDownload(true);
    getExportDetailClick(projectId)
      .then((res) => {
        let blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8",
        });
        const getFileName = res.headers["content-disposition"].split("=");
        setLoadingFileDownload(false);
        FileSaver.saveAs(blob, getFileName[1]);
      })
      .catch((err) => {
        console.log("err", err);
        scrollTopWindow();
        showExportDetail();
        setLoadingFileDownload(false);
      });
  };

  /**
   * Upload file for supervisor
   * @param {object} event
   */
  const selectUploadFile = (event) => {
    event.preventDefault();
    setDisablefilebtn(true);
    let updatedprojectId = parseInt(projectId);
    let fileName = supervisorFileInput.current.files[0];
    let formData = new FormData();
    formData.append("file", fileName);

    uploadSupervisorData(updatedprojectId, formData)
      .then(() => {
        setFieldValue(
          "supervisor_xlsx_file_name",
          supervisorFileInput.current.files[0].name
        );
        supervisorFileInput.current.value = "";
        setColorToggle(true);
        setSupervisormsg("File uploaded successfully");
        setDisablefilebtn(false);
        setTimeout(() => {
          setSupervisormsg("");
        }, 5000);
      })
      .catch(() => {
        supervisorFileInput.current.value = "";
        setColorToggle(false);
        setSupervisormsg("Error occurred in file upload please try again");
        setDisablefilebtn(false);
        setTimeout(() => {
          setSupervisormsg("");
        }, 5000);
      });
  };

  const handlePhoneNumber = () => {
    props.setProjectPhoneNumberError([]);
  };

  return (
    <Grid item size={12}>
      <div className="mb-2 mt-2 additional_configuration_accordion">
        <Accordion
          allowZeroExpanded="true"
          allowMultipleExpanded="true"
          preExpanded={["a"]}
        >
          <AccordionItem uuid="a">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="row-header">
                  <div>
                    <label className="row-header-title">
                      {t("Internal Use Only")}
                    </label>
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Grid
                container
                className="internal_view_old pt-2"
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item size={3}>
                  <div className="form-group">
                    <label htmlFor="defaultCustomerInterviewMethodId">
                      {t("Delivery Method")}
                    </label>
                    <Field
                      name={"defaultCustomerInterviewMethodId"}
                      id="defaultCustomerInterviewMethodId"
                      isDisabled={canUserUpdateField}
                      onBlur={handleBlur}
                      component={SelectField}
                      options={interviewOption}
                      handlePhoneNumber={handlePhoneNumber}
                      values={values.defaultCustomerInterviewMethodId}
                    />
                  </div>
                </Grid>
                <Grid item size={3}>
                  <div className="form-group">
                    <label htmlFor="multilingualPhoneId">
                      {t("Multilingual Phone")}
                    </label>

                    <Field
                      name={"multilingualPhoneId"}
                      id="multilingualPhoneId"
                      onBlur={handleBlur}
                      component={SelectField}
                      options={multilingualPhoneOption}
                      values={values.multilingualPhoneId}
                      isDisabled={
                        canUserUpdateField
                          ? canUserUpdateField
                          : !internalFieldDisabled
                      }
                    />
                  </div>
                </Grid>
                <Grid item size={3}>
                  <div className="form-group">
                    <label htmlFor="IntegrationMethodId">
                      {t("Integration Method")}
                    </label>
                    <Field
                      name={"integration_method_id"}
                      id="IntegrationMethodId"
                      onBlur={handleBlur}
                      isDisabled={canUserUpdateField}
                      component={SelectField}
                      options={integrationMethodOption}
                      values={values.integration_method_id}
                    />
                  </div>
                </Grid>
                <Grid item size={3}>
                  <div className="form-group">
                    <label htmlFor="accessCodeBehaviorId">
                      {t("Access Code Behaviour")}
                    </label>
                    <Field
                      name={"accessCodeBehaviorId"}
                      id="accessCodeBehaviorId"
                      onBlur={handleBlur}
                      isDisabled={canUserUpdateField}
                      component={SelectField}
                      options={acessCodeOption}
                      values={values.accessCodeBehaviorId}
                    />
                  </div>
                </Grid>
                <Grid item size={6}>
                  <div className="form-group">
                    <label htmlFor="configurationFile">
                      {t("Upload Configuration JSON")}
                    </label>
                    <div
                      className="custom-upload"
                      data-permission={canUserUpdateField}
                    >
                      <input
                        id="configurationFile"
                        name="file"
                        ref={fileInput}
                        accept=".json"
                        type="file"
                        className="configuration-file-input"
                        disabled={disableJsonbtn}
                        data-content={t("Upload JSON")}
                        onChange={(e) => onChangeHandler(e, setFieldValue)}
                      />
                    </div>
                    {invalidImage && (
                      <p className="input-feedback">{invalidImage}</p>
                    )}
                  </div>
                  <div className="form-group">
                    {showConfigurationJson != "" ? (
                      <textarea
                        rows="4"
                        disabled
                        value={showConfigurationJson || ""}
                        className="form-control"
                        readOnly
                      ></textarea>
                    ) : state.projectDetail.configurationJSON != "" ? (
                      <textarea
                        rows="4"
                        disabled
                        value={state.projectDetail.configurationJSON || ""}
                        className="form-control"
                        readOnly
                      ></textarea>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
                <Grid item size={6}>
                  <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item size={6}>
                      <div className="input-group mb-3 ">
                        <div className="d-block w-100">
                          <label htmlFor="irnLength">{t("IRN Length")}</label>
                        </div>
                        <input
                          type="number"
                          name="irnLength"
                          id="irnLength"
                          disabled={canUserUpdateField}
                          min="6"
                          max="15"
                          className={
                            "form-control" +
                            (errors.irnLength && touched.irnLength
                              ? " is-invalid"
                              : "")
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.irnLength}
                        />
                        <div className="d-block w-100">
                          {errors.irnLength && touched.irnLength && (
                            <div className="input-feedback">
                              {errors.irnLength}
                            </div>
                          )}
                        </div>
                      </div>
                    </Grid>
                    <Grid item size={6}>
                      <div className="form-group">
                        <label htmlFor="guideURLOptionId">
                          {t("Guide Domain URL")}
                        </label>

                        <Field
                          name={"guideURLOptionId"}
                          id="guideURLOptionId"
                          onBlur={handleBlur}
                          component={SelectField}
                          options={guideURLOption}
                          values={values.guideURLOptionId}
                          isDisabled={
                            canUserUpdateField
                              ? canUserUpdateField
                              : !internalFieldDisabled
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item size={6}>
                      <div className="form-group">
                        <label htmlFor="senderName">
                          {t("Email Sender Name")}
                        </label>
                        <Field
                          name="sender_name"
                          id="senderName"
                          type="text"
                          disabled={canUserUpdateField}
                          autoComplete="off"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          values={values.sender_name}
                        />
                        {errors.sender_name && touched.sender_name && (
                          <div className="input-feedback">
                            {errors.sender_name}
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item size={6}>
                      <div className="form-group">
                        <label htmlFor="resultEmailRecipient">
                          {t("Email Recipients")}
                        </label>
                        <Field
                          name="result_email_recipient"
                          id="resultEmailRecipient"
                          type="text"
                          disabled={canUserUpdateField}
                          autoComplete="off"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          values={values.result_email_recipient}
                        />
                        {errors.result_email_recipient &&
                          touched.result_email_recipient && (
                            <div className="input-feedback">
                              {errors.result_email_recipient}
                            </div>
                          )}
                      </div>
                    </Grid>
                    {internalFieldTemplateRead && (
                      <Grid item size={6}>
                        <div className="form-group">
                          <label htmlFor="result_configuration_template_id">
                            {t("Template Type")}
                          </label>

                          <Field
                            name={"result_configuration_template_id"}
                            id="result_configuration_template_id"
                            onBlur={handleBlur}
                            component={SelectField}
                            options={templateTypeOption}
                            values={values.result_configuration_template_id}
                            hasClearable={true}
                          />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item size={6}>
                  <Button
                    type="button"
                    className="mr-2 btn  bluecustom-btn dtheme_btn"
                    disabled={
                      canUserUpdateField
                        ? canUserUpdateField
                        : loadingFileDownload
                    }
                    onClick={() => exportDetailClick(projectId)}
                  >
                    {loadingFileDownload
                      ? t("Downloading")
                      : t("Export Detail")}
                  </Button>
                </Grid>

                <Grid item size={6}>
                  <div className="form-group">
                    <div
                      className="custom-upload"
                      data-permission={canUserUpdateField}
                    >
                      <input
                        name="file"
                        ref={supervisorFileInput}
                        accept=".xlsx, .xls"
                        type="file"
                        data-content={t("UPLOAD AUTOMATED SUPERVISOR XLSX")}
                        disabled={disablefilebtn}
                        className="configuration-file-input automated"
                        onChange={selectUploadFile}
                      />
                    </div>
                    {values.supervisor_xlsx_file_name != null && (
                      <div className="file_download_link_parent">
                        <p
                          onClick={() =>
                            fileDownload(
                              projectId,
                              "SUPERVISOR_XLSX",
                              values.supervisor_xlsx_file_name
                            )
                          }
                        >
                          {values.supervisor_xlsx_file_name}
                        </p>
                      </div>
                    )}
                    {supervisormsg && (
                      <div className="supervisormsg">
                        <p className={colorToggle ? "success" : "error"}>
                          {supervisormsg}
                        </p>
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item size={12}>
                  <div className="label-withline">
                    <h5 className="label-withline_text">
                      {t("Enable/Disable Features")}
                    </h5>
                    <div className="d-flex mt-3">
                      <Grid item size={4}>
                        <h6>{t("Result Features")}</h6>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            name="result_auto_release"
                            checked={values.result_auto_release}
                            disabled={canUserUpdateField}
                            className="form-check-input"
                            id="result_auto_release"
                          />
                          <label
                            htmlFor="result_auto_release"
                            className="form-check-label"
                          >
                            {t("Result Auto Release")}
                          </label>
                        </div>
                        <div className="form-check mt-1 mb-2">
                          <Field
                            type="checkbox"
                            name="mask_overall_result_to_HR_for_AD"
                            disabled={canUserUpdateField}
                            id="mask_overall_result_to_HR_for_AD"
                            checked={values.mask_overall_result_to_HR_for_AD}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="mask_overall_result_to_HR_for_AD"
                            className="form-check-label"
                          >
                            {t("AD equals HR overall")}
                          </label>
                        </div>
                        <div className="form-check mt-1 mb-2">
                          <Field
                            type="checkbox"
                            name="mask_overall_result_to_HR_for_CM"
                            id="mask_overall_result_to_HR_for_CM"
                            disabled={canUserUpdateField}
                            checked={values.mask_overall_result_to_HR_for_CM}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="mask_overall_result_to_HR_for_CM"
                            className="form-check-label"
                          >
                            {t("CM equals HR overall")}
                          </label>
                        </div>
                        <div className="form-check mt-1 mb-2">
                          <Field
                            type="checkbox"
                            id="mask_overall_result_to_HR_for_NC"
                            name="mask_overall_result_to_HR_for_NC"
                            disabled={canUserUpdateField}
                            checked={values.mask_overall_result_to_HR_for_NC}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="mask_overall_result_to_HR_for_NC"
                            className="form-check-label"
                          >
                            {t("NC equals HR overall")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="allowIswCreateParticipant"
                            name="allow_isw_create_participant"
                            disabled={canUserUpdateField}
                            checked={values.allow_isw_create_participant}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="allowIswCreateParticipant"
                            className="form-check-label"
                          >
                            {t("Allow ISW Create Participant")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="makePartialNcAsCompleteNc"
                            disabled={canUserUpdateField}
                            name="make_partial_nc_as_complete_nc"
                            checked={values.make_partial_nc_as_complete_nc}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="makePartialNcAsCompleteNc"
                            className="form-check-label"
                          >
                            {t("Make Partial NC as Overall NC")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="review_unreview_configuration"
                            disabled={canUserUpdateField}
                            name="review_unreview_configuration"
                            checked={values.review_unreview_configuration}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="review_unreview_configuration"
                            className="form-check-label"
                          >
                            {t("MARK_RESULT_AS_REVIEWED")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="cm_recordings_configuration"
                            disabled={canUserUpdateField}
                            name="cm_recordings_configuration"
                            checked={values.cm_recordings_configuration}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="cm_recordings_configuration"
                            className="form-check-label"
                          >
                            {t("SHOW_RECORDING")}
                          </label>
                        </div>
                      </Grid>
                      <Grid item size={4}>
                        <h6>{t("Communication Features")}</h6>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            disabled={canUserUpdateField}
                            id="invite_on_participant_create"
                            name="invite_on_participant_create"
                            checked={values.invite_on_participant_create}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="invite_on_participant_create"
                            className="form-check-label"
                          >
                            {t("Email Invite on create participant")}
                          </label>
                        </div>

                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            disabled={canUserUpdateField}
                            id="sms_invite_on_participant_create"
                            name="sms_invite_on_participant_create"
                            checked={values.sms_invite_on_participant_create}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="sms_invite_on_participant_create"
                            className="form-check-label"
                          >
                            {t("Sms Invite on create participant")}
                          </label>
                        </div>

                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            disabled={canUserUpdateField}
                            id="enableSMSCommunication"
                            name="enableSMSCommunication"
                            checked={values.enableSMSCommunication}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="enableSMSCommunication"
                            className="form-check-label"
                          >
                            {t("Send SMS")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            disabled={canUserUpdateField}
                            id="enable_refresh_accesscode"
                            name="enable_refresh_accesscode"
                            checked={values.enable_refresh_accesscode}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="enable_refresh_accesscode"
                            className="form-check-label"
                          >
                            {t("Refresh Access Code")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            disabled={canUserUpdateField}
                            id="enableEmailCommunication"
                            name="enableEmailCommunication"
                            checked={values.enableEmailCommunication}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="enableEmailCommunication"
                            className="form-check-label"
                          >
                            {t("E-Mail Communication")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            disabled={canUserUpdateField}
                            id="enable_indivisual_result_email"
                            name="enable_indivisual_result_email"
                            checked={values.enable_indivisual_result_email}
                            className="form-check-input"
                            onChange={(e) => {
                              setFieldTouched("result_email_recipient", true);
                              setFieldValue(
                                "enable_indivisual_result_email",
                                e.target.checked
                              );
                            }}
                          />
                          <label
                            htmlFor="enable_indivisual_result_email"
                            className="form-check-label"
                          >
                            {t("E-Mail report per participant")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            disabled={canUserUpdateField}
                            id="enable_pending_result_notification"
                            name="enable_pending_result_notification"
                            checked={values.enable_pending_result_notification}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="enable_pending_result_notification"
                            className="form-check-label"
                          >
                            {t("Pending Result Notification")}
                          </label>
                        </div>
                        <div className="form-check mt-1 field_with_select">
                          <Field
                            type="checkbox"
                            disabled={canUserUpdateField}
                            id="auto_reminder_flag"
                            name="auto_reminder_configuration"
                            checked={values.auto_reminder_configuration}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="auto_reminder_flag1"
                            className="form-check-label"
                          >
                            {t("AUTO_REMINDER_IN")}{" "}
                            <Field
                              name={"auto_reminder_time_in_days"}
                              id="auto_reminder_day"
                              onBlur={handleBlur}
                              component={SelectField}
                              className="select_auto_reminder"
                              options={getDaysOptions()}
                              values={values.auto_reminder_time_in_days}
                              isDisabled={
                                canUserUpdateField ||
                                !values.auto_reminder_configuration
                              }
                            />{" "}
                            {t("DAYS")}
                          </label>
                        </div>
                      </Grid>
                      <Grid item size={4}>
                        <h6>{t("General Features")}</h6>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="enablePilot"
                            name="enablePilot"
                            disabled={canUserUpdateField}
                            checked={values.enablePilot}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="enablePilot"
                            className="form-check-label"
                          >
                            {t("Pilot")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="isWebrtcEnable"
                            name="isWebrtcEnable"
                            disabled={canUserUpdateField}
                            checked={values.isWebrtcEnable}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="isWebrtcEnable"
                            className="form-check-label"
                          >
                            {t("Enable WebRTC")}
                          </label>
                        </div>
                        {/* // Added new flgs for IRN block feature (ISW) */}
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="block_irn_on_call_logs"
                            name="block_irn_on_call_logs"
                            checked={values.block_irn_on_call_logs}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="block_irn_on_call_logs"
                            className="form-check-label"
                          >
                            {t("Block IRN on Call logs")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="unblock_irn_after_nc_result"
                            name="unblock_irn_after_nc_result"
                            checked={values.unblock_irn_after_nc_result}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="unblock_irn_after_nc_result"
                            className="form-check-label"
                          >
                            {t("Unblock IRN after NC result")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="unblock_irn_after_dc_result"
                            name="unblock_irn_after_dc_result"
                            checked={values.unblock_irn_after_dc_result}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="unblock_irn_after_dc_result"
                            className="form-check-label"
                          >
                            {t("Unblock IRN after DC result")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="hide_deleted_participant"
                            name="hide_deleted_participant"
                            className="form-check-input"
                            checked={values.hide_deleted_participant}
                          />
                          <label
                            htmlFor="hide_deleted_participant"
                            className="form-check-label"
                          >
                            {t("Hide deleted participant")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="reverse_np_configuration"
                            name="reverse_np_configuration"
                            className="form-check-input"
                            checked={values.reverse_np_configuration}
                          />
                          <label
                            htmlFor="reverse_np_configuration"
                            className="form-check-label"
                          >
                            {t("REVERSE_NP_PARTICIPANT")}
                          </label>
                        </div>
                        <div className="form-check mt-1">
                          <Field
                            type="checkbox"
                            id="enable_pii_data_in_analytics"
                            name="enable_pii_data_in_analytics"
                            className="form-check-input"
                            checked={values.enable_pii_data_in_analytics}
                          />
                          <label
                            htmlFor="enable_pii_data_in_analytics"
                            className="form-check-label"
                          >
                            {t("ENABLE_PII_FLAG")}
                          </label>
                        </div>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    projectPhoneNumberErrors: state.projects?.projectPhoneNumberErrors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setProjectPhoneNumberError: (phoneNumberError) =>
      dispatch(projectActions.setProjectPhoneNumberError(phoneNumberError)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(InternalUseOnly)
);
