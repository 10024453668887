import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

export const SelectField = ({
  options,
  field,
  form,
  values,
  id,
  isDisabled,
  hasClearable,
  defaultValue,
  handleChange,
  isBusinessLine,
}) => {
  const { t } = useTranslation();
  const selectedValue =
    isBusinessLine && values
      ? values.value !== ""
        ? values
        : null
      : values
      ? options.find((option) => option.value === values)
      : null;

  return (
    <Select
      className="select-style"
      id={id}
      classNamePrefix="select"
      defaultValue={defaultValue ? defaultValue : options[0]}
      options={options}
      name={field.name}
      value={selectedValue}
      onChange={(option) => {
        if (isBusinessLine) {
          handleChange(option);
        } else {
          form.setFieldValue(field.name, option ? option.value : null);
        }
      }}
      onBlur={() => {
        form.setFieldTouched(field.name);
      }}
      placeholder={t("Select")}
      menuPlacement="auto"
      menuPosition="absolute"
      menuPortalTarget={window.document.body}
      isDisabled={isDisabled}
      isSearchable={false}
      isClearable={hasClearable ? hasClearable : false}
      // defaultMenuIsOpen
    />
  );
};

export const createDropdownOption = (option, flag = false) => {
  if (flag) {
    return {
      value: option.attributeId,
      label: option.description,
    };
  } else {
    return {
      value: option.attributeId,
      label: option.description,
      sequence: option.sequence,
      selected: option.sequence === 1 ? true : false,
    };
  }
};

export const sortByLabel = (a, b) => {
  if (a.label.toLowerCase() > b.label.toLowerCase()) {
    return 1;
  }
  if (a.label.toLowerCase() < b.label.toLowerCase()) {
    return -1;
  }
  return 0;
};

export const TextError = (props) => {
  return <div className="required-error">{props.children}</div>;
};
