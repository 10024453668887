import * as actionTypes from "../constants";

let getStatus = "";
if (localStorage.getItem("theme_preference") !== null) {
  const getThemePreference = localStorage.getItem("theme_preference");
  // const parseObject = JSON.parse(getThemePreference);
  // console.log("parseObject", getThemePreference);
  getStatus = getThemePreference;
} else {
  // console.log("parseObject else");
  getStatus = "light";
}

// console.log("status reducer", getStatus);
const initialState = {
  themeColor: getStatus,
};

export function theme(state = initialState, action) {
  switch (action.type) {
    case actionTypes.THEME_INFO:
      return {
        ...state,
        themeColor: action.theme,
      };
    default:
      return state;
  }
}
