import React, { Component } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./User.css";
import ManageClientapp from "./ManageClientapp";
import AddClientapp from "./AddClientapp";
import EditClientapp from "./EditClientapp";
import { AddCircleIcon } from "../../../assets/images/icons/customIcons.js";
import { withTranslation } from "react-i18next";
import * as userPermission from "../../Utility/userPermission.js";
class Clientapp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childNav: "Client Applications",
      searchValue: "",
      currentPage: 0,
    };
    this.updateNavBar = this.updateNavBar.bind(this);
  }

  manageUserHtml = (props) => {
    const { t } = this.props;
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="page-title">{t("Client Applications")}</h6>
              </div>
              <div className="col-lg-5">
                <div className="search-custom">
                  <FontAwesomeIcon
                    type="submit"
                    icon={faSearch}
                    className="searchicon"
                  />
                  <input
                    className="form-control customsearch"
                    name="tablesearch"
                    type="text"
                    value={this.state.searchValue}
                    onChange={(e) => {
                      this.setState({
                        searchValue: e.target.value,
                        currentPage: 0,
                      });
                    }}
                  />
                </div>
              </div>
              {userPermission.isClientCreateEnabled(
                this.props.statedata.userInfo.rolePermission.permissions
              ) ? (
                <div className="col-lg-1">
                  <div
                    className="tenant-add-button"
                    onClick={() => {
                      this.updateNavBar("Add Client Application");
                    }}
                  >
                    <AddCircleIcon className={{ color: "#34AA44" }} />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="card1">
          <div className="card-body1">
            <ManageClientapp
              dataFromParent={props}
              handleEditRow={this.handleEditRow}
              searchValue={this.state.searchValue}
              currentPage={this.state.currentPage}
            />
          </div>
        </div>
      </div>
    );
  };

  createClientappHtml = (props) => {
    const { t } = this.props;
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <h6 className="page-title">{t("Add Client Application")}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <AddClientapp
              dataFromParent={props}
              updateNavBar={this.updateNavBar}
            />
          </div>
        </div>
      </div>
    );
  };
  editClientappHtml = (props) => {
    const { t } = this.props;
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <h6 className="page-title">{t("View Client App Details")}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <EditClientapp
              dataFromParent={props}
              updateNavBar={this.updateNavBar}
            />
          </div>
        </div>
      </div>
    );
  };
  componentDidMount() {
    this.props.onRef(this);
    if (this.props.statedata.adminDetail.navBar != "Client Applications") {
      this.updateNavBar("Client Applications");
    }
  }

  updateNavBar = (navBar) => {
    this.setState({
      childNav: navBar,
    });
    this.props.handleBreadCrumInAdmin(navBar);
  };
  handleEditRow = (row, navBar) => {
    this.setState({
      childNav: navBar,
    });
    this.props.handleEditRow(row, navBar);
  };

  getUserComponent(navBar) {
    let showUser = "";

    switch (navBar) {
      case "Client Applications":
        showUser = this.manageUserHtml(this.props);
        break;
      case "Add Client Application":
        showUser = this.createClientappHtml(this.props);
        break;
      case "View Client Application":
        showUser = this.editClientappHtml(this.props);
        break;
    }

    return showUser;
  }

  render() {
    let showUser = this.getUserComponent(this.state.childNav);

    if (
      showUser == "" &&
      this.props.location.pathname == "/main/admin/clientapp"
    ) {
      showUser = this.getUserComponent(
        this.props.statedata.adminDetail.prevNavBar
      );
    }

    return <React.Fragment>{showUser}</React.Fragment>;
  }
}
export default withTranslation()(Clientapp);
