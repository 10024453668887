import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import Select from "react-select";
import { FieldArray } from "formik";
import { Grid2 as Grid } from "@mui/material";
import { getRiskLevelDefault } from "../../../../../Helpers";

const ResultTemplateFields = ({ title, data, formik, i18n }) => {
  // console.log("ResultTemplateFields", formik.values);
  const fieldName = formik.values.template_type[title];
  const fieldNameTemplate = "template_type";
  const { t } = useTranslation();

  const onChangeHandler = (e, fieldName) => {
    // console.log("onChangeHandler event", e, fieldName);
    formik.setFieldValue(fieldName, e.value);
    // console.log("formik.values", formik.values);
  };
  console.log("i18n.language", i18n);
  return (
    <>
      <h6>{t("Select Overrides")}</h6>
      <FieldArray name={title}>
        {(arrayHelpers) => (
          <div className="overrides_inner_content">
            {formik.values.template_type[title].map((data, index) => {
              const getDefaultRiskLevel = getRiskLevelDefault(data.result);
              const selectValue = data.icons
                .filter((elm) => elm.value === data.icon_id)
                .map((elm) => ({
                  value: elm.value,
                  label: (
                    <>
                      <img src={elm.label} alt={elm.value} height="20" />
                    </>
                  ),
                }));
              return (
                <div key={index}>
                  <Grid
                    container
                    className="anil mb-2 justify-content-center align-items-center"
                  >
                    <Grid item size={5} className="text-center">
                      <div className="d-flex align-items-center risklevel_col">
                        <div>
                          <span
                            className={`filter_risk_${getDefaultRiskLevel.color}`}
                          >
                            {getDefaultRiskLevel.color_text}
                          </span>
                        </div>
                        {i18n.language == "en" && <div>{data.result}</div>}
                        {i18n.language == "es" && <div>{data.result_es}</div>}
                        {i18n.language == "tl" && <div>{data.result}</div>}
                      </div>
                    </Grid>
                    <Grid item size={3} className="text-center">
                      {i18n.language == "en" && data.result_type}
                      {i18n.language == "es" && data.result_type_es}
                      {i18n.language == "tl" && data.result_type}
                    </Grid>
                    <Grid item size={4} className="text-center">
                      <Select
                        // menuIsOpen={true}
                        placeholder={"Display As"}
                        name={`${fieldNameTemplate}.${title}.${index}.icon_id`}
                        value={selectValue}
                        options={data.icons.map((icon) => {
                          let options = {
                            value: icon.value,
                            label: (
                              <>
                                <img
                                  src={icon.label}
                                  alt={icon.value}
                                  height="20"
                                />
                              </>
                            ),
                          };
                          return options;
                        })}
                        className="basic-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          onChangeHandler(
                            e,
                            `${fieldNameTemplate}.${title}.${index}.icon_id`
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </div>
        )}
      </FieldArray>
    </>
  );
};

export default withTranslation("ResultTemplateFields")(ResultTemplateFields);
