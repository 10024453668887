import React, { Component } from "react";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Grid2 as Grid } from "@mui/material";
import {
  Alert as AlertDismissible,
  Button,
  Spinner,
} from "../../../components/Common";
import {
  filterJson,
  getlanguageIdFromcode,
  scrollTopWindow,
  showRestrictionsField,
  sortByLabel,
} from "../../../Helpers/index";
import i18n from "../../../locale/i18n";
import {
  createTenant,
  getlaguageAttribute,
  verifyEmail,
} from "../../../Services/tenantService";
import NewFileUpload from "../../Utility/NewFileUpload";
import PreventLeaveRoute from "../../Utility/PreventLeaveRoute.js";
import { SelectField } from "../../Utility/selectField";
import ConfirmAddTenant from "./ConfirmAddTenant";

class AddTenant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMsg: "",
      alertStatus: false,
      variant: "",
      dirty: false,
      disablesavebtn: false,
      confirmModal: false,
      confirmModalList: [],
      language: i18n.language,
      fieldsValue: {},
      actions: {},
      accountTypeA: [],
      customerSegmentA: [],
      restrictionsOptions: [],
      logoImage: "",
      isImageUploading: false,
      isRestrictionsFieldEnable: false,
    };
  }

  loadAttribute = () => {
    let languagecode = i18n.language;
    let languageId = getlanguageIdFromcode(languagecode);
    getlaguageAttribute(languageId)
      .then((res) => {
        this.filterAttribute(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.setState({
      isRestrictionsFieldEnable: showRestrictionsField(this.props),
    });
    const self = this;
    self.loadAttribute();
    i18next.on("languageChanged", function (lng) {
      self.setState(
        {
          language: lng,
        },
        () => {
          self.loadAttribute();
        }
      );
    });
  }
  filterAttribute = (res) => {
    let accountType = filterJson(res.data.tenantAttributes, "ACCOUNT_TYPE");
    let customerSegment = filterJson(
      res.data.tenantAttributes,
      "CUSTOMER_SEGMENT"
    );

    let restrictionsOptions = filterJson(
      res.data.tenantAttributes,
      "SPECIAL_RESTRICTION"
    );
    console.log("restrictionsOptions", restrictionsOptions);

    this.setState({
      accountTypeA: accountType,
      customerSegmentA: customerSegment,
      restrictionsOptions: restrictionsOptions,
    });
  };
  shouldComponentUpdate() {
    return true;
  }
  cancelTenantClick = () => {
    this.props.updateNavBar("Tenant");
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  dirtyFlagChanged = (dirty) => {
    if (dirty != this.state.dirty) {
      this.setState({
        dirty: dirty,
      });
      this.props.dataFromParent.handleChildDirtyGFlag(dirty);
    }
  };

  handleVerifyEmail = (fields, actions) => {
    this.setState({
      disablesavebtn: true,
    });

    verifyEmail(fields.primaryUserEmail)
      .then((res) => {
        if (res.is_exist) {
          this.setState({
            confirmModal: true,
            confirmModalList: res.tenants,
            fieldsValue: fields,
            disablesavebtn: false,
            actions: actions,
          });
        } else {
          this.handleCreateTenant(fields, actions);
        }
      })
      .catch((err) => {
        scrollTopWindow();
        let errMsg = Object.assign({}, err).response?.data?.message;
        console.log("handleAddTenant errMsg", errMsg);
      });
  };
  createDropdownOption = (option) => {
    return {
      value: option.attributeId,
      label: option.description,
      sequence: option.sequence,
      selected: option.sequence === 1 ? true : false,
    };
  };
  confirmClose = () => {
    this.setState({
      confirmModal: false,
      confirmModalList: [],
    });
  };

  handleCreateTenant = (fields, actions) => {
    const isRestrictionsFieldEnable = this.state.isRestrictionsFieldEnable;
    createTenant(fields, isRestrictionsFieldEnable)
      .then((res) => {
        scrollTopWindow();
        this.setState(
          {
            alertMsg: `Tenant ${res.data.tenantName} Created Successfully`,
            variant: "success",
            alertStatus: true,
            // projectDetail: res.data,
            dirty: false,
            disablesavebtn: false,
            confirmModal: false,
            confirmModalList: [],
          },
          () => {
            actions.resetForm();
            setTimeout(() => {
              this.cancelTenantClick();
            }, 3000);
          }
        );
      })
      .catch((err) => {
        scrollTopWindow();
        let errMsg = Object.assign({}, err).response?.data?.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
          disablesavebtn: false,
          confirmModal: false,
          confirmModalList: [],
        });
        this.props.dataFromParent.handleChildDirtyGFlag(false);
      });
  };

  handleImageUpload = (event) => {
    this.setState({ isImageUploading: true });
    const uploadedImage = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({ isImageUploading: false });
      this.setState({ logoImage: reader.result });
    };
    reader.readAsDataURL(uploadedImage);
  };

  render() {
    const { t, currencyOptions } = this.props;
    const { confirmModal, confirmModalList, fieldsValue, actions } = this.state;
    const tenantStatusOption = [
      { id: 1, value: true, label: t("Active"), status: true },
      { id: 2, value: false, label: t("Inactive"), status: false },
    ];
    let accountTypeOption = this.state.accountTypeA
      .map((option) => {
        return this.createDropdownOption(option);
      })
      .sort(sortByLabel);

    let defaultAccountTypeOption = accountTypeOption.filter(
      (option) => option.sequence === 1
    );

    let customerSegmentOption = this.state.customerSegmentA
      .map((option) => {
        return this.createDropdownOption(option);
      })
      .sort((a, b) => a.sequence - b.sequence);
    let defaultCurrencyOption = currencyOptions
      .map((option) => {
        return this.createDropdownOption(option);
      })
      .sort((a, b) => a.sequence - b.sequence);

    let defaultCustomerSegmentOption = customerSegmentOption.filter(
      (option) => option.sequence === 1
    );

    let sortRestrictionsOptions = this.state.restrictionsOptions
      .map((option) => {
        return this.createDropdownOption(option);
      })
      .sort((a, b) => a.sequence - b.sequence);

    let defaultRestrictionsOption = sortRestrictionsOptions.filter(
      (option) => option.sequence === 1
    );

    const phoneRegExp =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={{
            firstName: "",
            accountName: "",
            salesPerson: "",
            backendSystemCode: "",
            numberOfInterviews: "",
            greenhouseAPIKey: "",
            accountAPIKey: "",
            greenhouseAccount: false,
            lastName: "",
            primaryUserEmail: "",
            primaryUserPhone: "",
            status: true,
            isUkEnvironment: this.state.isRestrictionsFieldEnable,
            accountTypeId:
              accountTypeOption.length > 0
                ? defaultAccountTypeOption[0].value
                : "",
            customerSegmentId:
              customerSegmentOption.length > 0
                ? defaultCustomerSegmentOption[0].value
                : "",
            restrictionId:
              sortRestrictionsOptions.length > 0
                ? defaultRestrictionsOption[0].value
                : "",
            customerId: "",
            greenhouseAppKey: "",
            partnerAccount: "",
            currencyId: "",
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required(t("First Name is required")),
            accountName: Yup.string().required(t("Account Name is required")),
            salesPerson: Yup.string()
              .email(t("Email is invalid"))
              .required(t("Sales Person Email is required"))
              .nullable(),
            numberOfInterviews: Yup.number()
              .positive(t("Only positive number"))
              .integer(t("Only positive number")),
            lastName: Yup.string().required(t("Last Name is required")),
            primaryUserEmail: Yup.string()
              .email(t("Primary User Email is invalid"))
              .required(t("Primary User Email is invalid")),
            primaryUserPhone: Yup.string().matches(
              phoneRegExp,
              t("Phone number is not valid")
            ),
            status: Yup.string().required(t("Status is required")),
            isUkEnvironment: Yup.boolean(),
            customerId: Yup.string().required(t("Customer ID is required")),
            currencyId: Yup.string().required(t("Currency is required")),
            restrictionId: Yup.string().when("isUkEnvironment", {
              is: () => true,
              then: () =>
                Yup.string()
                  .required("Special Restrictions is Required")
                  .nullable(),
            }),
          })}
          onSubmit={(fields, actions) => {
            this.handleVerifyEmail(fields, actions);
          }}
        >
          {(props) => (
            <Form onChange={this.dirtyFlagChanged(props.dirty)}>
              <PreventLeaveRoute
                reset={props.handleReset}
                when={props.dirty}
                // Navigate function
                navigate={(field, historyObj) => {
                  if (field == "/main/admin/tenant") {
                    this.cancelTenantClick();
                  } else {
                    historyObj.push(field);
                  }
                }}
                // Use as "message" prop of Prompt of React-Router
                shouldBlockNavigation={(location) => {
                  if (location.pathname === "/") {
                    return false;
                  }
                  return !!props.dirty;
                }}
              />
              <Grid container>
                <Grid item size={12}>
                  <AlertDismissible
                    msg={this.state.alertMsg}
                    variantType={this.state.variant}
                    show={this.state.alertStatus}
                    close={this.handleAlertClose}
                  ></AlertDismissible>
                </Grid>
              </Grid>
              <Grid container className="add-tenant-row">
                <div className="form-group col-md-4">
                  <label className="required" htmlFor="firstName">
                    {t("FIRST NAME")}
                  </label>
                  <Field
                    name="firstName"
                    autoComplete="off"
                    type="text"
                    className={
                      "form-control" +
                      (props.errors.firstName && props.touched.firstName
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {props.errors.firstName && props.touched.firstName && (
                    <div className="input-feedback add-tenant-validation">
                      {props.errors.firstName}
                    </div>
                  )}
                </div>
                <div className="form-group col-md-4">
                  <label className="required" htmlFor="lastName">
                    {t("LAST NAME")}
                  </label>
                  <Field
                    name="lastName"
                    autoComplete="off"
                    type="text"
                    className={
                      "form-control" +
                      (props.errors.lastName && props.touched.lastName
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {props.errors.lastName && props.touched.lastName && (
                    <div className="input-feedback add-tenant-validation">
                      {props.errors.lastName}
                    </div>
                  )}
                </div>
                <div className="form-group col-md-4">
                  <label className="required" htmlFor="accountName">
                    {t("ACCOUNT NAME")}
                  </label>
                  <Field
                    name="accountName"
                    type="text"
                    autoComplete="off"
                    className={
                      "form-control" +
                      (props.errors.accountName && props.touched.accountName
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {props.errors.accountName && props.touched.accountName && (
                    <div className="input-feedback add-tenant-validation">
                      {props.errors.accountName}
                    </div>
                  )}
                </div>
                <div className="form-group col-md-4">
                  <label className="required" htmlFor="firstName">
                    {t("Customer ID")}
                  </label>
                  <Field
                    name="customerId"
                    autoComplete="off"
                    type="text"
                    className={
                      "form-control" +
                      (props.errors.customerId && props.touched.customerId
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {props.errors.customerId && props.touched.customerId && (
                    <div className="input-feedback add-tenant-validation">
                      {t(props.errors.customerId)}
                    </div>
                  )}
                </div>
                <div className="form-group col-md-4">
                  <label className="required" htmlFor="primaryUserEmail">
                    {t("PRIMARY USER EMAIL")}
                  </label>
                  <Field
                    name="primaryUserEmail"
                    autoComplete="off"
                    onChange={(e) =>
                      props.setFieldValue(
                        "primaryUserEmail",
                        e.target.value.toLowerCase()
                      )
                    }
                    type="text"
                    className={
                      "form-control" +
                      (props.errors.primaryUserEmail &&
                      props.touched.primaryUserEmail
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {props.errors.primaryUserEmail &&
                    props.touched.primaryUserEmail && (
                      <div className="input-feedback add-tenant-validation">
                        {props.errors.primaryUserEmail}
                      </div>
                    )}
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="primaryUserPhone">
                    {t("PRIMARY USER PHONE")}
                  </label>
                  <Field
                    name="primaryUserPhone"
                    autoComplete="off"
                    type="text"
                    className={
                      "form-control" +
                      (props.errors.primaryUserPhone &&
                      props.touched.primaryUserPhone
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {props.errors.primaryUserPhone &&
                    props.touched.primaryUserPhone && (
                      <div className="input-feedback add-tenant-validation">
                        {t("Invalid phone number")}
                      </div>
                    )}
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="backendSystemCode">
                    {t("BACKEND SYSTEM CODE")}
                  </label>
                  <Field
                    name="backendSystemCode"
                    autoComplete="off"
                    type="text"
                    className={
                      "form-control" +
                      (props.errors.backendSystemCode &&
                      props.touched.backendSystemCode
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {props.errors.backendSystemCode &&
                    props.touched.backendSystemCode && (
                      <div className="input-feedback add-tenant-validation">
                        {props.errors.backendSystemCode}
                      </div>
                    )}
                </div>
                <div className="form-group col-md-4">
                  <label className="required" htmlFor="Account Type">
                    {t("Account type")}
                  </label>
                  <Field
                    name={"accountTypeId"}
                    onBlur={props.handleBlur}
                    component={SelectField}
                    options={accountTypeOption}
                    values={props.values.accountTypeId}
                    className={
                      "form-control" +
                      (props.errors.accountTypeId && props.touched.accountTypeId
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {props.errors.accountTypeId &&
                    props.touched.accountTypeId && (
                      <div className="input-feedback">
                        {props.errors.accountTypeId}
                      </div>
                    )}
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="customer Segment" className="required">
                    {t("Customer segment")}
                  </label>
                  <Field
                    name={"customerSegmentId"}
                    onBlur={props.handleBlur}
                    component={SelectField}
                    options={customerSegmentOption}
                    values={props.values.customerSegmentId}
                    className={
                      "form-control" +
                      (props.errors.customerSegmentId &&
                      props.touched.customerSegmentId
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {props.errors.customerSegmentId &&
                    props.touched.customerSegmentId && (
                      <div className="input-feedback">
                        {props.errors.customerSegmentId}
                      </div>
                    )}
                </div>
                {this.state.isRestrictionsFieldEnable ? (
                  <div className="form-group col-md-4">
                    <label htmlFor="customer Segment" className="required">
                      {t("Clearspeed Restrictions")}
                    </label>
                    <Field
                      name={"restrictionId"}
                      onBlur={props.handleBlur}
                      component={SelectField}
                      options={sortRestrictionsOptions}
                      values={props.values.restrictionId}
                      className={
                        "form-control" +
                        (props.errors.restrictionId &&
                        props.touched.restrictionId
                          ? " is-invalid"
                          : " add-tenant-input")
                      }
                    />
                    {props.errors.restrictionId &&
                      props.touched.restrictionId && (
                        <div className="input-feedback">
                          {" "}
                          {props.errors.restrictionId}
                        </div>
                      )}
                  </div>
                ) : (
                  ""
                )}

                <div className="form-group col-md-4">
                  <label htmlFor="numberOfInterviews">
                    {t("Number Of Calls")}
                  </label>
                  <Field
                    name="numberOfInterviews"
                    autoComplete="off"
                    type="text"
                    className={
                      "form-control" +
                      (props.errors.numberOfInterviews &&
                      props.touched.numberOfInterviews
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {props.errors.numberOfInterviews &&
                    props.touched.numberOfInterviews && (
                      <div className="input-feedback add-tenant-validation">
                        {t("Only positive number or nothing")}
                      </div>
                    )}
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="status" className="required">
                    {t("STATUS")}
                  </label>
                  <Field
                    name={"status"}
                    onBlur={props.handleBlur}
                    component={SelectField}
                    options={tenantStatusOption}
                    values={props.values.status}
                    className={
                      "form-control" +
                      (props.errors.status && props.touched.status
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {props.errors.status && props.touched.status && (
                    <div className="input-feedback add-tenant-validation">
                      {props.errors.status}
                    </div>
                  )}
                </div>
                <div className="form-group col-md-4">
                  <label className="required" htmlFor="salesPerson">
                    {t("SalesPerson")}
                  </label>
                  <Field
                    name="salesPerson"
                    autoComplete="off"
                    type="text"
                    placeholder={t("Sales Person Email")}
                    className={
                      "form-control" +
                      (props.errors.salesPerson && props.touched.salesPerson
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {props.errors.salesPerson && props.touched.salesPerson && (
                    <div className="input-feedback add-tenant-validation">
                      {props.errors.salesPerson}
                    </div>
                  )}
                </div>
                <Grid item size={12}>
                  <Grid container>
                    <Grid item size={8}>
                      <Grid container>
                        <div className="form-group col-md-6">
                          <label htmlFor="Partner Account">
                            {t("Partner Account")}
                          </label>
                          <Field
                            name="partnerAccount"
                            autoComplete="off"
                            type="text"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="accountAPIKey">
                            {t("Account API Key")}
                          </label>
                          <Field
                            name="accountAPIKey"
                            autoComplete="off"
                            type="text"
                            className={
                              "form-control" +
                              (props.errors.accountAPIKey &&
                              props.touched.accountAPIKey
                                ? " is-invalid"
                                : " add-tenant-input")
                            }
                          />
                          {props.errors.accountAPIKey &&
                            props.touched.accountAPIKey && (
                              <div className="input-feedback add-tenant-validation">
                                {props.errors.accountAPIKey}
                              </div>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="greenhouseAppKey">
                            {t("Greenhouse APP Key")}
                          </label>
                          <Field
                            name="greenhouseAppKey"
                            autoComplete="off"
                            type="text"
                            className={
                              "form-control" +
                              (props.errors.greenhouseAppKey &&
                              props.touched.greenhouseAppKey
                                ? " is-invalid"
                                : " add-tenant-input")
                            }
                          />
                          {props.errors.greenhouseAppKey &&
                            props.touched.greenhouseAppKey && (
                              <div className="input-feedback add-tenant-validation">
                                {props.errors.greenhouseAppKey}
                              </div>
                            )}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="greenhouseAPIKey">
                            {t("Greenhouse API Key")}
                          </label>
                          <Field
                            name="greenhouseAPIKey"
                            readOnly
                            autoComplete="off"
                            type="text"
                            className={
                              "form-control" +
                              (props.errors.greenhouseAPIKey &&
                              props.touched.greenhouseAPIKey
                                ? " is-invalid"
                                : " add-tenant-input")
                            }
                          />
                          {props.errors.greenhouseAPIKey &&
                            props.touched.greenhouseAPIKey && (
                              <div className="input-feedback add-tenant-validation">
                                {props.errors.greenhouseAPIKey}
                              </div>
                            )}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid oitem size={4}>
                      <Grid container>
                        <div className="form-group col-md-12  ">
                          <label htmlFor="file">
                            {t("UPLOAD LOGO")} (
                            <small className="">
                              {t("maximum file size")}: 1MB - Appx. 300px X
                              70px)
                            </small>
                          </label>
                          <input
                            id="file"
                            name="file"
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              if (event.currentTarget.files[0]) {
                                props.setFieldValue(
                                  "tenantProfileImage",
                                  event.currentTarget.files[0]
                                );
                                this.handleImageUpload(event);
                              }
                            }}
                            className="form-control"
                          />

                          {this.state.isImageUploading ? (
                            <p className="d-flex align-item-center justify-content-center">
                              ...uploading
                            </p>
                          ) : (
                            this.state.logoImage && (
                              <NewFileUpload logoImage={this.state.logoImage} />
                            )
                          )}
                        </div>
                        <Grid item size={12}>
                          <div className="form-group">
                            <label
                              className="required"
                              htmlFor="tenant_currency_id"
                            >
                              {t("Currency")}
                            </label>
                            <Field
                              name={"currencyId"}
                              id="tenant_currency_id"
                              onBlur={props.handleBlur}
                              component={SelectField}
                              options={defaultCurrencyOption}
                              values={props.values.currencyId}
                              placeholder={t("SELECT_CURRENCY_PLACEHOLDER")}
                            />
                          </div>
                          {props.errors.currencyId &&
                            props.touched.currencyId && (
                              <div className="input-feedback">
                                {props.errors.currencyId}
                              </div>
                            )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <div className="form-check col-md-12 padding-l35 mt-4">
                  <Field
                    type="checkbox"
                    name="greenhouseAccount"
                    className="form-check-input"
                  />
                  <label
                    htmlFor="greenhouseAccount"
                    className="form-check-label"
                  >
                    {t("Greenhouse Account")}
                  </label>
                </div>
              </Grid>

              <Grid container>
                <Grid item size={12}>
                  <div className="form-group float-right">
                    <Button
                      variant="primary"
                      type="submit"
                      className="rounded-0 mr-4 save-btn button_with_loader"
                      disabled={this.state.disablesavebtn || !props.dirty}
                    >
                      {this.state.disablesavebtn ? (
                        <>
                          {t("Save")}...
                          <div className="custom-loader linesLoader loader_inner_button">
                            <Spinner color="#ffffff" size={"19px"} />
                          </div>
                        </>
                      ) : (
                        <span>{t("Save")}</span>
                      )}
                    </Button>
                    <Button
                      variant="secondary"
                      type="button"
                      className="rounded-0 cancel-btn"
                      onClick={this.cancelTenantClick}
                    >
                      {t("Cancel")}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        {confirmModal ? (
          <ConfirmAddTenant
            show={confirmModal}
            close={this.confirmClose}
            addTenant={this.handleCreateTenant}
            confirmModalList={confirmModalList}
            fieldsValue={fieldsValue}
            actions={actions}
          />
        ) : null}
      </>
    );
  }
}

export default withTranslation()(AddTenant);
