import React from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Switch } from "@mui/material";

const BusinessLineToggle = ({
  isSystemTenant,
  handleBusinessSwitch,
  isBusinessLineOptional,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`business-line-toggle ${
        !isSystemTenant && "disable-accordion d-none"
      }`}
    >
      <label className="row-header-title mr-2">{t("OPTIONAL")}</label>
      <label
        className={`${!isBusinessLineOptional ? "off-active" : "off-inactive"}`}
      >
        {t("OFF")}
      </label>
      <FormControlLabel
        control={
          <Switch
            id="business-switch"
            name="isBusinessLineOptional"
            disabled={!isSystemTenant}
            onChange={handleBusinessSwitch}
            checked={isBusinessLineOptional}
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        }
        label=""
      />
      <label
        className={`row-header-title ${isBusinessLineOptional && "on-active"}`}
      >
        {" "}
        {t("ON")}
      </label>
    </div>
  );
};

export default BusinessLineToggle;
