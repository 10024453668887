import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getCallCenterList } from "../../../Services/callCenterServices";
import { withTranslation } from "react-i18next";
import { CustomGrid } from "../../CustomTable";
class ManageCallCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callCenterList: [],
      currentPage: 0,
      perPageCount: 10,
      totalPage: 0,
      loading: true,
    };
  }
  componentDidMount() {
    getCallCenterList()
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            callCenterList: res.data,
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log("call canter list error", err);
      });
  }

  onChangeRecords = (page) => {
    this.setState({
      currentPage: page.page,
      perPageCount: page.pageSize,
    });
  };

  onRowClick = (params) => {
    this.props.handleEditRow(params.row, "Edit CallCenter", params.row.id);
  };

  render() {
    let { t } = this.props;
    let callCenters = this.state.callCenterList;
    const columns = [
      {
        field: "name",
        headerName: `${t("Call Center Name")}`,
        flex: 1,
        cellClassName: "tenant-manage-table-col-1",
        sortable: false,
      },
      {
        field: "address.country",
        headerName: `${t("Location")}`,
        flex: 1,
        sortable: false,
      },
      {
        field: "contactPerson",
        headerName: `${t("Languages")}`,
        flex: 1,
        sortable: false,
      },
    ];

    const rowClasses = "callcenter-manage-table-row";
    return (
      <div className="table_wrapper custom_table_container app-table admin-callcenter-module-table">
        <CustomGrid
          data={callCenters}
          columns={columns}
          loading={this.state.loading}
          currentPage={this.state.currentPage}
          totalPages={callCenters.length}
          perPageCount={this.state.perPageCount}
          isServerPagination={false}
          isServerSorting={false}
          onChangeRecords={this.onChangeRecords}
          onRowClick={this.onRowClick}
        />
      </div>
    );
  }
}

export default withTranslation()(withRouter(ManageCallCenter));
