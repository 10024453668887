export const filenameFromResponseHeader = (filenameString) => {
  if (!filenameString || !filenameString.includes("filename=")) return "noname";

  return (
    filenameString
      .split(";")
      .find((n) => n.includes("filename="))
      .replace("filename=", "")
      .trim() || "noname"
  );
};

export const generateYears = (startYear, direction) => {
  const currentYear = new Date().getFullYear();
  let years = [];
  if (direction === "next") {
    years = Array.from({ length: 15 }, (_, i) => startYear + i);
  } else if (direction === "prev") {
    years = Array.from(
      { length: currentYear - startYear + 1 },
      (_, i) => startYear + i
    );
  }

  return years;
};

export const getAllMonthNames = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months;
};

// Function to generate days as options for react-select
export const getDaysOptions = () => {
  const days = [];
  for (let i = 1; i <= 31; i++) {
    days.push({ value: i, label: i.toString() });
  }
  return days;
};

export const generateRandomNumber = () => {
  return Math.floor(100000 + Math.random() * 900000);
};

export const transformString = (inputString) => {
  return inputString?.toLowerCase().replace(/\s+/g, "-");
};
