import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
const RedirectToLogin = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
  }, []);
  return null;
};

export default RedirectToLogin;
