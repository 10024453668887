import api from './api';
export const projectInterviewResults = (filters) => {
  return api.post(`execution-service/v1/interview/results/`, filters);
};

export const releaseProjectResults = (results) => {
  return api.put(`execution-service/v1/interview/result/status`, results);
};

export const exportInterviewResults = (fields) => {
  return api.post(`execution-service/v1/interview/result/exportResults`, fields, {
    responseType: 'arraybuffer',
  });
};
export const uploadResultsdata = (fields) => {
  return api.post(`execution-service/v1/interview/uploadResults`, fields);
};
export const getResultMitigationDetails = (id) => {
  return api.get(`execution-service/v1/interview/result/followup/${id}`);
};
export const getActionList = (id) => {
  return api.get(`execution-service/v1/result/action?projectId=${id}`);
};
export const getFilterActions = (id) => {
  return api.post(`execution-service/v1/interview/actions/mapped/results/filters?projectId=${id}`);
};
export const getRuleList = (id) => {
  return api.get(`execution-service/v1/rule?projectId=${id}`);
};
export const getRuleAttributes = (id) => {
  return api.get(`execution-service/v1/rule/attributes?projectId=${id}`);
};
export const AddRule = (response) => {
  return api.post(`execution-service/v1/rule`, response);
};
export const getRuleDetails = (ruleId) => {
  return api.get(`execution-service/v1/rule/${ruleId}`);
};
export const updateRuleDetails = (results) => {
  return api.put(`execution-service/v1/rule`, results);
};
export const updateRuleSequence = (results) => {
  return api.post(`execution-service/v1/rule/order`, results);
};
export const getActionListDetails = (projectId) => {
  return api.post(`execution-service/v1/interview/results/actions`, projectId);
};

export const sendEmail = (interviewResultsId) => {
  return api.post(`execution-service/v1/interview/result/email`, interviewResultsId);
};

export const getResultList = (filters) => {
  return api.post(`execution-service/v1/interview/actions/mapped/results`, filters);
};
// export const getResultList = (projectId, actionId) => {
//   return  api.post(`execution-service/v1/interview/actions/mapped/results`,{
//         projectId: projectId,
//         actionId: actionId
//     })
// }
export const deleteRule = (ruleId) => {
  return api.delete(`execution-service/v1/rule/${ruleId}`);
};
export const getRulesApproved = (projectId) => {
  return api.post(`execution-service//v1/rule/approve?projectId=${projectId}`);
};
export const getActionNameDropdown = (projectId, tenantId) => {
  return api.get(`execution-service/v1/result/action/add?projectId=${projectId}&tenantId=${tenantId}`);
};
export const getActionDetails = (id) => {
  return api.get(`execution-service/v1/result/action/${id}`);
};

export const updateActionDetails = (results) => {
  return api.put(`execution-service/v1/result/action`, results);
};

export const AddAction = (response) => {
  return api.post(`execution-service/v1/result/action`, response);
};

export const deleteAction = (results) => {
  return api.put(`execution-service/v1/result/action/delete`, results);
};
export const updateActionSequence = (results) => {
  return api.put(`execution-service/v1/result/action/sequence`, results);
};
export const updateResultMitigation = (response) => {
  return api.post(`execution-service/v1/interview/result/followup`, response);
};
export const getCallRecordsDirectdial = (filters) => {
  return api.post(`execution-service/v1/call/directdial`, filters);
};
export const getBackendLogData = (filters) => {
  return api.post(`execution-service/v1/interview/result/audit/logs`, filters);
};
export const repostData = (data) => {
  let response = JSON.parse(data);
  return api.post(`execution-service/v1/interview/result/repost`, response);
};

export const exportCallRecords = (fields) => {
  return api.post(`execution-service/v1/call/directdial/export`, fields, {
    responseType: 'arraybuffer',
  });
};
export const InterviewResultsReport = (filters) => {
  return api.post(`execution-service/v1/interview/results/report`, filters);
};

export const IncompleteCallExport = (fields) => {
  return api.post(`execution-service/v1/interview/result/exportResults/incomplete`, fields, {
    responseType: 'arraybuffer',
  });
};

export const updateNotesMsg = (resultId, text) => {
  return api.post(`execution-service/v1/interview/result/${resultId}/note`, text);
};

export const questionModalData = (projectId) => {
  return api.get(`execution-service/v1/interview/question/result/${projectId}`);
};

export const getInterviewRecording = async (interviewResultId) => {
  let response = await api.get(`execution-service/v1/interview/interviewrecordings/${interviewResultId}`, {
    responseType: 'blob',
  });

  return response.data;
};

export const markReviewResult = (review) => {
  return api.post('execution-service/v1/interview/result/mark/action', review);
};
