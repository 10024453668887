import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import React from "react";
import PdfDocument from "./PdfDocument";

export const generateNewResultPdf = async (
  resultData,
  questionList,
  projectDetail,
  selectedTimeZoneOffset,
  flagsOptionsData,
  pdfLogo
) => {
  if (resultData.interviewQuestionsResult) {
    const interviewQuestionsResult = resultData.interviewQuestionsResult;
    let updatedQuestionList = questionList.map((result, index) => {
      console.log("generateNewResultPdf result", result);
      return {
        result: "",
        sequence: (index + 1).toString(),
      };
    });
    const newItemsBySequence = interviewQuestionsResult.reduce((a, item) => {
      a[item.sequence] = item;
      return a;
    }, {});

    const filteredOutput = updatedQuestionList.map((item) =>
      newItemsBySequence[item.sequence]
        ? newItemsBySequence[item.sequence]
        : item
    );
    resultData.interviewQuestionsResult = filteredOutput;
  } else {
    resultData.interviewQuestionsResult = [];
  }

  const getProjectType = (questionnaireType) => {
    if (questionnaireType === "PRECISION_QUESTIONNAIRE") {
      return "PRECISION_RISK_REPORT";
    } else if (questionnaireType === "EXPRESS_QUESTIONNAIRE") {
      return "EXPRESS_RISK_REPORT";
    } else if (questionnaireType === "HYBRID_QUESTIONNAIRE") {
      return "Hybrid Questionnaire Risk Level Report";
    } else if (questionnaireType === "PRECISION_RED_GREEN_QUESTIONNAIRE") {
      return "PRECISION_RED_GREEN_RISK_REPORT";
    }
  };
  const blob = await pdf(
    <PdfDocument
      title="My PDF"
      result={resultData}
      questionnaireType={getProjectType(projectDetail.questionaire_type)}
      questionList={questionList ? questionList : []}
      selectedTimeZoneOffset={selectedTimeZoneOffset}
      flagsOptionsData={flagsOptionsData}
      pdfLogo={pdfLogo}
    />
  ).toBlob();
  saveAs(blob, `result_${resultData.interviewReferenceNumber}.pdf`);
};

export const generateNewResultPdfZip = async (
  resultData,
  questionList,
  projectDetail,
  selectedTimeZoneOffset,
  flagsOptionsData,
  pdfLogo
) => {
  if (resultData.interviewQuestionsResult) {
    const interviewQuestionsResult = resultData.interviewQuestionsResult;
    let updatedQuestionList = questionList.map((result, index) => {
      return {
        result: "",
        sequence: (index + 1).toString(),
      };
    });
    const newItemsBySequence = interviewQuestionsResult.reduce((a, item) => {
      a[item.sequence] = item;
      return a;
    }, {});

    const filteredOutput = updatedQuestionList.map((item) =>
      newItemsBySequence[item.sequence]
        ? newItemsBySequence[item.sequence]
        : item
    );
    resultData.interviewQuestionsResult = filteredOutput;
  } else {
    resultData.interviewQuestionsResult = [];
  }

  const getProjectType = (questionnaireType) => {
    if (questionnaireType === "PRECISION_QUESTIONNAIRE") {
      return "Precision Questionnaire Risk Level Report";
    } else if (questionnaireType === "EXPRESS_QUESTIONNAIRE") {
      return "Express Questionnaire Risk Level Report";
    } else if (questionnaireType === "HYBRID_QUESTIONNAIRE") {
      return "Hybrid Questionnaire Risk Level Report";
    }
  };
  const blob = await pdf(
    <PdfDocument
      title="My PDF"
      result={resultData}
      questionnaireType={getProjectType(projectDetail.questionaire_type)}
      questionList={questionList ? questionList : []}
      selectedTimeZoneOffset={selectedTimeZoneOffset}
      flagsOptionsData={flagsOptionsData}
      pdfLogo={pdfLogo}
    />
  ).toBlob();
  return blob;
};
