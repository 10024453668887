import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Card, Grid2 as Grid, CardContent, CardHeader } from "@mui/material";
import { Alert as AlertDismissible, Spinner } from "../../components/Common";
import api from "../../Services/api";
import {
  getDashboardTile,
  getProjectsYearlyData,
} from "../../Services/projectServices";
import { getUserInfo } from "../../Services/userServices";
import { projectActions } from "../../store/actions/projectActions";
import ProjectList from "../ProjectList";
import TimeDifferenceCalculator from "../Utility/TimeDifferenceCalculator.js";
import InterviewActivity from "./InterviewActivity.js";
import Riskytd from "./Riskytd.js";
import "./dashboard.css";

const numberWithCommas = function (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpsLogin: false,
      isShowPopup: true,
      accountList: [],
      tenentId: null,
      projectList: [],
      stageList: [],
      projectsYearlyData: [],
      alertMsg: "",
      alertStatus: false,
      variant: "",
      yearlyloader: true,
      tileGraphData: "",
      tileLoader: true,
      tenant_id: null,
    };
  }

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  getInterviewpercntage = (x, y) => {
    let percent = (x / y) * 100;
    percent = parseInt(percent);
    return Number.isNaN(percent) ? "0" : percent;
  };

  getDashboardInterviewsData = (hr, pr, ar, lr, nc) => {
    let datarisknew;
    let datalabels;
    if (
      (hr === 0 || hr === null) &&
      (ar === 0 || ar === null) &&
      (pr === 0 || pr === null) &&
      (lr === 0 || lr === null) &&
      (nc === 0 || nc === null)
    ) {
      datarisknew = [hr, pr, ar, lr, nc, 1];
      // console.log("datarisknewif", datarisknew);

      datalabels = [
        this.props.t("High Risk"),
        this.props.t("Potential Risk"),
        this.props.t("Average Risk"),
        this.props.t("Low Risk"),
        this.props.t("Non-Compliant"),
        this.props.t("No-Calls"),
      ];
    } else {
      datarisknew = [hr, pr, ar, lr, nc];
      // console.log("datarisknewelse", datarisknew);
      datalabels = [
        this.props.t("High Risk"),
        this.props.t("Potential Risk"),
        this.props.t("Average Risk"),
        this.props.t("Low Risk"),
        this.props.t("Non-Compliant"),
      ];
    }

    const barBackgroundColor =
      this.props.themeColor === "light"
        ? ["#FF0000", "#FFCA00", "#92D050", "#008000", "#d9d9d9", "grey"]
        : ["#ff4560", "#feb019", "#00e396", "#008000", "#d9d9d9", "grey"];

    const doghnutData = {
      labels: datalabels,
      datasets: [
        {
          data: datarisknew,
          backgroundColor: barBackgroundColor,
          hoverBackgroundColor: barBackgroundColor,
          borderWidth: 1,
          borderColor: this.props.themeColor === "dark" ? "#060818" : "#fffcef",
          cutout: "70%",
        },
      ],
    };
    return doghnutData;
  };

  getOperatingTenantInterviews = () => {
    let accountList = this.state.accountList;
    let operatingTenantId = api.defaults.headers.common["operatingTenant"];
    let TenantInterviews;
    accountList !== undefined &&
      accountList.map(function (item) {
        if (item.tenantId == operatingTenantId) {
          TenantInterviews = item.totalNumberOfInterviews;
        }
      });
    return TenantInterviews;
  };
  getQuestionData = () => {
    const dates = this.getMonthYearLabel(this.state.projectsYearlyData);
    const dataPack1 = this.getMonthResultCount(this.state.projectsYearlyData);
    const data = {
      labels: dates,
      datasets: [
        {
          label: this.props.t("Calls"),
          data: dataPack1,
          backgroundColor: "#512DA8",
          hoverBackgroundColor: "#7E57C2",
          hoverBorderWidth: 0,
        },
      ],
    };
    return data;
  };

  componentDidMount() {
    this.props.dispatch(projectActions.dataLoading());
    api
      .get("user-service/v1/tenant/list?pageNumber=1&pageSize=1000")
      .then((res) => this.setState({ accountList: res.data.tenantList }))
      .catch((err) => console.log(err));
    getUserInfo()
      .then((res) => {
        this.setState(
          {
            tenant_id: res.data.operatingTenant,
          },
          () => {
            getDashboardTile(this.state.tenant_id)
              .then((res) => {
                this.setState({
                  tileGraphData: res.data,
                  tileLoader: false,
                });
                // console.log("res.data", res.data);
              })
              .catch((err) => {
                let errMsg = err.response.data.message;
                this.setState({
                  alertMsg: errMsg,
                  variant: "danger",
                  alertStatus: true,
                });
              });
          }
        );
      })
      .catch((err) => {
        console.log("catch err", err);
        this.setState({
          alertMsg: err.response.data.message,
        });
      });

    getProjectsYearlyData()
      .then((res) => {
        this.setState({
          projectsYearlyData: res.data,
          yearlyloader: false,
        });
      })
      .catch((err) => {
        let errMsg = err.response.data.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
        });
      });
  }
  getQuestionchartOptions = () => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 10,
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
        },
      },
      tooltips: {
        mode: "label",
        callbacks: {
          label: function (tooltipItem, data) {
            return (
              data.datasets[tooltipItem.datasetIndex].label +
              ": " +
              numberWithCommas(tooltipItem.yLabel)
            );
          },
        },
      },
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 10,
          fontColor: this.props.themeColor === "light" ? "#000000" : "#c2c5d1",
        },
      },
    };
    return options;
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.projectList !== nextProps.projectList) {
      // console.log("nextProps.projectList", nextProps.projectList);
      this.setState({
        projectList: nextProps.projectList,
      });
    }
    if (this.state.stageList !== nextProps.stageList) {
      this.setState({
        stageList: nextProps.stageList,
      });
    }
  }
  getMonthResultCount = (data1) => {
    let projectmonthdata = [];
    data1 != undefined &&
      data1.map(function (item) {
        projectmonthdata.push(item.count);
      });

    return projectmonthdata;
  };

  getMonthYearLabel = (data1) => {
    let projectmonthlabel = [];
    data1 != undefined &&
      data1.map(function (item) {
        projectmonthlabel.push(item.month);
      });
    return projectmonthlabel;
  };

  getLastStageid = (stageList) => {
    let laststageid = stageList.reduce(function (oldest, pilot) {
      return (oldest.sequence || 0) > pilot.sequence ? oldest : pilot;
    }, {});
    return laststageid.attributeId;
  };

  getcallRemainingInterviewCount = () => {
    const Remainingcalls =
      this.state.tileGraphData?.total_number_of_calls -
      this.state.tileGraphData?.total_completed_interview;
    return Remainingcalls;
  };

  render(props) {
    const { t } = this.props;
    const dognutoptions = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          align: "center",
          fullSize: true,
          labels: {
            boxWidth: 20,
            fontColor:
              this.props.themeColor === "light" ? "#000000" : "#c2c5d1",
          },
        },
        tooltip: {
          callbacks: {
            title: (tooltipItem) => {
              return tooltipItem[0].label;
            },
            label: (tooltipItem) => {
              let total = tooltipItem.dataset.data.reduce(function (
                previousValue,
                currentValue
              ) {
                return previousValue + currentValue;
              });
              let currentValue =
                tooltipItem.dataset.data[tooltipItem.dataIndex];
              let precentage = Math.floor((currentValue / total) * 100 + 0.5);

              if (tooltipItem.dataset.data.length >= 6) {
                return "0 | 0% ";
              } else {
                return (
                  " " +
                  tooltipItem.dataset.data[tooltipItem.dataIndex] +
                  " | " +
                  precentage +
                  "%"
                );
              }
            },
          },
        },
      },
      cutout: "100%",
    };

    return (
      <div>
        <div>
          <Grid container className="w-1200">
            <Grid item size={12}>
              <AlertDismissible
                msg={this.state.alertMsg}
                variantType={this.state.variant}
                show={this.state.alertStatus}
                close={this.handleAlertClose}
              ></AlertDismissible>
            </Grid>
            <Grid item size={12}>
              <div className="text-right schedule_last_update_time_dash">
                <span className="scheduleLastUpdateTime">
                  {this.props.scheduleLastUpdateTime && (
                    <TimeDifferenceCalculator
                      epochTimestamp={this.props.scheduleLastUpdateTime}
                    />
                  )}
                </span>
              </div>
            </Grid>
            <Grid item size={12}>
              <Card className="dashboard-card-tile">
                <Grid container className="boxes1" spacing={2}>
                  <Grid item className="tile_card">
                    {this.state.tileLoader ? (
                      <div className="custom-loader">
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        <div className="boxContent">
                          <span className="boxValue">
                            {this.state.tileGraphData?.active_project_count}
                          </span>
                        </div>
                        <span>{t("active projects")}</span>
                      </>
                    )}
                  </Grid>
                  <Grid item className="tile_card">
                    {this.state.tileLoader ? (
                      <div className="custom-loader">
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        <div className="boxContent">
                          <span className="boxValue">
                            {this.state.tileGraphData?.completed_project_count}
                          </span>
                        </div>
                        <span>{t("Completed projects")}</span>
                      </>
                    )}
                  </Grid>
                  <Grid item className="tile_card">
                    {this.state.tileLoader ? (
                      <div className="custom-loader">
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        <div className="boxContent">
                          <span className="boxValue">
                            {this.state.tileGraphData
                              .total_life_time_completed_interview
                              ? this.state.tileGraphData
                                  .total_life_time_completed_interview
                              : 0}
                          </span>
                        </div>
                        <span>{t("LIFETIME CALLS COMPLETED")}</span>
                      </>
                    )}
                  </Grid>
                  <Grid item className="tile_card">
                    {this.state.tileLoader ? (
                      <div className="custom-loader">
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        <div className="boxContent">
                          <span className="boxValue">
                            {this.state.tileGraphData.total_completed_interview
                              ? this.state.tileGraphData
                                  .total_completed_interview
                              : 0}
                          </span>
                        </div>
                        <span>{t("Current Order Calls Completed")}</span>
                      </>
                    )}
                  </Grid>
                  <Grid item className="tile_card">
                    {this.state.tileLoader ? (
                      <div className="custom-loader">
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        <div className="boxContent">
                          <span className="boxValue">
                            {this.getcallRemainingInterviewCount()}/
                            {this.state.tileGraphData?.total_number_of_calls}
                          </span>
                        </div>
                        <span>{t("Calls Remaining")} </span>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid container className="w-1200 graph-card" spacing={3}>
            <Grid size={6}>
              <Card>
                <CardHeader
                  title={t("Current Order Calls Completed")}
                  className="card-header-no-border more-space"
                ></CardHeader>
                <CardContent>
                  {this.state.tileLoader ? (
                    <div className="dashboard_tile_graph">
                      <div className="custom-loader">
                        <Spinner />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="riskdashboard-wrapper">
                        <Riskytd
                          datarisk={this.getDashboardInterviewsData(
                            this.state.tileGraphData.hr_count,
                            this.state.tileGraphData.pr_count,
                            this.state.tileGraphData.ar_count,
                            this.state.tileGraphData.lr_count,
                            this.state.tileGraphData.nc_count
                          )}
                          optionsrisk={dognutoptions}
                          isLoading={this.state.tileLoader}
                          isLabelShow={true}
                        />
                      </div>
                      <div className="dashboard_doghnut_center_text">
                        {t("Current Order Calls Completed")} <br />
                        {this.state.tileGraphData?.total_completed_interview}/
                        {this.state.tileGraphData?.total_number_of_calls} <br />
                        <span className="mt-2">
                          {this.getInterviewpercntage(
                            this.state.tileGraphData?.total_completed_interview,
                            this.state.tileGraphData?.total_number_of_calls
                          )}
                          %
                        </span>
                      </div>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid size={6}>
              <Card>
                <CardHeader
                  title={t("Project Activity")}
                  className="card-header-no-border more-space"
                ></CardHeader>
                <CardContent>
                  {this.state.yearlyloader != undefined &&
                  this.state.yearlyloader ? (
                    <div className="dashboard_tile_graph">
                      <div className="custom-loader">
                        <Spinner />
                      </div>
                    </div>
                  ) : (
                    <div className="Interviewdata-wrapper">
                      <InterviewActivity
                        data={this.getQuestionData()}
                        options={this.getQuestionchartOptions()}
                        isLabelShow={true}
                      />
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container className="w-1200 dashProjects">
            <Grid item size={12}>
              <ProjectList parentCallback={this.callbackFunction} />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    projectList: state.projects.projectList,
    stageList: state.projects.stageList,
    loading: state.projects.loading,
    themeColor: state.theme.themeColor,
    scheduleLastUpdateTime: state.projects.scheduleLastUpdateTime,
  };
};
export default withTranslation()(connect(mapStateToProps)(Dashboard));
