import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { Date_Time_Format } from "../../../../../config";
import TrackingDataDialog from "../components/dialogs/TrackingDataDialog";
import { EnhancedTable } from "../../../../CustomTable";

const Default = ({ loading, allEmailTrackingData, t }) => {
  useEffect(() => {
    if (allEmailTrackingData.length > 0) {
      const groupedData = allEmailTrackingData?.reduce((acc, item) => {
        acc[item.sendgrid_message_id] = acc[item.sendgrid_message_id] || {
          sendgrid_message_id: item.sendgrid_message_id,
          create_ts: item.create_ts,
          innerData: [],
        };
        acc[item.sendgrid_message_id].innerData.push(item);
        return acc;
      }, {});
      const groupedDataArray = Object.entries(groupedData).map(
        (value) => value[1]
      );
      setTrackingDataTable(groupedDataArray);
    }
  }, [allEmailTrackingData]);

  const [trackingDataTable, setTrackingDataTable] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [trackingData, setTrackingData] = useState(null);

  const formateDate = (params) => {
    const date = params.row.create_ts;
    return moment(date).format(Date_Time_Format);
  };

  const formateCallDateTime = (params) => {
    const second = params.row.create_ts;
    return second ? moment(second).format(Date_Time_Format) : "-";
  };

  const openEmailTrackingDataRow = (rowData) => {
    setTrackingData(rowData);
    setDialogOpen(true);
  };

  const formateMessageId = (params) => {
    return (
      <span className="sessionId-color">{params.row.sendgrid_message_id}</span>
    );
  };

  const formateStatus = (params) => {
    const status = params.row.value;
    return <span>{`${status.charAt(0).toUpperCase()}${status.slice(1)}`}</span>;
  };

  const formateEventData = (params) => {
    return (
      <pre
        onClick={() => openEmailTrackingDataRow(params.row)}
        style={{ cursor: "pointer" }}
      >
        {params.row.event_data}
      </pre>
    );
  };

  const columns = [
    {
      headerName: `${t("Message ID")}`,
      field: "sendgrid_message_id",
      sortable: false,
      renderCell: formateMessageId,
    },
    {
      headerName: `${t("CREATED DATE")}`,
      field: "create_ts",
      sortable: false,
      renderCell: formateDate,
    },
  ];

  const innerTableColumn = [
    {
      headerName: `${t("Status")}`,
      field: "value",
      flex: 1,
      sortable: false,
      renderCell: formateStatus,
    },
    {
      headerName: `${t("CREATED DATE")}`,
      field: "create_ts",
      flex: 1,
      sortable: false,
      renderCell: formateCallDateTime,
    },
    {
      headerName: `${t("Email Event Data")}`,
      field: "event_data",
      flex: 1,
      sortable: false,
      renderCell: formateEventData,
    },
  ];

  return (
    <div className="app-table">
      <EnhancedTable
        columns={columns}
        data={trackingDataTable}
        loading={loading}
        openTrackingDataRow={openEmailTrackingDataRow}
        formateCallDateTime={formateCallDateTime}
        showExpendedRow={true}
        isHidePagination={true}
        innerTableColumn={innerTableColumn}
      />
      {dialogOpen && (
        <TrackingDataDialog
          isOpen={dialogOpen}
          trackingData={trackingData}
          t={t}
          close={() => setDialogOpen(false)}
          type="Email"
        />
      )}
    </div>
  );
};

export default memo(Default);
