import api from "./api";
export const createOrder = (orderdata) => {
  return api.post(`user-service/v1/order/`, orderdata);
};

export const getOrderList = (tanentId, pageNumber, pageSize) => {
  return api.get(
    `user-service/v1/order/orderList?tenantId=${tanentId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};

export const getOrderDetail = (orderId) => {
  return api.get(`user-service/v1/order/${orderId}`);
};

export const updateOrderDetail = (updatedOrderData) => {
  return api.put(
    `user-service/v1/order/${updatedOrderData.id}`,
    updatedOrderData
  );
};

export const deleteOrder = (orderId) => {
  return api.delete(`user-service/v1/order/delete/${orderId}`);
};
