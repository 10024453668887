import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Grid2 as Grid } from "@mui/material";
import {
  Alert as AlertDismissible,
  Button,
  Spinner,
} from "../../../components/Common";
import { createRole } from "../../../Services/roleServices";
import PreventLeaveRoute from "../../Utility/PreventLeaveRoute.js";

class CreateRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMsg: "",
      alertStatus: false,
      variant: "",
      dirty: false,
      disablesavebtn: false,
    };
  }

  componentDidMount = () => {};

  cancelUserClick = () => {
    this.props.updateNavBar("Roles");
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  dirtyFlagChanged = (dirty) => {
    if (dirty != this.state.dirty) {
      this.setState({
        dirty: dirty,
      });
      this.props.dataFromParent.handleChildDirtyGFlag(dirty);
    }
  };

  handleCreateRole = (fields, tenantId, resetForm) => {
    this.setState({
      disablesavebtn: true,
    });
    createRole(fields, tenantId)
      .then((res) => {
        this.setState({
          alertMsg: `Role created successfully`,
          variant: "success",
          alertStatus: true,
          projectDetail: res.data,
          dirty: false,
          disablesavebtn: false,
        });
        this.props.dataFromParent.handleChildDirtyGFlag(false);
        resetForm();
      })
      .catch((err) => {
        let errMsg = Object.assign({}, err).response.data.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
          disablesavebtn: false,
        });
        this.props.dataFromParent.handleChildDirtyGFlag(false);
      });
  };

  render() {
    const { t } = this.props;
    const tenantId = this.props.dataFromParent.statedata.tenantId;
    return (
      <Formik
        initialValues={{
          roleName: "",
          description: "",
        }}
        validationSchema={Yup.object().shape({
          roleName: Yup.string().required(t("Role Name is required")),
        })}
        onSubmit={(fields, { resetForm }) => {
          this.handleCreateRole(fields, tenantId, resetForm);
        }}
        render={({
          errors,
          values,
          touched,
          handleReset,
          dirty,
          handleBlur,
        }) => (
          <Form onChange={this.dirtyFlagChanged(dirty)}>
            <PreventLeaveRoute
              reset={handleReset}
              when={dirty}
              // Navigate function
              navigate={(field, historyObj) => {
                if (field == "/main/admin/roles") {
                  this.cancelUserClick();
                } else {
                  historyObj.push(field);
                }
              }}
              // Use as "message" prop of Prompt of React-Router
              shouldBlockNavigation={(location) => {
                if (location.pathname === "/") {
                  return false;
                }
                return !!(dirty || this.state.dirty);
              }}
            />
            <Grid container>
              <Grid item size={12}>
                <AlertDismissible
                  msg={this.state.alertMsg}
                  variantType={this.state.variant}
                  show={this.state.alertStatus}
                  close={this.handleAlertClose}
                ></AlertDismissible>
              </Grid>
            </Grid>
            <Grid container className="add-tenant-row">
              <Grid item className="add-tenant-col" size={4}>
                <div className="form-group">
                  <label className="required" htmlFor="roleName">
                    {t("ROLE NAME")}
                  </label>
                  <Field
                    name="roleName"
                    autoComplete="off"
                    type="text"
                    className={
                      "form-control" +
                      (errors.roleName && touched.roleName
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {errors.roleName && touched.roleName && (
                    <div className="input-feedback add-tenant-validation">
                      {errors.roleName}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="" htmlFor="description">
                    {t("DESCRIPTION")}
                  </label>
                  <Field
                    name="description"
                    type="text"
                    component="textarea"
                    rows="5"
                    className={
                      "form-control" +
                      (errors.description && touched.description
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                    onBlur={handleBlur}
                    value={values.description}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item size={12}>
                <div className="form-group float-right">
                  <Button
                    variant="primary"
                    type="submit"
                    className="rounded-0 mr-4 save-btn button_with_loader"
                    disabled={this.state.disablesavebtn}
                  >
                    {this.state.disablesavebtn ? (
                      <>
                        {t("Save")}...
                        <div className="custom-loader linesLoader loader_inner_button">
                          <Spinner color="#ffffff" size={"19px"} />
                        </div>
                      </>
                    ) : (
                      <span>{t("Save")}</span>
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    className="rounded-0 cancel-btn"
                    onClick={this.cancelUserClick}
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    );
  }
}

export default withTranslation()(CreateRoles);
