import { Date_Format } from "../../config";
export const formatDate = () => {
  let array = Date_Format.split("-");
  let updateFormat = array.map((value) => {
    if (value === "YYYY") {
      return "yyyy";
    }
    if (value === "DD") {
      return "dd";
    }
    return value;
  });
  return updateFormat.join("-");
};
