import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useHistory } from "react-router-dom";
const AuthProvider = ({ children }) => {
  const history = useHistory();
  /**
   * This function will run after redirection of redirect_url
   * @param {object} appState is whatever we pass through parameter of loginWithRedirect method of autho
   */
  const onRedirectCallback = (appState) => {
    if (appState?.returnTo) {
      history.push(appState.returnTo);
    }
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_DOMAIN}
      clientId={process.env.REACT_APP_CLIENTID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/main`,
        ...(process.env.REACT_APP_AUDIENCE
          ? { audience: process.env.REACT_APP_AUDIENCE }
          : null),
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
