import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { ModalPopUp } from "../../../../components/Common";

const DeleteOrder = (props) => {
  const { t } = useTranslation();
  const { show, onOkay, disabledBtn } = props;
  return (
    <ModalPopUp
      show={show}
      onHide={() => onOkay(false)}
      className="text-center"
    >
      <p className="d-flex justify-content-center text-bold text-center mb-5 modal-box-text">
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="save-change-warning-icon"
        />
        {props.children}
      </p>

      <button
        className="btn btn-secondary save-modal-btn"
        onClick={() => onOkay(false)}
      >
        {t("Cancel")}
      </button>
      <button
        type="submit"
        disabled={disabledBtn}
        className="btn btn-primary mr-2 save-modal-btn"
        onClick={() => onOkay(true)}
      >
        {disabledBtn ? t("Deleting") + "..." : t("Delete")}
      </button>
    </ModalPopUp>
  );
};

export default DeleteOrder;
