import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import ClearSpeedLogo from "../../assets/images/ClearspeedLogo.png";
import userIcon from "../../assets/images/user_icon.png";
import api from "../../Services/api";
import { authActions } from "../../store/actions/authAction";
import { themeActions } from "../../store/actions/ThemeAction";
import i18n from "./../../locale/i18n";
import LanguageDropdown from "./LanguageDropdown";
import UserDropdown from "./UserDropdown";

const Header = (props) => {
  const [defaultLanguage, setDefaultLanguage] = useState(i18n.language);
  const [tenantId, setTenantId] = useState(props.userInfo.tenantId);
  const [userName, setUserName] = useState(props.userInfo.firstName);
  const [userLogo, setUserLogo] = useState(props.userInfo.image);
  const [userInfo, setUserInfo] = useState(props.userInfo);
  const [tenantLogo, setTenantLogo] = useState(null);
  const [languagelist, setLanguagelist] = useState(props.languageList);

  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (props.userInfo !== userInfo) {
      setTenantId(props.userInfo.tenantId);
      setUserName(props.userInfo.firstName);
      setTenantLogo(props.userInfo.tenantLogo);
      setUserLogo(props.userInfo.image);
      setUserInfo(props.userInfo);
      setLanguagelist(props.languageList);
      props.setHeaderLogo(props.userInfo.tenantLogo);
    }
    if (props.languageList !== languagelist) {
      setLanguagelist(props.languageList);
    }
  }, [props.userInfo, props.languageList]);

  const handleLanguageChangeAll = (shortcode, id) => {
    i18n.changeLanguage(shortcode);
    setDefaultLanguage(shortcode);
    api.defaults.headers.common["langId"] = parseInt(id);
    api.defaults.headers.common["language"] = shortcode;
    localStorage.setItem("langid", shortcode);
    localStorage.setItem("languageId", id);
  };

  const themeSwitch = (e) => {
    const cb = document.querySelector("#theme_switch_id");
    if (cb.checked) {
      props.getTheme("light");
      document.body.className = document.body.className.replace(
        "dark_theme",
        ""
      );
      localStorage.setItem("theme_preference", "light");
    } else {
      props.getTheme("dark");
      document.body.classList.add("dark_theme");
      localStorage.setItem("theme_preference", "dark");
    }
  };

  const { t } = props;

  let permissionList = [];
  props.userInfo.rolePermission &&
    props.userInfo.rolePermission.permissions.forEach((option) => {
      permissionList = [...permissionList, option.permission];
    });

  const url = match.url;

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="navbar-brand" onClick={() => history.push("/")}>
        {props.headerLogo ? (
          <img
            className="img-fluid d-inline-block align-top"
            src={`data:image/png;base64,${props.headerLogo}`}
            alt="Logo"
          />
        ) : (
          <img
            className={"img-fluid align-top"}
            src={ClearSpeedLogo}
            alt="Logo"
          />
        )}
      </div>
      <div className="navbar-collapse collapse" id="responsive-navbar-nav">
        <div className="ml-auto mt-1 align-items-center nav">
          <div className="freshWork-container">
            <a
              href="https://clearspeed-542643318130079661.myfreshworks.com/login/auth/1676895437642?client_id=451979510707337272&redirect_uri=https%3A%2F%2Fclearspeeddev.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback%3Fhd%3Dfreshdesk.clearspeed.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Contact Us")}
            </a>
          </div>
          {
            <div className="user-locale dropdown">
              <LanguageDropdown
                defaultLanguage={defaultLanguage}
                languagelist={languagelist}
                handleLanguageChangeAll={handleLanguageChangeAll}
              />
            </div>
          }
          <UserDropdown
            t={t}
            userName={userName}
            userLogo={userLogo}
            userIcon={userIcon}
            themeSwitch={(e) => themeSwitch(e)}
            url={url}
            themeColor={props.themeColor}
          />
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    themeColor: state.theme.themeColor,
    headerLogo: state.authentication.headerLogo,
  };
};

const mapDispatchToProps = {
  getTheme: themeActions.getTheme,
  setHeaderLogo: authActions.setHeaderLogo,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Header)
);
