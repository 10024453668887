import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ResultsContext } from "../ResultsContext";
import downloadPdfIcon from "../../../../assets/images/download.png";
import downloadDone from "../../../../assets/images/download_done.png";
import { generateNewResultPdf } from "../../../Utility/NewResultPdf";
import { CustomTooltip } from "../../../Utility/CustomTooltip";

function DownloadResultPdf({ row, uniquekey, pdfLogo }) {
  const [img, setImg] = useState(null);
  const { t } = useTranslation();
  const themeColor = useSelector((state) => state.theme.themeColor);
  const { resultsState, resultsDispatch, projectDetail } =
    useContext(ResultsContext);
  const {
    displayTimeZoneOffset,
    flagsOptionsData,
    downloadPdfList,
    questionList,
  } = resultsState;

  /**
   * This function work as a setter function for state based
   * @param {string} type
   * @param {anyType} payload
   */
  const dispatch = (type, payload) => {
    resultsDispatch({ type: type, payload: payload });
  };

  const isExitinterviewResultId = () =>
    downloadPdfList.includes(row.interviewResultId);

  const handleMouseOver = () => {
    if (isExitinterviewResultId()) {
      setImg(downloadPdfIcon);
    }
  };

  const handleClick = () => {
    if (!isExitinterviewResultId()) {
      const uniqueIds = [
        ...new Set([...downloadPdfList, row.interviewResultId]),
      ];
      dispatch("set_all_interview_result_id", uniqueIds);
    }
    generateNewResultPdf(
      row,
      questionList,
      projectDetail,
      displayTimeZoneOffset,
      flagsOptionsData,
      pdfLogo
    );
  };

  return (
    <>
      <CustomTooltip placement="left" title={t("PDF Report")}>
        <div
          className={
            isExitinterviewResultId()
              ? "action-event download_pdf downloaded_pdf"
              : "action-event download_pdf"
          }
          data-tip
          data-for={`pdf_download_${uniquekey}`}
          onClick={() => {
            handleClick();
          }}
          onMouseOver={handleMouseOver}
          onMouseOut={() => setImg(null)}
        >
          <img
            src={
              img
                ? img
                : isExitinterviewResultId()
                ? downloadDone
                : downloadPdfIcon
            }
            key={row.interviewResultId}
            className="pdf-download-icon"
          />
        </div>
      </CustomTooltip>
    </>
  );
}

export default DownloadResultPdf;
