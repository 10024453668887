import React from "react";
import { styles } from "../PdfStyle";
import { PrecisionRGWithExpressFooterText } from "./PrecisionRGWithExpressFooterText";
import { PrecisionFooterText } from "./PrecisionFooterText";
import { View } from "@react-pdf/renderer";

export const FooterText = ({ questionnaireType }) => {
  const getFooterText = (type) => {
    switch (type) {
      case "EXPRESS_RISK_REPORT":
      case "PRECISION_RED_GREEN_RISK_REPORT":
        return <PrecisionRGWithExpressFooterText />;
      default:
        return <PrecisionFooterText />;
    }
  };
  return <View style={styles.content}>{getFooterText(questionnaireType)}</View>;
};
