import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Spinner } from "../../components/Common";

const Riskytd = (props) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  ChartJS.defaults.plugins.legend.position = "bottom";
  ChartJS.defaults.plugins.legend.labels.boxWidth = 20;
  ChartJS.defaults.maintainAspectRatio = false;
  ChartJS.defaults.plugins.legend.display = props.isLabelShow;
  return (
    <>
      {props.isLoading ? (
        <div className="custom-loader">
          <Spinner />
        </div>
      ) : (
        <Doughnut data={props.datarisk} options={props.optionsrisk} />
      )}
    </>
  );
};

export default Riskytd;
