import React, { useState, useEffect, memo } from "react";
import moment from "moment";
import { Date_Time_Format } from "../../../../../config";
import TrackingDataDialog from "../components/dialogs/TrackingDataDialog";
import EnhancedTable from "../../../../CustomTable/EnhancedTable";

const Default = ({ loading, allSMSTrackingData, t }) => {
  const [trackingDataTable, setTrackingDataTable] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [trackingData, setTrackingData] = useState(null);

  useEffect(() => {
    if (allSMSTrackingData.length > 0) {
      const groupedData = allSMSTrackingData?.reduce((acc, item) => {
        acc[item.sms_id] = acc[item.sms_id] || {
          sms_id: item.sms_id,
          create_ts: item.create_ts,
          innerData: [],
        };
        acc[item.sms_id].innerData.push(item);
        return acc;
      }, {});

      const groupedDataArray = Object.entries(groupedData).map(
        (value) => value[1]
      );

      console.log(groupedDataArray);
      setTrackingDataTable(groupedDataArray);
    }
  }, [allSMSTrackingData]);

  const formateDate = (params) => {
    const date = params.row.create_ts;
    return moment(date).format(Date_Time_Format);
  };
  const formateCallDateTime = (params) => {
    const second = params.row.create_ts;
    return second ? moment(second).format(Date_Time_Format) : "-";
  };

  const openSMSTrackingDataRow = (rowData) => {
    setTrackingData(rowData);
    setDialogOpen(true);
  };

  const formateSMSId = (params) => {
    return <span className="sessionId-color">{params.row.sms_id}</span>;
  };

  const formateStatus = (params) => {
    const status = params.row.invitation_status;
    return <span>{`${status.charAt(0).toUpperCase()}${status.slice(1)}`}</span>;
  };

  const formateEventData = (params) => {
    return (
      <pre
        onClick={() => openSMSTrackingDataRow(params.row)}
        style={{ cursor: "pointer" }}
      >
        {params.row.raw_data}
      </pre>
    );
  };
  const columns = [
    {
      headerName: `${t("SMS ID")}`,
      field: "sms_id",
      sortable: false,
      renderCell: formateSMSId,
    },
    {
      headerName: `${t("CREATED DATE")}`,
      field: "create_ts",
      sortable: false,
      renderCell: formateDate,
    },
  ];

  const innerTableColumn = [
    {
      headerName: `${t("Status")}`,
      field: "invitation_status",
      flex: 1,
      sortable: false,
      renderCell: formateStatus,
    },
    {
      headerName: `${t("CREATED DATE")}`,
      field: "create_ts",
      flex: 1,
      sortable: false,
      renderCell: formateCallDateTime,
    },
    {
      headerName: `${t("SMS Event Data")}`,
      field: "raw_data",
      flex: 1,
      sortable: false,
      renderCell: formateEventData,
    },
  ];

  return (
    <div className="app-table">
      <EnhancedTable
        columns={columns}
        data={trackingDataTable}
        loading={loading}
        openTrackingDataRow={openSMSTrackingDataRow}
        formateCallDateTime={formateCallDateTime}
        showExpendedRow={true}
        isHidePagination={true}
        innerTableColumn={innerTableColumn}
      />
      {dialogOpen && (
        <TrackingDataDialog
          isOpen={dialogOpen}
          trackingData={trackingData}
          t={t}
          close={() => setDialogOpen(false)}
          type="SMS"
        />
      )}
    </div>
  );
};

export default memo(Default);
