import { sortByLabel } from "../../../../../ProjectInfo/Helper";
import { getlanguageId } from "../../../../../../../Helpers";
const createDropdownOption = (option) => {
  return {
    value: option.attributeId,
    label: option.description,
    sequence: option.sequence,
  };
};

/**
 * This function will return option for status and currency dropdown
 * @param {array} tanentAttribute
 * @param {array} projectAttribute
 * @param {array} languageCode
 * @returns
 */
const OutComeTrackingOption = (
  tanentAttribute,
  projectAttribute,
  languageCode
) => {
  let languageId = getlanguageId(languageCode);
  let allStatus = projectAttribute
    .filter((item) => {
      return (
        item.attributeType === "OUTCOME_TRACKING_ACTION" &&
        item.languageId === languageId
      );
    })
    .sort((a, b) => a.sequence - b.sequence);

  let allCurrency = tanentAttribute
    .filter((item) => {
      return (
        item.attributeType === "CURRENCY" && item.languageId === languageId
      );
    })
    .sort((a, b) => a.sequence - b.sequence);

  let statusOption = allStatus.map((option) => {
    return createDropdownOption(option);
  });


  let currencyOption = allCurrency.map((option) => {
    return createDropdownOption(option);
  });



  return {
    statusOption,
    currencyOption,
  };
};

export default OutComeTrackingOption;
