import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { ModalPopUp, Button, Spinner } from "../../components/Common";
import { useGetReport } from "./context/actions";

const DownloadReport = ({
  show,
  handleClose,
  children,
  downloadReportId,
  fileName,
}) => {
  const { t } = useTranslation();
  const { getDownloadReportUrl } = useGetReport();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await getDownloadReportUrl(downloadReportId, handleClose, fileName);
    setLoading(false);
  };
  return (
    <ModalPopUp
      show={show}
      onHide={() => handleClose()}
      className="text-center"
    >
      <div className="clearfix">
        <p className="d-flex justify-content-center text-bold text-center mb-5 modal-box-text">
          {children}
        </p>
      </div>
      <Box mt={2} mb={2} />
      <Box mt={2} mb={2} />
      <div>
        <Button
          className="btn btn-secondary save-modal-btn mr-2"
          onClick={() => handleClose()}
          variant="secondary"
        >
          {t("Cancel")}
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={loading}
          onClick={() => handleSubmit()}
          className="ml-2"
        >
          {loading ? t("DOWNLOADING") : t("DOWNLOAD")}
          {loading && (
            <div className="custom-loader linesLoader loader_inner_button">
              <Spinner color="#ffffff" size={"19px"} />
            </div>
          )}
        </Button>
      </div>

      <Box mt={2} mb={2} />
    </ModalPopUp>
  );
};

export default DownloadReport;
