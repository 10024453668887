import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Grid2 as Grid } from "@mui/material";
import { Button, Alert as AlertDismissible } from "../../../components/Common";
import { updateUser } from "../../../Services/userServices";
import PreventLeaveRoute from "../../Utility/PreventLeaveRoute.js";
import { CopyIcon } from "../../../assets/images/icons/customIcons.js";

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMsg: "",
      alertStatus: false,
      variant: "",
      rowVal: {
        firstName: "",
        lastName: "",
        title: "",
        email: "",
        roleId: 0,
        isActive: false,
      },
      dirty: false,
      disablesavebtn: false,
    };
  }

  componentDidMount = () => {
    if (this.props.dataFromParent.statedata.editRow != null) {
      this.setState({
        rowVal: this.props.dataFromParent.statedata.editRow,
      });
    }
  };

  cancelUserClick = () => {
    this.props.updateNavBar("Client Applications");
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  dirtyFlagChanged = (dirty) => {
    if (dirty != this.state.dirty) {
      this.setState({
        dirty: dirty,
      });
      this.props.dataFromParent.handleChildDirtyGFlag(dirty);
    }
  };

  handleUpdateUser = (fields, UserId, tenantId, resetForm) => {
    this.setState({
      disablesavebtn: true,
    });
    //   return;
    updateUser(fields, UserId, tenantId)
      .then((res) => {
        this.setState({
          alertMsg: `User ${res.data.firstName} ${res.data.lastName} Updated Successfully`,
          variant: "success",
          alertStatus: true,
          projectDetail: res.data,
          rowVal: res.data,
          dirty: false,
          disablesavebtn: false,
        });
        this.props.dataFromParent.handleChildDirtyGFlag(false);
        resetForm();
      })
      .catch((err) => {
        let errMsg = Object.assign({}, err).response.data.message;
        this.setState({
          alertMsg: errMsg,
          variant: "danger",
          alertStatus: true,
          disablesavebtn: false,
        });
      });
  };

  handleCopy = (el) => {
    console.log(el);
    const range = document.createRange();
    const selection = window.getSelection();
    console.log(range);
    console.log(selection);
    range.selectNode(el);
    selection.empty();
    selection.addRange(range);
    document.execCommand("copy");
  };
  render() {
    const { t } = this.props;

    const tenantId = this.props.dataFromParent.statedata.tenantId;

    let curldata = this.state.rowVal.sampleCURLForAccessToken;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          clientName: this.state.rowVal.clientName,
          clientId: this.state.rowVal.clientId,
          clientSecret: this.state.rowVal.clientSecret,
          description: this.state.rowVal.description,
          sampleCURLForAccessToken: this.state.rowVal.sampleCURLForAccessToken,
        }}
        validationSchema={Yup.object().shape({
          clientName: Yup.string().required(t("First Name is required")),
          clientId: Yup.string().required(t("Last Name is required")),
        })}
        onSubmit={(fields, { resetForm }) => {
          this.handleUpdateUser(
            fields,
            this.props.dataFromParent.statedata.editRow.id,
            tenantId,
            resetForm
          );
        }}
        render={({ errors, values, touched, handleReset, dirty }) => (
          <Form onChange={this.dirtyFlagChanged(dirty)}>
            <PreventLeaveRoute
              reset={handleReset}
              when={dirty}
              // Navigate function
              navigate={(field, historyObj) => {
                if (field == "/main/admin/clientapp") {
                  this.cancelUserClick();
                } else {
                  historyObj.push(field);
                }
              }}
              // Use as "message" prop of Prompt of React-Router
              shouldBlockNavigation={(location) => {
                if (location.pathname === "/") {
                  return false;
                }
                return !!(dirty || this.state.dirty);
              }}
            />
            <Grid container>
              <Grid item size={12}>
                <AlertDismissible
                  msg={this.state.alertMsg}
                  variantType={this.state.variant}
                  show={this.state.alertStatus}
                  close={this.handleAlertClose}
                ></AlertDismissible>
              </Grid>
            </Grid>
            <Grid container className="add-tenant-row">
              <Grid item className="add-tenant-col" size={6}>
                <div className="form-group">
                  <label className="" htmlFor="clientName">
                    {t("CLIENT APP NAME")}
                  </label>
                  <Field
                    name="clientName"
                    type="text"
                    disabled
                    className={
                      "form-control" +
                      (errors.clientName && touched.clientName
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {errors.clientName && touched.clientName && (
                    <div className="input-feedback add-tenant-validation">
                      {errors.clientName}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label className="" htmlFor="clientId">
                    {t("CLIENT ID")}
                  </label>
                  <Field
                    name="clientId"
                    type="text"
                    disabled
                    className={
                      "form-control" +
                      (errors.clientId && touched.clientId
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {errors.clientId && touched.clientId && (
                    <div className="input-feedback add-tenant-validation">
                      {errors.clientId}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="" htmlFor="clientSecret">
                    {t("CLIENT KEY")}
                  </label>
                  <div className="position-relative">
                    <input
                      name="clientSecret"
                      ref={(r) => (this.clientKeyRef = r)}
                      disabled
                      value={values.clientSecret}
                      type="text"
                      className={
                        "form-control client-key-input" +
                        (errors.clientSecret && touched.clientSecret
                          ? " is-invalid"
                          : " add-tenant-input")
                      }
                    />
                    <CopyIcon
                      className="copy-icon"
                      color="#759fbd"
                      handleCopy={() => this.handleCopy(this.clientKeyRef)}
                    />
                  </div>
                  {errors.clientSecret && touched.clientSecret && (
                    <div className="input-feedback add-tenant-validation">
                      {errors.clientSecret}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="" htmlFor="description">
                    {t("DESCRIPTION")}
                  </label>
                  <Field
                    name="description"
                    type="text"
                    disabled
                    component="textarea"
                    rows="5"
                    className={
                      "form-control" +
                      (errors.description && touched.description
                        ? " is-invalid"
                        : " add-tenant-input")
                    }
                  />
                  {errors.description && touched.description && (
                    <div className="input-feedback add-tenant-validation">
                      {errors.description}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item size={4}>
                <a
                  className="rounded-0 mr-4 mt-4 btn btn-primary btn-sm text-white"
                  onClick={() => this.handleCopy(this.sample)}
                >
                  {t("Copy Sample Curl")}
                </a>
                <div className="card mt-4 card-code">
                  <pre>
                    <code
                      ref={(r) => (this.sample = r)}
                      className="hl hljs bash"
                    >
                      {curldata}
                    </code>
                  </pre>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item size={12}>
                <div className="form-group float-right">
                  <Button
                    variant="secondary"
                    type="button"
                    className="rounded-0 cancel-btn"
                    onClick={this.cancelUserClick}
                  >
                    {t("Back")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    );
  }
}

export default withTranslation()(EditUser);
