import React from "react";
import { Checkbox } from "@mui/material";

export const ParticipantIrnCheckBox = ({
  participant,
  checked,
  onChange,
  selectedParticipant,
}) => {
  const isRowSelected = () => {
    let isRowChecked = false;
    selectedParticipant.map((participant) => {
      if (participant.participantId === checked) {
        isRowChecked = true;
      }
    });
    return isRowChecked;
  };
  return (
    <div value={participant.interviewRefNum}>
      <span>
        <Checkbox
          type="checkbox"
          id={participant.participantId}
          name={`IRN_${participant.participantId}`}
          checked={isRowSelected()}
          onChange={onChange}
          className="form-check-input ml-2"
        />
        <label className="form-check-label"> </label>
      </span>
    </div>
  );
};

export const HandleParticipantRowSelection = ({
  checked,
  onSelect,
  onChange,
  participant,
  selectedParticipant,
}) => {
  return (
    <>
      <Checkbox
        type="checkbox"
        className="participant select-all-dropdown ml-2"
        name="selectAllCheckbox"
        indeterminate={
          selectedParticipant[0]?.participantId !==
            selectedParticipant[1]?.participantId &&
          participant.length !== selectedParticipant.length
        }
        onChange={onChange}
        checked={checked}
      />
    </>
  );
};
