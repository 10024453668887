import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import * as userPermission from "../../Utility/userPermission.js";
import moment from "moment";
import { Date_Format } from "../../../config/index.js";
import { CustomGrid } from "../../CustomTable";
class ManageTenant extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getDateInFormat = (params) => {
    if (params?.row?.value != null) {
      return moment(params.row.value).format(Date_Format);
    }
    return "";
  };

  onRowClick = (params) => {
    if (
      userPermission.isTenantReadEnabled(
        this.props.dataFromParent.statedata.userInfo.rolePermission.permissions
      )
    ) {
      this.props.handleEditRow(params.row, "Edit Tenant");
    }
  };

  render() {
    const { t } = this.props;
    const { tenants, totalTenants, sizePerPage, tableLoader, page } =
      this.props.dataFromParent.statedata;

    const columns = [
      {
        field: "tenantName",
        headerName: t("ACCOUNT NAME"),
        sortable: true,
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <span className="tenant-manage-table-col-1">
              {params.row.tenantName}
            </span>
          );
        },
      },
      {
        field: "statusValue",
        headerName: t("STATUS"),
        sortable: false,
        filterable: false,
        flex: 1,
      },
      {
        field: "createTs",
        headerName: t("CREATED DATE"),
        sortable: true,
        filterable: false,
        flex: 1,
        valueGetter: this.getDateInFormat,
      },
    ];

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total tenant-manage-table-pagi-text">
        Showing {from} to {to} of {size} Results
      </span>
    );

    return (
      <div className="table_wrapper custom_table_container app-table admin-account-module-table">
        <CustomGrid
          columns={columns}
          data={tenants}
          currentPage={page}
          perPageCount={sizePerPage}
          totalPages={totalTenants}
          loading={tableLoader}
          isServerPagination={true}
          isServerSorting={true}
          onChangeRecords={this.props.dataFromParent.handleTableChange}
          onSortModelChange={this.props.dataFromParent.handleTableSorting}
          onRowClick={this.onRowClick}
        />
      </div>
    );
  }
}

export default withTranslation()(ManageTenant);
