import api from "./api";
export const getAllUsersByTenantID = (id) => {
  return api.get(`user-service/v1/tenant/${id}/users`);
};

export const createUser = async (param, tenantId, isPrivilegeFieldEnable) => {
  // var token = JSON.parse(localStorage.getItem('token'));
  console.log(param);
  let form = new FormData();
  form.append("tenantId", tenantId);
  form.append("firstName", param.firstName);
  form.append("lastName", param.lastName);
  form.append("email", param.email);
  form.append("title", param.title);
  form.append("roleId", param.roleId);
  form.append("isActive", param.status);
  if (isPrivilegeFieldEnable) {
    form.append("privilegeId", param.privilegeId);
  }
  return api.post(`user-service/v1/user/`, form);
};

export const updateUser = async (
  param,
  userId,
  tenantId,
  isPrivilegeFieldEnable
) => {
  let form = new FormData();
  form.append("firstName", param.firstName);
  form.append("lastName", param.lastName);
  form.append("title", param.title);
  form.append("roleId", param.roleId);
  form.append("isActive", param.status);
  if (isPrivilegeFieldEnable) {
    form.append("privilegeId", param.privilegeId);
  }
  return api.put(`user-service/v1/user/${userId}`, form);
};
export const deleteUser = async (id) => {
  return api.delete(`user-service/v1/user/${id}`);
};

export const getUserRolesPermissions = (projectId) => {
  return api.get(`user-service/v1/user/permissionsNew/?projectId=${projectId}`);
};

export const getUserRolesPermissionsDefault = () => {
  return api.get("user-service/v1/user/permissionsNew");
};

export const getUserInfo = async () => {
  return await api.get("user-service/v1/user/userInfo");
};
export const updateUserprofile = async (form) => {
  return await api.put("user-service/v1/user/profile", form);
};

export const getTenantLanguage = async () => {
  return await api.get("user-service/v1/tenant/languages");
};

export const getTenantProjects = () => {
  return api.get(`user-service/v1/tenant/tenants/projects`);
};

export const getFilterActivity = () => {
  return api.get(`user-service/v1/user/activities`);
};

export const createClientapp = async (param) => {
  console.log(param);
  return api.post(`user-service/v1/thirdparty/consumer`, param);
};

export const getClientappList = async () => {
  return api.get(`user-service/v1/thirdparty/consumer`);
};
export const getConsumers = () => {
  return api.get("user-service/v1/thirdparty/consumers");
};

export const getVersionDetail = () => {
  return api.get("user-service/v1/app/version");
};

// Secrets api
export const getSecretsList = () => {
  return api.get("user-service/v1/secret");
};

export const createSecrets = (data) => {
  return api.post("user-service/v1/secret/add", data);
};

export const updateSecrets = (data, id) => {
  return api.put(`user-service/v1/secret/${id}`, data);
};

export const getSecretDetails = (id) => {
  return api.get(`user-service/v1/secret/${id}`);
};

export const getEmbedDashboardURL = () => {
  return api.get(`user-service/v1/tenant/getEmbedDashboardURL`);
};

export const getuserLogData = async (param) => {
  return api.post(`user-service/v1/user/userData`, param);
};

export const getuserExportLogData = async (param) => {
  return api.post(`user-service/v1/user/export/userData`, param, {
    responseType: "arraybuffer",
  });
};

export const getUserOptions = (id) => {
  return api.get(`user-service/v1/tenant/${id}/fetch/users`);
};

export const getUserTenantLogo = (operatingTenant) => {
  return api.get(`user-service/v1/tenant/img/${operatingTenant}`);
};
