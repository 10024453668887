import { sortByLabel, createDropdownOption } from "../Helper";

const ISWOptions = (state) => {
  let irnVerificationMethodsOption = state.irnVerificationMethodsA
    .map((option) => {
      return createDropdownOption(option);
    })
    .sort(sortByLabel);

  return {
    irnVerificationMethodsOption,
  };
};

export default ISWOptions;
