import React, { useContext, useState, useRef } from "react";
import { ResultsContext } from "../../../../ResultsContext";
import { useTranslation } from "react-i18next";
import { uploadResultsdata } from "../../../../../../../Services/executionService";
import { getResultErrorFileDownload } from "../../../../../../../Services/projectServices";
import * as FileSaver from "file-saver";
import { filenameFromResponseHeader } from "../../../../../../../shared/utils";
import { ModalPopUp } from "../../../../../../../components/Common";

const UploadResults = (props) => {
  const { t } = useTranslation();
  const { resultsDispatch, projectDetail } = useContext(ResultsContext);
  const { projectId, projectCode } = projectDetail;
  const [uploadResultsModalMsg, setUploadResultsModalMsg] = useState("");
  const [uploadResultsModalShow, setUploadResultsModalShow] = useState(false);
  const [isFileDuplicate, setIsFileDuplicate] = useState(false);
  const [isFileDuplicateName, setIsFileDuplicateName] = useState(null);
  const uploadResultFile = useRef(null);

  const resultUploadFile = (event) => {
    dispatch("loading_file_upload", true);
    event.preventDefault();
    if (event.target.files) {
      //  let fileName = event.target.files[0];
      let fileName = uploadResultFile.current.files[0];
      let formData = new FormData();
      formData.append("file", fileName);
      formData.append("projectId", projectId);
      formData.append("projectCode", projectCode);
      uploadResultsdata(formData)
        .then((res) => {
          uploadResultFile.current.value = "";
          if (res.data.duplicateFileName !== undefined) {
            dispatch("loading_file_upload", false);
            setUploadResultsModalShow(true);
            setIsFileDuplicate(true);
            setUploadResultsModalMsg(res.data.duplicateFileName);
            setIsFileDuplicateName(res.data.duplicateFileName);
          } else {
            dispatch("loading_file_upload", false);
            setUploadResultsModalShow(true);
            setUploadResultsModalMsg("Results successfully updated!");
            props.fetchProjectResult();
          }
        })
        .catch((err) => {
          uploadResultFile.current.value = "";
          console.log("error in file upload", err);
          let errMsg = err.response?.data.message;
          dispatch("loading_file_upload", false);
          setUploadResultsModalShow(true);
          setUploadResultsModalMsg(
            errMsg
              ? errMsg
              : "Some error occurred while updating Results, Please try again later."
          );
        });
    }
  };

  /**
   * This function work as a setter function for state based
   * @param {string} type
   * @param {anyType} payload
   */
  const dispatch = (type, payload) => {
    resultsDispatch({ type: type, payload: payload });
  };

  /**
   * This function is used to download the file
   */

  const handleisFileDuplicate = (e) => {
    e.preventDefault();
    const data = {
      error_file_name: isFileDuplicateName,
      error_type: "result",
    };

    getResultErrorFileDownload(data)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: res.headers["mime-type"],
        });
        FileSaver.saveAs(
          blob,
          filenameFromResponseHeader(res.headers["content-disposition"])
        );
        setUploadResultsModalShow(false);
        setUploadResultsModalMsg("");
        props.fetchProjectResult();
      })
      .catch((err) => {
        console.log("err", err);
      });

    // console.log(e);
  };

  return (
    <>
      <div className="dropdown-item">
        <input
          type="file"
          name="file"
          ref={uploadResultFile}
          className="upload-file-input"
          accept=".xlsx, .xls, .cop, .csv"
          onChange={resultUploadFile}
        />
        {t("Upload Results")}
      </div>
      {uploadResultsModalShow && (
        <ModalPopUp
          close={() => {
            setUploadResultsModalShow(false);
            setIsFileDuplicate(false);
            setIsFileDuplicateName(null);
          }}
          title={t("Upload Results")}
          show={uploadResultsModalShow}
        >
          {isFileDuplicate ? (
            <p>
              Following are the Duplicate results in the File: &nbsp;
              <span
                className="isFileDuplicate_link"
                onClick={(e) => handleisFileDuplicate(e)}
              >
                Click here to download the file
              </span>
            </p>
          ) : (
            <p>{uploadResultsModalMsg}</p>
          )}
        </ModalPopUp>
      )}
    </>
  );
};

export default UploadResults;
