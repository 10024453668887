import React, { useState } from "react";
import { Menu, MenuItem, Button, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const LanguageDropdown = ({
  defaultLanguage,
  languagelist,
  handleLanguageChangeAll,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  if (!languagelist || languagelist.length === 0) return null;

  const otherLanguages = languagelist.filter(
    (lang) => lang.shortCode !== defaultLanguage
  );
  const currentLanguage = languagelist.find(
    (lang) => lang.shortCode === defaultLanguage
  );

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (shortCode, id) => {
    handleLanguageChangeAll(shortCode, id);
    handleClose();
  };

  return (
    <>
      {currentLanguage && (
        <Button
          onClick={handleOpen}
          className="text-uppercase dropdown-toggle btn btn-std"
          sx={{
            padding: "0.7rem 1.5rem",
            borderRadius: "6px",
            color: "#212529",
          }}
          endIcon={<ArrowDropDownIcon fontSize="small" />}
        >
          {currentLanguage.label}
        </Button>
      )}
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="language-drop-down-menu"
      >
        {otherLanguages.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => handleLanguageChange(item.shortCode, item.id)}
          >
            <Typography textTransform="uppercase">{item.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageDropdown;
