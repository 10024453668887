import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, Grid2 as Grid } from "@mui/material";
import Actions from "./Actions";

const ResultsHeader = (props) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent className="py-2 px-3">
        <Grid container>
          <Grid item size={12} className="result-header-container">
            <h5 className="page-title float-left">{t("Results")} </h5>
            <div className="float-right">
              <Actions {...props} />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ResultsHeader;
