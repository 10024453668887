import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

export const CustomTooltip = ({ children, placement, title }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      placement={placement}
      title={t(title)}
      arrow
      style={{ cursor: "pointer" }}
    >
      {children}
    </Tooltip>
  );
};
