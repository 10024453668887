import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../../TextError";

function Input(props) {
  const { name, containerStyle, fieldStyle, errorMsgStyle, ...rest } = props;
  return (
    <div className={containerStyle}>
      <Field id={name} name={name} className={fieldStyle} {...rest} />
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default Input;
