import {GENERAL_ATTRIBUTES} from '../constants'
import {filterJson} from '../../Helpers'

let initialList=[]

export default (state=initialList,action)=>{
    switch(action.type){
        case GENERAL_ATTRIBUTES:
            let json ={}
            json.callReasonCode = filterJson(action.payload.attributes, "CALL_REASON_CODE")
            json.technicalIssues = filterJson(action.payload.attributes, "TECHNICAL_ISSUES")
            json.quickNotes = filterJson(action.payload.attributes, "QUICK_CCA_NOTES")
           return {...json}
        default:
            return state
    }
}