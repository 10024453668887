import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../../TextError";

function RadioButtonsWithLabel(props) {
  const {
    label,
    containerStyle,
    labelStyle,
    fieldStyle,
    errorMsgStyle,
    name,
    options,
    ...rest
  } = props;
  return (
    <div className={containerStyle}>
      <label className={labelStyle}>{label}</label>
      <Field name={name} className={fieldStyle}>
        {({ field }) => {
          return options.map((option) => {
            return (
              <React.Fragment key={option.key}>
                <input
                  type="radio"
                  id={option.value}
                  {...field}
                  {...rest}
                  value={option.value}
                  checked={field.value === option.value}
                />
                <label htmlFor={option.value}>{option.key}</label>
              </React.Fragment>
            );
          });
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default RadioButtonsWithLabel;
