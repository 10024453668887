import React, { Component } from 'react';
import './Permissions.css';
import ManagePermissions from './ManagePermissions';
import { withTranslation } from "react-i18next";

class Permissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.updateNavBar = this.updateNavBar.bind(this);
    }

    managePermissionsHtml = (props) => {
        const { t } = this.props;
        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-8">
                                <h6 className="page-title">{t("Manage Permissions")}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <ManagePermissions dataFromParent={props} />
                    </div>
                </div>
            </div>
        );
    };

    componentDidMount() {
        if (this.props.statedata.adminDetail.navBar != "Permissions") {
            this.updateNavBar("Permissions");
        }
    }

    updateNavBar(navBar) {
        this.props.handleBreadCrumInAdmin(navBar);
    }


    getPermissionComponent(navBar) {

        let showPermissions = "";
         if(navBar==="Permissions"){
            showPermissions = this.managePermissionsHtml(this.props);
         }    
        return showPermissions;
    }

    render() {
        let showPermissions = this.getPermissionComponent(this.props.statedata.adminDetail.navBar);

        if ((showPermissions == "") &&
            (this.props.location.pathname == "/main/admin/permissions")) {
            showPermissions = this.getPermissionComponent(this.props.statedata.adminDetail.prevNavBar);
        }

        return (
            <React.Fragment>
                {showPermissions}
            </React.Fragment>
        )
    }
}

export default withTranslation()(Permissions);