import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Menu } from "@mui/material";
import {
  Menu as MenuIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@mui/icons-material";
import DownloadResults from "./DownloadResults";
import UploadResults from "./UploadResults";
import { ResultsContext } from "../../../ResultsContext";
import DownloadPdfZip from "./DownloadPdfZip";
import { MarkReviewResult } from "./MarkReviewResults";
import "./action.css";

const Actions = (props) => {
  const { t } = useTranslation();
  const { permissionList, projectDetail } = useContext(ResultsContext);
  const { review_unreview_configuration } = projectDetail;
  const [showResultAction, setResultAction] = useState(null);
  const open = Boolean(showResultAction);
  const handleClick = (event) => {
    setResultAction(event.currentTarget);
  };
  const handleClose = () => {
    setResultAction(null);
  };
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className="resultAction-btn"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon fontSize="small" />}
      >
        <MenuIcon className="mr-2" /> {t("Actions")}
      </Button>
      <Menu
        anchorEl={showResultAction}
        open={open}
        onClose={handleClose}
        className="resultAction-dropdown"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <DownloadResults />
        {permissionList.indexOf("tenant.project.results.create") >= 0 && (
          <UploadResults {...props} />
        )}
        <DownloadPdfZip pdfLogo={props.pdfLogo} />
        {review_unreview_configuration && <MarkReviewResult />}
      </Menu>
    </div>
  );
};

export default Actions;
