import React, { useContext } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { ResultsContext } from "../../../../ResultsContext";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { generateNewResultPdfZip } from "../../../../../../Utility/NewResultPdf";
const DownloadPdfZip = ({ pdfLogo }) => {
  const { t } = useTranslation();
  const { resultsState, projectDetail, resultsDispatch } =
    useContext(ResultsContext);
  const {
    questionList,
    displayTimeZoneOffset,
    selectedResultRow,
    results,
    isSelectAllRow,
    flagsOptionsData,
    downloadPdfList,
  } = resultsState;

  const isIrnUnique = (interviewReferenceNumber, index) => {
    let count = 0;
    const selectedAllRow = selectedResultRow.map(
      (results) => results.interviewResultId
    );
    const selectedResult = results.filter((results) =>
      selectedAllRow.includes(results.interviewResultId)
    );
    for (let i = 0; i < index; i++) {
      if (
        selectedResult[i].interviewReferenceNumber === interviewReferenceNumber
      ) {
        count = ++count;
      }
    }
    if (count > 0) {
      return `${interviewReferenceNumber}_${index.toString()}`;
    } else {
      return interviewReferenceNumber;
    }
  };
  const dispatch = (type, payload) => {
    resultsDispatch({ type: type, payload: payload });
  };

  const getFormatDate = () => {
    const date = moment();
    const formattedDate = date.format("MM_DD_YYYY").toLowerCase();
    return formattedDate;
  };
  const createPdf = async () => {
    const selectedAllRow = selectedResultRow.map(
      (results) => results.interviewResultId
    );
    const selectedResult = results.filter((results) =>
      selectedAllRow.includes(results.interviewResultId)
    );
    const uniqueIds = [...new Set([...selectedAllRow, ...downloadPdfList])];
    let zip = new JSZip();
    Promise.all(
      selectedResult.map(async (result, index) => {
        const res = await generateNewResultPdfZip(
          result,
          questionList,
          projectDetail,
          displayTimeZoneOffset,
          flagsOptionsData,
          pdfLogo
        );
        zip.file(
          `result_${isIrnUnique(result.interviewReferenceNumber, index)}.pdf`,
          res,
          {
            blob: true,
          }
        );
      })
    )
      .then((value) => {
        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, `${projectDetail.name}_${getFormatDate()}.zip`);
        });
        dispatch("is_pdf_download", false);
        dispatch("set_all_interview_result_id", uniqueIds);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleDownloadPdf = () => {
    dispatch("is_pdf_download", true);
    createPdf();
  };
  return (
    <>
      <button
        onClick={handleDownloadPdf}
        className="dropdown-item"
        disabled={!selectedResultRow.length > 0 || isSelectAllRow}
      >
        <span>{t("Download PDFs")}</span>
      </button>
    </>
  );
};

export default DownloadPdfZip;
