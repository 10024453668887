import reportApi from "../../../Services/reportApi";

export const getReportHistory = () => {
  return reportApi.get(`/report-history`);
};

export const getReportTypeList = () => {
  return reportApi.get(`/tabledetails`);
};

export const getProjectList = () => {
  return reportApi.get(`/projectListNew`);
};

export const createReport = (values) => {
  return reportApi.post(`/submit/report`, values);
};

export const getDownloadUrl = (id) => {
  return reportApi.get(`/report/${id}`);
};
