import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalPopUp, Spinner } from "../../../../../../components/Common";
import i18n from "../../../../../../locale/i18n";
import { questionModalData } from "../../../../../../Services/executionService";
import { generateNewResultPdf } from "../../../../../Utility/NewResultPdf";
import { ResultsContext } from "../../../ResultsContext";
import { getRiskLabel } from "../../../ResultsUtils/filterOptions";
import "./question.css";
const QuestionsModal = (props) => {
  const { t } = useTranslation();
  const { resultDetail, show, closeModal } = props;
  const { resultsState, projectDetail } = useContext(ResultsContext);
  const { questionList, displayTimeZoneOffset } = resultsState;
  const [questionData, setQuestionData] = useState([]);
  const { interviewResultId } = resultDetail;
  const findQuestionType = (question) => {
    if (question === "yesno") {
      return "pertinent";
    } else {
      return question;
    }
  };

  useEffect(() => {
    getModalData();
  }, []);

  const getModalData = () => {
    questionModalData(interviewResultId)
      .then((response) => {
        const groupedArray = groupByType(response.data);
        const result = [];
        for (const item of groupedArray) {
          const type = Object.keys(item)[0];
          const value = item[type];
          result.push({ type: type.toLowerCase(), value });
        }
        setQuestionData(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // logic for arranging a question array based on type and index
  const groupByType = (arr) => {
    const grouped = [];
    let currentType = null;
    for (const obj of arr) {
      if (obj.type !== currentType) {
        grouped.push({ [obj.type]: [obj] });
        currentType = obj.type;
      } else {
        grouped[grouped.length - 1][currentType].push(obj);
      }
    }
    return grouped;
  };
  const getBackGroundColor = (overallEvaluation) => {
    switch (overallEvaluation) {
      case "LR":
        return "#008000";
      case "AR":
        return "#92d050";
      case "PR":
        return "#ffca00";
      case "HR":
        return "#ff0000";
      case "NC":
        return "#bcbcbc";
      case "DC":
        return "#6c6b6b";
      case "CM":
        return "#6758f3";
      case "AD":
        return "#1665d8";
      case "NP":
        return "#ffffff";
    }
  };
  return (
    <ModalPopUp
      show={show}
      onHide={closeModal}
      centered
      keyboard={false}
      width={1400}
      title={
        <div closeButton className="questions-header-container ">
          <div className="questions-title-container">
            <div className="d-flex align-items-center">
              <div className="result-irn">
                {t("IRN")} {resultDetail.interviewReferenceNumber}
              </div>
              <div
                className="overall-risk-label"
                style={{
                  backgroundColor: getBackGroundColor(
                    resultDetail.overallEvaluation
                  ),
                  color:
                    resultDetail.overallEvaluation === "NP" ? "#000000" : "",
                  border:
                    resultDetail.overallEvaluation === "NP"
                      ? "1px solid #000000"
                      : "none",
                }}
              >
                {getRiskLabel(resultDetail.overallEvaluation)}
              </div>
            </div>
            <div>
              <FontAwesomeIcon
                key={resultDetail.interviewResultId}
                className="result-pdf-icon"
                icon={faFilePdf}
                onClick={() =>
                  generateNewResultPdf(
                    resultDetail,
                    questionList,
                    projectDetail,
                    displayTimeZoneOffset
                  )
                }
              />
            </div>
          </div>
        </div>
      }
    >
      {!questionData.length > 0 ? (
        <div className="result_loader">
          <Spinner />
        </div>
      ) : (
        <div>
          <h5 className="question-body-title">{i18n.t("Risk Results")}</h5>
          <div className="question-list-container">
            {questionData.map((question, index) => {
              return (
                <div
                  key={question.projectQuestionId}
                  className="questions-main-container"
                >
                  <div className="question-container">
                    <p className="question-type">
                      {findQuestionType(question.type)} {i18n.t("Question")}
                    </p>
                  </div>
                  <div className="answer-container">
                    {question.value.map((questionDetail, innerindex) => {
                      return (
                        <div
                          className="question-wrapper"
                          key={questionDetail.questionNumber}
                        >
                          {question.type == "yesno" ? (
                            <p className="que-text">
                              {i18n.t("Question Text")}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="question-text">
                              {questionDetail.text}
                            </p>

                            <div
                              style={{
                                background: getBackGroundColor(
                                  questionDetail.result
                                ),
                                color:
                                  questionDetail.result === "NP"
                                    ? "#000000"
                                    : "#ffffff",
                                padding: "5px 30px",
                                fontWeight: 700,
                                marginRight: "70px",
                                border:
                                  questionDetail.result === "NP"
                                    ? "1px solid #000000"
                                    : "none",
                              }}
                            >
                              <p>{questionDetail.result}</p>
                            </div>
                          </div>

                          <div>
                            {question.type == "yesno" ? (
                              ""
                            ) : (
                              <p className="que-transcript">
                                {i18n.t("Transcriptions")}
                              </p>
                            )}
                            {questionDetail.transciption}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </ModalPopUp>
  );
};

export default QuestionsModal;
