import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { AddCircleIcon } from "../../../assets/images/icons/customIcons.js";
import { filterJson, getlanguageIdFromcode } from "../../../Helpers/index";
import i18n from "../../../locale/i18n";
import { getlaguageAttribute } from "../../../Services/tenantService";
import * as userPermission from "../../Utility/userPermission.js";
import NewTenant from "./AddTenant";
import EditTenant from "./EditTenant";
import ManageTenant from "./ManageTenant";
import "./Tenant.css";
class Tenant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childNav: "Tenant",
      searchValue: this.props.searchValue,
      currencyOptions: [],
    };
    this.updateNavBar = this.updateNavBar.bind(this);
  }

  searchValue = (e) => {
    this.setState(
      {
        searchValue: e.target.value,
      },
      () => {
        if (this.state.searchValue == "") {
          this.props.handleSearch(e, this.state.searchValue);
        }
      }
    );
  };

  manageTenantHtml = (props) => {
    const { t } = this.props;
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="page-title">{t("Manage Account")}</h6>
              </div>
              <div className="col-lg-5">
                <form
                  onSubmit={(e) =>
                    this.props.handleSearch(e, this.state.searchValue)
                  }
                >
                  <div className="search-custom">
                    <FontAwesomeIcon
                      type="submit"
                      icon={faSearch}
                      className="searchicon"
                      onClick={(e) =>
                        this.props.handleSearch(e, this.state.searchValue)
                      }
                    />
                    <input
                      className="form-control customsearch"
                      name="tablesearch"
                      type="text"
                      value={this.state.searchValue}
                      onChange={(e) => this.searchValue(e)}
                    />
                  </div>
                </form>
              </div>
              {userPermission.isTenantCreateEnabled(
                this.props.statedata.userInfo.rolePermission.permissions
              ) ? (
                <div className="col-lg-1">
                  <div
                    className="tenant-add-button"
                    onClick={() => {
                      this.updateNavBar("New Tenant");
                    }}
                  >
                    <AddCircleIcon className={{ color: "#34AA44" }} />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="card1">
          <div className="card-body1">
            <ManageTenant
              dataFromParent={props}
              handleEditRow={this.handleEditRow}
            />
          </div>
        </div>
      </div>
    );
  };
  createTenantHtml = (props) => {
    const { t } = this.props;
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <h6 className="page-title">{t("Add Account")}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <NewTenant
              dataFromParent={props}
              updateNavBar={this.updateNavBar}
              currencyOptions={this.state.currencyOptions}
            />
          </div>
        </div>
      </div>
    );
  };

  editTenantHtml = (props) => {
    const { t } = this.props;
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <h6 className="page-title">{t("Edit Account")}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <EditTenant
              dataFromParent={props}
              updateNavBar={this.updateNavBar}
              currencyOptions={this.state.currencyOptions}
            />
          </div>
        </div>
      </div>
    );
  };

  componentDidMount() {
    this.loadAttribute();
    this.props.onRef(this);
    if (this.props.statedata.adminDetail.navBar != "Tenant") {
      this.updateNavBar("Tenant");
    }
  }

  updateNavBar(navBar) {
    this.setState({
      childNav: navBar,
    });
    this.props.handleBreadCrumInAdmin(navBar);
  }

  handleEditRow = (row, navBar) => {
    this.setState({
      childNav: navBar,
    });
    this.props.handleEditRow(row, navBar);
  };

  getTenantComponent(navBar) {
    let showTenant = "";

    switch (navBar) {
      case "Tenant":
        showTenant = this.manageTenantHtml(this.props);
        break;
      case "New Tenant":
        showTenant = this.createTenantHtml(this.props);
        break;
      case "Edit Tenant":
        showTenant = this.editTenantHtml(this.props);
        break;
    }

    return showTenant;
  }

  loadAttribute = () => {
    let languagecode = i18n.language;
    let languageId = getlanguageIdFromcode(languagecode);
    getlaguageAttribute(languageId)
      .then((res) => {
        let currencyOptionsFilter = filterJson(
          res.data.tenantAttributes,
          "CURRENCY"
        );
        this.setState({
          currencyOptions: currencyOptionsFilter,
        });
      })
      .catch((err) => {
        console.log("getlaguageAttribute", err);
      });
  };

  render() {
    let showTenant = this.getTenantComponent(this.state.childNav);

    if (
      showTenant == "" &&
      this.props.location.pathname == "/main/admin/tenant"
    ) {
      showTenant = this.getTenantComponent(
        this.props.statedata.adminDetail.prevNavBar
      );
    }

    return <React.Fragment>{showTenant}</React.Fragment>;
  }
}

export default withTranslation()(Tenant);
