import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "./TextError";
import { Spinner, ModalPopUp } from "../../../../../../components/Common";
import { updateNotesMsg } from "../../../../../../Services/executionService";
import { ResultsContext } from "../../../ResultsContext";

const EditNoteMsgModal = (props) => {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation();
  const [editNoteMsg, setEditNoteMsg] = useState(false);
  const [noteMsg, setNoteMsg] = useState("");
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState(t("Notes Updated Successfully"));
  const { resultsState } = useContext(ResultsContext);
  const { resultDetails } = resultsState;

  const {
    interviewReferenceNumber,
    interviewResultId,
    audioQualityControlError,
  } = resultDetails;

  const { showNotesModal, closeNotesModal } = props;

  useEffect(() => {
    setNoteMsg(audioQualityControlError);
  }, []);

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  }, [showToast]);

  const initialValues = {
    noteText: audioQualityControlError,
  };

  const validationSchema = Yup.object({
    noteText: Yup.string().required(t("This field is required")),
  });

  const onSubmit = (values, submitProps) => {
    const noteText = values.noteText.trim();
    if (noteText) {
      setDisableSubmitBtn(true);
      const message = { text: noteText };
      updateNotesMsg(interviewResultId, message)
        .then(() => {
          setNoteMsg(values.noteText);
          setToastMsg(t("Notes Updated Successfully"));
          setEditNoteMsg(false);
          setDisableSubmitBtn(false);
          setShowToast(true);
          closeNotesModal();
        })
        .catch((err) => {
          setToastMsg(err.response?.data?.message);
          setDisableSubmitBtn(false);
          setShowToast(true);
        });
    } else {
      submitProps.setErrors({ noteText: t("This field is required") });
    }
  };

  return (
    <ModalPopUp
      show={showNotesModal}
      onHide={() => closeNotesModal("onlyClosedModal")}
      backdrop={"static"}
      keyboard={false}
      centered
      className="uploadfile-modal"
      title={t("Notes (IRN-") + interviewReferenceNumber + ")"}
    >
      <div>
        <div className="result-note-msg">
          {showToast && (
            <div className="col-12 alert alert-success">{toastMsg}</div>
          )}

          {editNoteMsg ? (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {(formik) => {
                return (
                  <Form>
                    <Field
                      rows="3"
                      component="textarea"
                      id="noteText"
                      name="noteText"
                    />
                    <ErrorMessage name="noteText" component={TextError} />
                    <div className="col-lg-12 mt-2 d-flex justify-content-end align-items-center">
                      <button
                        type="submit"
                        className="btn btn-primary mr-2 save-modal-btn d-flex align-items-center justify-content-center"
                        disabled={disableSubmitBtn || !formik.isValid}
                      >
                        {disableSubmitBtn ? (
                          <>
                            {t("Save")}...
                            <div className="custom-loader linesLoader loader_inner_button">
                              <Spinner color="#ffffff" size={"19px"} />
                            </div>
                          </>
                        ) : (
                          <>{t("Save")}</>
                        )}
                      </button>
                      <button
                        className="btn btn-secondary save-modal-btn"
                        onClick={() => setEditNoteMsg(false)}
                      >
                        {t("Cancel")}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <>
              <p className="space-wrap">{noteMsg}</p>
              {userInfo.tenant.isSystemTenant !== null &&
              userInfo.tenant.isSystemTenant ? (
                <span onClick={() => setEditNoteMsg(true)}>
                  <FontAwesomeIcon
                    className="edit-note-msg-btn"
                    icon={faEdit}
                  />
                </span>
              ) : null}
            </>
          )}
        </div>
      </div>
    </ModalPopUp>
  );
};
export default EditNoteMsgModal;
