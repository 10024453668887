import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: require("./eng/en.json"),
    es: require("./spa/de.json"),
    tl: require("./tag/tl.json"),
};
var langid = localStorage.getItem('langid')!= null ? localStorage.getItem('langid') : "en"

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "en",
        debug: true,
        lng: langid,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            bindI18n: "languageChanged"
        }
    });

export default i18n;