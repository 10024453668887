import React, { Component } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Role.css";
import ManageRoles from "./ManageRoles";
import CreateRoles from "./CreateRoles";
import { AddCircleIcon } from "../../../assets/images/icons/customIcons.js";
import { withTranslation } from "react-i18next";
import { isRoleCreateEnabled } from "../../Utility/userPermission";

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childNav: "Roles",
      searchValue: "",
      currentPage: 0,
    };
    this.updateNavBar = this.updateNavBar.bind(this);
  }

  manageRolesHtml = (props) => {
    const { t } = this.props;

    const userPermissionVal =
      this.props.statedata?.userInfo?.rolePermission?.permissions;
    const canUserCreateRole = isRoleCreateEnabled(userPermissionVal);

    return (
      <div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6">
                <h6 className="page-title">{t("Manage Roles")}</h6>
              </div>
              <div className="col-lg-5">
                <div className="search-custom">
                  <FontAwesomeIcon
                    type="submit"
                    icon={faSearch}
                    className="searchicon"
                  />
                  <input
                    className="form-control customsearch"
                    name="tablesearch"
                    type="text"
                    value={this.state.searchValue}
                    onChange={(e) => {
                      this.setState({
                        searchValue: e.target.value,
                        currentPage: 0,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-1">
                {canUserCreateRole && (
                  <div
                    className="tenant-add-button"
                    onClick={() => {
                      this.updateNavBar("Create Roles");
                    }}
                  >
                    <AddCircleIcon className={{ color: "#34AA44" }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card1">
          <div className="card-body1">
            <ManageRoles
              dataFromParent={props}
              searchValue={this.state.searchValue}
              currentPage={this.state.currentPage}
            />
          </div>
        </div>
      </div>
    );
  };
  createRolesHtml = (props) => {
    const { t } = this.props;
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <h6 className="page-title">{t("Create Roles")}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <CreateRoles
              dataFromParent={props}
              updateNavBar={this.updateNavBar}
            />
          </div>
        </div>
      </div>
    );
  };

  componentDidMount() {
    this.props.onRef(this);
    if (this.props.statedata.adminDetail.navBar != "Roles") {
      this.updateNavBar("Roles");
    }
  }

  updateNavBar(navBar) {
    this.setState({
      childNav: navBar,
    });
    this.props.handleBreadCrumInAdmin(navBar);
  }

  getRoleComponent(navBar) {
    let showRoles = "";

    switch (navBar) {
      case "Roles":
        showRoles = this.manageRolesHtml(this.props);
        break;
      case "Create Roles":
        showRoles = this.createRolesHtml(this.props);
        break;
    }

    return showRoles;
  }

  render() {
    let showRoles = this.getRoleComponent(this.state.childNav);

    if (
      showRoles == "" &&
      this.props.location.pathname == "/main/admin/roles"
    ) {
      showRoles = this.getRoleComponent(
        this.props.statedata.adminDetail.prevNavBar
      );
    }
    return <React.Fragment>{showRoles}</React.Fragment>;
  }
}

export default withTranslation()(Roles);
