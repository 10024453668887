import React from "react";
import { Spinner } from "../../components/Common";
import { useTranslation } from "react-i18next";

const FullScreenLoader = ({ message }) => {
  const { t } = useTranslation();

  return (
    <div className="file_upload_loader_full_screen">
      <div className="custom-loader">
        <Spinner />
      </div>
      <p> {t(`${message}`)} ...</p>
    </div>
  );
};

export default FullScreenLoader;
