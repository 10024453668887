import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalPopUp, Spinner } from "../../../../../../components/Common";
import { markReviewResult } from "../../../../../../Services/executionService";
import { ResultsContext } from "../../../ResultsContext";
export const MarkReviewModal = (props) => {
  const { showReviewModal, closeReviewModal } = props;
  const { resultsState, resultsDispatch, projectDetail } =
    useContext(ResultsContext);
  const { projectId } = projectDetail;
  const { markReviewAction, selectMarkReviewResult, isMarkReview } =
    resultsState;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const selectMarkedResult = selectMarkReviewResult
    .filter(({ reviewed }) => reviewed)
    .map((result) => result.interviewResultId);
  const selectUnMarkedResult = selectMarkReviewResult
    .filter(({ reviewed }) => !reviewed)
    .map((result) => result.interviewResultId);
  const selectMarkResult =
    markReviewAction === "Reviewed" ? selectUnMarkedResult : selectMarkedResult;

  const markReviewPayload = {
    action: markReviewAction,
    projectId: projectId,
    interviewResultIds: selectMarkResult,
  };
  const dispatch = (type, payload) => {
    resultsDispatch({ type: type, payload: payload });
  };

  const showResponseMessage = (data, variant, status) => {
    dispatch("set_alert_msg", data);
    dispatch("set_variant", variant);
    dispatch("set_alert_status", status);
  };

  const handleSubmitMark = () => {
    setLoading(true);
    markReviewResult(markReviewPayload)
      .then((res) => {
        setLoading(false);
        showResponseMessage(res.data, "success", true);
        dispatch("result_review_modal_show", false);
        dispatch("set_result_review_mark", isMarkReview ? false : true);
        dispatch("selected_result_row", []);
      })
      .catch((error) => {
        setLoading(false);
        let errMsg = error.response?.data.message;
        showResponseMessage(errMsg, "danger", true);
        dispatch("result_review_modal_show", false);
        dispatch("selected_result_row", []);
      });
  };

  return (
    <ModalPopUp
      show={showReviewModal}
      onHide={closeReviewModal}
      centered
      className="uploadfile-modal"
      title={
        markReviewAction === "Reviewed"
          ? t("REVIEW_CONFIRMATION")
          : t("UNREVIEW_CONFIRMATION")
      }
    >
      <div>
        <div className="p-1">
          <div className="d-flex">
            <div className="review-list"></div>
            <div>
              {markReviewAction === "Reviewed" ? (
                <>
                  {selectUnMarkedResult.length > 0 && (
                    <p>
                      {`${selectUnMarkedResult.length} ${t(
                        "MARK_REVIEWED_MESSAGE"
                      )}`}
                    </p>
                  )}
                  {selectMarkedResult.length > 0 && (
                    <p className="np-modal-text">{`${
                      selectMarkedResult.length
                    } ${t("NO_ACTION_IRN_MESSAGE")}`}</p>
                  )}
                </>
              ) : (
                <>
                  {selectMarkedResult.length > 0 && (
                    <p>
                      {`${selectMarkedResult.length} ${t(
                        "MARK_UNREVIEWED_MESSAGE"
                      )}`}
                    </p>
                  )}
                  {selectUnMarkedResult.length > 0 && (
                    <p className="np-modal-text">{`${
                      selectUnMarkedResult.length
                    } ${t("NO_ACTION_IRN_MESSAGE")}`}</p>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center mt-2">
            <button
              className="btn btn-primary d-flex align-items-center justify-content-center text-uppercase"
              onClick={handleSubmitMark}
            >
              {loading ? (
                <>
                  <div className="custom-loader linesLoader loader_inner_button">
                    <Spinner color="#ffffff" size={"19px"} />
                  </div>
                </>
              ) : (
                <>{t("OK")}</>
              )}
            </button>
            <button
              onClick={closeReviewModal}
              className="btn text-uppercase cancel_button"
            >
              {t("Cancel")}
            </button>
          </div>
        </div>
      </div>
    </ModalPopUp>
  );
};
