import React from "react";
import { BsArrowRight } from "react-icons/bs";

const ArrowIndicator = () => {
  const arrowColor = "#0169D9";

  return (
    <div className="arrow-icon">
      <BsArrowRight size={22} color={arrowColor} />
    </div>
  );
};

export default ArrowIndicator;
