import React from "react";
import { Box, IconButton, Alert as MuiAlert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function Alert({ show, variantType, msg = "", close }) {
  return (
    <>
      {show && msg !== "" && (
        <Box sx={{ width: "100%" }}>
          <MuiAlert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  close();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            severity={variantType !== "danger" ? variantType : "error"}
            variant="filled"
            sx={{ mb: 1, padding: "1px 16px" }}
          >
            {msg}
          </MuiAlert>
        </Box>
      )}
    </>
  );
}
