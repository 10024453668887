import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "./useDebounce"; // Adjust the import path accordingly

function SearchAccessCode({ handleSearchAccessCode }) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const debouncedInputValue = useDebounce(inputValue, 1500); // 1500 milliseconds debounce delay

  useEffect(() => {
    handleSearchAccessCode(debouncedInputValue);
  }, [debouncedInputValue]);

  return (
    <div className="position-relative">
      <input
        className="form-control"
        type="text"
        placeholder={t("Search IRN and Access Code")}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </div>
  );
}

export default SearchAccessCode;
