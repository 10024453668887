import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../../TextError";

function TextAreaWithLabel(props) {
  const {
    label,
    name,
    containerStyle,
    labelStyle,
    fieldStyle ,
    errorMsgStyle,
    ...rest
  } = props;
  return (
    <div className={containerStyle}>
      <label htmlFor={name} className={labelStyle}>
        {label}
      </label>
      <Field
        as="textarea"
        className={fieldStyle}
        id={name}
        name={name}
        {...rest}
      />
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default TextAreaWithLabel;
