import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import Signout from "../../Signout";

export default function UserDropdown(props) {
  const { userName, userLogo, userIcon, themeSwitch, t, url, themeColor } =
    props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <>
      <Tooltip title={userName} placement="bottom">
        <IconButton onClick={handleMenuOpen}>
          {userLogo ? (
            <span
              style={{
                width: 38,
                height: 38,
                display: "block",
                backgroundImage: `url(data:image/png;base64,${userLogo})`,
                backgroundSize: "cover",
                borderRadius: "50%",
              }}
            ></span>
          ) : (
            <img src={userIcon} width="38" height="38" alt="User" />
          )}
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={handleMenuClose}
          component={Link}
          to="/main/userprofile"
        >
          {t("Profile")}
        </MenuItem>
        <MenuItem
          onClick={handleMenuClose}
          component={Link}
          to={`${url}/about`}
        >
          {t("About")}
        </MenuItem>
        <MenuItem>
          <div className="theme_appearance">
            <div className="theme_appearance_text">{t("Theme")}</div>
            <div className="theme_appearance_switch">
              <div className="theme_change">
                <div className="theme_switch">
                  <input
                    type="checkbox"
                    className="switch__input"
                    id="theme_switch_id"
                    defaultChecked={themeColor === "light"}
                    onChange={themeSwitch}
                  />
                  <label className="switch__label" htmlFor="theme_switch_id">
                    <span className="switch__indicator"></span>
                    <span className="switch__decoration"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </MenuItem>
        <Signout />
      </Menu>
    </>
  );
}
