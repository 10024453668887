import React from "react";
import { useTranslation } from "react-i18next";
import {
  Stepper as MuiStepper,
  Step as MuiStep,
  StepLabel as MuiStepLabel,
  IconButton,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const Stepper = ({
  activeStep,
  stepsLabel,
  isStepCompleted,
  stepCompleted,
}) => {
  const { t } = useTranslation();

  const customStepIcon = ({ active, completed, icon }) => {
    return (
      <div
        className={`custom-step-icon ${active && "current-step"} ${
          completed && "completed-step"
        }`}
      >
        <div>
          <div>{icon}</div>
        </div>
      </div>
    );
  };

  return (
    <MuiStepper activeStep={activeStep} alternativeLabel>
      {stepsLabel.map((label, index) => {
        return (
          <MuiStep key={label} completed={isStepCompleted(index)}>
            <MuiStepLabel icon={index + 1} StepIconComponent={customStepIcon}>
              {stepCompleted[index] && (
                <CheckCircleOutlineIcon className="complete-step-icon" />
              )}
              {t(label)}
            </MuiStepLabel>
          </MuiStep>
        );
      })}
    </MuiStepper>
  );
};

export default Stepper;
