import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../../TextError";

function Textarea(props) {
  const {
    name,
    containerStyle,
    fieldStyle,
    errorMsgStyle,
    ...rest
  } = props;
  return (
    <div className={containerStyle}>
      <Field
        as="textarea"
        id={name}
        name={name}
        className={fieldStyle}
        {...rest}
      />
      <ErrorMessage
        component={TextError}
        name={name}
      />
    </div>
  );
}

export default Textarea;
