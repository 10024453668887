export const TOGGLE_PASS = "TOGGLE_PASS";
export const LOG_OUT = "LOG_OUT";
export const LOG_IN = "LOG_IN";
export const LOG_ERROR = "LOG_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

// projects action constants
export const GET_PROJECTS = "GET_PROJECTS";
export const ALERT_CLOSE = "ALERT_CLOSE";
export const DATA_LOADING = "DATA_LOADING";
export const IS_STAGE_SETUP = "IS_STAGE_SETUP";

//INTERVIEW SCREENS
export const INTERVIEW_LIST = "INTERVIEW_LIST";
export const TOTAL_INTERVIEW = "TOTAL_INTERVIEW";
export const INTERVIEW_REVIEW = "INTERVIEW_REVIEW";
export const GENERAL_ATTRIBUTES = "GENERAL_ATTRIBUTES";
export const IS_UPLOADED = "IS_UPLOADED";

//USERINFO
export const USER_INFO = "USER_INFO";

// USERTHEME
export const THEME_INFO = "THEME_INFO";

//PARTICIPANT
export const SMS_MESSAGE = "SMS_MESSAGE";
export const SMS_MESSAGE_ERROR = "SMS_MESSAGE_ERROR";
export const SMS_ALERT_CLOSE = "SMS_ALERT_CLOSE";
export const IS_PROJECTCODE_EXIST = "IS_PROJECTCODE_EXIST";
export const IS_PROJECTCODE_NOT_EXIST = "IS_PROJECTCODE_NOT_EXIST";
export const ACCORDIAN_UUID = "ACCORDIAN_UUID";
export const MULTI_ACCORDIAN_UUID = "MULTI_ACCORDIAN_UUID";
export const PROJECT_CODE_ERRORS = "PROJECT_CODE_ERRORS";
export const HEADER_LOGO = "HEADER_LOGO";
export const PROJECT_PHONE_NUMBER_ERROR = "PROJECT_PHONE_NUMBER_ERROR";
