import React from "react";
import { ModalPopUp } from "../../../../../components/Common";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/adventure_time.css";
const UploadedResult = (props) => {
  const { isOpen, uploadedResultData, t, close } = props;
  let JSONPrettyMon = require("react-json-pretty/themes/adventure_time.css");
  return (
    <ModalPopUp
      show={isOpen}
      onHide={close}
      dialogClassName="participant-detail-container"
      contentClassName="participant-detail-container-height"
      centered
      title={t("Particiant Detail")}
    >
      <div>
        <pre>
          <JSONPretty
            className="participant-json-pretty"
            theme={JSONPrettyMon}
            data={
              uploadedResultData != null
                ? uploadedResultData.uploadedResult
                : "NO Data Found"
            }
          ></JSONPretty>
        </pre>
      </div>
    </ModalPopUp>
  );
};
export default UploadedResult;
