import { toast } from "react-toastify";

const useCustomSnackbar = () => {
  const showSnackbar = (message, variant, options = {}) => {
    const defaultOptions = {
      position: "top-right",
      autoClose: 10000,
      type: variant,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
      ...options,
    };

    toast(message, defaultOptions);
  };

  return showSnackbar;
};

export default useCustomSnackbar;
