import React from "react";
import { useTranslation } from "react-i18next";
import { ModalPopUp, Spinner } from "../../../components/Common";
export const MarkNpModal = (props) => {
  const {
    show,
    onClose,
    npAction,
    loading,
    handleSubmit,
    selectedNpParticipant,
  } = props;
  const { t } = useTranslation();
  const invalidStatuses = [
    "RESULT_PUBLISHED",
    "INTERVIEWED",
    "ARCHIVED",
    "ATTEMPTED_INCOMPLETE",
    "ATTEMPTED_PARTIAL",
    "UNDELIVERED",
  ];

  const invalidForNP = selectedNpParticipant.filter((participant) =>
    invalidStatuses.includes(participant.status)
  );

  const validForNP = selectedNpParticipant.filter(
    (participant) => !invalidStatuses.includes(participant.status)
  );

  const selectedNP = validForNP.filter(
    (participant) => participant.status === "NON_RESPONSIVE_PARTICIPANT"
  );
  const selectedNotNP = validForNP.filter(
    (participant) => participant.status !== "NON_RESPONSIVE_PARTICIPANT"
  );

  return (
    <ModalPopUp
      show={show}
      centered
      onHide={onClose}
      title={
        npAction === "REVERSE_NP"
          ? t("CONFIRMATION_REVERSE_NON_RESPONSIVE_PARTICIPANT")
          : t("CONFIRMATION_NON_RESPONSIVE_PARTICIPANT")
      }
    >
      <div>
        <div className="p-1">
          <div>
            {npAction === "NP" ? (
              <>
                {selectedNotNP.length > 0 && (
                  <p className="review-modal-text">{`${
                    selectedNotNP.length
                  } ${t("MARK_NON_PARTICIPANT_MESSAGE")}`}</p>
                )}
                {selectedNP.length > 0 || invalidForNP.length > 0 ? (
                  <p className="np-modal-text">{`${
                    selectedNP.length + invalidForNP.length
                  } ${t("NO_ACTION_IRN_MESSAGE")}`}</p>
                ) : null}
              </>
            ) : (
              <>
                {selectedNP.length > 0 && (
                  <p className="review-modal-text">{`${selectedNP.length} ${t(
                    "MARK_REVERSE_NON_PARTICIPANT_MESSAGE"
                  )}`}</p>
                )}
                {selectedNotNP.length > 0 || invalidForNP.length > 0 ? (
                  <p className="np-modal-text">{`${
                    selectedNotNP.length + invalidForNP.length
                  } ${t("NO_ACTION_IRN_MESSAGE")}`}</p>
                ) : null}
              </>
            )}
          </div>
          <div className="d-flex justify-content-end align-items-center mt-2">
            <button
              className="btn btn-primary d-flex align-items-center justify-content-center text-uppercase"
              onClick={handleSubmit}
            >
              {loading ? (
                <>
                  <div className="custom-loader linesLoader loader_inner_button">
                    <Spinner color="#ffffff" size={"19px"} />
                  </div>
                </>
              ) : (
                <>{t("OK")}</>
              )}
            </button>
            <button
              className="btn text-uppercase cancel_button"
              onClick={onClose}
            >
              {t("Cancel")}
            </button>
          </div>
        </div>
      </div>
    </ModalPopUp>
  );
};
