import { Server_Restriction } from "../config";
import api from "./api";

const getTenantList = async (page, pageSize, searchValue, sortOrder) => {
  return await api.get(
    `user-service/v1/tenant/list?pageNumber=${page}&pageSize=${pageSize}&search=${searchValue}&tenantName=${sortOrder.tenantName}&date=${sortOrder.createTs}`
  );
};

const getTenant = async (id) => {
  let result = await api.get(`user-service/v1/tenant/${id}`);
  return result;
};
export const getlaguageAttribute = (id) => {
  // let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return api.get(`user-service/v1/tenant/attributes`, {
    params: {
      language_id: id,
    },
  });
};

const createTenant = async (param, isRestrictionsFieldEnable) => {
  let form = new FormData();
  form.append("tenantName", param.accountName);
  form.append("userFirstName", param.firstName);
  form.append("userLastName", param.lastName);
  form.append("email", param.primaryUserEmail);
  form.append("phoneNumber", param.primaryUserPhone);
  form.append("customerCode", param.backendSystemCode);
  form.append("isActive", param.status);
  form.append("salesPerson", param.salesPerson);
  form.append("accountTypeId", param.accountTypeId);
  form.append("customerSegmentId", param.customerSegmentId);
  form.append("customerId", param.customerId);
  form.append("backendSystemCode", param.backendSystemCode);
  form.append("numberOfInterviews", param.numberOfInterviews);
  form.append("greenhouseAccount", param.greenhouseAccount);
  form.append("greenhouseAppKey", param.greenhouseAppKey);
  form.append("accountAPIKey", param.accountAPIKey);
  form.append("partnerAccount", param.partnerAccount);
  form.append("currencyId", param.currencyId);
  if (isRestrictionsFieldEnable) {
    form.append("restrictionId", param.restrictionId);
  }
  if (param.tenantProfileImage != null) {
    form.append("tenantLogo", param.tenantProfileImage);
  }
  return api.post(`user-service/v1/tenant/`, form);
};

const updateTenant = async (param, tenantId, isRestrictionsFieldEnable) => {
  let form = new FormData();
  form.append("tenantName", param.accountName);
  form.append("customerCode", param.backendSystemCode);
  form.append("isActive", param.status);
  form.append("salesPerson", param.salesPerson);
  form.append("accountTypeId", param.accountTypeId);
  form.append("customerSegmentId", param.customerSegmentId);
  form.append("customerId", param.customerId);
  form.append("backendSystemCode", param.backendSystemCode);
  form.append("numberOfInterviews", param.numberOfInterviews);
  form.append("greenhouseAccount", param.greenhouseAccount);
  form.append("greenhouseAppKey", param.greenhouseAppKey);
  form.append("accountAPIKey", param.accountAPIKey);
  form.append("partnerAccount", param.partnerAccount);
  form.append("currencyId", param.currencyId);
  if (isRestrictionsFieldEnable) {
    form.append("restrictionId", param.restrictionId);
  }
  if (param.tenantProfileImage != null && param.logoUpdated) {
    form.append("tenantLogo", param.tenantProfileImage);
  }

  return api.put(`user-service/v1/tenant/${tenantId}`, form);
};

export const refreshCustifyCompanies = async (tenantId) => {
  return await api.post(
    `project-service/custify/companies?tenantId=${tenantId}`
  );
};
export const refreshCustifyPeople = async (tenantId) => {
  return await api.post(`project-service/custify/people?tenantId=${tenantId}`);
};

const verifyEmail = (email) => {
  return api
    .post(`project-service/v1/project/check/user-email?email=${email}`)
    .then((res) => res.data)
    .catch((error) => error);
};

export { getTenantList, getTenant, createTenant, updateTenant, verifyEmail };
