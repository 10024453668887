import React, { useState, useEffect } from "react";
import {
  Select,
  Checkbox,
  OutlinedInput,
  MenuItem,
  FormControl,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: "menu",
};

export const MultiSelectDrpDownWithAll = (props) => {
  const {
    options,
    setSelectedOption,
    placeholderButtonLabel,
    resetFilter,
    defaultSelectAll,
  } = props;
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  useEffect(() => {
    const selectedAll = options.map((item) => item.value);
    defaultSelectAll ? setSelected(selectedAll) : setSelected([]);
  }, [resetFilter]);

  const getDropdownButtonLabel = (value) => {
    if (value.length === 0) {
      return (
        <span className="select__placeholder">{placeholderButtonLabel}</span>
      );
    } else if (value.length === options.length) {
      return (
        <span className="select__placeholder">{`${placeholderButtonLabel}: All`}</span>
      );
    } else {
      return (
        <span className="select__placeholder">{`${placeholderButtonLabel}: ${value.length}`}</span>
      );
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      const selectedAll = options.map((item) => item.value);
      setSelected(selected?.length === options?.length ? [] : selectedAll);
      setSelectedOption(
        selected?.length === options?.length ? [] : selectedAll
      );
      return;
    }
    setSelected(value);
    setSelectedOption(value);
  };

  const CustomArrowIcon = (props) => {
    return <ExpandMoreIcon {...props} fontSize="17px" />;
  };

  return (
    <FormControl sx={{ width: 156, marginRight: "6px" }} size="small">
      <Select
        multiple
        value={selected}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => getDropdownButtonLabel(selected)}
        displayEmpty
        MenuProps={MenuProps}
        IconComponent={CustomArrowIcon}
        sx={{ height: 38 }}
      >
        <MenuItem value="all">
          <ListItemIcon>
            <Checkbox
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText primary="All" />
        </MenuItem>
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <Checkbox checked={selected.includes(item.value)} />
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
