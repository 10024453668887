import React from "react";
import { useTranslation } from "react-i18next";
import { findTemplateRiskLevelFlag } from "../../../../Helpers";

export const riskOption = (data) => {
  const { t } = useTranslation();
  const overallHighRisk =
    data && findTemplateRiskLevelFlag(data, "overall", "High Risk");
  const overallPotentialRisk =
    data && findTemplateRiskLevelFlag(data, "overall", "Potential Risk");
  const overallAverageRisk =
    data && findTemplateRiskLevelFlag(data, "overall", "Average Risk");
  const overallLowRisk =
    data && findTemplateRiskLevelFlag(data, "overall", "Low Risk");
  const overallNotCompleted =
    data && findTemplateRiskLevelFlag(data, "overall", "Not Completed");
  const overallDisconnected =
    data && findTemplateRiskLevelFlag(data, "overall", "Disconnected");
  const overallNonResponsive =
    data && findTemplateRiskLevelFlag(data, "overall", "Non-Responsive");
  // console.log("Check response", overallHighRisk, overallPotentialRisk);
  const response = [
    // { label: t("All"), value: "*" },
    {
      value: "HR",
      label: (
        <>
          {overallHighRisk ? (
            <>
              <div className="dropdown_template_risk_level_flag">
                <img src={overallHighRisk} alt="Not Found" />
                <span>{t("High Risk")}</span>
              </div>
            </>
          ) : (
            <>
              <span
                className="filter_risk_HR"
                dangerouslySetInnerHTML={{ __html: "HR" }}
              />
              {t("High Risk")}
            </>
          )}
        </>
      ),
    },
    {
      value: "LR",
      label: (
        <>
          {overallLowRisk ? (
            <>
              <div className="dropdown_template_risk_level_flag">
                <img src={overallLowRisk} alt="Not Found" />
                <span>{t("Low Risk")}</span>
              </div>
            </>
          ) : (
            <>
              <span
                className="filter_risk_LR"
                dangerouslySetInnerHTML={{ __html: "LR" }}
              />
              {t("Low Risk")}
            </>
          )}
        </>
      ),
    },
    {
      value: "AR",
      label: (
        <>
          {overallAverageRisk ? (
            <>
              <div className="dropdown_template_risk_level_flag">
                <img src={overallAverageRisk} alt="Not Found" />
                <span>{t("Average Risk")}</span>
              </div>
            </>
          ) : (
            <>
              <span
                className="filter_risk_AR"
                dangerouslySetInnerHTML={{ __html: "AR" }}
              />
              {t("Average Risk")}
            </>
          )}
        </>
      ),
    },
    {
      value: "PR",
      label: (
        <>
          {overallPotentialRisk ? (
            <>
              <div className="dropdown_template_risk_level_flag">
                <img src={overallPotentialRisk} alt="Not Found" />
                <span>{t("Potential Risk")}</span>
              </div>
            </>
          ) : (
            <>
              <span
                className="filter_risk_PR"
                dangerouslySetInnerHTML={{ __html: "PR" }}
              />
              {t("Potential Risk")}
            </>
          )}
        </>
      ),
    },
    {
      value: "PD",
      label: (
        <>
          <span
            className="filter_risk_PD"
            dangerouslySetInnerHTML={{ __html: "PD" }}
          />
          {t("Pending")}
        </>
      ),
    },
    {
      value: "NC",
      label: (
        <>
          {overallNotCompleted ? (
            <>
              <div className="dropdown_template_risk_level_flag">
                <img src={overallNotCompleted} alt="Not Found" />
                <span>{t("Non-Compliant")}</span>
              </div>
            </>
          ) : (
            <>
              <span
                className="filter_risk_NC"
                dangerouslySetInnerHTML={{ __html: "NC" }}
              />
              {t("Non-Compliant")}
            </>
          )}
        </>
      ),
    },
    {
      value: "DC",
      label: (
        <>
          {overallDisconnected ? (
            <>
              <div className="dropdown_template_risk_level_flag">
                <img src={overallDisconnected} alt="Not Found" />
                <span>{t("Disconnected")}</span>
              </div>
            </>
          ) : (
            <>
              <span
                className="filter_risk_DC"
                dangerouslySetInnerHTML={{ __html: "DC" }}
              />
              {t("Disconnected")}
            </>
          )}
        </>
      ),
    },
    {
      value: "NP",
      label: (
        <>
          {overallNonResponsive ? (
            <>
              <div className="dropdown_template_risk_level_flag">
                <img src={overallNonResponsive} alt="Not Found" />
                <span>{t("NON_RESPONSIVE_PARTICIPANT")}</span>
              </div>
            </>
          ) : (
            <>
              <span
                className="filter_risk_NP"
                dangerouslySetInnerHTML={{ __html: "NP" }}
              />
              {t("NON_RESPONSIVE_PARTICIPANT")}
            </>
          )}
        </>
      ),
    },
  ];
  return response;
};
export const riskOptionForRedGreenQuestionnaire = (data) => {
  const { t } = useTranslation();
  const overallRed = data && findTemplateRiskLevelFlag(data, "overall", "Red");
  const overallGreen =
    data && findTemplateRiskLevelFlag(data, "overall", "Green");
  const overallNotCompleted =
    data && findTemplateRiskLevelFlag(data, "overall", "Not Completed");
  const overallDisconnected =
    data && findTemplateRiskLevelFlag(data, "overall", "Disconnected");
  const overallNonResponsive =
    data && findTemplateRiskLevelFlag(data, "overall", "Non-Responsive");
  const response = [
    // { label: t("All"), value: "*" },
    {
      value: "R",
      label: (
        <>
          {overallRed ? (
            <>
              <div className="dropdown_template_risk_level_flag">
                <img src={overallRed} alt="Not Found" />
                <span>{t("Red")}</span>
              </div>
            </>
          ) : (
            <>
              <span
                className="filter_risk_R"
                dangerouslySetInnerHTML={{ __html: "R" }}
              />
              {t("Red")}
            </>
          )}
        </>
      ),
    },
    {
      value: "G",
      label: (
        <>
          {overallGreen ? (
            <>
              <div className="dropdown_template_risk_level_flag">
                <img src={overallGreen} alt="Not Found" />
                <span>{t("Green")}</span>
              </div>
            </>
          ) : (
            <>
              <span
                className="filter_risk_G"
                dangerouslySetInnerHTML={{ __html: "G" }}
              />
              {t("Green")}
            </>
          )}
        </>
      ),
    },
    {
      value: "NC",
      label: (
        <>
          {overallNotCompleted ? (
            <>
              <div className="dropdown_template_risk_level_flag">
                <img src={overallNotCompleted} alt="Not Found" />
                <span>{t("Non-Compliant")}</span>
              </div>
            </>
          ) : (
            <>
              <span
                className="filter_risk_NC"
                dangerouslySetInnerHTML={{ __html: "NC" }}
              />
              {t("Non-Compliant")}
            </>
          )}
        </>
      ),
    },
    {
      value: "DC",
      label: (
        <>
          {overallDisconnected ? (
            <>
              <div className="dropdown_template_risk_level_flag">
                <img src={overallDisconnected} alt="Not Found" />
                <span>{t("Disconnected")}</span>
              </div>
            </>
          ) : (
            <>
              <span
                className="filter_risk_DC"
                dangerouslySetInnerHTML={{ __html: "DC" }}
              />
              {t("Disconnected")}
            </>
          )}
        </>
      ),
    },
    {
      value: "NP",
      label: (
        <>
          {overallNonResponsive ? (
            <>
              <div className="dropdown_template_risk_level_flag">
                <img src={overallNonResponsive} alt="Not Found" />
                <span>{t("NON_RESPONSIVE_PARTICIPANT")}</span>
              </div>
            </>
          ) : (
            <>
              <span
                className="filter_risk_NP"
                dangerouslySetInnerHTML={{ __html: "NP" }}
              />
              {t("NON_RESPONSIVE_PARTICIPANT")}
            </>
          )}
        </>
      ),
    },
  ];
  return response;
};

export const questionRiskOption = (data) => {
  const { t } = useTranslation();
  const questionsCounterMeasure =
    data && findTemplateRiskLevelFlag(data, "questions", "Counter Measure");
  const questionsAdmission =
    data && findTemplateRiskLevelFlag(data, "questions", "Admission");
  const questionsNotCompleted =
    data && findTemplateRiskLevelFlag(data, "questions", "Not Completed");
  const response = [
    // { label: t("All"), value: "*" },
    {
      value: "CM",
      label: (
        <>
          {questionsCounterMeasure ? (
            <>
              <div className="dropdown_template_risk_level_flag">
                <img src={questionsCounterMeasure} alt="Not Found" />
                <span>{t("Counter Measure")}</span>
              </div>
            </>
          ) : (
            <>
              <span
                className="filter_risk_CM"
                dangerouslySetInnerHTML={{ __html: "CM" }}
              />
              {t("Counter Measure")}
            </>
          )}
        </>
      ),
      checked: true,
    },
    {
      value: "AD",
      label: (
        <>
          {questionsAdmission ? (
            <>
              <div className="dropdown_template_risk_level_flag">
                <img src={questionsAdmission} alt="Not Found" />
                <span>{t("Admission")}</span>
              </div>
            </>
          ) : (
            <>
              <span
                className="filter_risk_AD"
                dangerouslySetInnerHTML={{ __html: "AD" }}
              />
              {t("Admission")}
            </>
          )}
        </>
      ),
    },
    {
      value: "NC",
      label: (
        <>
          {questionsNotCompleted ? (
            <>
              <div className="dropdown_template_risk_level_flag">
                <img src={questionsNotCompleted} alt="Not Found" />
                <span>{t("Non-Compliant")}</span>
              </div>
            </>
          ) : (
            <>
              <span
                className="filter_risk_NC"
                dangerouslySetInnerHTML={{ __html: "NC" }}
              />
              {t("Non-Compliant")}
            </>
          )}
        </>
      ),
    },
  ];
  return response;
};

export const getRiskLabel = (initial) => {
  if (initial === "AD") {
    return "Admission";
  }
  if (initial === "NC") {
    return "Non-Compliant";
  }
  if (initial === "HR") {
    return "High Risk";
  }
  if (initial === "PR") {
    return "Potential Risk";
  }
  if (initial === "AR") {
    return "Average Risk";
  }
  if (initial === "LR") {
    return "Low Risk";
  }
  if (initial === "DC") {
    return "Disconnected";
  }
  if (initial === "PD") {
    return "Pending";
  }
  if (initial === "NP") {
    return "Non Responsive Participant";
  }
  if (initial === "Incomplete") {
    return "Incomplete";
  } else {
    return "";
  }
};

export const getInitialsSeq = (initial) => {
  if (initial === "AD") {
    return 1;
  }
  if (initial === "NC") {
    return 2;
  }
  if (initial === "HR") {
    return 3;
  }
  if (initial === "PR") {
    return 4;
  }
  if (initial === "AR") {
    return 5;
  }
  if (initial === "LR") {
    return 6;
  }
  if (initial === "RJ") {
    return 7;
  }
  if (initial === "DC") {
    return 8;
  }
  if (initial === "PD") {
    return 9;
  }
  if (initial === "Incomplete") {
    return 8;
  } else {
    return 0;
  }
};

export const perPageOptions = [
  { value: "10", label: "10" },
  { value: "25", label: "25" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
];

export const displayTImeZoneOption = [
  {
    label: "(GMT-11) Midway Island, Samoa",
    offset: "-11:00",
    default: false,
    value: "1",
  },
  {
    label: "(GMT-10) Adak",
    offset: "-10:00",
    default: false,
    value: "2",
  },
  {
    label: "(GMT-10) Hawaii, Tahiti",
    offset: "-10:00",
    default: false,
    value: "3",
  },
  {
    label: "(GMT-9) Alaska",
    offset: "-09:00",
    default: false,
    value: "4",
  },
  {
    label: "(GMT-9) Gambier Islands",
    offset: "-09:00",
    default: false,
    value: "5",
  },
  {
    label: "(GMT-8) Pacific Time (US & Canada)",
    offset: "-08:00",
    default: true,
    value: "6",
  },
  {
    label: "(GMT-8) Pitcairn",
    offset: "-08:00",
    default: false,
    value: "7",
  },
  {
    label: "(GMT-8) Tijuana, Baja California",
    offset: "-08:00",
    default: false,
    value: "8",
  },
  {
    label: "(GMT-7) Arizona",
    offset: "-07:00",
    default: false,
    value: "9",
  },
  {
    label: "(GMT-7) Chihuahua, Mazatlan",
    offset: "-07:00",
    default: false,
    value: "10",
  },
  {
    label: "(GMT-7) Dawson Creek",
    offset: "-07:00",
    default: false,
    value: "11",
  },
  {
    label: "(GMT-7) Hermosillo, Sonora",
    offset: "-07:00",
    default: false,
    value: "12",
  },
  {
    label: "(GMT-7) Mountain Time (US & Canada)",
    offset: "-07:00",
    default: false,
    value: "13",
  },
  {
    label: "(GMT-7) Navajo Nation",
    offset: "-07:00",
    default: false,
    value: "14",
  },
  {
    label: "(GMT-6) Central America",
    offset: "-06:00",
    default: false,
    value: "15",
  },
  {
    label: "(GMT-6) Central Time (US & Canada)",
    offset: "-06:00",
    default: false,
    value: "16",
  },
  {
    label: "(GMT-6) Guadalajara, Mexico City, Monterrey",
    offset: "-06:00",
    default: false,
    value: "17",
  },
  {
    label: "(GMT-6) Saskatchewan",
    offset: "-06:00",
    default: false,
    value: "18",
  },
  {
    label: "(GMT-5) Bogotá, Lima, Quito",
    offset: "-05:00",
    default: false,
    value: "19",
  },
  {
    label: "(GMT-5) Cayman Islands, Haiti, Jamaica, Panamad",
    offset: "-05:00",
    default: false,
    value: "20",
  },
  {
    label: "(GMT-5) Eastern Time (US & Canada)",
    offset: "-05:00",
    default: false,
    value: "21",
  },
  {
    label: "(GMT-5) Ecuador",
    offset: "-05:00",
    default: false,
    value: "22",
  },
  {
    label: "(GMT-5) Havana, Cuba",
    offset: "-05:00",
    default: false,
    value: "23",
  },
  {
    label: "(GMT-5) Turks and Caicos Islands",
    offset: "-05:00",
    default: false,
    value: "24",
  },
  {
    label: "(GMT-4:30) Caracas",
    offset: "-04:30",
    default: false,
    value: "25",
  },
  {
    label: "(GMT-4) Atlantic Time (Canada)",
    offset: "-04:00",
    default: false,
    value: "26",
  },
  {
    label: "(GMT-4) Bermuda",
    offset: "-04:00",
    default: false,
    value: "27",
  },
  {
    label: "(GMT-4) Blanc-Sablon",
    offset: "-04:00",
    default: false,
    value: "28",
  },
  {
    label: "(GMT-4) Boa Vista, Manaus, Porto Velho, Rio Branco",
    offset: "-04:00",
    default: false,
    value: "29",
  },
  {
    label: "(GMT-4) Campo Grande, Cuiabá",
    offset: "-04:00",
    default: false,
    value: "30",
  },
  {
    label: "(GMT-4) Caribbean, West Indies",
    offset: "-04:00",
    default: false,
    value: "31",
  },
  {
    label: "(GMT-4) Falkland Islands, Paraguay",
    offset: "-04:00",
    default: false,
    value: "32",
  },
  {
    label: "(GMT-4) La Paz, Bolivia",
    offset: "-04:00",
    default: false,
    value: "33",
  },
  {
    label: "(GMT-4) Nassau, Bahamas",
    offset: "-04:00",
    default: false,
    value: "34",
  },
  {
    label: "(GMT-4) Puerto Rico",
    offset: "-04:00",
    default: false,
    value: "35",
  },
  {
    label: "(GMT-4) San Luis, Argentina",
    offset: "-04:00",
    default: false,
    value: "36",
  },
  {
    label: "(GMT-4) Santiago, Chile",
    offset: "-04:00",
    default: false,
    value: "37",
  },
  {
    label: "(GMT-4) Santo Domingo, Dominican Republic",
    offset: "-04:00",
    default: false,
    value: "38",
  },
  {
    label: "(GMT-4) Trinidad and Tobago",
    offset: "-04:00",
    default: false,
    value: "39",
  },
  {
    label: "(GMT-4) Virgin Islands",
    offset: "-04:00",
    default: false,
    value: "40",
  },
  {
    label: "(GMT-3:30) St. Johns, Newfoundland and Labrador",
    offset: "-03:30",
    default: false,
    value: "41",
  },
  {
    label: "(GMT-3) Araguaina, Bahia, Fortaleza, Santarém",
    offset: "-03:00",
    default: false,
    value: "42",
  },
  {
    label: "(GMT-3) Buenos Aires, Cordoba, Tucuman",
    offset: "-03:00",
    default: false,
    value: "43",
  },
  {
    label: "(GMT-3) Catamarca, La Rioja, Mendoza, Salta, San Juan",
    offset: "-03:00",
    default: false,
    value: "44",
  },
  {
    label: "(GMT-3) Cayenne, French Guiana",
    offset: "-03:00",
    default: false,
    value: "45",
  },
  {
    label: "(GMT-3) Godthab/Nuuk",
    offset: "-03:00",
    default: false,
    value: "46",
  },
  {
    label: "(GMT-3) Montevideo",
    offset: "-03:00",
    default: false,
    value: "47",
  },
  {
    label: "(GMT-3) Paramaribo",
    offset: "-03:00",
    default: false,
    value: "48",
  },
  {
    label: "(GMT-3) Saint Pierre and Miquelon-Langlade",
    offset: "-03:00",
    default: false,
    value: "49",
  },
  {
    label: "(GMT-3) São Paulo",
    offset: "-03:00",
    default: false,
    value: "50",
  },
  {
    label: "(GMT-2) Mid-Atlantic",
    offset: "-02:00",
    default: false,
    value: "51",
  },
  {
    label: "(GMT-1) Azores",
    offset: "-01:00",
    default: false,
    value: "52",
  },
  {
    label: "(GMT-1) Cape Verde",
    offset: "-01:00",
    default: false,
    value: "53",
  },
  {
    label: "(GMT) Casablanca, Canary Islands, Faroe Islands, Madeira Islands",
    offset: "-00:00",
    default: false,
    value: "54",
  },
  {
    label: "(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
    offset: "-00:00",
    default: false,
    value: "55",
  },
  {
    label: "(GMT) Monrovia, Reykjavik",
    offset: "-00:00",
    default: false,
    value: "56",
  },
  {
    label: "(GMT+1) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    offset: "+01:00",
    default: false,
    value: "57",
  },
  {
    label: "(GMT+1) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    offset: "+01:00",
    default: false,
    value: "58",
  },
  {
    label: "(GMT+1) Brussels, Copenhagen, Madrid, Paris",
    offset: "+01:00",
    default: false,
    value: "59",
  },
  {
    label: "(GMT+1) Namibia",
    offset: "+01:00",
    default: false,
    value: "60",
  },
  {
    label: "(GMT+1) Sarajevo, Skopje, Warsaw, Zagreb",
    offset: "+01:00",
    default: false,
    value: "61",
  },
  {
    label: "(GMT+1) West Central Africa",
    offset: "+01:00",
    default: false,
    value: "62",
  },
  {
    label: "(GMT+2) Amman, Beirut, Jerusalem",
    offset: "+02:00",
    default: false,
    value: "63",
  },
  {
    label: "(GMT+2) Athens, Bucharest, Istanbul, Kiev, Minsk",
    offset: "+02:00",
    default: false,
    value: "64",
  },
  {
    label: "(GMT+2) Cairo",
    offset: "+02:00",
    default: false,
    value: "65",
  },
  {
    label: "(GMT+3) Bahrain, Baghdad, Kuwait, Qatar, Riyadh, Yemen",
    offset: "+03:00",
    default: false,
    value: "66",
  },
  {
    label: "(GMT+3) East Africa",
    offset: "+03:00",
    default: false,
    value: "67",
  },
  {
    label: "(GMT+3) Moscow, St. Petersburg, Volgograd",
    offset: "+03:00",
    default: false,
    value: "68",
  },
  {
    label: "(GMT+3:30) Tehran",
    offset: "+03:30",
    default: false,
    value: "69",
  },
  {
    label: "(GMT+4) Abu Dhabi",
    offset: "+04:00",
    default: false,
    value: "70",
  },
  {
    label: "(GMT+4) Baku",
    offset: "+04:00",
    default: false,
    value: "71",
  },
  {
    label: "(GMT+4) Mauritius",
    offset: "+04:00",
    default: false,
    value: "72",
  },
  {
    label: "(GMT+4) Muscat",
    offset: "+04:00",
    default: false,
    value: "73",
  },
  {
    label: "(GMT+4) Réunion",
    offset: "+04:00",
    default: false,
    value: "74",
  },
  {
    label: "(GMT+4) Samara",
    offset: "+04:00",
    default: false,
    value: "75",
  },
  {
    label: "(GMT+4) Seychelles",
    offset: "+04:00",
    default: false,
    value: "76",
  },
  {
    label: "(GMT+4) Tbilisi",
    offset: "+04:00",
    default: false,
    value: "77",
  },
  {
    label: "(GMT+4) Yerevan",
    offset: "+04:00",
    default: false,
    value: "78",
  },
  {
    label: "(GMT+4:30) Asia/Kabul",
    offset: "+04:30",
    default: false,
    value: "79",
  },
  {
    label: "(GMT+5) Aqtau, Aqtobe, Oral, Maldives, Kerguelen",
    offset: "+05:00",
    default: false,
    value: "80",
  },
  {
    label: "(GMT+5) Tajikistan",
    offset: "+05:00",
    default: false,
    value: "81",
  },
  {
    label: "(GMT+5) Turkmenistan",
    offset: "+05:00",
    default: false,
    value: "82",
  },
  {
    label: "(GMT+5) Uzbekistan",
    offset: "+05:00",
    default: false,
    value: "83",
  },
  {
    label: "(GMT+5:30) Kolkata, Sri Lanka",
    offset: "+05:30",
    default: false,
    value: "84",
  },
  {
    label: "(GMT+5:45) Katmandu",
    offset: "+05:45",
    default: false,
    value: "85",
  },
  {
    label: "(GMT+6) Almaty, Qyzylorda",
    offset: "+06:00",
    default: false,
    value: "86",
  },
  {
    label: "(GMT+6) Bangladesh",
    offset: "+06:00",
    default: false,
    value: "87",
  },
  {
    label: "(GMT+6) Bhutan",
    offset: "+06:00",
    default: false,
    value: "88",
  },
  {
    label: "(GMT+6) Chagos",
    offset: "+06:00",
    default: false,
    value: "89",
  },
  {
    label: "(GMT+6) Kyrgyzstan",
    offset: "+06:00",
    default: false,
    value: "90",
  },
  {
    label: "(GMT+6) Pakistan",
    offset: "+06:00",
    default: false,
    value: "91",
  },
  {
    label: "(GMT+6) Yekaterinburg",
    offset: "+06:00",
    default: false,
    value: "92",
  },
  {
    label: "(GMT+6:30) Cocos (Keeling) Islands",
    offset: "+06:30",
    default: false,
    value: "93",
  },
  {
    label: "(GMT+6:30) Myanmar",
    offset: "+06:30",
    default: false,
    value: "94",
  },
  {
    label: "(GMT+7) Bangkok, Hanoi, Jakarta",
    offset: "+07:00",
    default: false,
    value: "95",
  },
  {
    label: "(GMT+7) Novosibirsk, Omsk",
    offset: "+07:00",
    default: false,
    value: "96",
  },
  {
    label: "(GMT+8) Beijing, Chongqing, Hong Kong, Shanghai",
    offset: "+08:00",
    default: false,
    value: "97",
  },
  {
    label: "(GMT+8) Brunei",
    offset: "+08:00",
    default: false,
    value: "98",
  },
  {
    label: "(GMT+8) Irkutsk, Ulaan Bataar",
    offset: "+08:00",
    default: false,
    value: "99",
  },
  {
    label: "(GMT+8) Krasnoyarsk",
    offset: "+08:00",
    default: false,
    value: "100",
  },
  {
    label: "(GMT+8) Kuala Lumpur, Singapore",
    offset: "+08:00",
    default: false,
    value: "101",
  },
  {
    label: "(GMT+8) Perth",
    offset: "+08:00",
    default: false,
    value: "102",
  },
  {
    label: "(GMT+8) Taipei, Taiwan",
    offset: "+08:00",
    default: false,
    value: "103",
  },
  {
    label: "(GMT+8:45) Eucla",
    offset: "+08:45",
    default: false,
    value: "104",
  },
  {
    label: "(GMT+9) Osaka, Sapporo, Tokyo",
    offset: "+09:00",
    default: false,
    value: "105",
  },
  {
    label: "(GMT+9) Seoul",
    offset: "+09:00",
    default: false,
    value: "106",
  },
  {
    label: "(GMT+9:30) Adelaide",
    offset: "+09:30",
    default: false,
    value: "107",
  },
  {
    label: "(GMT+9:30) Darwin, Northern Territory",
    offset: "+09:30",
    default: false,
    value: "108",
  },
  {
    label: "(GMT+10) Brisbane",
    offset: "+10:00",
    default: false,
    value: "109",
  },
  {
    label: "(GMT+10) Canberra, Melbourne, Sydney",
    offset: "+10:00",
    default: false,
    value: "110",
  },
  {
    label: "(GMT+10) Federated States of Micronesia",
    offset: "+10:00",
    default: false,
    value: "111",
  },
  {
    label: "(GMT+10) Guam, Port Moresby",
    offset: "+10:00",
    default: false,
    value: "112",
  },
  {
    label: "(GMT+10) Northern Mariana Islands",
    offset: "+10:00",
    default: false,
    value: "113",
  },
  {
    label: "(GMT+10) Vladivostok",
    offset: "+10:00",
    default: false,
    value: "114",
  },
  {
    label: "(GMT+10) Yakutsk",
    offset: "+10:00",
    default: false,
    value: "115",
  },
  {
    label: "(GMT+10:30) Lord Howe",
    offset: "+10:30",
    default: false,
    value: "116",
  },
  {
    label: "(GMT+11) Magadan, Solomon Islands, New Caledonia",
    offset: "+11:00",
    default: false,
    value: "117",
  },
  {
    label: "(GMT+11:30) Norfolk Island",
    offset: "+11:30",
    default: false,
    value: "118",
  },
  {
    label: "(GMT+12) Anadyr, Kamchatka",
    offset: "+12:00",
    default: false,
    value: "119",
  },
  {
    label: "(GMT+12) Auckland, Wellington",
    offset: "+12:00",
    default: false,
    value: "120",
  },
  {
    label: "(GMT+12) Fiji, Marshall Islands",
    offset: "+12:00",
    default: false,
    value: "121",
  },
  {
    label: "(GMT+12:45) Chatham Islands",
    offset: "+12:45",
    default: false,
    value: "122",
  },
  {
    label: "(GMT+13) Kiribati, Tonga",
    offset: "+13:00",
    default: false,
    value: "123",
  },
  {
    label: "(GMT+14) Kiritimati",
    offset: "+14:00",
    default: false,
    value: "124",
  },
];
