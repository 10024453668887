import React, { useState } from "react";
import { ErrorMessage } from "formik";
import TextError from "../../TextError";
//import CheckboxGroup from "react-checkbox-group";

function CheckBoxGroup(props) {
  const {
    name,
    options,
    formik,
    label,
    containerStyle,
    labelStyle,
    fieldContainerStyle,
    fieldStyle,
  } = props;

  const [values, setValues] = useState([]);

  const handleChange = (selectedValue) => {
    setValues(selectedValue);
    formik.setFieldValue(name, selectedValue);
  };

  return (
    <div className={containerStyle}>
      <label className={labelStyle}>{label}</label>
      <div className={fieldContainerStyle}>
        {/* <CheckboxGroup name={name} value={values} onChange={handleChange}>
          {(Checkbox) =>
            options.map((option) => {
              return (
                <label className={fieldStyle}>
                  <Checkbox value={option.value} />
                  {option.label}
                </label>
              );
            })
          }
        </CheckboxGroup> */}
      </div>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default CheckBoxGroup;
