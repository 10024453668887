import api from './api';
export const addDocument = (form) => {
    console.log(form);
    return api.post('user-service/v1/docs/add', form);
}

export const getDocument = (id) => {
    return api.get(`user-service/v1/docs/${id}`,{  responseType: 'blob'})
}

export const getDocumentList = (data) => {
    return api.post(`user-service/v1/tenant/documents`, data);
}

export const updateDocumentComment = (id, comment) => {
    return api.put(`user-service/v1/docs/${id}`, comment);
}

export const deleteDocument = (id) => {
    return api.delete(`user-service/v1/docs/${id}`)
}