import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Grid2 as Grid } from "@mui/material";
import {
  BusinessLineFields,
  BusinessLineOptionalConfirmation,
  BusinessLineToggle,
} from "../../../Common";

const BusinessLine = (props) => {
  const { t } = useTranslation();
  const { formik, state } = props;
  const { values, setFieldValue } = formik;
  const [isBusinessLineConfirm, setBusinessLineConfirm] = useState(false);
  const [businessLineValue, setBusinessLineValue] = useState([
    {
      value: "",
      label: "",
      currentFieldKey: "industry",
      currentFieldlabel: "Industry",
    },
  ]);
  const [businessLineTags, setBusinessLineTags] = useState([]);
  const hasValues = values.businessLine.length > 1;
  const handleBusinessSwitch = (event) => {
    const { checked } = event.target;
    if (checked) {
      if (hasValues) {
        setBusinessLineConfirm(true);
      } else {
        setFieldValue("isBusinessLineOptional", checked);
      }
    } else {
      setFieldValue("isBusinessLineOptional", checked);
      if (
        (businessLineValue.length > 1 || businessLineTags.length > 0) &&
        values.businessLine.length === 1 &&
        values.tags.length === 0
      ) {
        setFieldValue("businessLine", businessLineValue);
        setFieldValue("tags", businessLineTags);
      } else {
        setFieldValue("businessLine", values.businessLine);
        setFieldValue("tags", values.tags);
      }
    }
  };
  const handleClose = () => {
    setBusinessLineConfirm(false);
    setFieldValue("isBusinessLineOptional", false);
  };

  const handleSubmit = () => {
    setFieldValue("isBusinessLineOptional", true);
    setBusinessLineValue(values.businessLine);
    setBusinessLineTags(values.tags);
    setFieldValue("businessLine", [
      {
        value: "",
        label: "",
        currentFieldKey: "industry",
        currentFieldlabel: "Industry",
      },
    ]);
    setFieldValue("tags", []);
    setBusinessLineConfirm(false);
  };

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { isSystemTenant } = userInfo?.tenant;

  return (
    <Grid item size={12}>
      <div
        className={`mb-2 mt-2 additional_configuration_accordion ${
          !isSystemTenant && !hasValues && "not-visible d-none"
        }`}
      >
        <Accordion
          allowZeroExpanded="true"
          allowMultipleExpanded="true"
          preExpanded={["a"]}
        >
          <AccordionItem uuid="a">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="row-header d-flex align-items-center justify-content-between">
                  <div>
                    <label className="row-header-title">
                      {t("BUSINESS_LINE")}
                    </label>
                  </div>
                  <BusinessLineToggle
                    isSystemTenant={isSystemTenant}
                    handleBusinessSwitch={handleBusinessSwitch}
                    isBusinessLineOptional={values?.isBusinessLineOptional}
                  />
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <BusinessLineFields
                formik={formik}
                state={state}
                FormType="EDIT"
              />
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
      {isBusinessLineConfirm && (
        <BusinessLineOptionalConfirmation
          isOpen={isBusinessLineConfirm}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      )}
    </Grid>
  );
};

export default BusinessLine;
