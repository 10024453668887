import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { generateYears, getAllMonthNames } from "../../../shared/utils";
import "./StandardDatePicker.css";

const CustomInput = forwardRef(({ value, onClick, label }, ref) => {
  return (
    <div
      className="custom-datePicker"
      onClick={() => {
        onClick();
      }}
    >
      {label && <label>{`${label}: `}</label>}
      <div className="custom-datePicker-value" ref={ref}>
        {value}
      </div>
      <div className="calendar_icon_wrapper">
        <FontAwesomeIcon icon={faCalendarAlt} className="calendar_icon" />
      </div>
    </div>
  );
});

const StandardDatePicker = (props) => {
  const {
    selected,
    onChange,
    placeholderText,
    dateFormat,
    className,
    maxDate,
    label,
    disabled = false,
    isCustomInput = true,
    startYear = 2019,
    direction = "next",
  } = props;

  const years = generateYears(startYear, direction);
  const months = getAllMonthNames();
  // console.log("StandardDatePicker selected", selected);
  return (
    <div className="position-relative date-picker-container">
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="d-flex justify-content-between pl-2 pr-2 align-items-center">
            <button
              className="react-datepicker__navigation react-datepicker__navigation--previous datePicker__custom__header"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              type="button"
            >
              {"<"}
            </button>
            <div className="datePicker__custom__header__select__wrapper">
              <select
                className="datePicker__custom__header__select mr-2"
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                className="datePicker__custom__header__select"
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <button
              className="react-datepicker__navigation react-datepicker__navigation--next datePicker__custom__header"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              type="button"
            >
              {">"}
            </button>
          </div>
        )}
        onFocus={(e) => e.target.blur()}
        {...props}
        dateFormat={dateFormat}
        className={className}
        disabled={disabled}
        selected={(selected && new Date(selected)) || null}
        onChange={onChange}
        placeholderText={placeholderText}
        maxDate={maxDate ? maxDate : null}
        customInput={isCustomInput ? <CustomInput label={label} /> : null}
      />
    </div>
  );
};

export default StandardDatePicker;
