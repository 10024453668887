import React from "react";
import { CircularProgress } from "@mui/material";

export default function Spinner({
  size = "30px",
  color = "primary",
  thickness = 4,
  className,
  children,
}) {
  return (
    <CircularProgress
      className={className}
      thickness={thickness}
      size={size}
      sx={{ color: color }}
    >
      {children}
    </CircularProgress>
  );
}
