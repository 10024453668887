import React from "react";
import Select from "react-select";
import { Field, ErrorMessage } from "formik";
import TextError from "../../TextError";

function SelectBoxWithLabel(props) {
  const {
    label,
    options,
    containerStyle,
    labelStyle,
    fieldStyle,
    errorMsgStyle,
    name,
    ...rest
  } = props;
  
  return (
    <div className={containerStyle}>
      <label htmlFor={name} className={labelStyle}>
        {label}
      </label>
      <Field name={name} className={fieldStyle}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <Select
              className="select-style"
              classNamePrefix="select"
              id={name}
              {...rest}
              options={options}
              value={value}
              onChange={(value) => setFieldValue(name, value)}
            />
          );
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default SelectBoxWithLabel;
