import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ModalPopUp({
  show = false,
  children,
  title,
  onHide,
  className,
  width = 600,
}) {
  return (
    <>
      <Dialog
        open={show}
        onClose={onHide}
        maxWidth="md"
        sx={{ "& .MuiDialog-paper": { width: `${width}px`, maxWidth: "none" } }}
      >
        {title && (
          <>
            <DialogTitle
              sx={{
                borderBottom: "1px solid #dee2e6",
                color: "#007bff",
                lineHeight: "20px",
                fontSize: "18px",
                letterSpacing: "0px",
              }}
            >
              {title}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={onHide}
              sx={(theme) => ({
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              })}
            >
              <CloseIcon />
            </IconButton>
          </>
        )}

        <DialogContent className={className}>{children}</DialogContent>
      </Dialog>
    </>
  );
}
