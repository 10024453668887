import React, { Component } from "react";
import { Card, CardContent, Grid2 as Grid } from "@mui/material";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import { Button } from "../../../components/Common";
import AddCallCenter from "./addCallCenter";
import EditCallCenter from "./editCallCenter";
import ManageCallCenter from "./manageCallCenter";
import "./CallCenter.css";

class CallCenters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childNav: "CallCenters",
    };
    this.updateNavBar = this.updateNavBar.bind(this);
  }

  manageCallCenterHtml = (props) => {
    let { t } = props;
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <h6 className="page-title">{t("Call Centers")} </h6>
              </div>
              <div className="col-lg-4">
                <div
                  className="float-right"
                  onClick={() => {
                    this.updateNavBar("Add New CallCenters");
                  }}
                >
                  <Button
                    variant="success"
                    className="btn-rounded btn-sm mr-2"
                    iconButton={true}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="button_icon_only"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card1">
          <div className="card-body1">
            <ManageCallCenter
              dataFromParent={props}
              handleEditRow={this.handleEditRow}
            />
          </div>
        </div>
      </div>
    );
  };

  createCallCenterHtml = (props) => {
    let { t } = props;
    return (
      <div>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item size={12}>
                <h5 className="page-title">{t("Add New Call Center")}</h5>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <AddCallCenter
          dataFromParent={props}
          updateNavBar={this.updateNavBar}
        />
      </div>
    );
  };
  editCallCenterHtml = (props) => {
    let { t } = props;
    return (
      <div>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item size={12}>
                <h5 className="page-title">{t("Edit Call Center")}</h5>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <EditCallCenter
          dataFromParent={props}
          updateNavBar={this.updateNavBar}
        />
      </div>
    );
  };

  componentDidMount() {
    this.props.onRef(this);
    if (this.props.statedata.adminDetail.navBar != "CallCenters") {
      this.updateNavBar("CallCenters");
    }
  }

  updateNavBar(navBar) {
    console.log(
      "condition check in call center index",
      navBar,
      this.props.statedata.adminDetail.navBar != "CallCenters"
    );
    this.setState({
      childNav: navBar,
    });
    this.props.handleBreadCrumInAdmin(navBar);
  }

  handleEditRow = (row, navBar, id) => {
    this.setState({
      childNav: navBar,
    });
    this.props.handleEditRow(row, navBar, id);
  };

  getTenantComponent(navBar) {
    let showTenant = "";

    switch (navBar) {
      case "CallCenters":
        showTenant = this.manageCallCenterHtml(this.props);
        break;
      case "Add New CallCenters":
        showTenant = this.createCallCenterHtml(this.props);
        break;
      case "Edit CallCenter":
        showTenant = this.editCallCenterHtml(this.props);
        break;
    }

    return showTenant;
  }
  render() {
    console.log(this);
    let showTenant = this.getTenantComponent(this.state.childNav);

    if (
      showTenant == "" &&
      this.props.location.pathname == "/main/admin/callcenters"
    ) {
      showTenant = this.getTenantComponent(
        this.props.statedata.adminDetail.prevNavBar
      );
    }
    return <React.Fragment> {showTenant} </React.Fragment>;
  }
}
export default withTranslation("", { withRef: true })(CallCenters);
