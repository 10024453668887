import React, { useReducer } from 'react';
import { resultsInitialState, resultsReducer } from '../ResultsReducer';

const ResultsContext = React.createContext();

const ResultsProvider = (props) => {
  const { projectDetail, permissionList } = props;
  const [state, dispatch] = useReducer(resultsReducer, resultsInitialState);
  return (
    <ResultsContext.Provider
      value={{
        resultsState: state,
        resultsDispatch: dispatch,
        projectDetail: projectDetail,
        permissionList: permissionList,
      }}
    >
      {props.children}
    </ResultsContext.Provider>
  );
};

export { ResultsContext, ResultsProvider };
