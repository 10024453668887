import React from "react";
import { ModalPopUp } from "../../../../../../components/Common";

import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/adventure_time.css";
const TrackingDataDialog = (props) => {
  const { isOpen, trackingData, t, close, type } = props;
  let JSONPrettyMon = require("react-json-pretty/themes/adventure_time.css");
  return (
    <ModalPopUp
      show={isOpen}
      onHide={close}
      dialogClassName="participant-detail-container"
      contentClassName="participant-detail-container-height"
      centered
      title={type === "SMS" ? t("SMS Event Detail") : t("Email Event Detail")}
    >
      <div>
        <pre>
          <JSONPretty
            className="participant-json-pretty"
            theme={JSONPrettyMon}
            data={
              trackingData != null
                ? type === "SMS"
                  ? trackingData.raw_data
                  : trackingData.event_data
                : "No Data Found"
            }
          ></JSONPretty>
        </pre>
      </div>
    </ModalPopUp>
  );
};
export default TrackingDataDialog;
