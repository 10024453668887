import { sortByLabel, createDropdownOption } from "../Helper";

const InternalUseOnlyOption = (state) => {
  let interviewOption = state.interviewMethodA
    .map((option) => {
      return createDropdownOption(option);
    })
    .sort(sortByLabel);

  let acessCodeOption = state.acessCodeB
    .map((option) => {
      return createDropdownOption(option);
    })
    .sort(sortByLabel);

  let guideURLOption = state.guideURLB
    .map((option) => {
      return createDropdownOption(option);
    })
    .sort(sortByLabel);

  let integrationMethodOption = state.integrationMethodA
    .map((option) => {
      return createDropdownOption(option);
    })
    .sort((a, b) => a.sequence - b.sequence);

  let multilingualPhoneOption = state.multilingualPhoneA
    .map((option) => {
      return createDropdownOption(option);
    })
    .sort((a, b) => a.sequence - b.sequence);

  return {
    interviewOption,
    acessCodeOption,
    guideURLOption,
    integrationMethodOption,
    multilingualPhoneOption,
  };
};
export default InternalUseOnlyOption;
