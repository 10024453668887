import React, { memo, useState } from "react";
import moment from "moment";
import { Date_Time_Format } from "../../../../../config";
import { CustomGrid } from "../../../../CustomTable";
const ParticipantStatus = (props) => {
  const { loading, participantStatusData, t } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [perPageCount, setPerPageCount] = useState(10);

  const formateCallDateTime = (params) => {
    return params.value ? moment(params.value).format(Date_Time_Format) : "-";
  };

  const onChangeRecords = (page) => {
    setCurrentPage(page.page);
    setPerPageCount(page.pageSize);
  };
  const columns = [
    {
      headerName: `${t("status")}`,
      field: "status",
      flex: 1,
      sortable: false,
    },
    {
      headerName: `${t("Create Ts")}`,
      field: "createTs",
      flex: 1,
      renderCell: formateCallDateTime,
      sortable: false,
    },
  ];

  return (
    <div className="table_wrapper app-table participant-table custom_table_container">
      <CustomGrid
        data={participantStatusData || []}
        columns={columns}
        loading={loading}
        getRowId={(row) => `${row.createTs}_status`}
        totalPages={participantStatusData.length}
        currentPage={currentPage}
        perPageCount={perPageCount}
        isServerPagination={false}
        onChangeRecords={onChangeRecords}
      />
    </div>
  );
};
export default memo(ParticipantStatus);
