import {
  Document,
  Font,
  Image,
  Page,
  pdf,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import moment from "moment";
import React from "react";
import RobotoBlack from "../../assets/fonts/Roboto-Black.ttf";
import RobotoBold from "../../assets/fonts/Roboto-Bold.ttf";
import RobotoRegular from "../../assets/fonts/Roboto-Regular.ttf";
import clearspeedLogo from "../../assets/images/ClearspeedLogo.png";
import { Date_Format } from "../../config";
import i18n from "../../locale/i18n";

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoRegular, fontWeight: 400 }, // font-style: normal, font-weight: normal
    { src: RobotoBold, fontWeight: 700 },
    { src: RobotoBlack, fontWeight: 900 },
  ],
});
const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    boxSizing: "border-box",
    width: "100%",
    boxShadow: "0px 0px 30px -11px rgba(128,128,128,1)",
    height: "1000px",
  },
  content: {
    padding: "0 20px",
  },
  logoLeft: {
    width: "49%",
    display: "inline-block",
  },
  logoRight: {
    width: "49%",
    display: "inline-block",
  },
  leftImg: {
    // padding: "10px",
    width: "40%",
  },
  topHead: {
    textAlign: "center",
    backgroundColor: "#000",
    minHeight: "10px",
  },
  table: {
    width: "100%",
    display: "table",
    border: "1pt solid #ccc",
    marginBottom: "9pt",
  },
  tableRow: {
    display: "table-row",
    flexDirection: "row",
    width: "100%",
  },
  tableCol: {
    display: "table-cell",
    width: "25%",
    padding: "5px",
  },
  tableCol50: {
    display: "table-cell",
    width: "50%",
    padding: "5px",
  },
  tableCol40: {
    display: "table-cell",
    width: "40%",
    padding: "5px",
  },
  tableCol70: {
    display: "table-cell",
    width: "70%",
    padding: "5px",
  },
  tableCol75: {
    display: "table-cell",
    width: "75%",
    padding: "5px",
  },
  tableCol80: {
    display: "table-cell",
    width: "80%",
    padding: "5px",
  },
  tableCol8: {
    display: "table-cell",
    width: "40px",
    padding: "5px",
  },
  tableCol10: {
    display: "table-cell",
    width: "10%",
    padding: "5px",
  },
  tableCol20: {
    display: "table-cell",
    width: "20%",
    padding: "5px",
  },
  tableCol30: {
    display: "table-cell",
    width: "30%",
    padding: "5px",
  },
  tableCol90: {
    display: "table-cell",
    width: "90%",
    padding: "5px",
  },
  tableCol100: {
    isplay: "table-cell",
    width: "100%",
    padding: "5px",
  },
  infoBlue: {
    backgroundColor: "#e7e7e7",
    textAlign: "left",
    color: "#333333",
  },
  infoGray: {
    backgroundColor: "#cccccc",
    color: "#000000",
  },
  interviewHeading: {
    color: "#555",
    fontSize: "13pt",
    fontWeight: "700",
  },
  borderB: {
    borderBottom: "1pt solid #ccc",
  },
  borderL: {
    borderLeft: "1pt solid #ccc",
  },
  borderR: {
    borderRight: "1pt solid #ccc",
  },
  borderT: {
    borderTop: "1pt solid #ccc",
  },
  borderA: {
    border: "1pt solid #ccc",
  },
  borderNone: {
    border: "none",
  },
  MainHead: {
    width: "100%",
    display: "block",
    textAlign: "center",
    marginBottom: "2rem",
  },
  mainText: {
    fontSize: "18pt",
    color: "#555555",
    padding: "10px 0",
    fontWeight: "bold",
  },
  nopaddding: {
    padding: "0 !important",
  },
  font14: {
    fontSize: "10.50pt",
  },
  font16: {
    fontSize: "11pt",
  },
  font17: {
    fontSize: "12.75pt",
  },
});

const getDateInFormat = (time, format, displayTimeZoneOffset) => {
  console.log("displayTimeZoneOffset", time, displayTimeZoneOffset);
  if (time) return moment(time).utcOffset(displayTimeZoneOffset).format(format);
  return "";
};
const getFullRiskCode = (val) => {
  val = val.toUpperCase();
  switch (val) {
    case "LR":
      return "Low Risk";
    case "AR":
      return "Average Risk";
    case "PR":
      return "Potential Risk";
    case "HR":
      return "High Risk";
    case "CM":
      return "Countermeasures";
    case "AD":
      return "Admissions";
    case "NC":
      return "Not Completed";
    case "PD":
      return "Pending";
    case "RJ":
      return "Rejected";
    case "DC":
      return "Disconnected";
    default:
      return "";
  }
};
const getMaskedAccessCode = (numbe) => {
  if (numbe != null) {
    const numb = numbe.toString();
    let number = `${numb.substring(0, 3)}-${numb.substring(
      3,
      6
    )}-${numb.substring(6, numb.length)}`;
    return number;
  }
};

const getQuestionTable1 = (questions, questiontext) => {
  const newquestiontext = getPertinentSequence1(questiontext);
  let question =
    questions !== null && questions.sort((a, b) => a.sequence - b.sequence);
  return newquestiontext.map((ques, index) => {
    let incdexnew = index + 1;
    return (
      <View
        style={{ ...styles.tableRow, ...styles.borderT }}
        key={ques.sequence}
      >
        <View style={{ ...styles.tableCol80, ...styles.borderR }}>
          <Text>{ques.questionText}</Text>
        </View>
        <View style={{ ...styles.tableCol20 }}>
          <Text
            style={{
              color:
                question[index] != undefined
                  ? question[index].result == "AR"
                    ? "#92D050"
                    : question[index].result == "PR"
                    ? "#FFCA00"
                    : question[index].result == "HR"
                    ? "#FF0000"
                    : question[index].result == "LR"
                    ? "#008000"
                    : question[index].result == "NC"
                    ? "#BCBCBC"
                    : "#1665d8"
                  : "",
            }}
          >
            {questions !== null
              ? incdexnew <= question.length
                ? question[index].result
                : ""
              : ""}
          </Text>
        </View>
      </View>
    );
  });
};

const getPertinentSequence1 = (data) => {
  // let data = this.state.questionDetail
  var pertnentquestionlist =
    data != undefined &&
    data
      .sort((a, b) => a.sequence - b.sequence)
      .filter(function (pertnentquestion) {
        return pertnentquestion.isPertinant === true;
      });
  return pertnentquestionlist;
};
export function PdfDocument(props) {
  console.log("PdfDocument", props);
  //const {t} = props;
  const imgElement = React.useRef(null);
  let questionlabeltext = props.questionlabels;
  let questionsHtml = getQuestionTable1(
    props.pdfDocumentData.interviewQuestionsResult,
    questionlabeltext
  );
  let questionaire_type =
    props.questionaire_typeData == "EXPRESS_QUESTIONNAIRE"
      ? "Express Questionnaire"
      : "Precision Questionnaire";
  let result = props.pdfDocumentData;
  let irn = props.irnno;
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let b64Data = "";
  let contentType = "";
  let blob = "";
  let imageLogo = "";
  if (userInfo.tenantLogo != null) {
    b64Data = userInfo.tenantLogo;
    contentType = "image/png";
    blob = b64toBlob(b64Data, contentType);
    imageLogo = URL.createObjectURL(blob);
  }
  let image =
    userInfo.tenantLogo != null ? (
      <Image
        style={styles.leftImg}
        src={imageLogo}
        alt="Logo"
        ref={imgElement}
        onLoad={() =>
          console.log("imgElement", imgElement.current.naturalHeight)
        }
      />
    ) : (
      <Image src={clearspeedLogo} alt="Logo" />
    );
  //console.log('comment', result)
  let comment =
    result.interviewResultComments !== null
      ? result.interviewResultComments.map((comment) => {
          return comment.comment;
        })
      : false;
  let notes =
    result.audioQualityControlError !== null
      ? result.audioQualityControlError
      : "";
  let ad = result.AD;
  let cm = result.CM;
  let nc = result.NC;
  //console.log("imageLogo.offsetWidth", imageLogo.offsetWidth )
  //console.log('images for ad nc cm', result.AD != null && result.AD == true, result.CM != null && result.CM == true, result.NC != null && result.NC == true)
  return (
    <Document style={{ backgroundColor: "#f2f3f8" }}>
      <Page style={styles.page} renderMode="canvas">
        <View style={styles.topHead}></View>

        <View style={styles.content}>
          <View
            style={{
              ...styles.table,
              ...styles.borderNone,
              marginBottom: "3px",
            }}
          >
            <View style={styles.tableRow}>
              <View style={styles.tableCol50}>{image}</View>
            </View>
          </View>
          <View style={styles.MainHead}>
            <Text style={{ ...styles.mainText, ...styles.nopaddding }}>
              {i18n.t(questionaire_type)}
            </Text>
            <Text style={{ ...styles.mainText }}>{i18n.t("Risk Report")}</Text>
          </View>
          <View
            style={{
              ...styles.table,
              ...styles.font17,
              ...styles.borderA,
              marginBottom: "5px",
              opacity: ".7",
            }}
          >
            <View style={{ ...styles.tableRow }}>
              <View style={{ ...styles.tableCol, ...styles.infoBlue }}>
                <Text style={{ fontFamily: "Roboto", fontSize: "12pt" }}>
                  {i18n.t("Client Name")}:
                </Text>
              </View>
              <View style={{ ...styles.tableCol, ...styles.borderB }}>
                <Text>{result.clientName}</Text>
              </View>
              <View style={{ ...styles.tableCol, ...styles.infoBlue }}>
                <Text>{i18n.t("Date")}:</Text>
              </View>
              <View style={{ ...styles.tableCol, ...styles.borderB }}>
                <Text>
                  {getDateInFormat(
                    result.date,
                    Date_Format,
                    props.displayTimeZoneOffset
                  )}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol, ...styles.infoBlue }}>
                <Text>{i18n.t("Participant / IRN")}:</Text>
              </View>
              <View style={{ ...styles.tableCol, ...styles.borderB }}>
                <Text>{result.interviewReferenceNumber}</Text>
              </View>
              <View style={{ ...styles.tableCol, ...styles.infoBlue }}>
                <Text> {i18n.t("Access Code")}:</Text>
              </View>
              <View style={{ ...styles.tableCol, ...styles.borderB }}>
                <Text>{getMaskedAccessCode(result.accessCode)}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol, ...styles.infoBlue }}>
                <Text>{i18n.t("Location")}:</Text>
              </View>
              <View style={{ ...styles.tableCol }}>
                <Text>{result.location}</Text>
              </View>
              <View style={{ ...styles.tableCol, ...styles.infoBlue }}>
                <Text>{i18n.t("Language")}:</Text>
              </View>
              <View style={{ ...styles.tableCol }}>
                <Text>{result.participantLanguage}</Text>
              </View>
            </View>
          </View>
          <View
            style={{ ...styles.table, fontWeight: "700", marginBottom: "15px" }}
          >
            <View
              style={{
                ...styles.tableRow,
                fontSize: "11.99pt",
                padding: "5px",
              }}
            >
              <Text style={{ color: "#555555", fontWeight: "bold" }}>
                {i18n.t("Overall Evaluation")}: &nbsp;
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    color:
                      getFullRiskCode(result.overallEvaluation) ===
                      "Average Risk"
                        ? "#92D050"
                        : getFullRiskCode(result.overallEvaluation) ===
                          "Potential Risk"
                        ? "#FFCA00"
                        : getFullRiskCode(result.overallEvaluation) ===
                          "High Risk"
                        ? "#FF0000"
                        : getFullRiskCode(result.overallEvaluation) ===
                          "Low Risk"
                        ? "#008000"
                        : getFullRiskCode(result.overallEvaluation) ===
                          "Not Completed"
                        ? "#BCBCBC"
                        : getFullRiskCode(result.overallEvaluation) ===
                          "Countermeasures"
                        ? "#6758F3"
                        : getFullRiskCode(result.overallEvaluation) ===
                          "Pending"
                        ? "#f2933d"
                        : getFullRiskCode(result.overallEvaluation) ===
                          "Admissions"
                        ? "#1665D8"
                        : getFullRiskCode(result.overallEvaluation) ===
                          "Disconnected"
                        ? "#6c6b6b"
                        : "#000000",
                  }}
                >
                  {getFullRiskCode(result.overallEvaluation)}
                </Text>
              </Text>
            </View>
          </View>
          <View>
            <View
              style={{
                ...styles.table,
                ...styles.font14,
                marginBottom: "5px",
                fontFamily: "Roboto",
                fontWeight: "bold",
              }}
            >
              <View style={{ ...styles.tableRow }}>
                <View style={{ ...styles.tableCol80, ...styles.infoGray }}>
                  <Text>{i18n.t("Questions")}</Text>
                </View>
                <View style={{ ...styles.tableCol20, ...styles.infoGray }}>
                  <Text>{i18n.t("Results")}</Text>
                </View>
              </View>
              {questionsHtml.length > 0 ? (
                questionsHtml
              ) : (
                <View style={styles.tableRow}>
                  <View style={{ ...styles.tableCol80 }}>
                    <Text>{i18n.t("No question")}</Text>
                  </View>
                </View>
              )}
            </View>
          </View>
          <View
            style={{ ...styles.table, fontWeight: "700", marginBottom: "5px" }}
          >
            <View
              style={{
                ...styles.tableRow,
                fontSize: "11.99pt",
                padding: "5px",
              }}
            >
              <View style={{ ...styles.tableCol10 }}>
                <Text>{i18n.t("Notes")}: </Text>
              </View>
              <View style={{ ...styles.tableCol90 }}>
                <Text style={{ color: "#555555" }}>{notes}</Text>
              </View>
            </View>
          </View>
          <View style={{ padding: "10px 0px" }}>
            <View
              style={{
                ...styles.table,
                ...styles.font16,
                fontWeight: "normal",
                fontSize: "8.0pt",
                marginBottom: "0px",
              }}
            >
              <View style={styles.tableRow}>
                <View
                  style={{
                    ...styles.tableCol100,
                    backgroundColor: "#ccc",
                    padding: "4.2px",
                  }}
                >
                  <Text> {i18n.t("Risk Level Classifications")}</Text>
                </View>
              </View>
              <View style={{ ...styles.tableRow, ...styles.borderT }}>
                <View
                  style={{
                    ...styles.tableCol30,
                    padding: "4.2px",
                    ...styles.borderR,
                  }}
                >
                  <Text style={{ color: "#008000" }}>
                    {i18n.t("Low Risk (LR)")}
                  </Text>
                </View>
                <View style={{ ...styles.tableCol70, padding: "4.2px" }}>
                  <Text style={{ color: "#008000" }}>
                    {i18n.t(
                      "Little to no risk identified. No mitigation action needed."
                    )}
                  </Text>
                </View>
              </View>
              {questionaire_type === "Express Questionnaire" ? null : (
                <>
                  <View style={{ ...styles.tableRow, ...styles.borderT }}>
                    <View
                      style={{
                        ...styles.tableCol30,
                        padding: "4.2px",
                        ...styles.borderR,
                      }}
                    >
                      <Text style={{ color: "#92D050" }}>
                        {i18n.t("Average Risk (AR)")}
                      </Text>
                    </View>
                    <View style={{ ...styles.tableCol70, padding: "4.2px" }}>
                      <Text style={{ color: "#92D050" }}>
                        {i18n.t(
                          "Neutral risk identified. No mitigation action needed."
                        )}
                      </Text>
                    </View>
                  </View>
                  <View style={{ ...styles.tableRow, ...styles.borderT }}>
                    <View
                      style={{
                        ...styles.tableCol30,
                        padding: "4.2px",
                        ...styles.borderR,
                      }}
                    >
                      <Text style={{ color: "#FFCA00" }}>
                        {i18n.t("Potential Risk (PR)")}
                      </Text>
                    </View>
                    <View style={{ ...styles.tableCol70, padding: "4.2px" }}>
                      <Text style={{ color: "#FFCA00" }}>
                        {i18n.t(
                          "Risk factors above average identified. Recommend reviewing with other data points."
                        )}
                      </Text>
                    </View>
                  </View>
                </>
              )}
              <View style={{ ...styles.tableRow, ...styles.borderT }}>
                <View
                  style={{
                    ...styles.tableCol30,
                    padding: "4.2px",
                    ...styles.borderR,
                  }}
                >
                  <Text style={{ color: "#FF0000" }}>
                    {i18n.t("High Risk (HR)")}
                  </Text>
                </View>
                <View style={{ ...styles.tableCol70, padding: "4.2px" }}>
                  <Text style={{ color: "#FF0000" }}>
                    {i18n.t(
                      "Specific risk factors identified. Further action is highly recommended to mitigate risk."
                    )}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                ...styles.table,
                ...styles.font16,
                fontWeight: "normal",
                fontSize: "8.0pt",
                marginBottom: "2px",
              }}
            >
              <View style={styles.tableRow}>
                <View
                  style={{
                    ...styles.tableCol100,
                    backgroundColor: "#ccc",
                    padding: "4.2px",
                  }}
                >
                  <Text> {i18n.t("Special Category Classifications")}</Text>
                </View>
              </View>
              {questionaire_type === "Express Questionnaire" ? null : (
                <View style={{ ...styles.tableRow, ...styles.borderT }}>
                  <View
                    style={{
                      ...styles.tableCol30,
                      padding: "4.2px",
                      ...styles.borderR,
                    }}
                  >
                    <Text style={{ color: "#6758F3" }}>
                      {i18n.t("Counter Measure (CM)")}
                    </Text>
                  </View>
                  <View style={{ ...styles.tableCol70, padding: "4.2px" }}>
                    <Text style={{ color: "#6758F3" }}>
                      {i18n.t(
                        "Suspected countermeasures detected. Action recommended."
                      )}
                    </Text>
                  </View>
                </View>
              )}
              <View style={{ ...styles.tableRow, ...styles.borderT }}>
                <View
                  style={{
                    ...styles.tableCol30,
                    padding: "4.2px",
                    ...styles.borderR,
                  }}
                >
                  <Text style={{ color: "#1665D8" }}>
                    {i18n.t("Admissions (AD)")}
                  </Text>
                </View>
                <View style={{ ...styles.tableCol70, padding: "4.2px" }}>
                  <Text style={{ color: "#1665D8" }}>
                    {i18n.t(
                      "Participant answered 'Yes' to one or more questions during the questionnaire. Action recommended."
                    )}
                  </Text>
                </View>
              </View>
              <View style={{ ...styles.tableRow, ...styles.borderT }}>
                <View
                  style={{
                    ...styles.tableCol30,
                    padding: "4.2px",
                    ...styles.borderR,
                  }}
                >
                  <Text style={{ color: "#BCBCBC" }}>
                    {i18n.t("Non Compliant (NC)")}
                  </Text>
                </View>
                <View style={{ ...styles.tableCol70, padding: "4.2px" }}>
                  <Text style={{ color: "#BCBCBC" }}>
                    {i18n.t(
                      "Participant completed the questionnaire but did not follow instructions and responses could not be evaluated. See Notes for additional details, if available."
                    )}
                  </Text>
                </View>
              </View>
              <View style={{ ...styles.tableRow, ...styles.borderT }}>
                <View
                  style={{
                    ...styles.tableCol30,
                    padding: "4.2px",
                    ...styles.borderR,
                  }}
                >
                  <Text style={{ color: "#6c6b6b" }}>
                    {i18n.t("Disconnected (DC)")}{" "}
                  </Text>
                </View>
                <View style={{ ...styles.tableCol70, padding: "4.2px" }}>
                  <Text style={{ color: "#6c6b6b" }}>
                    {i18n.t(
                      "Participant hung up or call was disconnected during the questionnaire and could not be evaluated."
                    )}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ fontSize: "8.24pt", margin: "5px 5px" }}>
              <Text>{i18n.t("pdf_disclaimer")}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export const generatePdfDocument = async (
  documentData,
  questiontext,
  irnno,
  questionaire_type,
  displayTimeZoneOffset
) => {
  console.log("displayTimeZoneOffset32", displayTimeZoneOffset);
  if (documentData.interviewQuestionsResult != null) {
    const dataq = documentData.interviewQuestionsResult;
    let filteredquestionArray = [
      {
        sequence: "1",
        result: "",
      },
      {
        sequence: "2",
        result: "",
      },
      {
        sequence: "3",
        result: "",
      },
      {
        sequence: "4",
        result: "",
      },
      {
        sequence: "5",
        result: "",
      },
      {
        sequence: "6",
        result: "",
      },
      {
        sequence: "7",
        result: "",
      },
    ];

    const newItemsBySequence = dataq.reduce((a, item) => {
      a[item.sequence] = item;
      return a;
    }, {});
    const filteredOutput = filteredquestionArray.map((item) =>
      newItemsBySequence[item.sequence]
        ? newItemsBySequence[item.sequence]
        : item
    );
    documentData.interviewQuestionsResult = filteredOutput;
  }

  const blob = await pdf(
    <PdfDocument
      title="My PDF"
      pdfDocumentData={documentData}
      questionaire_typeData={questionaire_type}
      questionlabels={
        questiontext !== undefined && questiontext != null ? questiontext : []
      }
      irnno={irnno}
      displayTimeZoneOffset={displayTimeZoneOffset}
    />
  ).toBlob();

  saveAs(blob, `result_${irnno}.pdf`);
};

export const generatePdfDocumentForZip = async (
  documentData,
  questiontext,
  irnno,
  questionaire_type,
  displayTimeZoneOffset
) => {
  if (documentData.interviewQuestionsResult != null) {
    const dataq = documentData.interviewQuestionsResult;
    let filteredquestionArray = [
      {
        sequence: "1",
        result: "",
      },
      {
        sequence: "2",
        result: "",
      },
      {
        sequence: "3",
        result: "",
      },
      {
        sequence: "4",
        result: "",
      },
      {
        sequence: "5",
        result: "",
      },
      {
        sequence: "6",
        result: "",
      },
      {
        sequence: "7",
        result: "",
      },
    ];

    const newItemsBySequence = dataq.reduce((a, item) => {
      a[item.sequence] = item;
      return a;
    }, {});
    const filteredOutput = filteredquestionArray.map((item) =>
      newItemsBySequence[item.sequence]
        ? newItemsBySequence[item.sequence]
        : item
    );
    documentData.interviewQuestionsResult = filteredOutput;
  }

  const blob = await pdf(
    <PdfDocument
      title="My PDF"
      pdfDocumentData={documentData}
      questionaire_typeData={questionaire_type}
      questionlabels={
        questiontext !== undefined && questiontext != null ? questiontext : []
      }
      irnno={irnno}
      displayTimeZoneOffset={displayTimeZoneOffset}
    />
  ).toBlob();

  return blob;
};
