import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Field, Form, Formik, getIn } from "formik";
import * as Yup from "yup";
import { Card, CardContent, Grid2 as Grid } from "@mui/material";
import {
  Alert as AlertDismissible,
  Button,
  Spinner,
} from "../../../components/Common";
import { scrollTopWindow } from "../../../Helpers/index";
import { createCallCenter } from "../../../Services/callCenterServices";
import PreventLeaveRoute from "../../Utility/PreventLeaveRoute.js";
import { SelectField } from "../../Utility/selectField";

class AddCallCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMsg: "",
      alertStatus: false,
      variant: "",
      dirty: false,
      disablesavebtn: false,
    };
  }

  cancelCallCenterClick = () => {
    this.props.updateNavBar("CallCenters");
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  dirtyFlagChanged = (dirty) => {
    if (dirty != this.state.dirty) {
      this.setState({
        dirty: dirty,
      });
      this.props.dataFromParent.handleChildDirtyGFlag(dirty);
    }
  };

  handleAddCallCenter = (fields, resetForm) => {
    this.setState({
      disablesavebtn: true,
    });
    createCallCenter(fields)
      .then((res) => {
        scrollTopWindow();
        this.setState({
          alertMsg: `Call Center ${res.data.name} Created Successfully`,
          variant: "success",
          alertStatus: true,
          dirty: false,
          disablesavebtn: false,
        });
        this.props.dataFromParent.handleChildDirtyGFlag(false);
        resetForm();
      })
      .catch((err) => {
        console.log("error in call center", err);
        this.setState({
          alertMsg: `${err.response.data.message}`,
          variant: "danger",
          alertStatus: true,
          disablesavebtn: false,
        });
        this.props.dataFromParent.handleChildDirtyGFlag(false);
        resetForm();
      });
  };
  render() {
    let { t } = this.props;
    const tenantStatusOption = [
      { id: 1, value: true, label: t("Active"), status: true },
      { id: 2, value: false, label: t("Inactive"), status: false },
    ];
    const phoneRegExp =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            name: "",
            callCenterPhone: "",
            contactPhone: "",
            contactPersonFirstName: "",
            contactPersonLastName: "",
            contactEmail: "",
            notificationEmail: "",
            callCenterStatus: "",
            queue1: "",
            queue2: "",
            queue3: "",
            note: "",
            address: {
              type: "Call Center",
              street1: "",
              street2: "",
              city: "",
              country: "",
              countryId: "",
              stateId: "",
            },
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(t("Call Center Name is required")),
            notificationEmail: Yup.string().email(
              t("Notification Email is invalid")
            ),
            contactEmail: Yup.string().email(t("Contact Email is invalid")),
            contactPhone: Yup.string().matches(
              phoneRegExp,
              t("Contact phone number is not valid")
            ),
            callCenterPhone: Yup.string().matches(
              phoneRegExp,
              t("Call center phone number is not valid")
            ),
          })}
          onSubmit={(fields, { resetForm }) => {
            this.handleAddCallCenter(fields, resetForm);
          }}
          render={(props) => (
            <Form
              className="callcenter-view"
              onChange={this.dirtyFlagChanged(props.dirty)}
            >
              <PreventLeaveRoute
                reset={props.handleReset}
                when={props.dirty}
                // Navigate function
                navigate={(field, historyObj) => {
                  if (field == "/main/admin/callcenters") {
                    this.cancelCallCenterClick();
                  } else {
                    setTimeout(() => {
                      historyObj.push(field);
                    }, 2000);
                  }
                }}
                // Use as "message" prop of Prompt of React-Router
                shouldBlockNavigation={(location) => {
                  if (location.pathname === "/") {
                    return false;
                  }
                  return !!props.dirty;
                }}
              />
              <Grid container>
                <Grid item size={12} sx={{ padding: "0px" }}>
                  <AlertDismissible
                    msg={this.state.alertMsg}
                    variantType={this.state.variant}
                    show={this.state.alertStatus}
                    close={this.handleAlertClose}
                  ></AlertDismissible>
                </Grid>
              </Grid>
              <Card>
                <CardContent>
                  <Grid container className="add-tenant-row">
                    <Grid item className="add-tenant-col" size={4}>
                      <div className="form-group">
                        <label className="required" htmlFor="name">
                          {t("Call Center Name")}
                        </label>
                        <Field
                          name="name"
                          type="text"
                          autoComplete="off"
                          className={
                            "form-control" +
                            (props.errors.name && props.touched.name
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                        {props.errors.name && props.touched.name && (
                          <div className="input-feedback add-tenant-validation">
                            {props.errors.name}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="address.street1">
                          {t("Call Center Address")}
                        </label>
                        <Field
                          name="address.street1"
                          type="text"
                          autoComplete="off"
                          className={
                            "form-control" +
                            (getIn(props.errors, "address.street1") &&
                            getIn(props.touched, "address.street1")
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="address.street2">
                          {t("Call Center Address")} 2
                        </label>
                        <Field
                          name="address.street2"
                          type="text"
                          autoComplete="off"
                          className={
                            "form-control" +
                            (getIn(props.errors, "address.street2") &&
                            getIn(props.touched, "address.street2")
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="address.city">
                          {t("Call Center City")}
                        </label>
                        <Field
                          name="address.city"
                          type="text"
                          autoComplete="off"
                          className={
                            "form-control" +
                            (getIn(props.errors, "address.city") &&
                            getIn(props.touched, "address.city")
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="address.zip">
                          {t("Call Center Zip")}
                        </label>
                        <Field
                          name="address.zip"
                          type="text"
                          autoComplete="off"
                          className={
                            "form-control" +
                            (getIn(props.errors, "address.zip") &&
                            getIn(props.touched, "address.zip")
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="callCenterPhone">
                          {t("Call Center Phone")}
                        </label>
                        <Field
                          name="callCenterPhone"
                          autoComplete="off"
                          type="text"
                          className={
                            "form-control" +
                            (props.errors.callCenterPhone &&
                            props.touched.callCenterPhone
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                        {props.errors.callCenterPhone &&
                          props.touched.callCenterPhone && (
                            <div className="input-feedback add-tenant-validation">
                              Invalid phone numbber
                            </div>
                          )}
                      </div>
                    </Grid>
                    <Grid item className="add-tenant-col" size={4}>
                      <div className="form-group">
                        <label htmlFor="contactPersonFirstName">
                          {t("Contact Person First Name")}
                        </label>
                        <Field
                          name="contactPersonFirstName"
                          type="text"
                          autoComplete="off"
                          className={
                            "form-control" +
                            (props.errors.contactPersonFirstName &&
                            props.touched.contactPersonFirstName
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                        {props.errors.contactPersonFirstName &&
                          props.touched.contactPersonFirstName && (
                            <div className="input-feedback add-tenant-validation">
                              {props.errors.contactPersonFirstName}
                            </div>
                          )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="contactPersonLastName">
                          {t("Contact Person Last Name")}
                        </label>
                        <Field
                          name="contactPersonLastName"
                          type="text"
                          autoComplete="off"
                          className={
                            "form-control" +
                            (props.errors.contactPersonLastName &&
                            props.touched.contactPersonLastName
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                        {props.errors.contactPersonLastName &&
                          props.touched.contactPersonLastName && (
                            <div className="input-feedback add-tenant-validation">
                              {props.errors.contactPersonLastName}
                            </div>
                          )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="contactPhone">
                          {t("Contact Phone")}
                        </label>
                        <Field
                          name="contactPhone"
                          type="text"
                          autoComplete="off"
                          className={
                            "form-control" +
                            (props.errors.contactPhone &&
                            props.touched.contactPhone
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                        {props.errors.contactPhone &&
                          props.touched.contactPhone && (
                            <div className="input-feedback add-tenant-validation">
                              Invalid phone numbber
                            </div>
                          )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="contactEmail">
                          {t("Contact Email")}
                        </label>
                        <Field
                          name="contactEmail"
                          type="text"
                          autoComplete="off"
                          onChange={(e) =>
                            props.setFieldValue(
                              "contactEmail",
                              e.target.value.toLowerCase()
                            )
                          }
                          className={
                            "form-control" +
                            (props.errors.contactEmail &&
                            props.touched.contactEmail
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                        {props.errors.contactEmail &&
                          props.touched.contactEmail && (
                            <div className="input-feedback add-tenant-validation">
                              {props.errors.contactEmail}
                            </div>
                          )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="notificationEmail">
                          {t("Notification Email")}
                        </label>
                        <Field
                          name="notificationEmail"
                          type="text"
                          autoComplete="off"
                          onChange={(e) =>
                            props.setFieldValue(
                              "notificationEmail",
                              e.target.value.toLowerCase()
                            )
                          }
                          className={
                            "form-control" +
                            (props.errors.notificationEmail &&
                            props.touched.notificationEmail
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                        {props.errors.notificationEmail &&
                          props.touched.notificationEmail && (
                            <div className="input-feedback add-tenant-validation">
                              {props.errors.notificationEmail}
                            </div>
                          )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="callCenterStatus">{t("Status")}</label>
                        <Field
                          name={"callCenterStatus"}
                          onBlur={props.handleBlur}
                          component={SelectField}
                          options={tenantStatusOption}
                          values={props.values.callCenterStatus}
                        />
                      </div>
                    </Grid>
                    <Grid item className="add-tenant-col" size={4}>
                      <div className="form-group">
                        <label htmlFor="note">{t("Call Center Notes")}</label>
                        <textarea
                          name="note"
                          rows="6"
                          className={
                            "form-control" +
                            (props.errors.note && props.touched.note
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="queue1">{t("Queue")} #1</label>
                        <Field
                          name="queue1"
                          type="text"
                          autoComplete="off"
                          className={
                            "form-control" +
                            (props.errors.queue1 && props.touched.queue1
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="queue2">{t("Queue")} #2</label>
                        <Field
                          name="queue2"
                          type="text"
                          autoComplete="off"
                          className={
                            "form-control" +
                            (props.errors.queue2 && props.touched.queue2
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="queue3">{t("Queue")} #3</label>
                        <Field
                          name="queue3"
                          type="text"
                          autoComplete="off"
                          className={
                            "form-control" +
                            (props.errors.queue3 && props.touched.queue3
                              ? " is-invalid"
                              : " add-tenant-input")
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item size={12}>
                      <div className="form-group float-right">
                        <Button
                          variant="primary"
                          type="submit"
                          className="rounded-0 mr-4 save-btn button_with_loader"
                          //   disabled={!props.dirty || props.isSubmitting}
                          disabled={this.state.disablesavebtn}
                        >
                          {this.state.disablesavebtn ? (
                            <>
                              {t("Save")}...
                              <div className="custom-loader linesLoader loader_inner_button">
                                <Spinner color="#ffffff" size={"19px"} />
                              </div>
                            </>
                          ) : (
                            <span>{t("Save")}</span>
                          )}
                        </Button>
                        <Button
                          variant="secondary"
                          type="button"
                          className="rounded-0 cancel-btn"
                          onClick={this.cancelCallCenterClick}
                        >
                          {t("Cancel")}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Form>
          )}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation()(AddCallCenter);
