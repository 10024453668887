import * as actionTypes from "../constants";
import { getSendInvitation } from "../../Services/participantsService";

const rowSMSData = (participantId, loadParticipantsListCallback) => {
  console.log("clicked", participantId);
  return (dispatch) => {
    getSendInvitation(participantId, "sms")
      .then((res) => {
        // console.log(res.data)
        dispatch(
          getParticpantAction({
            alertMsg: res.data ? res.data : "Something went wrong",
            variant: "success",
            alertStatus: true,
          })
        );
        loadParticipantsListCallback();
      })
      .catch((err) => {
        console.log("Error", err);
        dispatch(
          getParticpantActionError({
            alertMsg: err.response.data.message
              ? err.response.data.message
              : "Something went wrong",
            variant: "danger",
            alertStatus: true,
          })
        );
      });
  };
};

const handleSMSAlertClose = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SMS_ALERT_CLOSE,
    });
  };
};
const getParticpantAction = (res) => {
  //   console.log("getParticpantAction", res);
  return {
    type: actionTypes.SMS_MESSAGE,
    payload: res,
  };
};
const getParticpantActionError = (res) => {
  console.log("get Action_list", res);
  return {
    type: actionTypes.SMS_MESSAGE_ERROR,
    payload: res,
  };
};

export const participantActions = {
  rowSMSData,
  handleSMSAlertClose,
};
