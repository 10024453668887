import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Field } from "formik";
import { Grid2 as Grid } from "@mui/material";
import viewCloseIcon from "../../../assets/icon/view-close.png";
import viewIcon from "../../../assets/icon/view-icon.png";

const AdditionConfiguration = (props) => {
  const { t } = useTranslation();
  const { formik } = props;
  const { values, setFieldValue } = formik;
  const [toggleApikeyValue, setToggleApikeyValue] = useState(false);
  const [toggleSecretkeyValue, setToggleSecretkeyValue] = useState(false);
  const [toggleClientIdkeyValue, setToggleClientIdkeyValue] = useState(false);

  return (
    <Grid item size={12} className="clearfix">
      <div className="mb-2 mt-2 additional_configuration_accordion">
        <Accordion allowZeroExpanded="true" allowMultipleExpanded="true">
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="row-header">
                  <div>
                    <label className="row-header-title">
                      {t("Additional Configuration")}
                    </label>
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item size={6}>
                  <div className="form-group inline_label_fields">
                    <label htmlFor="participantCallbackUrl">
                      {t("Participant Webhook URL")}
                    </label>
                    <Field
                      name="clientAPIUrl1.participant_callback_url"
                      id="participantCallbackUrl"
                      type="text"
                      className={"form-control"}
                      value={values.clientAPIUrl1.participant_callback_url}
                    />
                  </div>
                </Grid>
                <Grid item size={6}>
                  <div className="form-group inline_label_fields">
                    <label htmlFor="resultCallbackUrl">
                      {t("Result Webhook URL")}
                    </label>
                    <Field
                      name="clientAPIUrl1.result_callback_url"
                      id="resultCallbackUrl"
                      type="text"
                      className={"form-control"}
                      value={values.clientAPIUrl1.result_callback_url}
                    />
                  </div>
                </Grid>
                <Grid item size={6}>
                  <div className="form-group inline_label_fields">
                    <label htmlFor="projectCallbackUrl">
                      {t("Project Webhook URL")}
                    </label>
                    <Field
                      name="clientAPIUrl1.project_callback_url"
                      type="text"
                      id="projectCallbackUrl"
                      className={"form-control"}
                      value={values.clientAPIUrl1.project_callback_url}
                    />
                  </div>
                </Grid>
                <Grid item size={6}>
                  <div className="form-group inline_label_fields">
                    <label htmlFor="participantVerificationUrl">
                      {t("Participant Verification URL")}
                    </label>
                    <Field
                      name="clientAPIUrl1.participant_verification_url"
                      id="participantVerificationUrl"
                      type="text"
                      className={"form-control"}
                      value={values.clientAPIUrl1.participant_verification_url}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item size={6}>
                  <div className="form-group1">
                    <label htmlFor="security_type">{t("Security Type")}</label>
                  </div>
                  <Grid container>
                    <Grid item size={3} className="pr-0">
                      <div className="form-group">
                        <label className="custom-radio mt-2">
                          <input
                            type="radio"
                            id="securityTypeFirst"
                            name={"clientAPIUrl1.security_type"}
                            value="API_KEY"
                            onChange={() =>
                              setFieldValue(
                                "clientAPIUrl1.security_type",
                                "API_KEY"
                              )
                            }
                            checked={
                              values.clientAPIUrl1.security_type === "API_KEY"
                            }
                          />
                          {t("API KEY")}
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </Grid>
                    <Grid item size={9} className="pl-0">
                      <div className="form-group">
                        <label className="custom-radio mt-2">
                          <input
                            type="radio"
                            name={"clientAPIUrl1.security_type"}
                            id="securityTypeSecond"
                            value="CLIENT_CREDENTIAL"
                            onChange={() =>
                              setFieldValue(
                                "clientAPIUrl1.security_type",
                                "CLIENT_CREDENTIAL"
                              )
                            }
                            checked={
                              values.clientAPIUrl1.security_type ===
                              "CLIENT_CREDENTIAL"
                            }
                          />
                          {t("CLIENT CREDENTIAL")}
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item size={6}>
                  <Grid container>
                    <Grid item size={12}>
                      <div className="form-check mt-4">
                        <Field
                          type="checkbox"
                          name="clientAPIUrl1.caller_id_check"
                          checked={values.clientAPIUrl1.caller_id_check}
                          className="form-check-input"
                          id="callerIdCheck"
                        />
                        <label
                          htmlFor="callerIdCheck"
                          className="form-check-label"
                        >
                          {t("Caller ID Check")}
                        </label>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {values.clientAPIUrl1.security_type === "API_KEY" && (
                <Grid container>
                  <Grid item size={6}>
                    <div className="form-group inline_label_fields">
                      <label htmlFor="apiKey">{t("Api Key")}</label>
                      <Field
                        name="clientAPIUrl1.api_key"
                        autoComplete="api_key"
                        id="apiKey"
                        type={!toggleApikeyValue ? "text" : "password"}
                        className={"form-control"}
                        value={values.clientAPIUrl1.api_key}
                      />
                      <span
                        className="eye-icon"
                        onClick={() =>
                          setToggleApikeyValue(
                            (toggleApikeyValue) => !toggleApikeyValue
                          )
                        }
                      >
                        {toggleApikeyValue ? (
                          <img src={viewCloseIcon} />
                        ) : (
                          <img src={viewIcon} />
                        )}
                      </span>
                    </div>
                  </Grid>
                </Grid>
              )}
              {values.clientAPIUrl1.security_type === "CLIENT_CREDENTIAL" && (
                <Grid container>
                  <Grid item size={6}>
                    <div className="form-group inline_label_fields">
                      <label htmlFor="secret">{t("Secret")}</label>
                      <Field
                        name="clientAPIUrl1.client_credential.secret"
                        autoComplete="secret"
                        id="secret"
                        type={!toggleSecretkeyValue ? "text" : "password"}
                        className={"form-control"}
                        value={values.clientAPIUrl1.client_credential.secret}
                      />
                      <span
                        className="eye-icon"
                        onClick={() =>
                          setToggleSecretkeyValue(
                            (toggleSecretkeyValue) => !toggleSecretkeyValue
                          )
                        }
                      >
                        {toggleSecretkeyValue ? (
                          <img src={viewCloseIcon} />
                        ) : (
                          <img src={viewIcon} />
                        )}
                      </span>
                    </div>
                  </Grid>
                  <Grid item size={6}>
                    <div className="form-group inline_label_fields">
                      <label htmlFor="audience">{t("Audience")}</label>
                      <Field
                        name="clientAPIUrl1.client_credential.audience"
                        type="text"
                        id="audience"
                        className={"form-control"}
                        value={values.clientAPIUrl1.client_credential.audience}
                      />
                    </div>
                  </Grid>
                  <Grid item size={6}>
                    <div className="form-group inline_label_fields">
                      <label htmlFor="clientId">{t("client ID")}</label>
                      <Field
                        name="clientAPIUrl1.client_credential.clientid"
                        autoComplete="clientid"
                        id="clientId"
                        type={!toggleClientIdkeyValue ? "text" : "password"}
                        className={"form-control"}
                        value={values.clientAPIUrl1.client_credential.clientid}
                      />
                      <span
                        className="eye-icon"
                        onClick={() =>
                          setToggleClientIdkeyValue(
                            (toggleClientIdkeyValue) => !toggleClientIdkeyValue
                          )
                        }
                      >
                        {toggleClientIdkeyValue ? (
                          <img src={viewCloseIcon} />
                        ) : (
                          <img src={viewIcon} />
                        )}
                      </span>
                    </div>
                  </Grid>
                  <Grid item size={6}>
                    <div className="form-group inline_label_fields">
                      <label htmlFor="tokenUri">{t("Token URI")}</label>
                      <Field
                        name="clientAPIUrl1.client_credential.tokenuri"
                        type="text"
                        id="tokenUri"
                        className={"form-control"}
                        value={values.clientAPIUrl1.client_credential.tokenuri}
                      />
                    </div>
                  </Grid>
                  <Grid item size={6}>
                    <div className="form-group inline_label_fields">
                      <label htmlFor="authorization">
                        {t("AUTHORIZATION")}
                      </label>
                      <Field
                        name="clientAPIUrl1.client_credential.authourization"
                        type="text"
                        id="authorization"
                        className={"form-control"}
                        value={
                          values.clientAPIUrl1.client_credential.authourization
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item size={6}>
                    <div className="form-group inline_label_fields">
                      <label htmlFor="scope">{t("Scope")}</label>
                      <Field
                        name="clientAPIUrl1.client_credential.scope"
                        type="text"
                        id="scope"
                        className={"form-control"}
                        value={values.clientAPIUrl1.client_credential.scope}
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </Grid>
  );
};

export default AdditionConfiguration;
