import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Button, ModalPopUp, Spinner } from "../../../components/Common";

function ConfirmDeleteUser(props) {
  const { t } = useTranslation();
  return (
    <ModalPopUp
      show={props.show}
      size={"md"}
      onHide={() => props.delete(props.id, false)}
      backdrop={"static"}
      keyboard={false}
      centered
      className="delete-confirmation-modal"
      title={"Confirmation"}
    >
      <div>
        <div className="react-bootstrap-table">
          <p className="d-flex text-bold text-left modal-box-text1 m-0">
            Are you sure you want to delete this user?
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <div className="d-flex justify-content-around mt-3 mr-3">
          <Button
            variant="primary"
            type="submit"
            className="rounded-0 mr-4 save-btn"
            onClick={() => props.delete(props.id, true)}
            disabled={props.isLoading}
          >
            {props.isLoading ? (
              <>
                {t("Save")}...
                <div className="custom-loader linesLoader loader_inner_button">
                  <Spinner color="#ffffff" size={"19px"} />
                </div>
              </>
            ) : (
              <span>{t("Confirm")}</span>
            )}
          </Button>
          <Button
            variant="secondary"
            onClick={() => props.delete(props.id, false)}
          >
            Close
          </Button>
        </div>
      </div>
    </ModalPopUp>
  );
}

export default withTranslation()(ConfirmDeleteUser);
