import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button as MuiButton } from "@mui/material";

export default function Button({
  type = "button",
  variant = "primary",
  disabled = false,
  className = "",
  children,
  onClick,
  isEndIcon = false,
  icon = faPlus,
  iconButton = false,
  ref,
}) {
  const buttonStyles = useMemo(() => {
    const baseStyles = {
      textAlign: "center",
      minWidth: "1px",
      cursor: "pointer",
    };
    const buttonIcon = iconButton && {
      height: "30px",
      width: "30px",
    };
    const variantStyles =
      variant === "primary"
        ? {
            backgroundColor: "#1665d8",
            borderColor: "#1665d8",
            "&:hover": {
              backgroundColor: "#1665d8",
            },
          }
        : variant === "secondary"
        ? {
            backgroundColor: "#5a6268",
            borderColor: "#545b62",
            "&:hover": {
              backgroundColor: "#545b62",
            },
          }
        : {};

    return { ...baseStyles, ...variantStyles, ...buttonIcon };
  }, [variant]);

  return (
    <MuiButton
      type={type}
      color={variant}
      className={className}
      disabled={disabled}
      variant="contained"
      onClick={onClick}
      endIcon={
        isEndIcon && <FontAwesomeIcon icon={icon} className="button_end_icon" />
      }
      sx={buttonStyles}
      ref={ref}
    >
      {children}
    </MuiButton>
  );
}
