import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MenuItem } from "@mui/material";
import { authActions } from "./store/actions/authAction";

const Signout = ({ loggedIn, logOut, history }) => {
  const { logout } = useAuth0();
  const { t } = useTranslation();
  const handleClick = () => {
    logOut("signOutFromBtn", logout);
  };

  return (
    <MenuItem onClick={handleClick}>
      <span className="">{t("Logout")}</span>
    </MenuItem>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.authentication.loggedIn,
  };
};

const mapDispatchToProps = {
  logOut: authActions.logOut,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Signout)
);
