import React from "react";
import Select from "react-select";
import { Field, ErrorMessage } from "formik";
import TextError from "../../TextError";

function SelectBox(props) {
  const { options, containerStyle, fieldStyle, errorMsgStyle, name, ...rest } =
    props;

  return (
    <div className={containerStyle}>
      <Field name={name} className={fieldStyle}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <Select
              className="select-style"
              classNamePrefix="select"
              id={name}
              {...rest}
              options={options}
              value={value}
              onChange={(val) => setFieldValue(name, val)}
            />
          );
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default SelectBox;
