import React, { Component } from "react";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { Card, CardContent, CardHeader, Grid2 as Grid } from "@mui/material";
import * as FileSaver from "file-saver";
import moment from "moment";
import { getlanguageIdFromcode } from "../../../Helpers/index";
import {
  downloadParticipantTemplate,
  exportParticipants,
  getParticipantList,
  getRefreshAccessCode,
  getSendInvitation,
  isEmailTemplateConfigured,
  removeParticipants,
  selectedNPParticipant,
  selectedParticipantHide,
  sendInvitationEmail,
  updateNPParticipantStatus,
  uploadParticipants,
  verifySendInvitations,
} from "../../../Services/participantsService";
import {
  getAttribute,
  getProjectDetail,
  getResultErrorFileDownload,
} from "../../../Services/projectServices";
import { getUserOptions } from "../../../Services/userServices";
import email_icon from "../../../assets/icon/Email.png";
import data_options from "../../../assets/icon/dataoptions.png";
import data_options_dark from "../../../assets/icon/dataoptionsw.png";
import delete_data from "../../../assets/icon/delete-data.png";
import delete_dataw from "../../../assets/icon/delete-dataw.png";
import download_icon from "../../../assets/icon/download_icon.png";
import download_iconw from "../../../assets/icon/download_iconw.png";
import email_failure from "../../../assets/icon/email_failure.png";
import email_scheduled_dark from "../../../assets/icon/email_scheduled_dark.png";
import email_scheduled_white from "../../../assets/icon/email_scheduled_light.png";
import email_success from "../../../assets/icon/email_success.png";
import export_icon from "../../../assets/icon/export_icon.png";
import export_iconw from "../../../assets/icon/export_iconw.png";
import hide_icon from "../../../assets/icon/hide-blue.png";
import hide_iconw from "../../../assets/icon/hide-white.png";
import small_email_icon from "../../../assets/icon/mail.png";
import mail_Fail_Dark from "../../../assets/icon/mail_Fail_Dark.png";
import mail_Sent_Dark from "../../../assets/icon/mail_Sent_Dark.png";
import message_icon_new from "../../../assets/icon/message1.png";
import marked_not_np from "../../../assets/icon/notNpMark.png";
import marked_np from "../../../assets/icon/npMark.png";
import phone_icon from "../../../assets/icon/phone.png";
import refresh_icon from "../../../assets/icon/refreshNew.png";
import sms_Fail_Dark from "../../../assets/icon/sms_Fail_Dark.png";
import sms_Sent_Dark from "../../../assets/icon/sms_Sent_Dark.png";
import sms_failure from "../../../assets/icon/sms_failure.png";
import sms_scheduled_dark from "../../../assets/icon/sms_scheduled_dark.png";
import sms_scheduled_white from "../../../assets/icon/sms_scheduled_light.png";
import sms_success from "../../../assets/icon/sms_success.png";
import upload_icon from "../../../assets/icon/upload_icon.png";
import upload_iconw from "../../../assets/icon/upload_iconw.png";
import view_icon from "../../../assets/icon/view-blue.png";
import view_iconw from "../../../assets/icon/view-white.png";
import {
  Alert as AlertDismissible,
  ModalPopUp,
} from "../../../components/Common";
import { Date_Format } from "../../../config";
import i18n from "../../../locale/i18n";
import { filenameFromResponseHeader } from "../../../shared/utils";
import { participantActions } from "../../../store/actions/participantAction";
import { CustomGrid } from "../../CustomTable";
import { CustomTooltip } from "../../Utility/CustomTooltip";
import FullScreenLoader from "../../Utility/FullScreenLoader";
import { IsConfirm, IsOK } from "../../Utility/ModalPopUp";
import { MultiSelectDrpDownWithAll } from "../../Utility/MultiSelectDropDown/MultiSelectDrpDownWithAll";
import { MarkNpModal } from "./MarkNpModal";
import { MarkNpParticipant } from "./MarkNpParticipant";
import ParticipantDetail from "./ParticipantDetail";
import {
  HandleParticipantRowSelection,
  ParticipantIrnCheckBox,
} from "./ParticipantUtils";

const getDateInFormat = (time, format) => {
  if (time != null) return moment(time).format(format);
  return "";
};

class ParticipantList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participants: [],
      projectDetail: [],
      selectedParticipant: [],
      selectAllCheckbox: false,
      applyFilter: false,
      searchText: "",
      locationId: null,
      statusValue: null,
      projectId: "",
      rows: [],
      statusA: [],
      locationA: [],
      uploafFileModalShow: false,
      file: "",
      errorModalShow: false,
      catchModalShow: false,
      successModalShow: false,
      currentPage: 0,
      perPageCount: 25,
      totalPages: 0,
      totalResults: 0,
      sortOrder: {
        irnno: "asc",
        date: "desc",
      },
      isinternal: [],
      noPermissionmsg: "Loading...",
      loading: true,
      sortLoading: true,
      alertStatus: false,
      alertMsg: "",
      variant: "",
      isSearch: false,
      isConfirm: false,
      isSendEmailConfirm: false,
      isSendEmailConfirmError: false,
      deleteParticipantMsg: this.props.t(
        "Please click OK to delete the selected participant(s) from the participant list"
      ),
      deletePartFlag: false,
      selectAllPageData: false,
      language: i18n.language,
      TenantName: "",
      scrollHeight: "",
      rowid: "",
      inviteType: "",
      loadingFileUpload: false,
      showParticipantDetail: false,
      ParticipantData: "",
      languageOption: [],
      languageValue: [],
      languageIds: "",
      showmsgInModal: false,
      modalMsg: "",
      errorMsg: "",
      testParticipant: false,
      isSystemTenant: false,
      canAccountReadAuditTrail: false,
      configuredMsg: "",
      createdByOption: [],
      createdByUserId: null,
      selectCreatedByOption: null,
      exportParticipantFileDownload: false,
      exportError: false,
      exportErrorMessage: "",
      showMarkNpModal: false,
      npAction: "",
      selectedNpParticipant: [],
      isNpLoading: false,
    };
    this.fileInput = React.createRef();
    this.selectDropdown = this.selectDropdown.bind(this);
  }

  loadAttribute = () => {
    let self = this;
    let languagecode = i18n.language;
    let languageId = getlanguageIdFromcode(languagecode);
    getAttribute(languageId)
      .then((res) => {
        self.filterAttribute(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  canAccountReadAuditTrail = (permissionList) => {
    const permission = permissionList.filter(function (permissionObj) {
      return (
        permissionObj.permission ==
        "tenant.project.participant.action.list.read"
      );
    });

    return permission.length > 0;
  };

  componentDidMount() {
    this.getUserDataOption();
    this.checkEmailTemplateConfiguration();
    this.props.handleSMSAlertClose();
    let self = this;
    let id = parseInt(this.props.id);

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const isSystemTenant = userInfo.tenant.isSystemTenant;

    const canAccountReadAuditTrail = this.canAccountReadAuditTrail(
      userInfo.rolePermission.permissions
    );

    this.setState({
      isSystemTenant: isSystemTenant,
      canAccountReadAuditTrail: canAccountReadAuditTrail,
    });

    getProjectDetail(id)
      .then((res) => {
        let langaugeIds = [];
        res.data.projectLanguagesData.map((languages) => {
          langaugeIds.push(languages.value);
        });
        this.setState(
          {
            projectDetail: res.data,
            languageOption: res.data.projectLanguagesData,
            languageIds: langaugeIds,
          },
          () => {
            let initialParticipants = this.initialParticipants();
            this.loadParticipantsList(initialParticipants);
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
    this.loadAttribute();
    i18next.on("languageChanged", function (lng) {
      self.setState(
        {
          language: lng,
        },
        () => {
          self.loadAttribute();
        }
      );
    });
  }
  initialParticipants = () => {
    const id = this.props.id;
    let initialdata = {
      page: this.state.currentPage,
      pageSize: this.state.perPageCount,
      sortBy: this.state.sortOrder,
      search: this.state.searchText !== "" ? this.state.searchText : null,
      locationId: this.state.locationId,
      participantLanguageIds: this.state.languageIds,
      projectId: id,
      participantStatus: this.state.statusValue,
      testParticipant: this.state.testParticipant,
      createdBy: this.state.createdByUserId,
      systemCreateByUser:
        this.state.selectCreatedByOption === "SYSTEM" ? true : false,
    };
    return initialdata;
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.state.isinternal.length !== nextProps.statedata.isinternal.length
    ) {
      this.setState((preState) => {
        return {
          isinternal: nextProps.statedata.isinternal,
          noPermissionmsg: "",
        };
      });
    }
  }

  loadParticipantsList = (initialdata) => {
    const id = this.props.id;
    let currentPage = this.state.currentPage;
    const box = document.getElementById("handleDataOption");
    getParticipantList(id, initialdata)
      .then((res) => {
        const totalPages = Math.ceil(
          res.data.totalRecords / this.state.perPageCount
        );
        this.setState(
          {
            participants: res.data,
            rows: res.data.participants,
            totalResults: res.data.totalRecords,
            totalPages: totalPages,
            loading: false,
            sortLoading: false,
          },
          () => {
            let selectAll = false;
            box.classList.remove("hideDataOption");
            selectAll =
              this.state["page" + currentPage] != undefined &&
              this.state["page" + currentPage].length == this.state.rows.length;
            let isallDataselected = this.state.selectAllPageData
              ? true
              : selectAll;
          }
        );
      })
      .catch((err) => {
        box.classList.remove("hideDataOption");
        this.setState({
          sortLoading: false,
        });
      });
  };

  onChangeRecords = (perPageCount) => {
    this.setState(
      {
        perPageCount: perPageCount.pageSize,
        participants: [],
        rows: [],
        currentPage: perPageCount.page,
        selectAllCheckbox: false,
        selectAllPageData: false,
        selectedParticipant: [],
        loading: true,
      },
      () => {
        let initialParticipants = this.initialParticipants();
        this.loadParticipantsList(initialParticipants);
      }
    );
  };

  getUserDataOption = () => {
    let id = parseInt(this.props.id);
    getUserOptions(id)
      .then((res) => {
        let option =
          res.data &&
          res.data.map((option) => {
            return {
              value: option.userid,
              label: option.name,
            };
          });
        this.setState({
          createdByOption: option,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onHandleChangeUser = (option) => {
    let optionValue = option != null ? option.value : null;
    let optionLabel = option !== null ? option.label : null;
    this.setState(
      {
        createdByUserId: optionValue,
        participants: [],
        selectedParticipant: [],
        selectedNpParticipant: [],
        applyFilter: true,
        rows: [],
        totalResults: 0,
        currentPage: 0,
        loading: true,
        selectCreatedByOption: optionLabel,
      },
      () => {
        let initialParticipants = this.initialParticipants();
        this.loadParticipantsList(initialParticipants);
      }
    );
  };

  filterAttribute = (res) => {
    let { t } = this.props;
    let statusOption = res.data.participantStatus.map((option) => {
      return {
        value: option.statusKey,
        label: t(option.statusValue),
      };
    });
    let locations = res.data.attributes.filter((item) => {
      return item.attributeType === "LOCATION";
    });
    let locationOption = locations.map((option) => {
      return {
        value: option.attributeId,
        label: option.description,
        sequence: option.sequence,
      };
    });
    this.setState({
      statusA: statusOption,
      locationA: locationOption,
    });
  };

  selectParticipant = (participant, event) => {
    let selectAll = false;
    let currentPage = this.state.currentPage;
    let name = event.target.name;
    let target = event.target;
    const row_id = participant.participantId;
    // return;
    this.setState({
      rowid: row_id,
    });

    const value = target.type === "checkbox" ? target.checked : target.value;
    let preParticipant = [];
    let npParticipant = [];
    if (value) {
      preParticipant = [
        ...this.state.selectedParticipant,
        { participantId: participant.participantId },
      ];
      npParticipant = [...this.state.selectedNpParticipant, participant];
    } else {
      preParticipant = this.state.selectedParticipant.filter(
        (participants) => participants.participantId !== row_id
      );
      npParticipant = this.state.selectedNpParticipant.filter(
        (participants) => participants.participantId !== row_id
      );
    }
    this.setState(
      (prevState) => {
        return {
          [name]: value,
          ["page" + currentPage]: preParticipant,
          selectedParticipant: preParticipant,
          selectAllPageData: false,
          rowId: participant.participantId,
          selectedNpParticipant: npParticipant,
        };
      },
      () => {
        if (this.state["page" + currentPage].length == this.state.rows.length) {
          selectAll = true;
        }
        this.setState((prevState) => {
          return {
            selectAllCheckbox: selectAll,
          };
        });
        this.setDeleteMsg();
      }
    );
  };

  selectAllParticipant = (value) => {
    let preSelect = [];
    let npParticipant = [];
    let pageKey = "page" + this.state.currentPage;
    let keyObj = {};
    this.state.rows.map((participant) => {
      let cname = `IRN_${participant.participantId}`;
      if (value) {
        preSelect = [
          ...preSelect,
          { participantId: participant.participantId },
        ];
        npParticipant = [...npParticipant, participant];
      } else {
        preSelect = [];
        npParticipant = [];
      }
      keyObj = { ...keyObj, [cname]: value };
    });
    this.setState((preState) => {
      return {
        ...preState,
        ...keyObj,
        selectedParticipant: preSelect,
        [pageKey]: preSelect,
        selectedNpParticipant: npParticipant,
      };
    });
  };

  selectAll = (event) => {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    let name = event.target.name;
    this.selectAllParticipant(value);
    if (!value) {
      this.setState((prevState) => {
        return {
          selectAllPageData: value,
        };
      });
    }
    this.setState(
      (prevState) => {
        return {
          [name]: value,
        };
      },
      () => this.setDeleteMsg()
    );
  };

  selectDropdown(e) {
    switch (e) {
      case "allRecord":
        this.setState(
          {
            selectAllPageData: true,
            selectAllCheckbox: true,
          },
          () => {
            this.selectAllParticipant(true);
          }
        );
        break;
      case "selected":
        this.selectAllParticipant(true);
        this.setState(
          {
            selectAllCheckbox: true,
            selectAllPageData: false,
          },
          () => this.setDeleteMsg()
        );
        break;
      case "none":
        this.selectAllParticipant(false);
        this.setState(
          {
            selectAllCheckbox: false,
            selectAllPageData: false,
          },
          () => this.setDeleteMsg()
        );
        break;
    }
  }

  selectStatus = (option) => {
    this.state.selectedParticipant = [];
    this.state.selectedNpParticipant = [];
    let text = option != null ? option.value : null;

    let particvalue = {};

    this.state.participants.participants.map(
      (participant) => (particvalue["IRN_" + participant.participantId] = false)
    );

    Object.keys(this.state).forEach((key) => {
      if (key.startsWith("page")) {
        this.state[key] = []; // set it to false
      }
    });
    this.setState(
      {
        statusValue: text,
        participants: [],
        selectedParticipant: [],
        selectedNpParticipant: [],
        applyFilter: true,
        rows: [],
        totalResults: 0,
        currentPage: 0,
        loading: true,
        ...particvalue,
      },
      () => {
        let initialParticipants = this.initialParticipants();
        this.loadParticipantsList(initialParticipants);
      }
    );
  };

  setDeleteMsg = () => {
    let { t } = this.props;
    let msg = t("Please Deselect Result Published & Interviewed participants?");
    let flag = false;
    for (let i = 0; i < this.state.selectedParticipant.length; i++) {
      let part = this.state.selectedParticipant[i];
      let index = this.state.rows.findIndex(
        (item) => item.participantId === part.participantId
      );
      if (index >= 0) {
        flag =
          this.state.rows[index].status !== null &&
          this.state.rows[index].status != "INVITATION_SENT" &&
          this.state.rows[index].status != "INVITATION_RESENT" &&
          this.state.rows[index].status !== "NEW_PARTICIPANT" &&
          this.state.rows[index].status !== "INVITATION_SCHEDULED"
            ? true
            : false;
      }

      if (flag) break;
    }
    if (flag) {
      this.setState({
        deleteParticipantMsg: msg,
        deletePartFlag: true,
      });
    } else {
      this.setState({
        deleteParticipantMsg: t(
          "Please click OK to delete the selected participant(s) from the participant list"
        ),
        deletePartFlag: false,
      });
    }
  };

  loadParticipantsListCallback = () => {
    setTimeout(() => {
      let initialParticipants = this.initialParticipants();
      this.loadParticipantsList(initialParticipants);
    }, 1000);
  };

  rowEmailData = (participantId) => {
    let { t } = this.props;
    this.setState({
      loading: true,
    });
    getSendInvitation(participantId, "email")
      .then((res) => {
        this.setState(
          {
            alertMsg: t("Invitation email has been sent to participant"),
            alertStatus: true,
            variant: "success",
            loading: false,
          },
          () => {
            let initialParticipants = this.initialParticipants();
            this.loadParticipantsList(initialParticipants);
          }
        );
      })
      .catch((err) => {
        this.setState({
          alertMsg: err.response.data.message
            ? err.response.data.message
            : t("Something went wrong"),
          variant: "danger",
          alertStatus: true,
          loading: false,
        });
      });
  };

  rowAcessCode = (participantId) => {
    let { t } = this.props;
    this.setState({
      loading: true,
    });
    getRefreshAccessCode(participantId)
      .then((res) => {
        this.setState(
          {
            alertMsg: t("Acess Code Refreshed"),
            alertStatus: true,
            variant: "success",
            loading: false,
          },
          () => {
            let initialParticipants = this.initialParticipants();
            this.loadParticipantsList(initialParticipants);
          }
        );
      })
      .catch((err) => {
        this.setState({
          alertMsg: err.response.data.message
            ? err.response.data.message
            : t("Access code can not be refreshed for this participant"),
          variant: "danger",
          alertStatus: true,
          loading: false,
        });
      });
  };

  isRowChecked = (participantId) => {
    let isRowChecked = false;
    this.state.selectedParticipant.map((participant) => {
      if (participant.participantId === participantId) {
        isRowChecked = true;
      }
    });
    return isRowChecked;
  };

  getParticipantRows = (participants) => {
    let row = participants.map((participant) => {
      return {
        check: participant,
        irnno: participant,
        interviewRefNum: participant.interviewRefNum,
        participantId: participant.participantId,
        name:
          (participant.first_name || "") + " " + (participant.last_name || ""),
        accesscode: participant.accessCode || "",
        email: participant.email || "",
        phoneno: participant.phoneNumber || "",
        participantLanguage: participant.participantLanguage || "",
        location: participant.location || "",
        status: participant.status,
        isActive: participant.isActive,
        date: getDateInFormat(participant.updateTs, Date_Format) || "",
        isAllowedQuestionnaire:
          (participant.isAllowedQuestionnaire == true ? "Yes" : "No") || "",
        createTs: participant.create_ts,
        createdBy: participant.created_by,
        accessCodeUpdateTs: participant.accessCodeUpdateTs,
        sms_sent: participant.sms_sent,
        email_sent: participant.email_sent,
        email_pending: participant.email_pending,
        sms_pending: participant.sms_pending,
      };
    });
    return row;
  };

  selectUploadFile = (event) => {
    event.preventDefault();
    let id = parseInt(this.props.id);
    let fileName = this.fileInput.current.files[0];
    let formData = new FormData();
    formData.append("file", fileName);
    formData.append("projectId", id);
    this.fileInput.current.value = "";
    this.setState({
      file: formData,
      uploafFileModalShow: true,
    });
  };

  checkEmailTemplateConfiguration = () => {
    const projectId = parseInt(this.props.id);
    isEmailTemplateConfigured(projectId)
      .then((response) => {
        this.setState({ configuredMsg: response.data });
      })
      .catch((error) => {
        this.setState({ configuredMsg: error.data });
      });
  };

  uploadFile = (replaceAll) => {
    this.setState({
      uploafFileModalShow: false,
      loadingFileUpload: true,
    });
    let formData = this.state.file;
    formData.append("replaceAll", replaceAll);
    uploadParticipants(formData)
      .then((res) => {
        this.setState(
          (preState) => {
            return {
              loadingFileUpload: false,
              errorModalShow: true,
              modalData: res.data,
            };
          },
          () => {
            let initialParticipants = this.initialParticipants();
            this.loadParticipantsList(initialParticipants);
          }
        );
      })
      .catch((err) => {
        this.setState((preState) => {
          return {
            loadingFileUpload: false,
            errorModalShow: false,
            catchModalShow: true,
            errorMsg: err.response.data.message,
          };
        });
      });
  };

  debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  findParticipantByText = (event) => {
    let text = event.target.value;
    this.setState(
      (preState) => {
        return {
          searchText: text,
          participants: [],
          selectedParticipant: [],
          selectedNpParticipant: [],
          rows: [],
          totalResults: 0,
          currentPage: 0,
          loading: true,
        };
      },
      () => {
        let initialParticipants = this.initialParticipants();
        this.loadParticipantsList(initialParticipants);
      }
    );
  };

  debouncedOnChange = this.debounce(this.findParticipantByText, 1000);

  findParticipantByLanguage = (seletcedLanguage) => {
    let langaugeIds = [];
    seletcedLanguage.map((languages) => {
      if (languages !== "*") {
        langaugeIds.push(languages);
      }
    });
    this.setState(
      {
        languageValue: seletcedLanguage,
        languageIds: langaugeIds,
      },
      () => {
        let initialParticipants = this.initialParticipants();
        this.loadParticipantsList(initialParticipants);
      }
    );
  };

  deleteParticipant = () => {
    let { t } = this.props;
    const id = parseInt(this.props.id);
    let Obj = {};
    Obj.allRecords = this.state.selectAllPageData;
    Obj.projectId = id;
    Obj.participants = this.state.selectedParticipant;
    removeParticipants(Obj)
      .then((res) => {
        let current = this.state.currentPage;
        if (current != 0 && current == this.state.totalPages - 1) {
          current -= 1;
        }
        delete this.state["page" + current];
        this.setState(
          (preState) => {
            return {
              selectedParticipant: [],
              selectedNpParticipant: [],
              selectAllCheckbox: false,
              currentPage: current,
              alertStatus: true,
              alertMsg: t("Participants Delete Successfully!"),
              variant: "success",
            };
          },
          () => {
            let initialParticipants = this.initialParticipants();
            this.loadParticipantsList(initialParticipants);
          }
        );
      })
      .catch((err) => {
        console.log(err, "error in delete api");
      });
  };

  exportParticipant = () => {
    let { t } = this.props;
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = mm + dd + yyyy;
    this.state.TenantName = localStorage.getItem("operatingTenantName");
    let userTenantName = JSON.parse(localStorage.getItem("userInfo")).tenant
      .tenantName;
    this.setState({
      exportParticipantFileDownload: true,
    });
    if (this.state.TenantName == null) {
      this.setState({
        TenantName: userTenantName,
      });
    }
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const id = parseInt(this.props.id);
    let Obj = {};
    const {
      selectAllPageData,
      selectedParticipant,
      languageIds,
      statusValue,
      createdByUserId,
    } = this.state;

    Obj.allRecords = selectAllPageData;
    Obj.tenantId = userInfo ? userInfo.tenantId : null;
    Obj.projectId = id;
    Obj.participants = selectedParticipant;
    Obj.participantLanguageIds = languageIds;
    // Obj.participantStatus = statusValue;
    Obj.statusFilters = statusValue;
    Obj.createdBy = createdByUserId;
    exportParticipants(Obj)
      .then((res) => {
        const blob = new Blob([res.data], { type: "text/csv; charset=utf-8" });
        FileSaver.saveAs(
          blob,
          filenameFromResponseHeader(res.headers["content-disposition"])
        );
        this.setState({
          exportParticipantFileDownload: false,
          exportErrorMessage: "",
        });
      })
      .catch((err) => {
        if (err?.response?.status === 500) {
          this.setState({
            exportParticipantFileDownload: false,
            exportError: true,
            exportErrorMessage:
              err.response.data.message ||
              t("Something went wrong please contact support"),
          });
        } else {
          const buffer = Buffer.from(err?.response?.data);
          const object = JSON.parse(buffer.toString("utf-8"));
          this.setState({
            exportParticipantFileDownload: false,
            exportError: true,
            exportErrorMessage:
              object?.message ||
              t("Something went wrong please contact support"),
          });
        }
      });
  };

  verifyInvitation = (e, type) => {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const id = parseInt(this.props.id);
    let Obj = {};
    Obj.allRecords = this.state.selectAllPageData;
    Obj.tenantId = userInfo.tenantId;
    Obj.projectId = id;
    Obj.participants = this.state.selectedParticipant;
    Obj.statusFilters = this.state.statusValue;
    Obj.invitationType = type;
    verifySendInvitations(Obj)
      .then((res) => {
        this.setState({
          isSendEmailConfirm: true,
          inviteType: type,
        });
      })
      .catch((err) => {
        if (err.response.status === 409) {
          this.setState({
            isSendEmailConfirmError: true,
            msgtext: err.response.data.message,
            inviteType: type,
          });
        } else if (err.response.status === 417) {
          this.setState({
            isSendEmailConfirm: true,
            showmsgInModal: true,
            modalMsg: err.response.data.message,
            inviteType: type,
          });
        } else {
          this.setState({
            alertStatus: true,
            alertMsg: err.response.data.message,
            variant: "danger",
          });
        }
      });
  };

  sendInvitation = (type) => {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const id = parseInt(this.props.id);
    let Obj = {};
    Obj.allRecords = this.state.selectAllPageData;
    Obj.tenantId = userInfo.tenantId;
    Obj.projectId = id;
    Obj.participants = this.state.selectedParticipant;
    Obj.statusFilters = this.state.statusValue;
    Obj.invitationType = type;
    sendInvitationEmail(Obj)
      .then((res) => {
        this.setState(
          {
            alertMsg:
              type === "sms"
                ? "SMS inivitation have been scheduled for " +
                  this.state.selectedParticipant.length +
                  " selected participants (Participant status will update approximately in 10 minutes)"
                : "Email inivitation have been scheduled for " +
                  this.state.selectedParticipant.length +
                  " selected participants (Participant status will update approximately in 10 minutes)",
            alertStatus: true,
            variant: "success",
          },
          () => {
            let initialParticipants = this.initialParticipants();
            this.loadParticipantsList(initialParticipants);
          }
        );
      })
      .catch((err) => {
        if (err.response.status === 409) {
          this.setState({
            isSendEmailConfirmError: true,
            msgtext: err.response.data.message,
          });
        } else {
          this.setState({
            alertStatus: true,
            alertMsg: err.response.data.message,
            variant: "danger",
          });
        }
      });
  };

  handleConfirm = (e) => {
    if (e !== false && !this.state.deletePartFlag) {
      this.deleteParticipant();
    }
    this.setState({ isConfirm: false });
  };

  handleSendEmailConfirm = (e, type) => {
    if (e !== false) {
      this.sendInvitation(type);
    }
    this.setState({ isSendEmailConfirm: false, inviteType: "" });
  };

  handleSendEmailConfirmError = (e) => {
    if (e !== false) {
      this.setState({ isSendEmailConfirmError: false });
    }
  };

  downloadTemplate = () => {
    downloadParticipantTemplate()
      .then((res) => {
        const blob = new Blob([res.data], { type: "text/xlsx; charset=utf-8" });
        FileSaver.saveAs(blob, "ParticipantTemplate.xlsx");
      })
      .catch((err) => {
        console.log("error in downloadParticipantTemplate");
      });
  };

  handleModalClose = () => {
    this.setState({
      uploafFileModalShow: false,
      errorModalShow: false,
      successModalShow: false,
      catchModalShow: false,
    });
  };

  handleAlertClose = () => {
    this.setState({
      alertStatus: false,
    });
  };

  dateFormatter = (cell, row, index) => {
    return (
      <>
        {this.state.canAccountReadAuditTrail || this.state.isSystemTenant ? (
          <a
            href="#"
            key={index}
            onClick={(e) => this.openParticipantDetail(e, row)}
          >
            <span>
              {cell ? moment(new Date(cell)).format(Date_Format) : cell}
            </span>
          </a>
        ) : (
          <span className="participant-irn">
            {cell ? moment(new Date(cell)).format(Date_Format) : cell}
          </span>
        )}
      </>
    );
  };

  openParticipantDetail = (e, row) => {
    e.preventDefault();
    this.setState({ ParticipantData: row });
    this.setState({ showParticipantDetail: true });
  };

  showTestParticipant = () => {
    const box = document.getElementById("handleDataOption");
    box.classList.add("hideDataOption");
    this.setState(
      {
        testParticipant: true,
        participants: [],
        rows: [],
        totalResults: 0,
        currentPage: 0,
        loading: true,
        selectedParticipant: [],
        selectedNpParticipant: [],
      },
      () => {
        let initialParticipants = this.initialParticipants();
        this.loadParticipantsList(initialParticipants);
      }
    );
  };

  hideTestParticipant = () => {
    const box = document.getElementById("handleDataOption");
    box.classList.add("hideDataOption");
    this.setState(
      {
        testParticipant: false,
        participants: [],
        rows: [],
        totalResults: 0,
        currentPage: 0,
        loading: true,
        selectedParticipant: [],
        selectedNpParticipant: [],
      },
      () => {
        let initialParticipants = this.initialParticipants();
        this.loadParticipantsList(initialParticipants);
      }
    );
  };

  hideSelectedParticipant = () => {
    const {
      selectAllPageData,
      statusValue,
      languageIds,
      selectedParticipant,
      createdByUserId,
    } = this.state;
    const { id, t } = this.props;
    const selectedParticipantMapData =
      selectedParticipant &&
      selectedParticipant.map((value) => value.participantId);

    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const data = {
      allRecords: selectAllPageData,
      tenantId: userInfo.tenantId,
      projectId: parseInt(id),
      participantIds: selectedParticipantMapData,
      participantLanguageIds: languageIds,
      statusFilters: statusValue,
      createdBy: createdByUserId,
    };
    selectedParticipantHide(data)
      .then((res) => {
        this.setState(
          {
            participants: [],
            selectedParticipant: [],
            selectedNpParticipant: [],
            rows: [],
            totalResults: 0,
            currentPage: 0,
            loading: true,
          },
          () => {
            let initialParticipants = this.initialParticipants();
            this.loadParticipantsList(initialParticipants);
          }
        );
      })
      .catch((err) => {
        this.setState({
          alertMsg: err.response.data.message
            ? err.response.data.message
            : t("Something went wrong"),
          variant: "danger",
          alertStatus: true,
          loading: false,
        });
        console.log("Err", err);
      });
  };

  handleName = (configjson, t) => {
    if (configjson != "") {
      if (configjson.data.participant_list.name != "") {
        return configjson.data.participant_list.name;
      } else {
        return `${t("Name")}`;
      }
    } else {
      return `${t("Name")}`;
    }
  };

  formatterParticipant = (params) => {
    return (
      <div className="participant-formatter-container">
        {params.row.name.trim() && (
          <div className="participant-name">{params.row.name}</div>
        )}
        {params.row.phoneno && (
          <div className="participant-phone">
            <img src={phone_icon} />
            {params.row.phoneno}
          </div>
        )}
        {params.row.email && (
          <CustomTooltip placement="top" title={params.row.email}>
            <div className="participant-email">
              <img src={small_email_icon} />
              <span data-tip>{params.row.email}</span>
            </div>
          </CustomTooltip>
        )}
      </div>
    );
  };

  shouldRenderIcon = (status, isIconEnable) => {
    const validStatuses = [
      "INVITATION_SENT",
      "INVITATION_RESENT",
      "NEW_PARTICIPANT",
      "ATTEMPTED_INCOMPLETE",
      "INVITATION_SCHEDULED",
      "ATTEMPTED_PARTIAL",
      "UNDELIVERED",
    ];

    return validStatuses.includes(status) && isIconEnable;
  };

  shouldRenderNPIcon = (status, isIconEnable) => {
    const validStatuses = [
      "NON_RESPONSIVE_PARTICIPANT",
      "INVITATION_SENT",
      "INVITATION_RESENT",
      "NEW_PARTICIPANT",
      "INVITATION_SCHEDULED",
    ];

    return validStatuses.includes(status) && isIconEnable;
  };

  handleMarkNP = (row) => {
    const participantAction =
      row.status === "NON_RESPONSIVE_PARTICIPANT" ? "REVERSE_NP" : "NP";
    this.setState({
      showMarkNpModal: true,
      npAction: participantAction,
      selectedNpParticipant: [row],
    });
  };

  closeMarkNpModal = () => {
    this.setState({
      showMarkNpModal: false,
      selectAllCheckbox: false,
      selectedNpParticipant: [],
      selectedParticipant: [],
    });
  };

  handleSubmitNP = () => {
    const invalidStatuses = [
      "RESULT_PUBLISHED",
      "INTERVIEWED",
      "ARCHIVED",
      "ATTEMPTED_INCOMPLETE",
      "ATTEMPTED_PARTIAL",
      "UNDELIVERED",
    ];
    const validForNP = this.state.selectedNpParticipant.filter(
      (participant) => !invalidStatuses.includes(participant.status)
    );
    const selectedNP = validForNP
      .filter(
        (participant) => participant.status === "NON_RESPONSIVE_PARTICIPANT"
      )
      .map((participant) => participant.participantId);
    const selectedNotNP = validForNP
      .filter(
        (participant) => participant.status !== "NON_RESPONSIVE_PARTICIPANT"
      )
      .map((participant) => participant.participantId);

    const participantId =
      this.state.npAction === "NP" ? selectedNotNP : selectedNP;

    this.setState({
      isNpLoading: true,
    });
    const payload = {
      action: this.state.npAction,
      projectId: this.props.id,
      participantIds: participantId,
    };
    selectedNPParticipant(payload)
      .then((response) => {
        this.setState(
          {
            selectedNpParticipant: [],
            selectedParticipant: [],
            selectAllCheckbox: false,
            alertMsg: response.data,
            alertStatus: true,
            variant: "success",
            isNpLoading: false,
            showMarkNpModal: false,
          },
          () => {
            let initialParticipants = this.initialParticipants();
            this.loadParticipantsList(initialParticipants);
          }
        );
      })
      .catch((error) => {
        let errMsg = error.response?.data.message;
        this.setState({
          selectedNpParticipant: [],
          selectedParticipant: [],
          selectAllCheckbox: false,
          alertMsg: errMsg,
          alertStatus: true,
          variant: "danger",
          isNpLoading: false,
          showMarkNpModal: false,
        });
      });
  };

  actionsFormatter = (params) => {
    const isEmailCommunicationEnable =
      this.state.projectDetail.enableEmailCommunication;
    //enable_refresh_accesscode
    const isRefreshAccessCodeEnable =
      this.state.projectDetail.enable_refresh_accesscode;
    const isSMSCommunicationEnable =
      this.state.projectDetail.enableSMSCommunication;

    const isReverseParticipantEnable =
      this.state.projectDetail.reverse_np_configuration;

    const participantId = params.row.participantId;

    const isRefreshIconVisible = this.shouldRenderIcon(
      params.row.status,
      isRefreshAccessCodeEnable
    );
    const isEmailIconVisible = this.shouldRenderIcon(
      params.row.status,
      isEmailCommunicationEnable
    );
    const isSMSIconVisible = this.shouldRenderIcon(
      params.row.status,
      isSMSCommunicationEnable
    );

    const isNpIconVisible = this.shouldRenderNPIcon(
      params.row.status,
      isReverseParticipantEnable
    );

    let { t } = this.props;

    return (
      <>
        {isRefreshIconVisible && (
          <CustomTooltip placement="left" title="Refresh Access code">
            <div className="hoverbox action-tab-img">
              <span
                onClick={() => this.rowAcessCode(participantId)}
                style={{
                  cursor: "pointer",
                }}
              >
                <img src={refresh_icon} />
              </span>
            </div>
          </CustomTooltip>
        )}

        {isEmailIconVisible && (
          <CustomTooltip placement="left" title="Send Email">
            <div className="hoverbox action-tab-img">
              <span
                onClick={() => this.rowEmailData(participantId)}
                style={{ cursor: "pointer" }}
              >
                <img src={email_icon} />
              </span>
            </div>
          </CustomTooltip>
        )}

        {isSMSIconVisible && (
          <CustomTooltip placement="left" title="Send SMS">
            <div className="hoverbox action-tab-img">
              <span
                onClick={() =>
                  this.props.rowSMSData(
                    participantId,
                    this.loadParticipantsListCallback
                  )
                }
                style={{
                  cursor: "pointer",
                }}
              >
                <img src={message_icon_new} />
              </span>
            </div>
          </CustomTooltip>
        )}
        {isNpIconVisible && (
          <CustomTooltip
            placement="left"
            title={
              params.row.status === "NON_RESPONSIVE_PARTICIPANT"
                ? t("MARK_REVERSE_NP")
                : t("MARK_NP")
            }
          >
            <div className="hoverbox action-tab-img">
              <span
                className={
                  params.row.status === "NON_RESPONSIVE_PARTICIPANT"
                    ? "mark_np"
                    : "mark_reverse_np"
                }
                onClick={() => this.handleMarkNP(params.row)}
              >
                <img
                  src={
                    params.row.status === "NON_RESPONSIVE_PARTICIPANT"
                      ? marked_np
                      : marked_not_np
                  }
                />
              </span>
            </div>
          </CustomTooltip>
        )}
      </>
    );
  };

  getStatusIconAndTooltip = (
    isPending,
    isSent,
    successIcon,
    failIcon,
    scheduledIcon,
    themeColor,
    tooltipId,
    tooltipText
  ) => {
    const { t } = this.props;
    const statusIcon =
      isSent !== null
        ? isSent
          ? successIcon
          : failIcon
        : isPending
        ? scheduledIcon
        : "";
    return (
      <>
        <CustomTooltip placement="top" title={tooltipText}>
          <span
            data-tip
            data-for={tooltipId}
            className="image_icon_notification"
          >
            <img src={statusIcon} alt={tooltipText} />
          </span>
        </CustomTooltip>
      </>
    );
  };

  statusFormatter = (params) => {
    const {
      email_sent,
      status,
      sms_sent,
      date,
      uuid,
      email_pending,
      sms_pending,
    } = params.row;
    const { themeColor } = this.props;
    const emailSuccessIcon =
      themeColor === "light" ? email_success : mail_Sent_Dark;
    const emailFailIcon =
      themeColor === "light" ? email_failure : mail_Fail_Dark;
    const emailScheduledIcon =
      themeColor === "light" ? email_scheduled_dark : email_scheduled_white;
    const emailTooltipId = `mail_${
      email_sent !== null ? (email_sent ? "sent" : "fail") : "scheduled"
    }_${email_sent !== null ? uuid : ""}`;
    const emailTooltipText =
      email_sent !== null
        ? email_sent
          ? "MAIL_SENT"
          : "MAIL_FAILED"
        : email_pending
        ? "MAIL_SCHEDULED"
        : "";
    const smsSuccessIcon = themeColor === "light" ? sms_success : sms_Sent_Dark;
    const smsFailIcon = themeColor === "light" ? sms_failure : sms_Fail_Dark;
    const smsScheduledIcon =
      themeColor === "light" ? sms_scheduled_dark : sms_scheduled_white;
    const smsTooltipId = `sms_${
      sms_sent !== null ? (sms_sent ? "success" : "fail") : "scheduled"
    }_${sms_sent !== null ? uuid : ""}`;
    const smsTooltipText =
      sms_sent !== null
        ? sms_sent
          ? "SMS_SENT"
          : "SMS_FAILED"
        : sms_pending
        ? "SMS_SCHEDULED"
        : "";
    return (
      <div className="participant-formatter-container">
        {status && (
          <div className="participant_status">
            <span className="text-capitalize">
              {status === "NON_RESPONSIVE_PARTICIPANT"
                ? "Non-Responsive Participant"
                : status?.toLowerCase().replaceAll("_", " ")}
            </span>
          </div>
        )}
        {date && (
          <>
            {this.state.canAccountReadAuditTrail ||
            this.state.isSystemTenant ? (
              <a
                href="#"
                onClick={(e) => this.openParticipantDetail(e, params.row)}
              >
                <span>
                  {date ? moment(new Date(date)).format(Date_Format) : date}
                </span>
              </a>
            ) : (
              <span className="participant-irn">
                {date ? moment(new Date(date)).format(Date_Format) : date}
              </span>
            )}
          </>
        )}
        <div className="email_sms_sent_icon">
          <div>
            {this.getStatusIconAndTooltip(
              email_pending,
              email_sent,
              emailSuccessIcon,
              emailFailIcon,
              emailScheduledIcon,
              themeColor,
              emailTooltipId,
              emailTooltipText
            )}
          </div>
          <div>
            {this.getStatusIconAndTooltip(
              sms_pending,
              sms_sent,
              smsSuccessIcon,
              smsFailIcon,
              smsScheduledIcon,
              themeColor,
              smsTooltipId,
              smsTooltipText
            )}
          </div>
        </div>
      </div>
    );
  };

  accessCodeFormatter = (params) => {
    return (
      <>
        {params.row.accesscode && (
          <div className="participant_access_code">
            <span>{params.row.accesscode}</span>
          </div>
        )}
        {/* {row.accessCodeUpdateTs && (
          <div className="participant_update_ts">
            <span>
              {moment(new Date(row.accessCodeUpdateTs)).format(Date_Format)}
            </span>
          </div>
        )} */}
      </>
    );
  };

  createByCodeFormatter = (params) => {
    return (
      <div className="participant-formatter-container">
        {params.row.createdBy && (
          <div className="participant_created_by">
            <span>{params.row.createdBy}</span>
          </div>
        )}
        {params.row.createTs && (
          <div className="participant_update_ts">
            <span>
              {moment(new Date(params.row.createTs)).format(Date_Format)}
            </span>
          </div>
        )}
      </div>
    );
  };

  /**
   * This function is used to download the file
   */

  handleisFileDuplicate = (e) => {
    e.preventDefault();
    const { error_file_name } = this.state.modalData;
    const data = {
      error_file_name: error_file_name,
      error_type: "participant",
    };

    getResultErrorFileDownload(data)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: res.headers["mime-type"],
        });
        FileSaver.saveAs(
          blob,
          filenameFromResponseHeader(res.headers["content-disposition"])
        );
        this.setState({
          errorModalShow: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleSelectedParticipantNP = (type, isDisabled = false) => {
    if (!isDisabled) {
      this.setState({
        npAction: type || "REVERSE_NP",
        showMarkNpModal: true,
        selectedNpParticipant: this.state.selectedNpParticipant,
      });
    }
  };

  /*
  This function is used for Update Participant Status when user will click on the NP action
  @param obj = API request payload
  */
  handleUpdateParticipantStatus = (obj) => {
    updateNPParticipantStatus(obj)
      .then((res) => {
        let currentPage = this.state.currentPage;
        this.setState(
          {
            participants: [],
            rows: [],
            totalResults: 0,
            currentPage: 0,
            loading: true,
            selectedParticipant: [],
            selectedNpParticipant: [],
            alertMsg: res.data,
            alertStatus: true,
            variant: "success",
            ["page" + currentPage]: [],
          },
          () => {
            let initialParticipants = this.initialParticipants();
            this.loadParticipantsList(initialParticipants);
          }
        );
      })
      .catch((err) => {
        if (err.response.status === 409) {
          this.setState({
            isSendEmailConfirmError: true,
            msgtext: err.response.data.message,
          });
        } else {
          this.setState({
            alertStatus: true,
            alertMsg: err.response.data.message,
            variant: "danger",
          });
        }
      });
  };

  render() {
    const { t, statedata } = this.props;
    const { isinternal } = statedata;
    const { showParticipantDetail, ParticipantData } = this.state;
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let conJson = this.state.projectDetail.configurationJSON;
    let configjson = "";
    if (conJson) {
      let finaljsonData = JSON.parse(conJson);
      if (Array.isArray(finaljsonData)) {
        configjson = finaljsonData.find((row) =>
          row.language ? row.language === i18n.language : ""
        );
      }
    }

    let rows = this.getParticipantRows(this.state.rows);
    let tmdbData = {
      columns: [
        {
          renderHeader: () => (
            <HandleParticipantRowSelection
              checked={this.state.selectAllCheckbox}
              onChange={this.selectAll}
              onSelect={(e) => this.selectDropdown(e)}
              participant={this.state.rows}
              selectedParticipant={this.state.selectedParticipant}
            />
          ),
          field: "check",
          width: 60,
          sortable: false,
          renderCell: (params) => {
            const participant = params.value;
            return (
              <ParticipantIrnCheckBox
                participant={participant}
                //checked={this.state[`IRN_${participant.participantId}`]}
                selectedParticipant={this.state.selectedParticipant}
                checked={participant.participantId}
                onChange={(event) => this.selectParticipant(participant, event)}
              />
            );
          },
        },
        {
          headerName: (
            <React.Fragment>
              <span>Irn</span>
            </React.Fragment>
          ),
          field: "irnno",
          flex: 1,
          sortable: false,
          renderCell: (params) => {
            const participant = params.value;
            let editLink = this.state.isSystemTenant ? (
              <span className="participant-irn">
                {participant.interviewRefNum}
              </span>
            ) : (
              <Link
                to={`${this.props.match.url}/edit/${participant.participantId}`}
              >
                {participant.interviewRefNum}
              </Link>
            );
            return <label className="form-check-label">{editLink}</label>;
          },
        },
        ...(this.state.isSystemTenant !== null && !this.state.isSystemTenant
          ? [
              {
                headerName: `${t("Participant")}`,
                field: "participant",
                width: 200,
                renderCell: this.formatterParticipant,
                sortable: false,
              },
            ]
          : []),
        {
          headerName: `${t("Language")}`,
          field: "participantLanguage",
          flex: 1,
          sortable: false,
        },
        {
          headerName: `${t("Created By")}`,
          field: "createTs",
          renderCell: this.createByCodeFormatter,
          flex: 1,
          sortable: false,
          //cellClassName: "participant-cell",
        },
        {
          headerName: `${t("Participant status")}`,
          field: "status",
          renderCell: this.statusFormatter,
          flex: 1,
          sortable: false,
          //cellClassName: "participant-cell",
        },
        // {
        //   headerName: `${t("Status Date")}`,
        //   field: "date",
        //   formatter: this.dateFormatter,
        //   headerStyle: () => {
        //     return { width: "190px" };
        //   },
        // },
        {
          headerName: `${t("Access Code")}`,
          field: "accesscode",
          renderCell: this.accessCodeFormatter,
          flex: 1,
          sortable: false,
        },
        {
          headerName: `${t("Action")}`,
          field: "action",
          renderCell: this.actionsFormatter,
          sortable: false,
          width: 250,
          headerAlign: "center",
          align: "center",
        },
      ],
      rows: rows,
    };

    let canReadTestParticipant =
      isinternal.indexOf("tenant.project.participant.test.read") >= 0
        ? true
        : false;
    const {
      loadingFileUpload,
      exportParticipantFileDownload,
      exportError,
      exportErrorMessage,
    } = this.state;

    return (
      <React.Fragment>
        <div className="w-1200">
          {loadingFileUpload ? (
            <FullScreenLoader message={"Uploading Files"} />
          ) : null}
          {exportParticipantFileDownload ? (
            <FullScreenLoader message={"Please Wait"} />
          ) : null}
          <Grid container className="participants-page">
            <Grid item size={12}>
              <div className="d-flex particiant-controls">
                <div>
                  <Card>
                    <CardHeader title={t("Find Participant")}></CardHeader>
                    <CardContent>
                      <div className="position-relative">
                        <input
                          className="form-control"
                          type="text"
                          placeholder={t("Search")}
                          onChange={this.findParticipantByText}
                        />
                      </div>
                    </CardContent>
                  </Card>
                </div>

                <div>
                  <Card className="zindex99">
                    <CardHeader title={t("Data Filter")}></CardHeader>
                    <CardContent className="resultsearchindex">
                      <Grid container>
                        <Select
                          // menuIsOpen={true}
                          name="status"
                          className="col-lg-4 select-style custom-class particiant-status-dropdown"
                          classNamePrefix="select"
                          options={this.state.statusA}
                          isClearable={true}
                          placeholder={t("Status")}
                          onChange={this.selectStatus}
                        />
                        {this.state?.languageOption.length > 0 && (
                          <MultiSelectDrpDownWithAll
                            options={this.state?.languageOption}
                            placeholderButtonLabel={t("Language")}
                            setSelectedOption={(selectedOption) => {
                              this.findParticipantByLanguage(selectedOption);
                            }}
                            defaultSelectAll={true}
                          />
                        )}
                        <Select
                          name="create_by"
                          className="col-lg-4 select-style"
                          classNamePrefix="select"
                          options={this.state.createdByOption}
                          isClearable={true}
                          placeholder={t("Created By")}
                          onChange={this.onHandleChangeUser}
                        />
                      </Grid>
                    </CardContent>
                  </Card>
                </div>

                <div>
                  <Card className="card zindex99">
                    <CardHeader title={t("Data Options")}></CardHeader>
                    <CardContent className="resultsearchindex">
                      <div className="d-flex tables-control-menu">
                        <div className="position-relative">
                          <div
                            className="data-option-container"
                            id="handleDataOption"
                          >
                            <div className="data-control">
                              <div className="d-flex align-items-center download-btn">
                                <div>
                                  <img
                                    src={
                                      this.props.themeColor === "light"
                                        ? data_options
                                        : data_options_dark
                                    }
                                  />
                                </div>
                                <span className="text-uppercase">
                                  {t("Data Options")}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <nav className="data-menu nav">
                                <div className="nav-item">
                                  <div className="d-flex align-items-center download-btn data-item">
                                    <div>
                                      <img
                                        src={
                                          this.props.themeColor === "light"
                                            ? data_options
                                            : data_options_dark
                                        }
                                      />
                                    </div>
                                    <span className="text-uppercase">
                                      {t("Data Options")}
                                    </span>
                                  </div>
                                </div>
                                <div className="nav-item">
                                  <div
                                    className={`d-flex align-items-center export-btn data-item ${
                                      this.state.selectedParticipant.length > 0
                                        ? "event-not-disabled"
                                        : "event-disabled"
                                    }`}
                                    onClick={
                                      this.state.selectedParticipant.length > 0
                                        ? (e) => {
                                            this.verifyInvitation(e, "email");
                                          }
                                        : () => {}
                                    }
                                  >
                                    <div className="disabled-opacity">
                                      <img
                                        className="fa-rotate-90"
                                        src={
                                          this.props.themeColor === "light"
                                            ? upload_icon
                                            : upload_iconw
                                        }
                                      />
                                    </div>
                                    <span className="text-uppercase">
                                      {t("Send Email")}
                                    </span>
                                  </div>
                                </div>
                                <div className="nav-item">
                                  <div
                                    className={`d-flex align-items-center export-btn data-item ${
                                      this.state.selectedParticipant.length > 0
                                        ? "event-not-disabled"
                                        : "event-disabled"
                                    }`}
                                    onClick={
                                      this.state.selectedParticipant.length > 0
                                        ? (e) => {
                                            this.verifyInvitation(e, "sms");
                                          }
                                        : () => {}
                                    }
                                  >
                                    <div className="disabled-opacity">
                                      <img
                                        className="fa-rotate-90"
                                        src={
                                          this.props.themeColor === "light"
                                            ? upload_icon
                                            : upload_iconw
                                        }
                                      />
                                    </div>
                                    <span className="text-uppercase">
                                      {t("Send SMS")}
                                    </span>
                                  </div>
                                </div>
                                <div className="nav-item">
                                  <div
                                    className="d-flex align-items-center download-btn data-item"
                                    onClick={this.downloadTemplate}
                                  >
                                    <div>
                                      <img
                                        src={
                                          this.props.themeColor === "light"
                                            ? download_icon
                                            : download_iconw
                                        }
                                      />
                                    </div>
                                    <span className="text-uppercase">
                                      {t("Download Template")}
                                    </span>
                                  </div>
                                </div>
                                <div className="nav-item">
                                  <div className="position-relative upload-btn data-item">
                                    <input
                                      type="file"
                                      name="file"
                                      className="upload-file-input"
                                      accept=".xlsx, .xls"
                                      ref={this.fileInput}
                                      onChange={this.selectUploadFile}
                                    />
                                    <div className="d-flex align-items-center ">
                                      <div>
                                        <img
                                          src={
                                            this.props.themeColor === "light"
                                              ? upload_icon
                                              : upload_iconw
                                          }
                                        />
                                      </div>
                                      <span className="text-uppercase">
                                        {t("Upload Data")}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="nav-item">
                                  <div
                                    className={`d-flex align-items-center export-btn data-item ${
                                      this.state.selectedParticipant.length > 0
                                        ? "event-not-disabled"
                                        : "event-disabled"
                                    }`}
                                    onClick={
                                      this.state.selectedParticipant.length > 0
                                        ? this.exportParticipant
                                        : () => {}
                                    }
                                  >
                                    <div className="disabled-opacity">
                                      <img
                                        src={
                                          this.props.themeColor === "light"
                                            ? export_icon
                                            : export_iconw
                                        }
                                      />
                                    </div>
                                    <span className="text-uppercase">
                                      {t("Export Data")}
                                    </span>
                                  </div>
                                </div>
                                {isinternal.length > 0 &&
                                isinternal.indexOf(
                                  "tenant.project.participants.delete"
                                ) >= 0 ? (
                                  <div className="nav-item">
                                    <div
                                      className={`d-flex align-items-center export-btn data-item ${
                                        this.state.selectedParticipant.length >
                                        0
                                          ? "event-not-disabled"
                                          : "event-disabled"
                                      }`}
                                      onClick={
                                        this.state.selectedParticipant.length >
                                        0
                                          ? () =>
                                              this.setState({ isConfirm: true })
                                          : () => {}
                                      }
                                    >
                                      <div className="disabled-opacity">
                                        <img
                                          src={
                                            this.props.themeColor === "light"
                                              ? delete_data
                                              : delete_dataw
                                          }
                                        />
                                      </div>
                                      <span className="text-uppercase">
                                        {t("Delete")}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {canReadTestParticipant && (
                                  <div className="nav-item">
                                    {this.state.testParticipant ? (
                                      <div
                                        className="d-flex align-items-center export-btn data-item"
                                        onClick={this.hideTestParticipant}
                                      >
                                        <div>
                                          <img
                                            src={
                                              this.props.themeColor === "light"
                                                ? hide_icon
                                                : hide_iconw
                                            }
                                          />
                                        </div>
                                        <span className="text-uppercase">
                                          {t("Hide Test Participants")}
                                        </span>
                                      </div>
                                    ) : (
                                      <div
                                        className="d-flex align-items-center export-btn data-item"
                                        onClick={this.showTestParticipant}
                                      >
                                        <div>
                                          <img
                                            src={
                                              this.props.themeColor === "light"
                                                ? view_icon
                                                : view_iconw
                                            }
                                          />
                                        </div>
                                        <span className="text-uppercase">
                                          {t("Show Only Test Participants")}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                )}

                                {this.state.isSystemTenant && (
                                  <div className="nav-item">
                                    <div
                                      className={`d-flex align-items-center export-btn data-item ${
                                        this.state.selectedParticipant.length >
                                          0 && !this.state.testParticipant
                                          ? "event-not-disabled"
                                          : "event-disabled"
                                      }`}
                                      onClick={
                                        this.state.selectedParticipant.length >
                                          0 && !this.state.testParticipant
                                          ? this.hideSelectedParticipant
                                          : () => {}
                                      }
                                    >
                                      <div className="disabled-opacity">
                                        <img
                                          src={
                                            this.props.themeColor === "light"
                                              ? hide_icon
                                              : hide_iconw
                                          }
                                        />
                                      </div>
                                      <span className="text-uppercase">
                                        {t("Hide Selected Participants")}
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {this.state.projectDetail
                                  .reverse_np_configuration && (
                                  <div className="nav-item">
                                    <MarkNpParticipant
                                      selectedParticipant={
                                        this.state.selectedParticipant
                                      }
                                      selectedNPParticipant={
                                        this.state.selectedNpParticipant
                                      }
                                      handleSelectedParticipantNP={
                                        this.handleSelectedParticipantNP
                                      }
                                      selectAllPageData={
                                        this.state.selectAllPageData
                                      }
                                    />
                                  </div>
                                )}
                              </nav>
                            </div>
                          </div>
                        </div>
                        <div className="position-relative">
                          {isinternal.length > 0 &&
                          isinternal.indexOf(
                            "tenant.project.participants.create"
                          ) >= 0 ? (
                            <div className="">
                              <Link
                                to={`${this.props.match.url}/add`}
                                className="add-participant-link"
                              >
                                <button className="btn btn-success btn-rounded btn-sm">
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                  ></FontAwesomeIcon>
                                </button>
                                <span className="add-participant-text">
                                  {t("Add Participant")}
                                </span>
                              </Link>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </CardContent>
                  </Card>

                  <ModalPopUp
                    close={this.handleModalClose}
                    title={t("Upload Data")}
                    show={this.state.uploafFileModalShow}
                  >
                    {this.state.configuredMsg?.nonConfigureEmailLanguages && (
                      <div className="col-12 alert alert-danger">
                        {this.state.configuredMsg?.nonConfigureEmailLanguages}
                      </div>
                    )}

                    {this.state.configuredMsg?.nonConfigureEmailLanguages && (
                      <div className="col-12 alert alert-danger">
                        {this.state.configuredMsg?.nonConfigureSMSLanguages}
                      </div>
                    )}
                    <p>You have chosen to upload new participant data.</p>
                    <p>
                      "Replace all" will replace all records if none of the
                      participants have taken an interview. If any of the
                      participants have taken an interview then the file upload
                      will be rejected.
                    </p>
                    <p>
                      "Append" will add new records from the file. None of the
                      existing records will change.
                    </p>
                    <p>
                      Would you like to continue to replace all records or just
                      append new records?
                    </p>
                    <div className="d-flex justify-content-around mt-3">
                      <button
                        className="btn btn-danger"
                        onClick={() => this.uploadFile(true)}
                      >
                        Replace All
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => this.uploadFile(false)}
                      >
                        Append
                      </button>
                    </div>
                  </ModalPopUp>
                  {this.state.catchModalShow ? (
                    <ModalPopUp
                      close={this.handleModalClose}
                      title={"Errors Found!"}
                      show={this.state.catchModalShow}
                    >
                      <div className="d-flex">
                        <span>{this.state.errorMsg}</span>
                      </div>
                    </ModalPopUp>
                  ) : (
                    ""
                  )}
                  {this.state.errorModalShow ? (
                    <ModalPopUp
                      close={this.handleModalClose}
                      title={
                        this.state.modalData?.uploadStatus
                          ? "Success!"
                          : "Errors Found!"
                      }
                      show={this.state.errorModalShow}
                    >
                      <div className="d-flex justify-content-between mb-3">
                        <div>
                          <b>
                            {this.state.modalData.numberOfRecords} Records
                            Processed
                          </b>
                        </div>
                        {this.state.modalData.uploadStatus ? (
                          ""
                        ) : (
                          <div>
                            <span
                              className="isFileDuplicate_link"
                              onClick={(e) => this.handleisFileDuplicate(e)}
                            >
                              Import Errors File.txt
                            </span>
                            {/* <a
                              target="_blank"
                              href={this.state.modalData.errorFileUrl}
                                className="file-link"
                                
                            >
                              Import Errors File.txt
                            </a> */}
                          </div>
                        )}
                      </div>
                      <div className="d-flex">
                        <span className="span-column">Columns</span>
                        <span>
                          -
                          {this.state.modalData.columns
                            ? "Passed"
                            : `Failed   [${this.state.modalData.columnsErrors} Errors]`}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="span-column">IRNs Unique</span>
                        <span>
                          -
                          {this.state.modalData.irnUnique
                            ? "Passed"
                            : `Failed   [${
                                this.state.modalData.irnErrors || ""
                              } Errors]`}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="span-column">Emails Valid</span>
                        <span>
                          -
                          {this.state.modalData.emailValid
                            ? "Passed"
                            : `Failed   [${
                                this.state.modalData.emailErrors || ""
                              } Errors]`}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="span-column">Phone #s</span>
                        <span>
                          -
                          {this.state.modalData.phone
                            ? "Passed"
                            : `Failed   [${
                                this.state.modalData.phoneErrors || ""
                              } Errors]`}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="span-column">Language</span>
                        <span>
                          -
                          {this.state.modalData.language
                            ? "Passed"
                            : `Failed   [${
                                this.state.modalData.languageErrors || ""
                              } Errors]`}
                        </span>
                      </div>
                      {this.state.modalData.uploadStatus ? (
                        <div className="text-center mt-3">
                          <button
                            className="btn btn-success btn-success-upload"
                            onClick={this.handleModalClose}
                          >
                            <img src={right} /> Upload Completed
                          </button>
                        </div>
                      ) : (
                        <div className="text-center mt-3">
                          <button
                            className="btn btn-danger btn-error-upload"
                            onClick={this.handleModalClose}
                          >
                            <img src={cloud_up} /> Upload Failed
                          </button>
                        </div>
                      )}
                    </ModalPopUp>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <IsConfirm
            show={this.state.isConfirm}
            t={this.props.t}
            onOkay={(e) => this.handleConfirm(e)}
          >
            {this.state.deleteParticipantMsg}
          </IsConfirm>
          <IsConfirm
            show={this.state.isSendEmailConfirm}
            onOkay={(e) =>
              this.handleSendEmailConfirm(e, this.state.inviteType)
            }
            t={this.props.t}
          >
            {this.state.showmsgInModal ? <p>{this.state.modalMsg}</p> : ""}
            {!this.state.showmsgInModal
              ? this.state.inviteType === "sms"
                ? "You have selected " +
                  (this.state.selectAllPageData
                    ? this.state.totalResults
                    : this.state.selectedParticipant.length) +
                  " participants, pressing ok will send sms invitations to these selected participants. Are you sure you want to continue?"
                : "You have selected " +
                  (this.state.selectAllPageData
                    ? this.state.totalResults
                    : this.state.selectedParticipant.length) +
                  " participants, pressing ok will send email invitations to these selected participants. Are you sure you want to continue?"
              : ""}
          </IsConfirm>
          <IsOK
            show={this.state.isSendEmailConfirmError}
            onOkay={(e) => this.handleSendEmailConfirmError(e)}
            t={this.props.t}
          >
            {this.state.msgtext}
          </IsOK>

          <div className="clearfix"></div>
          <Grid container>
            <Grid item size={12}>
              <AlertDismissible
                msg={exportErrorMessage || this.props.alertMsg}
                variantType={this.props.variant}
                show={this.props.alertStatus}
                close={this.props.handleSMSAlertClose}
              ></AlertDismissible>
            </Grid>
          </Grid>
          {/* particiant export error message  */}
          {exportError && (
            <Grid container>
              <Grid item size={12}>
                <AlertDismissible
                  msg={exportErrorMessage}
                  variantType={"danger"}
                  show={exportError}
                  close={() => {
                    this.setState({
                      exportError: false,
                      exportErrorMessage: "",
                    });
                  }}
                ></AlertDismissible>
              </Grid>
            </Grid>
          )}

          <Grid container>
            <Grid item size={12}>
              <AlertDismissible
                msg={this.state.alertMsg}
                variantType={this.state.variant}
                show={this.state.alertStatus}
                close={this.handleAlertClose}
              ></AlertDismissible>
            </Grid>

            {isinternal.length > 0 &&
            isinternal.indexOf("tenant.project.participants.read") >= 0 ? (
              <Grid item size={12}>
                <Card>
                  <div className="table_wrapper as_result_page_table">
                    <div className="participant_list_table custom_table_container app-table participant-module-table">
                      <CustomGrid
                        data={tmdbData.rows}
                        loading={this.state.loading}
                        columns={tmdbData.columns}
                        currentPage={this.state.currentPage}
                        perPageCount={this.state.perPageCount}
                        totalPages={this.state.totalResults}
                        getRowId={(row) => row.participantId}
                        isServerPagination={true}
                        rowHeight={90}
                        onChangeRecords={this.onChangeRecords}
                        selectedCount={this.state.selectedParticipant.length}
                        selectedTableLabel="Participant"
                      />
                    </div>
                  </div>
                </Card>
              </Grid>
            ) : (
              <div className="text-center w-100 text-color">
                {this.state.noPermissionmsg}
              </div>
            )}
          </Grid>
          {showParticipantDetail && (
            <ParticipantDetail
              isOpenParticipantDetail={showParticipantDetail}
              ParticipantData={ParticipantData}
              state={this.state}
              t={t}
              closeParticipantDetail={() =>
                this.setState({ showParticipantDetail: false })
              }
            />
          )}
          {this.state.showMarkNpModal && (
            <MarkNpModal
              loading={this.state.isNpLoading}
              show={this.state.showMarkNpModal}
              onClose={this.closeMarkNpModal}
              handleSubmit={this.handleSubmitNP}
              npAction={this.state.npAction}
              selectedNpParticipant={this.state.selectedNpParticipant}
            />
          )}
        </div>
        <ModalPopUp props={this.props} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    themeColor: state.theme.themeColor,
    alertStatus: state.participant.alertStatus,
    alertMsg: state.participant.alertMsg,
    variant: state.participant.variant,
  };
};
const mapDispatchToProps = {
  rowSMSData: participantActions.rowSMSData,
  handleSMSAlertClose: participantActions.handleSMSAlertClose,
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ParticipantList))
);
