import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Button, ModalPopUp } from "../../../components/Common";

function ConfirmAddTenant(props) {
  const { t } = useTranslation();
  return (
    <ModalPopUp
      show={props.show}
      size={"md"}
      onHide={() => props.close()}
      backdrop={"static"}
      keyboard={false}
      centered
      className="confirmation-modal"
      title={"Confirmation"}
    >
      <div>
        <div className="react-bootstrap-table">
          <table className="table table-hover tenant-manage-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {props.confirmModalList.map(function (value, index) {
                return (
                  <tr key={`table_confirm_modal_list_${index}`}>
                    <td>{value.name}</td>
                    <td>{value.role_name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-around mt-3 mb-3 mr-3">
          <Button
            variant="primary"
            type="submit"
            className="rounded-0 mr-4 save-btn"
            onClick={() => props.addTenant(props.fieldsValue, props.actions)}
          >
            {t("Confirm")}
          </Button>
          <Button variant="secondary" onClick={props.close}>
            Close
          </Button>
        </div>
      </div>
    </ModalPopUp>
  );
}

export default withTranslation()(ConfirmAddTenant);
