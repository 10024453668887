import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Grid2 as Grid, CardContent } from "@mui/material";
import moment from "moment";
import {
  burnSelectedAccessCodesList,
  getAccessCodesList,
} from "../../../../Services/projectServices";
import { Date_Time_Format } from "../../../../config";
import { CustomGrid } from "../../../CustomTable";
import FiltersTable from "./FiltersTable";

const BurnAccessCodeTable = (props) => {
  const { projectId } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPageCount, setPerPageCount] = useState(100);
  const [totalRows, setTotalRows] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [typeValue, setTypeValue] = useState("");
  const [showTestAccessCode, setShowTestAccessCode] = useState(false);
  const [selectedAccessCode, setSelectedAccessCode] = useState([]);
  const [accessCodeList, setAccessCodeList] = useState([]);
  const [searchEventTrigger, setSearchEventTrigger] = useState(false);

  useEffect(() => {
    loadAccessCodeList();
  }, [perPageCount, currentPage]);

  const getDateInFormat = (time, format) => {
    if (time != null) return moment(time).format(format);
    return "";
  };

  const columns = [
    {
      headerName: `${t("Irn")}`,
      field: "irnCode",
      width: 130,
      sortable: false,
    },
    {
      headerName: `${t("Access Code")}`,
      field: "accessCode",
      flex: 1,
      sortable: false,
    },
    {
      headerName: `${t("Status")}`,
      field: "status",
      flex: 1,
      sortable: false,
    },
    {
      headerName: `${t("Source File")}`,
      field: "originalFileName",
      width: 220,
      sortable: false,
    },
    {
      headerName: `${t("Type")}`,
      field: "is_backup",
      renderCell: (params) => {
        return params.value ? "Backup" : "Primary";
      },
      sortable: false,
    },
    {
      headerName: `${t("Date Added")}`,
      field: "dateAdded",
      renderCell: (params) => {
        return getDateInFormat(params.value, Date_Time_Format) || "";
      },
      width: 220,
      sortable: false,
    },
    {
      headerName: `${t("Date Used")}`,
      field: "dateUsed",
      renderCell: (params) => {
        return getDateInFormat(params.value, Date_Time_Format) || "";
      },
      width: 220,
      sortable: false,
    },
  ];

  const loadAccessCodeList = (
    inputSearchValue,
    inputStatusValue,
    inputBackupValue,
    testAccessCode
  ) => {
    setLoading(false);
    const payload = {
      projectId: parseInt(projectId),
      page: currentPage,
      pageSize: parseInt(perPageCount),
      search: inputSearchValue ? inputSearchValue : "",
      status: inputStatusValue ? inputStatusValue : "",
      isBackup: inputBackupValue
        ? inputBackupValue === "Primary"
          ? false
          : true
        : null,
      isTestAccessCode: testAccessCode,
    };

    getAccessCodesList(payload)
      .then((res) => {
        setSelectedAccessCode([]);
        setAccessCodeList(res.data.accesscode_list);
        setTotalRows(res.data.total_count);
        setLoading(true);
        setSearchEventTrigger(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(true);
        setAccessCodeList([]);
      });
  };

  const handleSearchText = (value) => {
    if (searchEventTrigger) {
      setSearchValue(value);
      loadAccessCodeList(value, statusValue, typeValue, showTestAccessCode);
    }
  };

  const handleStatusChange = (statusValue) => {
    setStatusValue(statusValue);
    loadAccessCodeList(searchValue, statusValue, typeValue, showTestAccessCode);
  };

  const handleTypeChange = (typevalue) => {
    setTypeValue(typevalue);
    loadAccessCodeList(searchValue, statusValue, typevalue, showTestAccessCode);
  };

  const handleShowTestAccessCode = (param) => {
    const testAccessCode =
      param === "SHOW" ? true : param === "HIDE" ? false : false;
    setShowTestAccessCode(testAccessCode);
    console.log(
      "🚀 ~ file: index.js:170 ~ handleShowTestAccessCode ~ param:",
      param,
      testAccessCode
    );
    loadAccessCodeList(searchValue, statusValue, typeValue, testAccessCode);
  };

  const burnSelectedCode = () => {
    let selectedCode = selectedAccessCode;
    burnSelectedAccessCodesList(selectedCode)
      .then((res) => {
        loadAccessCodeList();
      })
      .catch((err) => {});
  };

  const handleBurnSelectedCode = () => {
    if (selectedAccessCode.length > 0) {
      console.log("handleBurnSelectedCode");
      // return;
      let selectedCode = selectedAccessCode;
      burnSelectedAccessCodesList(selectedCode)
        .then((res) => {
          console.log("res", res);
          loadAccessCodeList(
            searchValue,
            statusValue,
            typeValue,
            showTestAccessCode
          );
        })
        .catch((err) => {});
    } else {
      console.log("handleBurnSelectedCode else");
    }
  };

  const onChangePerPage = (perPageCount) => {
    setPerPageCount(perPageCount.pageSize);
    setCurrentPage(perPageCount.page);
    setLoading(false);
  };

  const handleRowSelectable = (params) => {
    return params.row.status === "Available";
  };

  const onRowSelectionModelChange = (ids) => {
    setSelectedAccessCode(ids);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item size={12}>
              <h5 className="page-title access-title">{t("Access Codes")} </h5>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <FiltersTable
        handleSearchText={(value) => handleSearchText(value)}
        handleStatusChange={(value) => handleStatusChange(value)}
        handleTypeChange={(value) => handleTypeChange(value)}
        handleShowTestAccessCode={(value) => handleShowTestAccessCode(value)}
        showTestAccessCode={showTestAccessCode}
        IsDisabled={selectedAccessCode.length > 0 ? false : true}
        handleBurnSelectedCode={handleBurnSelectedCode}
      />

      <div className="table_wrapper as_result_page_table custom_table_container app-table view-accesscode-module-table">
        <CustomGrid
          loading={!loading}
          columns={columns}
          data={accessCodeList}
          currentPage={currentPage}
          perPageCount={perPageCount}
          isServerPagination={true}
          totalPages={totalRows}
          onChangeRecords={onChangePerPage}
          checkboxSelection={true}
          handleRowSelectable={handleRowSelectable}
          onRowSelectionModelChange={onRowSelectionModelChange}
          selectedCount={selectedAccessCode.length}
          selectedTableLabel="Access code"
        />
      </div>
    </>
  );
};

export default BurnAccessCodeTable;
