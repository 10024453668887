import React, { useEffect, useState } from "react";
import { Field, ErrorMessage } from "formik";
import { SelectField } from "../../CreateProject/Helper";
import { TextError } from "../../CreateProject/Helper";
import ArrowIndicator from "../../Utility/BusinessLine/ArrowIndicator";

export const BusinessLineField = ({
  optionsData,
  item,
  index,
  formik,
  pushArrayField,
  removeArrayField,
  replace,
  isBusinessLineOptional,
  isDisabled,
}) => {
  const { values, handleBlur, setFieldValue } = formik;
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const processOptionsData = (data = {}) => {
    const result = [];
    Object.keys(data).forEach((key) => {
      if (key !== "label" && key !== "value") {
        const item = data[key];
        if (item && item.label && item.value) {
          result.push({
            label: item.label,
            value: item.value,
            nextData: item,
          });
        }
      }
    });

    return result;
  };

  function getBusinessLineOptions(data) {
    const options = processOptionsData(data);
    return options;
  }

  useEffect(() => {
    const getOptions = item?.nextData
      ? getBusinessLineOptions(item?.nextData[item.currentFieldKey])
      : getBusinessLineOptions(optionsData[item.currentFieldKey]);

    setTimeout(() => {
      setDropdownOptions(getOptions);
    }, 100);
    // return () => {
    //   setDropdownOptions([]);
    // };
  }, [item]);

  return (
    <>
      {index !== 0 && <ArrowIndicator />}

      <div className="business-drop-down-container">
        <div className="form-group">
          <div key={`businessLine_dropdown_${index}`}>
            <label
              className={`${
                !isBusinessLineOptional && "required"
              } business-line-field`}
              data-permission={item.value !== ""}
            >
              {item.currentFieldlabel}
            </label>
            <Field
              name={`businessLine[${index}][value]`}
              component={SelectField}
              options={dropdownOptions}
              onBlur={handleBlur}
              values={item}
              isBusinessLine={true}
              isDisabled={isDisabled}
              handleChange={(option) => {
                const optionItem = option?.nextData?.child;
                const nextFieldLabel =
                  optionItem && option?.nextData[optionItem]["label"];
                setFieldValue(`businessLine[${index}][value]`, option.value);
                setFieldValue(`businessLine[${index}][label]`, option.label);

                const newOptionValue = {
                  label: "",
                  value: "",
                  currentFieldKey: optionItem,
                  nextData: option.nextData,
                  currentFieldlabel: nextFieldLabel,
                };

                const nextIndex = index + 1;
                const isNextExists = values.businessLine[nextIndex];

                if (isNextExists) {
                  replace(nextIndex, newOptionValue);
                  const totalItem = values.businessLine.length;
                  for (let i = nextIndex + 1; i <= totalItem; i++) {
                    removeArrayField(nextIndex + 1);
                  }
                } else {
                  if (newOptionValue.currentFieldKey !== undefined) {
                    pushArrayField({
                      ...newOptionValue,
                      nextData: option.nextData,
                    });
                  }
                }
              }}
            />
            <ErrorMessage
              name={`businessLine[${index}][value]`}
              component={TextError}
            />
          </div>
        </div>
      </div>
    </>
  );
};
