import React, { Component } from "react";
import { Route } from "react-router-dom";
import AddParticipant from "./AddParticipant";
import EditParticipant from "./EditParticipant";
import ParticipantList from "./ParticipantList";
class ProjectParticipant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participants: [],
      tableData: [],
      selectedParticipant: [],
      selectAll: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <Route
          exact
          path={this.props.match.url}
          render={(props) => (
            <ParticipantList {...this.props} id={this.props.match.params.id} />
          )}
        />
        <Route
          path={`${this.props.match.url}/add`}
          render={(props) => (
            <AddParticipant {...this.props} id={this.props.match.params.id} />
          )}
        ></Route>
        <Route
          path={`${this.props.match.url}/edit/:id`}
          render={(props) => (
            <EditParticipant {...this.props} id={this.props.match.params.id} />
          )}
        ></Route>
      </React.Fragment>
    );
  }
}

export default ProjectParticipant;
