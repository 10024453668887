import React from 'react'
const FilterLabel = (props) => {
  return (
    <span className={`result-filter-label ${props.styleName}`}>
      <span className={`result-filter-icon_${props.color}`}>
        {props.initial}
      </span>
    </span>
  );
};

export default FilterLabel;
